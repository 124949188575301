import {LoadingButton} from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ReactNode} from 'react';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
  handleClickCompleted: () => void;
  isLoading?: boolean;
  mainText?: string;
  secondaryText?: string;
  children?: ReactNode;
};

const ConfirmDialog = ({
  state,
  handleClose,
  handleClickCompleted,
  isLoading = false,
  mainText = '¿Estás seguro que deseas completar esta operación?',
  secondaryText = 'Esta acción no se puede deshacer.',
  children,
}: PropsTypes) => {
  return (
    <Dialog
      open={state}
      onClose={handleClose}
      aria-labelledby="comanda-title"
      aria-describedby="descripcion">
      <DialogTitle id="comanda-title">{mainText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="descripcion">{secondaryText}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleClickCompleted}
          autoFocus>
          De acuerdo
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
