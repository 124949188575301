import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {CardActionArea, Grid} from '@mui/material';
import {Box} from '@mui/system';
import {useEffect, useRef} from 'react';
import {selectFilterSubcategory} from '../../redux/slices/orden-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import CategoriesItem from '../categories-item/categories-item';

function CategoriesList() {
  const dispatch = useAppDispatch();
  const subcategorias = useAppSelector(
    store => store.ordenSlice.uiData.subcategorias,
  );

  const gridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gridRef.current?.addEventListener('mousewheel', e => {
      //@ts-ignore
      if (gridRef.current) gridRef.current.scrollLeft -= e.wheelDelta;
    });
  }, []);

  const selectedSubcategory = useAppSelector(
    store => store.ordenSlice.selectedSubcategory?.id_subcategoria_producto,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#3C3C3C',
      }}>
      <CardActionArea
        onClick={() => {
          if (gridRef.current) gridRef.current.scrollLeft -= 100;
        }}
        sx={{
          height: '170px',
          width: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ArrowDropDown
          sx={{
            transform: 'scale(3) rotate(90deg);',
            color: 'white',
          }}
        />
      </CardActionArea>
      <Grid
        ref={gridRef}
        container
        spacing={0.5}
        direction="column"
        sx={{
          overflowX: 'auto',
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          height: '170px',
          pt: 1.5,
        }}>
        {subcategorias.map(value => (
          <CategoriesItem
            key={value.subcategory.id_subcategoria_producto}
            categoryName={value.subcategory.nombre_subcategoria}
            selected={
              value.subcategory.id_subcategoria_producto === selectedSubcategory
                ? true
                : false
            }
            addEm={() => {
              dispatch(
                selectFilterSubcategory(
                  value.subcategory.id_subcategoria_producto,
                ),
              );
            }}
          />
        ))}
      </Grid>
      <CardActionArea
        onClick={() => {
          if (gridRef.current) gridRef.current.scrollLeft += 100;
        }}
        sx={{
          height: '170px',
          display: 'flex',
          width: '35px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ArrowDropDown
          sx={{
            transform: 'scale(3) rotate(270deg);',
            color: 'white',
          }}
        />
      </CardActionArea>
    </Box>
  );
}

export default CategoriesList;
