import React from 'react';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {Box} from '@mui/system';
class ErrorBoundary extends React.Component<
  any,
  {hasError: boolean; error: any}
> {
  constructor(props: any) {
    super(props);
    this.state = {hasError: false, error: null};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error};
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  // }

  render() {
    if (this.state.hasError) {
      return (
        <Pagetemplate isLoading={false} title="ERROR" soloLayout>
          <Box maxWidth="100vw" sx={{wordWrap: 'break-word'}}>
            <h3>Lastimosamente ha ocurrido un error</h3>
            <br />
            <p>
              Se le agradecería tomar una captura de pantalla de este error y
              reportarlo a los administradores de iFudis.
            </p>
            <br />
            <p>{(this.state.error as Error).name}</p>
            <br />
            <p>{(this.state.error as Error).message}</p>
            <br />
            <p>{(this.state.error as Error).stack}</p>
          </Box>
        </Pagetemplate>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
