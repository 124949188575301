import {MobileDatePicker, MobileDatePickerProps} from '@mui/lab';

export default function DatePicker(props: MobileDatePickerProps<Date>) {
  return <MobileDatePicker {...props} />;

  // const sm = useMediaQuery('(min-width:600px)');
  // return sm ? (
  //   <MobileDatePicker {...props} />
  // ) : (
  //   <DesktopDatePicker {...props} />
  // );
}
