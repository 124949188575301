import {Card, CardContent, Grid, Stack} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {LoadingButton} from '@mui/lab';
import {putPostSubCategory} from '../../redux/slices/menu-slice/menu.thunks';
import {ControlledNumericTextField} from '../form-input/controlled-numeric-text-field';
import {useForm} from 'react-hook-form';
import {subcategoriaSchema} from '../../redux/slices/menu-slice/menu.schemas';
import yup from '../../common/yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ControlledTextField} from '../form-input/controlled-text-field';
import ControlledSelector from '../form-input/controlled-selector';
import {subcategoriaMenu} from '../../services/dto/menu';

type propsTypes = {
  defaultValues?: subcategoriaMenu;
  handleClose: VoidFunction;
};

function NewSubcategoriaForm({defaultValues, handleClose}: propsTypes) {
  //////USA ESTE COMPONENTE PARA EL FORMULAIRO DE LA ENTIDAD RECEPTORA/CLIENTE
  const dispatch = useAppDispatch();

  const categorias = useAppSelector(store => store.menuSlice.categorias);

  const onSubmit = async (data: yup.InferType<typeof subcategoriaSchema>) => {
    await dispatch(putPostSubCategory({...data}))
      .unwrap()
      .then(x => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
  };

  const {control, handleSubmit, formState} = useForm<
    yup.InferType<typeof subcategoriaSchema>
  >({
    mode: 'onBlur',
    defaultValues: {
      categoria_producto_id:
        defaultValues?.categoria_producto.id_categoria_producto,
      descripcion_subcategoria: defaultValues?.descripcion_subcategoria,
      id_subcategoria_producto: defaultValues?.id_subcategoria_producto,
      nombre_subcategoria: defaultValues?.nombre_subcategoria,
      orden: defaultValues?.orden,
    },
    resolver: yupResolver(subcategoriaSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <ControlledTextField
                  fullWidth
                  label="Nombre"
                  controllerProps={{control, name: 'nombre_subcategoria'}}
                  required
                />
                <ControlledSelector
                  sourceData={categorias}
                  controllerProps={{control, name: 'categoria_producto_id'}}
                  title="Categoria"
                  idKey="id_categoria_producto"
                  valueKey={['nombre_categoria']}
                  fullWidth
                />
                <ControlledNumericTextField
                  fullWidth
                  maxValue={100}
                  minValue={0}
                  label="orden"
                  controllerProps={{control, name: 'orden'}}
                  required
                />
                <ControlledTextField
                  fullWidth
                  label="Descripcion"
                  controllerProps={{control, name: 'descripcion_subcategoria'}}
                  required
                />
              </Stack>
              <Grid
                item
                xs={12}
                style={{display: 'flex', justifyContent: 'center'}}
                sx={{my: 2}}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={formState.isSubmitting}
                  disabled={!!Object.keys(formState.errors).length}>
                  Guardar Cambios
                </LoadingButton>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewSubcategoriaForm;
