import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {fCurrency} from '../../../../common/formatNumber';
import DropdownMenu from '../../../../components/dropdown-menu';
import {
  selectedRestauranteSelector,
  setSelectedSucursal,
} from '../../../../redux/slices/reporte-restaurante-slice';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import {reporteVentasRestaurante} from '../../../../services/dto/reportes';
import DialogPopUp from '../../../../components/dialog-pop-up/dialog-pop-up';
import OtpInput from 'react-otp-input';
import './reportes.css';
type propType = {
  balance: reporteVentasRestaurante;
};

const RestauranteInfo = ({balance}: propType) => {
  const [otp, setOtp] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const restauranteInfo = useAppSelector(
    store => store.reporteRestaurante.reporteSucursal?.ventas,
  );
  const selectedRestaurante = useAppSelector(selectedRestauranteSelector);
  const selectedSucursal = useAppSelector(
    store => store.reporteRestaurante.selectedSucursalID,
  );
  const dispatch = useAppDispatch();
  const sucursalInfo = useAppSelector(
    store => store.reporteRestaurante.reporteSucursal,
  );
  //Abre el modal cuando se desmarca el swich
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setIsOpen(true);
    }
  };

  if (!restauranteInfo) return null;
  return (
    <Box sx={{flex: 1}}>
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        alignItems="center"
        sx={{width: '100%', display: 'flex', justifyContent: 'space-evenly'}}>
        <Box
          sx={{
            flex: 1,
            minWidth: 200,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Button
            sx={{position: 'absolute', top: 15, left: 15}}
            onClick={history.goBack}
            variant="contained"
            size="small"
            color="secondary">
            Volver
          </Button>
          <img
            src={restauranteInfo.logo}
            alt="restaurante-logo"
            style={{
              height: 150,
              objectFit: 'contain',
            }}
          />
        </Box>
        <Box
          sx={{
            height: 200,
            flex: 1,
            minWidth: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Typography
            noWrap
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              lineHeight: 0.9,
            }}>
            {restauranteInfo?.nombre}
          </Typography>
          <Box py="5px">
            <DropdownMenu
              notConditionalShow
              data={selectedRestaurante?.sucursales ?? []}
              selectedId={selectedSucursal}
              idKey="id_sucursal"
              style="button"
              valueKey={['descripcion_ubicacion']}
              fallBackMessage="Sin seccion Seleccionada"
              onSelect={sucursal => {
                dispatch(setSelectedSucursal(sucursal.id_sucursal));
              }}
            />
          </Box>

          <Typography
            noWrap
            sx={{
              fontSize: '10px',
              lineHeight: 0.9,
            }}>
            {restauranteInfo.razon_social}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              mb: 1,
            }}>
            {restauranteInfo.ruc}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: '10px',
              lineHeight: 0.9,
            }}>
            Email: {restauranteInfo.email}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              mb: 1,
            }}>
            Teléfono: {restauranteInfo.telefono}
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
            Estatus:
            <FormControlLabel
              sx={{ml: 1}}
              control={
                <Switch
                  color="success"
                  size="small"
                  defaultChecked
                  // onChange={handleSwitchChange}
                />
              }
              label="Activo"
            />
            {isOpen && ( // Abre el DialogPopUp si isOpen es true
              <DialogPopUp
                open={isOpen}
                handleClose={() => setIsOpen(false)}
                title="Ingrese el PIN para confirmar la desactivación del restaurante">
                <Grid container className="grid-otp">
                  <OtpInput
                    inputStyle="inputStyle"
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={props => <input {...props} />}
                  />
                  <Button
                    className="button-confirmar"
                    // onClick={history.goBack}
                    variant="contained"
                    size="small"
                    color="secondary">
                    Confirmar
                  </Button>
                  <Button
                    className="button-cancelar"
                    // onClick={history.goBack}
                    variant="outlined"
                    size="small"
                    color="secondary">
                    Cancelar
                  </Button>
                </Grid>
              </DialogPopUp>
            )}
          </Box>
          {/* <Box
            sx={{
              flex: 1,
              alignItems: 'center',
              display: 'flex',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
            Contrato: 2%{' '}
            <Button
              onClick={() => setIsOpen(true)}
              sx={{ml: 1}}
              size="small"
              variant="contained"
              color="secondary">
              Editar
            </Button>
          </Box> */}
          {/* <EditModal state={isOpen} handleClose={() => setIsOpen(false)} /> */}
        </Box>
      </Stack>
      {/* <Box sx={{px: 3}}>
        <Stack
          direction="column"
          sx={{backgroundColor: '#c3c3c3', minHeight: '70px', px: 2, py: 1}}>
          <Typography sx={{fontSize: '10px'}}>
            Este contrato vence el 31/12/2023.
          </Typography>
          <Typography sx={{fontSize: '10px'}}>
            Intercambio activo (publicidad ifudis en espacios del local).
          </Typography>
        </Stack>
      </Box> */}
      <Box sx={{p: 2}}>
        <Stack>
          <Typography
            noWrap
            sx={{
              fontSize: '19px',
              fontWeight: 'bold',
            }}>
            Mes anterior
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Facturación del restaurante:</TableCell>
                  <TableCell sx={{textAlign: 'right'}}>
                    {fCurrency(sucursalInfo?.ventas.monto_mes_anterior)}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>Facturación ifudis:</TableCell>
                  <TableCell>{fCurrency(0)}</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            noWrap
            sx={{
              fontSize: '19px',
              fontWeight: 'bold',
              mt: 2,
            }}>
            Mes actual
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Facturación del restaurante:</TableCell>
                  <TableCell sx={{textAlign: 'right'}}>
                    {fCurrency(sucursalInfo?.ventas.monto_mes_actual)}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>Facturación ifudis:</TableCell>
                  <TableCell>{fCurrency(0)}</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Button
            sx={{
              alignSelf: 'flex-end',
              mt: 2,
              minWidth: '125px',
              color: 'white',
              height: '20px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            variant="contained"
            color="success"
            size="small">
            Facturar
          </Button> */}
        </Stack>
      </Box>
    </Box>
  );
};

export default RestauranteInfo;
