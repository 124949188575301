import {Notifications} from '../../services/dto/orden';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {deleteNotificationMesa} from '../../redux/slices/user-slice/user.thunks';
import {useAppDispatch} from '../../redux/store';
import {useCallback, useEffect, useState} from 'react';

type PropType = {
  state: boolean;
  notifications: Notifications[];
  handleClose: () => void;
  handleNext: () => void;
};

const NotificacionesModal = ({
  state = false,
  notifications = [],
  handleClose = () => null,
  handleNext = () => null,
}: PropType) => {
  const dispatch = useAppDispatch();
  const [checks, setChecks] = useState<boolean[]>([]);
  useEffect(() => {
    setChecks([...notifications.map(() => false)]);
  }, []);

  const clear = useCallback(() => {
    const aux: boolean[] = [];
    notifications.forEach((value, index) => {
      dispatch(deleteNotificationMesa(value.id_notification));
      const idsNotification = JSON.parse(
        localStorage.getItem('browsernotificationsid') || '[]',
      ) as Array<number>;
      const index2 = idsNotification.indexOf(value.id_notification);
      if (index2 !== -1) {
        idsNotification.splice(index2, 1);
      }
      localStorage.setItem(
        'browsernotificationsid',
        JSON.stringify(idsNotification),
      );
      aux.push(true);
    });
    setChecks([...aux]);
  }, []);
  return (
    <DialogPopUp
      open={state}
      handleClose={handleClose}
      title={'Notificaciones'}>
      <Box>
        <List>
          {notifications.map(({id_notification, observaciones}, index) => (
            <div key={index}>
              {!checks[index] ? (
                <>
                  <ListItem alignItems="flex-start" key={index}>
                    <ListItemText
                      primary={<Typography>{observaciones}</Typography>}
                    />
                    <ListItemButton>
                      <CheckIcon
                        onClick={() => {
                          const aux = checks;
                          aux[index] = true;
                          setChecks([...aux]);
                          dispatch(deleteNotificationMesa(id_notification));
                          const idsNotification = JSON.parse(
                            localStorage.getItem('browsernotificationsid') ||
                              '[]',
                          ) as Array<number>;
                          const index2 =
                            idsNotification.indexOf(id_notification);
                          if (index2 !== -1) {
                            idsNotification.splice(index2, 1);
                          }
                          localStorage.setItem(
                            'browsernotificationsid',
                            JSON.stringify(idsNotification),
                          );
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider
                    variant="inset"
                    component="li"
                    key={`dividernotif${index}`}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          ))}
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Typography>Limpiar notificaciones de la mesa</Typography>
              }></ListItemText>
            <Button onClick={clear} variant="outlined" color={'secondary'}>
              Aceptar
            </Button>
          </ListItem>
        </List>
        <Grid container justifyContent={'center'} my={2}>
          <Button onClick={handleNext} variant="outlined" color="primary">
            Continuar
          </Button>
        </Grid>
      </Box>
    </DialogPopUp>
  );
};

export default NotificacionesModal;
