import {Stack, Typography} from '@mui/material';
import {fCurrency} from '../../../../common/formatNumber';
import BottomBar from '../../../../components/bottom-bar/bottom-bar';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LanguageIcon from '@mui/icons-material/Language';
import {useAppSelector} from '../../../../redux/store';
import {useMemo} from 'react';

const CustomTableFooter = () => {
  const restaurantes = useAppSelector(
    store => store.reporteRestaurante.restaurantes,
  );

  const total = useMemo(
    () => restaurantes.reduce((prev, curr) => prev + curr.monto_mes_actual, 0),
    [],
  );
  return (
    <BottomBar>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{
          backgroundColor: '#fff',
          alignItems: 'center',
          height: '100%',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          px: {
            xs: 2,
            lg: 5,
          },
        }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: {
                xs: 'none',
                md: 'block',
              },
            }}>
            Cantidad restaurantes
          </Typography>
          <StorefrontIcon />
          <Typography
            sx={{
              border: '2px solid black',
              borderRadius: '15px',
              pl: 1,
              pr: 1,
              width: 'clamp(10px, 1fr, max-content)',
              textAlign: 'right',
            }}>
            {restaurantes.length}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: {
                xs: 'none',
                md: 'block',
              },
            }}>
            Facturación global
          </Typography>
          <LanguageIcon />
          <Typography
            sx={{
              border: '2px solid black',
              borderRadius: '15px',
              pl: 1,
              pr: 1,
              width: 'clamp(10px, 1fr, max-content)',
              textAlign: 'right',
            }}>
            {fCurrency(total)}
          </Typography>
        </Stack>
        {/* <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: {
                xs: 'none',
                md: 'block',
              },
            }}>
            Ganancia
          </Typography>
          <CurrencyExchangeIcon />
          <Typography
            sx={{
              border: '2px solid black',
              borderRadius: '15px',
              pl: 1,
              pr: 1,
              width: 'clamp(10px, 1fr, max-content)',
              textAlign: 'right',
            }}>
            {fCurrency(6112)}
          </Typography>
        </Stack> */}
      </Stack>
    </BottomBar>
  );
};

export default CustomTableFooter;
