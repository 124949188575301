import {createAsyncThunk} from '@reduxjs/toolkit';
import isBoolean from '../../../common/isBoolean';
import isNullish from '../../../common/isNullish';
import {msgSuccess} from '../../../common/toast';
import {api} from '../../../services';
import {
  cargo,
  EmpleadoRestaurante,
  EmpleadoSucursal,
  permisos,
} from '../../../services/dto/empleados';
import {putPost} from '../menu-slice/menu.types';
import {thunkAPiConfig} from '../user-slice/user.types';
import {
  cargoStoreTypePrimary,
  empleadoRestauranteStoreTypePrimary,
} from './empleados.types';

export const getPermisos = createAsyncThunk<permisos[], undefined>(
  'empleados/getPermisos',
  async (data, thunkAPI) => {
    const response = await api.getPermisos();
    if (!response)
      return thunkAPI.rejectWithValue('Error al obtener los permisos');
    return response.data;
  },
);

export const getCargos = createAsyncThunk<cargo[], undefined, thunkAPiConfig>(
  'empleados/getCargos',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {
        restauranteEmpleadoSeleccionado: restauranteSeleccionado,
      },
    } = thunkAPI.getState();
    if (!restauranteSeleccionado)
      return thunkAPI.rejectWithValue('no hay restaurante seleccionado');
    const response = await api.getCargos(restauranteSeleccionado.id);
    if (!response)
      return thunkAPI.rejectWithValue('Error al obtener los cargos');
    return response.data;
  },
);

export const getEmpleados = createAsyncThunk<
  EmpleadoRestaurante[],
  undefined,
  thunkAPiConfig
>('empleados/getEmpleados', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();
  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('no hay restaurante seleccionado');
  const response = await api.getEmpleadoRestaurante(restauranteSeleccionado.id);
  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener los empleados');
  return response.data;
});

export const getEmpleadosSucursal = createAsyncThunk<
  EmpleadoSucursal[],
  number,
  thunkAPiConfig
>('empleados/getEmpleadosSucursal', async (data, thunkAPI) => {
  const response = await api.getEmpleadoSucursal(data);
  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener los empleado sucursal');
  return response.data;
});

export const getEmpleadosSucursalBySucursalID = createAsyncThunk<
  EmpleadoSucursal[],
  number,
  thunkAPiConfig
>('empleados/getEmpleadosSucursalBySucursalID', async (data, thunkAPI) => {
  const response = await api.getEmpleadoSucursalBySucursalID(data);
  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener los empleado sucursal');
  return response.data;
});

export const putPostCargo = createAsyncThunk<null, putPost, thunkAPiConfig>(
  'empleados/putPostCargo',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {
        restauranteEmpleadoSeleccionado: restauranteSeleccionado,
      },
      empleadoSlice: {
        editable: {cargo: tempCargo},
      },
    } = thunkAPI.getState();
    if (!restauranteSeleccionado)
      return thunkAPI.rejectWithValue('no hay restaurante seleccionado');
    if (data == 'crear') {
      const {id_cargo, ...rest} = tempCargo;
      if (isNullish(rest))
        return thunkAPI.rejectWithValue('algun valor es nulo');
    } else {
      const {...rest} = tempCargo;
      if (isNullish(rest))
        return thunkAPI.rejectWithValue('algun valor es nulo');
    }

    const cargo = tempCargo as cargoStoreTypePrimary;

    const bodyPut = {
      nombre_cargo: cargo.nombre_cargo,
      descripcion_cargo: cargo.descripcion_cargo,
      porcentaje_descuento: cargo.porcentaje_descuento,
      permisos: cargo.permisos,
      restaurante_id: restauranteSeleccionado.id,
    };

    if (data == 'crear') {
      const response = await api.postCargos(bodyPut);
      if (!response) return thunkAPI.rejectWithValue('Error al crear el cargo');
      thunkAPI.dispatch(getCargos());
      msgSuccess('Cargo creado con exito');
      return null;
    }
    const response = await api.putCargos(bodyPut, cargo.id_cargo);
    if (!response)
      return thunkAPI.rejectWithValue('Error al actualizar el cargo');
    thunkAPI.dispatch(getCargos());
    msgSuccess('Cargo actualizado con exito');
    return null;
  },
);

export const putPostEmpleado = createAsyncThunk<null, putPost, thunkAPiConfig>(
  'empleados/putPostEmpleado',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {
        restauranteEmpleadoSeleccionado: restauranteSeleccionado,
      },
      empleadoSlice: {
        empleadoSucursales: tempEmpleadoSucursales,
        editable: {empleado: tempEmpleado},
      },
    } = thunkAPI.getState();

    if (!restauranteSeleccionado)
      return thunkAPI.rejectWithValue('no hay restaurante seleccionado');

    if (data == 'crear') {
      const {restaurante_id, empleado_id, status, ...rest} = tempEmpleado;
      if (isNullish(rest))
        return thunkAPI.rejectWithValue('algun valor es nulo');
    } else {
      const {restaurante_id, ...rest} = tempEmpleado;
      if (isNullish(rest))
        return thunkAPI.rejectWithValue('algun valor es nulo');
    }

    const empleado = tempEmpleado as empleadoRestauranteStoreTypePrimary;

    const bodyEmpRestaurante = {
      usuario_id: empleado.usuario_id,
      restaurante_id: restauranteSeleccionado.id,
      cargo_id: empleado.cargo_id,
      //esta validacion permite que sea imposible crear un owner desactivado.
      status:
        empleado.cargo_id === 1
          ? true
          : empleado.status != null
          ? isBoolean(empleado.status)
          : false,
    };

    if (data == 'crear') {
      const response = await api.postEmpleadoRestaurante(bodyEmpRestaurante);
      if (!response)
        return thunkAPI.rejectWithValue('Error al crear el empleado');
      for (const sucursal of empleado['sucursales']) {
        const bodySucursal = {
          empleado_id: response.data.id_empleado,
          sucursal_id: sucursal,
        };
        const empleadoSucursalResponse = await api.postEmpleadoSucursal(
          bodySucursal,
        );
        if (!empleadoSucursalResponse)
          return thunkAPI.rejectWithValue('Error al crear el empleado');
      }
      thunkAPI.dispatch(getEmpleados());
      msgSuccess('Empleado creado con exito');
      return null;
    } else {
      const response = await api.putEmpleadoRestaurante(
        bodyEmpRestaurante,
        empleado.empleado_id,
      );
      if (!response)
        return thunkAPI.rejectWithValue('Error al actualizar el empleado');
      let sinCambios = [];
      let empleadoSucursales = tempEmpleadoSucursales;
      for (const sucursal of empleado['sucursales']) {
        const existe = tempEmpleadoSucursales.find(
          element => element.sucursal_id == sucursal,
        );
        if (!existe) {
          const bodySucursal = {
            empleado_id: response.data.id_empleado,
            sucursal_id: sucursal,
          };
          const empleadoSucursalResponse = await api.postEmpleadoSucursal(
            bodySucursal,
          );
          if (!empleadoSucursalResponse)
            return thunkAPI.rejectWithValue(
              'error al crear el empleado sucursal',
            );
          empleadoSucursales = empleadoSucursales.filter(
            element => element.sucursal_id != sucursal,
          );
        } else {
          sinCambios.push(sucursal);
        }
      }
      for (const sucursal of empleadoSucursales) {
        if (sinCambios.includes(sucursal.sucursal_id)) continue;
        const empleadoSucursalResponse = await api.deleteEmpleadoSucursal(
          sucursal.id_empleado_asignado_sucursal,
        );
        if (!empleadoSucursalResponse)
          return thunkAPI.rejectWithValue(
            'error al eliminar el empleado sucursal',
          );
      }
      thunkAPI.dispatch(getEmpleados());
      msgSuccess('Empleado actualizado con exito');
      return null;
    }
  },
);

export const CargarEmpleado = createAsyncThunk<
  {
    empleado: EmpleadoRestaurante;
    sucursales: EmpleadoSucursal[];
  },
  number,
  thunkAPiConfig
>('empleados/CargarEmpleado', async (data, thunkAPI) => {
  const {
    empleadoSlice: {empleadoRestaurante},
  } = thunkAPI.getState();

  const empleado = empleadoRestaurante.find(
    element => element.id_empleado == data,
  );

  if (!empleado) return thunkAPI.rejectWithValue('no se encontro el empleado');

  const response = await api.getEmpleadoSucursal(data);

  if (!response) return thunkAPI.rejectWithValue('error al cargar la sucursal');

  return {
    empleado: empleado,
    sucursales: response.data,
  };
});

export const deleteEmpleadoRestaurante = createAsyncThunk<
  EmpleadoRestaurante,
  number,
  thunkAPiConfig
>('empleados/deleteEmpleadoRestaurante', async (data, thunkAPI) => {
  const response = await api.deleteEmpleadoRestaurante(data);
  const res = await api.getEmpleadoSucursal(data);
  if (res?.data) {
    for (const sucursal of res.data) {
      const empleadoSucursalResponse = await api.deleteEmpleadoSucursal(
        sucursal.id_empleado_asignado_sucursal,
      );
      if (!empleadoSucursalResponse)
        return thunkAPI.rejectWithValue(
          'error al eliminar el empleado sucursal',
        );
    }
  }
  if (!response) return thunkAPI.rejectWithValue('error al borrar el empleado');
  msgSuccess('Empleado eliminado con exito');
  thunkAPI.dispatch(getEmpleados());
  return response.data;
});
