import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';

import {ordenSliceInitialState} from '.';
import roundMoney from '../../../common/roundMoney';
import {
  descuentoRestaurantType,
  infoCliente,
  ModificadoresMenu,
} from '../../../services/dto/orden';
import {SeccionSucursal} from '../../../services/dto/restaurante';
import {
  carritoType,
  descuentoType,
  ordenMode,
  ordenSliceType,
  updatedClienteStoreType,
} from './orden.types';

const sumaPorcentajes = (acc: number, cur: any) => {
  return acc + cur.porcentaje;
};
const seleccionarUbicacion = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<number>,
) => {
  if (!state.uiData.ubicaciones) return;
  state.orden.ubicacion = state.uiData.ubicaciones.find(
    element => element.id_tipo_ubicacion === action.payload,
  )!;
};

const seleccionarMesa = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<number | null>,
) => {
  if (!action.payload) {
    state.mesaSeleccionada = null;
    return;
  }
  const mesa = state.uiData.mesas.find(
    mesa => mesa.id_mesa_sucursal === action.payload,
  );
  if (!mesa) return;
  state.mesaSeleccionada = mesa;
};

const editOrdenProducto = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{
    type: 'agregar' | 'restar' | 'eliminar';
    id: string | number;
  }>,
) => {
  const {type, id} = action.payload;
  if (!state.orden.clienteSeleccionado) return;

  switch (type) {
    case 'agregar':
      const productoElem = state.uiData.productos.find(
        element => element.menu_restaurante_id === id,
      );

      if (!productoElem) {
        return;
      }
      let nanoId = nanoid();
      while (state.orden.carrito.some(element => element.id === nanoId)) {
        nanoId = nanoid();
      }
      const global =
        state.orden.clienteSeleccionado.descuentoType === descuentoType.global;

      let item: carritoType = {
        cantidad: 1,
        producto: productoElem,
        id: nanoId,
        clienteId: state.orden.clienteSeleccionado.id,
        modificadores: [],
      };
      if (global && state.orden.clienteSeleccionado.descuentos.length) {
        const descuento = state.orden.clienteSeleccionado.descuentos[0];
        if (
          !(
            descuento.tipo_pago_descuento_id == 2 &&
            productoElem.categoria_producto_id === 2
          )
        ) {
          item = {
            descuentoId: descuento.id_tipo_descuento_restaurante,
            descuentoCant: item.cantidad,
            ...item,
          };
        }
      }
      state.orden.carrito.push(item);
      break;
    case 'restar':
      const productIndex = state.orden.carrito.findIndex(
        element => element.producto.menu_restaurante_id == id,
      );
      if (productIndex < 0) return;
      if (state.orden.carrito[productIndex].cantidad === 1) return;
      state.orden.carrito[productIndex].cantidad--;
      break;
    case 'eliminar':
      const carritoIndex = state.orden.carrito.findIndex(
        element => element.id == id,
      );
      state.orden.carrito.splice(carritoIndex, 1);
      break;
    default:
      break;
  }
};

const setCantidadMetodoPago = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{cantidad?: number; id: number}>,
) => {
  const {cantidad, id} = action.payload;
  if (!state.orden.clienteSeleccionado) return;
  const metodoPagoOrdenIndex =
    state.orden.clienteSeleccionado.metodosPago.metodos.findIndex(
      element => element.metodoPago.id_metodo_pago_restaurante === id,
    );
  if (metodoPagoOrdenIndex < 0) return;
  state.orden.clienteSeleccionado.metodosPago.metodos[
    metodoPagoOrdenIndex
  ].cantidad = cantidad;
};
//PASE PRODUCCIÓN - DECIMALES FACTURACIÓN RÁPIDA
const calcularMetodosPagos = (state: Draft<ordenSliceType>) => {
  let cuentaTotal = parseFloat(state.cuenta.total.toFixed(2));
  if (!state.orden.clienteSeleccionado) return;
  const totalEnMetodos =
    state.orden.clienteSeleccionado.metodosPago.metodos.reduce(
      (a, b) => a + (b.cantidad ?? 0),
      0,
    );
  state.orden.clienteSeleccionado.metodosPago.zeroMetodo =
    !!state.orden.clienteSeleccionado.metodosPago.metodos.find(
      element => element.cantidad == 0,
    ) && state.orden.clienteSeleccionado.metodosPago.metodos.length > 1;

  state.orden.clienteSeleccionado.metodosPago.cantidad = totalEnMetodos;
  state.orden.clienteSeleccionado.metodosPago.restante = roundMoney(
    cuentaTotal - totalEnMetodos,
  );
  if (state.orden.clienteSeleccionado.metodosPago.metodos.length === 1) {
    if (
      state.orden.clienteSeleccionado.metodosPago.metodos[0].cantidad !=
      cuentaTotal
    )
      state.orden.clienteSeleccionado.metodosPago.metodos[0].cantidad =
        cuentaTotal;
  }
};
// FIN PASE PRODUCCIÓN - DECIMALES FACTURACIÓN RÁPIDA

const setPropinaEfectivo = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<number | null | undefined>,
) => {
  if (!state.orden.clienteSeleccionado) return;
  if (!action.payload) {
    state.orden.clienteSeleccionado.propinaEfectivo = null;
    return;
  }
  state.orden.clienteSeleccionado.propinaEfectivo = action.payload;
};

const setCurrentStep = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<number>,
) => {
  state.currentStep = action.payload;
};

const resetOrdenSlice = (state: Draft<ordenSliceType>) => {
  return ordenSliceInitialState;
};
//PASE PRODUCCIÓN - DECIMALES FACTURACIÓN RÁPIDA
const calculateCarrito = (state: Draft<ordenSliceType>) => {
  let descuento = 0;
  let subtotal = 0;
  let modificadores = 0;
  let descuentoMonto = 0;

  const calcularCarrito = (carrito: carritoType[]) => {
    carrito
      .filter(element => {
        // Filtra los elementos del carrito dependiendo de si hay un cliente seleccionado en el estado
        if (!state.orden.clienteSeleccionado) return true;
        return element.clienteId === state.orden.clienteSeleccionado.id;
      })
      .forEach(element => {
        if (!state.orden.clienteSeleccionado) return;
        // Calcula el precio de los modificadores para el elemento actual
        const precioModificador = element.modificadores
          .filter(
            e =>
              e.accion_asignada_modificador.accion.id_accion_modificador !==
                2 &&
              e.accion_asignada_modificador.accion.id_accion_modificador !== 3,
          )
          .reduce(
            (acc, curr) =>
              acc + curr.accion_asignada_modificador.modificador.precio,
            0,
          );
        // Suma el precio de los modificadores al total de modificadores
        modificadores += precioModificador;
        // Calcula el precio unitario del producto, incluyendo los modificadores
        const precioUnitario =
          element.producto.producto.precio_producto + precioModificador;
        const precio = precioUnitario * element.cantidad;
        subtotal += precio;
        // Calcula el precio del descuento, si existe, basado en el precio unitario y la cantidad con descuento
        // const precioDescuento = Number(
        //   precioUnitario * (element.descuentoCant ?? element.cantidad),
        // );

        const cantidadDecuento = element.descuentoCant ?? element.cantidad;

        if (!element.descuentoId) return;
        for (let index = 0; index < cantidadDecuento; index++) {
          const porcentajeDescuento =
            state.orden.clienteSeleccionado.descuentos.reduce((acc, curr) => {
              if (curr.tipo_pago_descuento_id === 8) {
                descuentoMonto = curr.cantidad;
                return 0;
              }
              if (
                curr.tipo_pago_descuento_id === 2 &&
                element.producto.categoria_producto_id === 2
              )
                return 0;
              return acc + curr.porcentaje;
            }, 0) / 100;

          const descuentoCalculado = parseFloat(
            (precioUnitario * porcentajeDescuento).toFixed(2),
          );

          descuento += descuentoCalculado;
        }
      });
  };
  calcularCarrito(state.orden.carrito);
  state.cuenta.descuentos = descuento;
  state.cuenta.subtotal = subtotal;
  state.cuenta.modificadores = modificadores;
  state.cuenta.descuentoMontoFijo = descuentoMonto;

  descuento = 0;
  subtotal = 0;
  modificadores = 0;
  descuentoMonto = 0;

  calcularCarrito(state.orden.carritoAPI);
  state.cuenta.subtotal += parseFloat(subtotal.toFixed(2));
  state.cuenta.modificadores += parseFloat(modificadores.toFixed(2));
  state.cuenta.descuentos += parseFloat(descuento.toFixed(2));
  state.cuenta.descuentoMontoFijo += parseFloat(descuentoMonto.toFixed(2));
};
//FINAL PASE PRODUCCIÓN - DECIMALES FACTURACIÓN RÁPIDA
const calculateImpuesto = (state: Draft<ordenSliceType>) => {
  if (!state.orden.clienteSeleccionado) return;
  let totalDescuentos =
    state.cuenta.descuentoMontoFijo + state.cuenta.descuentos;
  const subtotalConDescuentos = state.cuenta.subtotal - totalDescuentos;
  state.cuenta.impuestos = parseFloat(
    (
      (state.orden.clienteSeleccionado.impuestos.reduce(sumaPorcentajes, 0) /
        100) *
      subtotalConDescuentos
    ).toFixed(2),
  );
};
const calculatePropinas = (state: Draft<ordenSliceType>) => {
  if (!state.orden.clienteSeleccionado) return;
  state.cuenta.propinaEfectiva = state.orden.clienteSeleccionado.propinaEfectivo
    ? state.orden.clienteSeleccionado.propinaEfectivo
    : 0;

  let totalDescuentos =
    state.cuenta.descuentoMontoFijo + state.cuenta.descuentos;
  const subtotalConDescuentos = state.cuenta.subtotal - totalDescuentos;
  state.cuenta.propinas =
    (state.orden.clienteSeleccionado.propinas.reduce((acc, cur) => {
      if (cur.tipo_pago_propina_id === 2) {
        return 0;
      }
      return acc + cur.porcentaje;
    }, 0) /
      100) *
    subtotalConDescuentos;

  state.cuenta.propinasTotal =
    state.cuenta.propinaEfectiva + state.cuenta.propinas;
};

const calculateTotal = (state: Draft<ordenSliceType>) => {
  let totalDescuentos =
    state.cuenta.descuentoMontoFijo + state.cuenta.descuentos;
  const subtotalConDescuentos = state.cuenta.subtotal - totalDescuentos;
  if (state.cuenta.subtotal < subtotalConDescuentos) state.cuenta.total = 0;
  else state.cuenta.total = subtotalConDescuentos + state.cuenta.impuestos;
  if (state.cuenta.total < 0) state.cuenta.total = 0;
};

const selectFilterSubcategory = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<number>,
) => {
  state.selectedSubcategory = state.uiData.subcategorias.find(
    element => element.subcategory.id_subcategoria_producto === action.payload,
  )!.subcategory;
};

const resetOrden = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<
    {onlyOrden: boolean; maintainSelectedClient?: boolean} | undefined
  >,
) => {
  const clienteSeleccionado = state.orden.clienteSeleccionado;
  Object.assign(state.orden, ordenSliceInitialState.orden);
  if (action.payload?.maintainSelectedClient) {
    state.orden.clienteSeleccionado = clienteSeleccionado;
  }
  if (action.payload?.onlyOrden) return;
  state.mesaSeleccionada = null;
  state.lastFacturaId = null;
};

const actualizarCliente = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{data: updatedClienteStoreType; id: string | number}>,
) => {
  const index = state.orden.clientes.findIndex(
    element => element.id === action.payload.id,
  );
  if (index === -1) return;
  state.orden.clientes[index] = {
    ...state.orden.clientes[index],
    ...action.payload.data,
  };
  if (state.orden.clienteSeleccionado?.id === action.payload.id) {
    state.orden.clienteSeleccionado = state.orden.clientes[index];
  }
  state.orden.shouldUpdate = true;
};

const seleccionarCliente = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<string | number | null>,
) => {
  const cliente = state.orden.clientes.find(
    cliente => cliente.id === action.payload,
  );
  state.orden.clienteSeleccionado = cliente ?? null;
};

const agregarObservacion = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{id: string | number; observacion?: string}>,
) => {
  const carritoIndex = state.orden.carrito.findIndex(
    element => element.id == action.payload.id,
  );
  if (carritoIndex >= 0) {
    if (action.payload.observacion && action.payload.observacion.length > 0)
      state.orden.carrito[carritoIndex].observacion =
        action.payload.observacion;
    else state.orden.carrito[carritoIndex].observacion = undefined;
    return;
  }
};

const _setDescuentoPorLinea = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{
    products: carritoType[];
    productsAPI: carritoType[];
    descuentoId?: number;
    descuentos: descuentoRestaurantType[];
  }>,
) => {
  if (!state.orden.clienteSeleccionado) return;
  state.orden.shouldUpdate = true;

  if (!action.payload.descuentoId) {
    state.orden.carrito = state.orden.carrito.map(element => {
      return {...element, descuentoId: undefined, descuentoCant: undefined};
    });

    state.orden.carritoAPI = state.orden.carritoAPI.map(element => {
      return {...element, descuentoId: undefined, descuentoCant: undefined};
    });
    state.orden.clienteSeleccionado.descuentos = [];
    state.orden.clienteSeleccionado.descuentoType = descuentoType.none;
    return;
  }
  state.orden.carrito = state.orden.carrito.map(element => {
    const product = action.payload.products.find(
      product => product.id === element.id,
    );
    if (product) {
      return product;
    }
    return element;
  });
  state.orden.carritoAPI = state.orden.carritoAPI.map(element => {
    const product = action.payload.productsAPI.find(
      product => product.id === element.id,
    );
    if (product) {
      return product;
    }
    return element;
  });
  const descuento = action.payload.descuentos.find(
    element =>
      element.id_tipo_descuento_restaurante == action.payload.descuentoId,
  );
  if (descuento) state.orden.clienteSeleccionado.descuentos = [descuento];
  state.orden.clienteSeleccionado.descuentoType = descuentoType.producto;
};

const updateClienteArray = (state: Draft<ordenSliceType>) => {
  if (!state.orden.clienteSeleccionado) return;
  const index = state.orden.clientes.findIndex(
    cliente => cliente.id === state.orden.clienteSeleccionado?.id,
  );
  if (index === -1) {
    state.orden.clienteSeleccionado = null;
    return;
  }
  state.orden.clientes[index] = state.orden.clienteSeleccionado;
};

const updateCarrito = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{carrito: carritoType[]; isCarritoAPI?: boolean}>,
) => {
  if (action.payload.isCarritoAPI) {
    state.orden.carritoAPI = action.payload.carrito;
    state.orden.shouldUpdate = true;
  } else {
    state.orden.carrito = action.payload.carrito;
  }
};

const updateMetaData = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<Partial<ordenSliceType['orden']['metaData']>>,
) => {
  state.orden.metaData = {...state.orden.metaData, ...action.payload};
};

const setOrdenMode = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<ordenMode>,
) => {
  state.uiData.mesaMode = action.payload;
};

const setMesaAyB = (
  state: Draft<ordenSliceType>,
  action: {
    payload: {
      mesaID: number | null;
      which: 'A' | 'B';
    };
  },
) => {
  const {mesaID, which} = action.payload;
  if (which === 'A') {
    state.uiData.mesaA = mesaID;
    return;
  }
  state.uiData.mesaB = mesaID;
};

const updateModificadoresProducto = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<{
    id: number | string;
    data: ModificadoresMenu[];
  }>,
) => {
  const index = state.orden.carrito.findIndex(e => e.id === action.payload.id);

  if (index < 0) return;

  state.orden.carrito[index].modificadores = action.payload.data;
};

const setMesaSeccion = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<SeccionSucursal | null>,
) => {
  state.uiData.selectedMesaSeccion = action.payload;
};

const setInfoCliente = (
  state: Draft<ordenSliceType>,
  action: PayloadAction<infoCliente | null>,
) => {
  state.orden.infoCliente = action.payload;
};

export const actions = {
  resetOrdenSlice,
  setCurrentStep,
  setPropinaEfectivo,
  editOrdenProducto,
  seleccionarUbicacion,
  calcularMetodosPagos,
  setCantidadMetodoPago,
  resetOrden,
  selectFilterSubcategory,
  calculateTotal,
  actualizarCliente,
  agregarObservacion,
  _setDescuentoPorLinea,
  seleccionarMesa,
  calculateCarrito,
  calculateImpuesto,
  calculatePropinas,
  seleccionarCliente,
  updateClienteArray,
  updateCarrito,
  updateMetaData,
  setUnirMesaMode: setOrdenMode,
  setMesaAyB,
  updateModificadoresProducto,
  setMesaSeccion,
  setInfoCliente,
};
