import {TableHead} from '@mui/material';
import {memo} from 'react';
import {BottomRow, TopRow} from './table-header-rows';

const CustomTableHeader = () => {
  return (
    <TableHead>
      <TopRow />
      <BottomRow />
    </TableHead>
  );
};

export default memo(CustomTableHeader);
