import {useCallback, useMemo, useState} from 'react';

import BorderedButton from './bordered-button';
import MenuButton from './menu-button';
import {Menu, MenuItem, useMediaQuery} from '@mui/material';
import getNestedValue from '../../common/getNestedValue';

type variants = 'default' | 'button';
type propType<T> = {
  data: T[];
  selectedId?: number | null;
  onSelect: (seccionObj: T) => void;
  onClickSelected?: (seccionObj: T) => void;
  style?: variants;
  idKey: keyof T;
  valueKey: (keyof T)[];
  fallBackMessage?: string;
  notConditionalShow?: boolean;
};

function DropdownMenu<T>({
  data,
  selectedId,
  onSelect,
  onClickSelected,
  style = 'default',
  notConditionalShow = false,
  idKey,
  valueKey,
  fallBackMessage = 'Sin registro Seleccionado',
}: propType<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const show = useMediaQuery('(max-width:1100px)');

  const currentSelected = useMemo(
    () => data.find((element: any) => element[idKey] === selectedId),
    [idKey, data, selectedId],
  );

  const GetClickableComponent = useCallback(() => {
    const text = getNestedValue(currentSelected, valueKey) ?? fallBackMessage;

    switch (style) {
      case 'button':
        return (
          <MenuButton
            show={notConditionalShow || !show}
            text={text}
            handleClickListItem={handleClickListItem}
          />
        );
      default:
        return (
          <BorderedButton
            show={notConditionalShow || show}
            text={text}
            handleClickListItem={handleClickListItem}
          />
        );
    }
  }, [style, show, currentSelected, notConditionalShow]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <GetClickableComponent />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}>
        {data.map(seccion => {
          const selected =
            seccion[idKey] === getNestedValue(currentSelected, [idKey]);
          return (
            <MenuItem
              key={seccion[idKey] as any}
              selected={selected}
              onClick={() => {
                if (selected && onClickSelected) onClickSelected(seccion);
                else onSelect(seccion);
                handleClose();
              }}>
              {getNestedValue(seccion, valueKey)}
            </MenuItem>
          );
        })}
        {!data.length && <MenuItem>No hay secciones creadas.</MenuItem>}
      </Menu>
    </>
  );
}

export default DropdownMenu;
