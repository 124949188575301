import {Button, FormHelperText, Stack, TextField} from '@mui/material';
import React, {ChangeEvent} from 'react';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';

type PropsTypes = {
  state: boolean;
  handleClose: VoidFunction;
  observacion: string;
  handleOnChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  editObservation?: (observation?: string) => void;
};

const ObservacionModal = ({
  state,
  handleClose,
  observacion,
  handleOnChange,
  editObservation,
}: PropsTypes) => {
  return (
    <DialogPopUp
      open={state}
      handleClose={handleClose}
      title={`${observacion ? `Editar` : `Agregar`} observación`}>
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleClose();
        }}>
        <Stack direction="column" spacing={2}>
          <TextField
            fullWidth
            multiline
            rows={4}
            maxRows={4}
            value={observacion}
            onChange={handleOnChange}
          />
          <FormHelperText>
            {`Puedes ${
              observacion ? `editar` : `agregar`
            } un comentario sobre este plato a las comandas.`}
          </FormHelperText>
          <Button
            type="submit"
            onClick={() => {
              if (!editObservation) return;
              editObservation(observacion.length ? observacion : undefined);
            }}
            variant="contained"
            color="secondary">
            Guardar Cambios
          </Button>
        </Stack>
      </form>
    </DialogPopUp>
  );
};

export default ObservacionModal;
