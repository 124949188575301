import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {ChangeEvent, useState} from 'react';
import {borrarProducto} from '../../redux/slices/orden-slice/orden.thunks';
import {carritoType} from '../../redux/slices/orden-slice/orden.types';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import NumericTextField from '../form-input/controlled-numeric-text-field';

type PropsTypes = {
  handleClose: () => void;
};

export type deleteCarritoType = {
  deleteCant?: number;
  motivoId?: number;
  obervacionBorrar?: string;
} & carritoType;

const ERROR_TEMPLATES = [
  'Error de Mesero.',
  'Cliente cambio de opinión.',
  'Cliente no le gustó.',
  'Producto 86',
  'Otros.',
];

const BorrarProductosModal = ({handleClose}: PropsTypes) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<deleteCarritoType | null>(null);
  const carritoAPI = useAppSelector(state => state.ordenSlice.orden.carritoAPI);
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );

  const motivosCancelacion = useAppSelector(
    store => store.ordenSlice.uiData.motivosCancelacion,
  );

  const handleOnClickBorrarProductos = async (): Promise<unknown> => {
    if (!selectedProduct) return;
    setLoading(true);
    await dispatch(borrarProducto(selectedProduct));
    setLoading(false);
    setSelectedProduct(null);
    handleClose();
  };

  const handleOnChangeNumericQty = (value: number | undefined): void => {
    setSelectedProduct(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        deleteCant: value,
      };
    });
  };

  const handleOnChangeTextArea = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedProduct(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        obervacionBorrar: event.target.value,
      };
    });
  };
  const sm = useMediaQuery('(max-width:550px)');

  return (
    <DialogPopUp
      open={true}
      fullScreen={sm}
      handleClose={handleClose}
      title="Borrar Productos"
      PaperProps={{
        sx: {
          minWidth: '70%',
          height: '100%',
          position: 'relative',
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}>
            {carritoAPI
              .filter(producto => {
                if (!clienteSeleccionado) return true;
                return producto.clienteId === clienteSeleccionado.id;
              })
              .map(producto => {
                const isSelected = selectedProduct?.id === producto.id;
                const isDisabled = selectedProduct != null && !isSelected;

                const handleOnCheck = () => {
                  if (isSelected) {
                    setSelectedProduct(null);
                    return;
                  }
                  setSelectedProduct({
                    ...producto,
                    deleteCant: 1,
                  });
                };

                return (
                  <Box
                    key={producto.id}
                    component={Stack}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: '100%',
                      height: '50px',
                    }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleOnCheck}
                          disabled={isDisabled}
                          checked={isSelected}
                        />
                      }
                      label={`${producto.producto.producto.nombre_producto} x ${producto.cantidad}`}
                    />
                    {producto.cantidad > 1 && isSelected && (
                      <NumericTextField
                        maxValue={producto.cantidad}
                        minValue={1}
                        value={selectedProduct?.deleteCant}
                        onChange={handleOnChangeNumericQty}
                        sx={{width: '60px', height: '50px'}}
                      />
                    )}
                  </Box>
                );
              })}
          </FormGroup>
        </FormControl>
        <Divider sx={{my: 2}} />
        <Typography>Motivos de borrado</Typography>
        <Grid container spacing={1} gap={1} my={2}>
          {motivosCancelacion.map(motivo => {
            const isSelected =
              selectedProduct?.motivoId === motivo.id_motivo_cancelacion;
            return (
              <Grid item key={motivo.id_motivo_cancelacion}>
                <Button
                  fullWidth
                  disabled={!selectedProduct}
                  value={motivo.nombre_motivo}
                  variant="contained"
                  color={isSelected ? 'primary' : 'secondary'}
                  onClick={() => {
                    if (isSelected) {
                      setSelectedProduct(prev => {
                        if (!prev) return prev;
                        return {
                          ...prev,
                          motivoId: undefined,
                        };
                      });
                    } else
                      setSelectedProduct(prev => {
                        if (!prev) return prev;
                        return {
                          ...prev,
                          motivoId: motivo.id_motivo_cancelacion,
                        };
                      });
                  }}>
                  {motivo.nombre_motivo}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <FormControl sx={{my: 2}}>
          <TextField
            disabled={!selectedProduct}
            variant="outlined"
            value={selectedProduct?.obervacionBorrar || ''}
            multiline
            onChange={handleOnChangeTextArea}
            rows={4}
            placeholder="Comentario opcional..."
          />
        </FormControl>

        <LoadingButton
          fullWidth
          loading={loading}
          onClick={handleOnClickBorrarProductos}
          sx={{maxWidth: '200px', alignSelf: 'center'}}
          variant="contained"
          disabled={
            selectedProduct == null || selectedProduct.motivoId == undefined
          }
          color="secondary">
          Borrar Productos
        </LoadingButton>
      </Box>
    </DialogPopUp>
  );
};

export default BorrarProductosModal;
