import {useState, useEffect} from 'react';
import {Container, styled} from '@mui/material';
import Page from '../../components/Page';
import ProfileForm from './profileForm';
import {errorToast} from '../../common/toast';
import ImagesForm from './imagesForm';
import {Spinner} from '../../components/with-spinner/with-spinner.component';
import PasswordForm from './passwordForm';
import {useAppDispatch} from '../../redux/store';

import Waves from '../../components/waves/waves';
import {
  cargarUser,
  updatePasswordUser,
  updateUser,
} from '../../redux/slices/user-slice/user.thunks';
import {resetEditables} from '../../redux/slices/user-slice';

const RootStyle = styled(Page)(({theme}) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export default function Profile() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');

  const resetFields = () => {
    setPassword('');
    setVerifyPassword('');
  };

  const handleSubmit = async (event: any, message = false) => {
    if (event) {
      event.preventDefault();
    }
    setIsLoading(true);
    await dispatch(updateUser());

    setIsLoading(false);
  };
  //@ts-ignore
  const handleSubmitPassword = async event => {
    event.preventDefault();
    setIsLoading(true);

    if (password.trim() !== verifyPassword.trim()) {
      errorToast('Contraseñas no coinciden.');
      setIsLoading(false);
    } else {
      dispatch(updatePasswordUser(password)).then(() => {
        resetFields();
      });
    }
  };

  useEffect(() => {
    dispatch(cargarUser());

    return () => {
      dispatch(resetEditables('user'));
    };
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <RootStyle title="Perfil || IFUDIS">
      <Waves />
      <Container maxWidth="xl">
        <ProfileForm isLoading={isLoading} handleSubmit={handleSubmit} />
        <PasswordForm
          password={password}
          setPassword={setPassword}
          verifyPassword={verifyPassword}
          setVerifyPassword={setVerifyPassword}
          handleSubmitPassword={handleSubmitPassword}
        />
        <ImagesForm submitImages={handleSubmit} />
      </Container>
    </RootStyle>
  );
}
