import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../services';
import {
  reporteVentasRestaurante,
  reporteVentasSucursal,
} from '../../../services/dto/reportes';
import {RootState} from '../../store';

export const getReporteDeVentasRestaurante = createAsyncThunk<
  reporteVentasRestaurante[],
  undefined,
  {state: RootState}
>(
  'reporteRestaurante/getReporteDeVentasRestaurante',
  async (data, thunkAPI) => {
    const response = await api.getReporteVentasRestaurantes();

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    return response.data;
  },
);

export const getReporteSucursal = createAsyncThunk<
  reporteVentasSucursal,
  undefined,
  {state: RootState}
>('reporteRestaurante/getReporteSucursal', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_final, fecha_inicio},
    },
    reporteRestaurante: {selectedSucursalID},
  } = thunkAPI.getState();

  if (!selectedSucursalID)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');
  const response = await api.getReporteVentasSucursal(
    fecha_inicio.toISOString(),
    fecha_final.toISOString(),
    selectedSucursalID,
  );

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response.data;
});
