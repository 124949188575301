import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialStoreType} from './reportes.type';

const actualizarPlanilla = (
  state: Draft<initialStoreType>,
  action: PayloadAction<number | undefined>,
) => {
  state.reporteBalanceActual.planilla = action.payload;
};

const setDate = (
  state: Draft<initialStoreType>,
  action: PayloadAction<{type: 'inicial' | 'final'; date: Date}>,
) => {
  if (action.payload.type === 'inicial') {
    state.fecha.fecha_inicio = action.payload.date;
    return;
  }
  state.fecha.fecha_final = action.payload.date;
};

const setDateBalanceActual = (
  state: Draft<initialStoreType>,
  action: PayloadAction<{type: 'inicial' | 'final'; date: Date}>,
) => {
  if (action.payload.type === 'inicial') {
    state.reporteBalanceActual.fecha_inicio = action.payload.date;
    return;
  }
  state.reporteBalanceActual.fecha_final = action.payload.date;
};

const setInventarioFilter = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteBalanceActual.isInventarioFilterActive = action.payload;
};

const setGlobalVentasPorArticulo = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorArticulo.isGlobal = action.payload;
};

const setGlobalFacturasAnuladas = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteFactorasAnuladas.isGlobal = action.payload;
};

const setGlobalArticulosBorrados = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteArticulosBorrados.isGlobal = action.payload;
};

const setGlobalVentasMetodoDePago = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorMetodoPago.isGlobal = action.payload;
};

const setGlobalVentasSeccion = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorSeccion.isGlobal = action.payload;
};

const setGlobalVentasCategoria = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorCatergoria.isGlobal = action.payload;
};

const setGlobalVentasSubCategoria = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorSubCatergoria.isGlobal = action.payload;
};

const setGlobalVentasEmpleado = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorUsuario.isGlobal = action.payload;
};

const setGlobalVentasTiempo = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorTiempo.isGlobal = action.payload;
};

const setGlobalVentasMesa = (
  state: Draft<initialStoreType>,
  action: PayloadAction<boolean>,
) => {
  state.reporteVentasPorMesa.isGlobal = action.payload;
};

const refetch = (state: Draft<initialStoreType>) => {
  state.fecha.refetch += 1;
};

export const actions = {
  actualizarPlanilla,
  setDate,
  refetch,
  setDateBalanceActual,
  setInventarioFilter,
  setGlobalVentasPorArticulo,
  setGlobalFacturasAnuladas,
  setGlobalArticulosBorrados,
  setGlobalVentasMetodoDePago,
  setGlobalVentasCategoria,
  setGlobalVentasSubCategoria,
  setGlobalVentasEmpleado,
  setGlobalVentasTiempo,
  setGlobalVentasMesa,
  setGlobalVentasSeccion,
};
