import {useEffect} from 'react';
import BottomBar from '../../components/bottom-bar/bottom-bar';
import MesasMenu from '../../components/bottom-bar/mesasMenu';
import MesasList from '../../components/mesas-list';
import {setMesaAyB, setUnirMesaMode} from '../../redux/slices/orden-slice';
import {useAppDispatch} from '../../redux/store';

function Mesas() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setUnirMesaMode('normal'));
      dispatch(setMesaAyB({mesaID: null, which: 'A'}));
      dispatch(setMesaAyB({mesaID: null, which: 'B'}));
    };
  }, []);

  return (
    <>
      <MesasList />
      <BottomBar>
        <MesasMenu />
      </BottomBar>
    </>
  );
}

export default Mesas;
