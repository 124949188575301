import {resetCierreSLice} from '../redux/slices/cierre-slice';
import {resetComandaSlice} from '../redux/slices/comanda-slice';
import {resetComprasSlice} from '../redux/slices/compras-slice';
import {resetDiarioSlice} from '../redux/slices/diario-ventas-slice';
import {resetEmpleadoSlice} from '../redux/slices/empleados-slice';
import {resetFinanzasSlice} from '../redux/slices/finanzas-slice';
import {resetInsumoSlice} from '../redux/slices/insumo-slice';
import {resetInventarioSlice} from '../redux/slices/inventario-slice';
import {resetMarketingSlice} from '../redux/slices/marketing-slice';
import {ResetMenuSlice} from '../redux/slices/menu-slice';
import {resetOrdenSlice} from '../redux/slices/orden-slice';
import {ResetRestauranteSlice} from '../redux/slices/restaurante-slice';
import {ResetRestauranteUtilsSlice} from '../redux/slices/restaurante-utils-slice';
import {ResetUiSlice} from '../redux/slices/ui-slice';
import {ResetUserSlice} from '../redux/slices/user-slice';
import {store} from '../redux/store';

const resetReduxStore = () => {
  store.dispatch(resetEmpleadoSlice());
  store.dispatch(resetFinanzasSlice());
  store.dispatch(resetMarketingSlice());
  store.dispatch(ResetMenuSlice());
  store.dispatch(resetOrdenSlice());
  store.dispatch(ResetRestauranteSlice());
  store.dispatch(ResetRestauranteUtilsSlice());
  store.dispatch(ResetUserSlice());
  store.dispatch(ResetUiSlice());
  store.dispatch(resetComandaSlice());
  store.dispatch(resetCierreSLice());
  store.dispatch(resetDiarioSlice());
  store.dispatch(resetInsumoSlice());
  store.dispatch(resetInventarioSlice());
  store.dispatch(resetComprasSlice());
};

export default resetReduxStore;
