import {Collapse, Grid} from '@mui/material';
import {editOrdenProducto} from '../../redux/slices/orden-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {TransitionGroup} from 'react-transition-group';
import ProductoItem from '../product-item/product-item';

function ProductList() {
  const dispatch = useAppDispatch();

  const productos = useAppSelector(store => store.ordenSlice.uiData.productos);

  const selectedSubcategory = useAppSelector(
    store => store.ordenSlice.selectedSubcategory?.id_subcategoria_producto,
  );

  const productosFiltrados = [...productos]
    .filter(value => value.subcategoria_producto_id === selectedSubcategory)
    .sort((a, b) =>
      a.producto.nombre_producto.localeCompare(b.producto.nombre_producto),
    );

  return (
    <Grid
      component={TransitionGroup}
      container
      sx={{
        overflowY: 'auto',
        display: 'flex',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        pl: 1,
        pt: 0.5,
        pb: 10,
      }}>
      {productosFiltrados.map(value => {
        const product = value.producto;
        return (
          <Collapse key={value.menu_restaurante_id}>
            <ProductoItem
              productPrice={product.precio_producto}
              productName={product.nombre_producto}
              menuRestauranteId={value.menu_restaurante_id}
              addProduct={() => {
                dispatch(
                  editOrdenProducto({
                    type: 'agregar',
                    id: value.menu_restaurante_id,
                  }),
                );
              }}
            />
          </Collapse>
        );
      })}
    </Grid>
  );
}

export default ProductList;
