import {Box, Button, Stack} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {seleccionarMesa, setCurrentStep} from '../../redux/slices/orden-slice';
import {
  addUpdateProductIndirecta,
  agregarProductosIndirecta,
  facturaIndirectaInit,
  getOrdenMesas,
  initPrecuenta,
  resetSlice,
} from '../../redux/slices/orden-slice/orden.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import isOrdenIndirecta from '../../common/isOrdenIndirecta';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import MoverCuentaModal from './mover-cuenta-modal';
import BorrarProductosModal from './borrar-productos-modal';
import isRoleAllowed from '../../common/isRoleAllowed';

const TotalizarButtons = () => {
  const currentStep = useAppSelector(state => state.ordenSlice.currentStep);
  const carrito = useAppSelector(state => state.ordenSlice.orden.carrito);
  const carritoAPI = useAppSelector(state => state.ordenSlice.orden.carritoAPI);
  const shouldUpdate = useAppSelector(
    state => state.ordenSlice.orden.shouldUpdate,
  );
  const mesaSeleccionada = useAppSelector(
    store => store.ordenSlice.mesaSeleccionada,
  );
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );

  const [precuentaLoading, setPrecuentaLoading] = useState(false);

  ///Esto indica que ya la pre-cuenta fue iniciada. Genios?
  //Que mal codigo Yusuf
  const alreadyInit = useAppSelector(
    store => store.ordenSlice.orden.clientes,
  ).some(cliente => typeof cliente.id === 'number');
  const dispatch = useAppDispatch();

  const [openModalMoverCuenta, setOpenModalMoverCuenta] = useState(false);
  const [openModalBorrarProductos, setOpenModalBorrarProductos] =
    useState(false);

  const handleCloseDivirCuentaModal = (): void =>
    setOpenModalMoverCuenta(false);

  const handleOpenMoverCuentaModal = (): void => setOpenModalMoverCuenta(true);

  const handleCloseBorrarProductoModal = (): void =>
    setOpenModalBorrarProductos(false);

  const handleOpenBorrarProductoModal = (): void =>
    setOpenModalBorrarProductos(true);

  const handleOnClickEnviar = async (): Promise<void> => {
    dispatch(startLoading());
    if (!alreadyInit) await dispatch(facturaIndirectaInit());
    if (alreadyInit) await dispatch(addUpdateProductIndirecta());

    await dispatch(resetSlice());
    dispatch(setCurrentStep(1));
    dispatch(stopLoading());
  };
  ////click pre cuenta
  const handleOnClickPreCuenta = async (): Promise<unknown> => {
    //Si no es una orden indirecta: const isOrdenIndirecta = () => !!store.getState().ordenSlice.mesaSeleccionada; ?
    /// que codigo de mierda
    /// Si no tiene mesa seleccionada se setea state.currentStep
    if (!isOrdenIndirecta()) return dispatch(setCurrentStep(3));
    setPrecuentaLoading(true);
    ///Si no ha sido iniciada la pre cuenta store.ordenSlice.orden.clientes ?
    if (!alreadyInit) {
      ///como no tienes mesa a esta altura?
      if (!mesaSeleccionada) return;
      await dispatch(facturaIndirectaInit());
      await dispatch(getOrdenMesas());
      ///Osea que la tienes seteada en el store, la inicializas y la vuelves a setear?
      //state.mesaSeleccionada
      dispatch(seleccionarMesa(mesaSeleccionada.id_mesa_sucursal));
    }
    ///Entonces... inicie la precuenta en el if anterior. Tengo carrito.lenght ?
    //state.ordenSlice.orden.carrito
    if (carrito.length && alreadyInit) {
      ///como no tienes mesa a esta altura?
      if (!mesaSeleccionada || !mesaSeleccionada.cuenta) return;
      ///agregarProductosIndirecta?
      await dispatch(agregarProductosIndirecta());
    }
    ///Tienes mesa y store.ordenSlice.orden.clienteSeleccionado no esta en estado 0
    //Creo que si no tienes clienteSeleccionado. Tambien pasa, es confuso. Bad code
    if (mesaSeleccionada && clienteSeleccionado?.estado != 0) {
      await dispatch(initPrecuenta());
    }
    setPrecuentaLoading(false);
    dispatch(setCurrentStep(3));
  };

  return (
    <Box sx={{width: '100%', mt: 2}}>
      {openModalMoverCuenta && (
        <MoverCuentaModal handleClose={handleCloseDivirCuentaModal} />
      )}
      {openModalBorrarProductos && (
        <BorrarProductosModal handleClose={handleCloseBorrarProductoModal} />
      )}

      {currentStep === 2 && (
        <Stack spacing={2} direction="row" justifyContent="space-around">
          {isOrdenIndirecta() && (
            <>
              {isRoleAllowed([1, 8]) && (
                <Button
                  disabled={!carritoAPI.length}
                  onClick={handleOpenBorrarProductoModal}
                  sx={{maxWidth: '150px', fontSize: '0.6rem'}}
                  size="small"
                  variant="contained"
                  color="secondary">
                  BORRAR PRODUCTOS
                </Button>
              )}
              {isRoleAllowed([4, 2, 3, 8, 12]) && (
                <Button
                  disabled={!carritoAPI.length}
                  onClick={handleOpenMoverCuentaModal}
                  sx={{maxWidth: '150px', fontSize: '0.6rem'}}
                  size="small"
                  variant="contained"
                  color="secondary">
                  MOVER PRODUCTOS
                </Button>
              )}

              <Button
                onClick={handleOnClickEnviar}
                size="small"
                sx={{maxWidth: '150px', fontSize: '0.6rem'}}
                variant="contained"
                disabled={!(carrito.length > 0) && !shouldUpdate}
                color="primary">
                ENVIAR
              </Button>
            </>
          )}

          <LoadingButton
            loading={precuentaLoading}
            onClick={handleOnClickPreCuenta}
            size="small"
            sx={{
              maxWidth: '150px',
              fontSize: !isOrdenIndirecta() ? undefined : '0.6rem',
            }}
            variant="contained"
            disabled={!(carrito.length || carritoAPI.length)}
            color="secondary">
            {isOrdenIndirecta() ? 'PRE-CUENTA' : 'TOTALIZAR'}
          </LoadingButton>
        </Stack>
      )}
    </Box>
  );
};

export default TotalizarButtons;
