import {useCallback, useMemo, useState} from 'react';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import BorderedBox, {CategoriesBorderedBox} from '../bordered-box/bordered-box';
import NewSubcategoriaForm from './new-sub-categoria-form';
import NewMenuForm from './new-menu-form';
import {
  deleteSubCategoria,
  deleteMenuRestaurante,
} from '../../redux/slices/menu-slice/menu.thunks';
import {seleccionarSubcategoria} from '../../redux/slices/menu-slice';
import CrudTable from '../crud-table';
import getNestedValue from '../../common/getNestedValue';
import sortArrayObject from '../../common/sortArrayObject';
import sortItBro from '../../common/sortItBro';
import ConfirmDialog from '../confirm-dialog';

function Menu() {
  const [openCrearSub, setOpenCrearSub] = useState(false);

  const [openCrearMenu, setOpenCrearMenu] = useState(false);

  const [editId, setEditId] = useState<number | null>(null);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('nombre_producto');
  const [arrayKeys, setArrayKeys] = useState<string[]>([
    'producto',
    'nombre_producto',
  ]);
  const [deleteID, setDeleteID] = useState<{
    type: 'menu' | 'subcategoria';
    id: number;
  } | null>(null);

  const dispatch = useAppDispatch();

  const selectedCategory = useAppSelector(
    store => store.menuSlice.selectedCategory?.id_categoria_producto,
  );

  const selectedSubcategory = useAppSelector(
    store => store.menuSlice.selectedSubcategory?.id_subcategoria_producto,
  );

  const menuRestaurante = useAppSelector(
    store => store.menuSlice.menuRestaurantes,
  );

  const subcategorias = useAppSelector(store => store.menuSlice.subcategorias);

  const filteredSubcategories = useMemo(
    () =>
      [...subcategorias]
        .filter(el => {
          if (selectedCategory)
            return (
              el.categoria_producto.id_categoria_producto === selectedCategory
            );
          return el;
        })
        .sort((a, b) => a.orden - b.orden),
    [subcategorias, selectedCategory],
  );

  const filteredMenuRestaurante = useMemo(
    () =>
      menuRestaurante.filter(el => {
        let category = true;
        let subCategory = true;
        if (selectedCategory)
          category =
            el.producto.categoria_producto.id_categoria_producto ===
            selectedCategory;

        if (selectedSubcategory)
          subCategory =
            el.producto.subcategoria_producto.id_subcategoria_producto ===
            selectedSubcategory;

        return category && subCategory;
      }),
    [selectedCategory, selectedSubcategory, menuRestaurante],
  );

  const handleCloseEdit = () => {
    setEditId(null);
    setOpenCrearSub(false);
  };

  const handleOnEdit = (id: number) => {
    setEditId(id);
    setOpenCrearMenu(true);
  };
  const handleCloseCrearMenu = () => {
    setOpenCrearMenu(false);
    setEditId(null);
  };

  const handleOnEditSubCat = async (id: number) => {
    setEditId(id);
    setOpenCrearSub(true);
  };

  const handleOnDeleteSubCat = async (id: number) => {
    setDeleteID({type: 'subcategoria', id});
  };

  const handleOnDeleteMenu = async (id: number) => {
    // await dispatch(deleteMenuRestaurante(id));
    setDeleteID({type: 'menu', id});
  };

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['producto', 'nombre_producto'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, ['producto', 'descripcion'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  const onClickSubcategoriaFilter = (id: number) => {
    if (selectedSubcategory === id)
      return dispatch(seleccionarSubcategoria(null));
    dispatch(seleccionarSubcategoria(id));
  };

  return (
    <>
      <CategoriesBorderedBox />
      <BorderedBox
        onClickSelected={onClickSubcategoriaFilter}
        selectedKey={selectedSubcategory}
        title="Sub-categoria"
        idKey="id_subcategoria_producto"
        valueKey="nombre_subcategoria"
        onOpenModalCrear={setOpenCrearSub}
        elements={filteredSubcategories.sort((a, b) =>
          sortItBro(a.orden, b.orden, 'asc'),
        )}
        onDelete={handleOnDeleteSubCat}
        onEdit={handleOnEditSubCat}
      />
      <CrudTable
        setOpenCrear={setOpenCrearMenu}
        title="Menú del restaurante"
        columns={column}
        rows={filteredMenuRestaurante.sort((a, b) =>
          sortArrayObject(a, b, arrayKeys, order),
        )}
        OnEdit={handleOnEdit}
        onDelete={handleOnDeleteMenu}
        onSearch={handleOnSearch}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
        columnBlacklisted={['Imagen']}
      />
      <ConfirmDialog
        secondaryText={
          deleteID?.type == 'menu'
            ? 'Estas seguro de que deseas borrar este producto?'
            : 'Al borrar la subcategoria, se borraran todos los productos de la subcategoria. ¿Estas seguro de querer borrarla?'
        }
        state={deleteID != null}
        handleClose={() => {
          setDeleteID(null);
        }}
        handleClickCompleted={async () => {
          if (deleteID) {
            if (deleteID.type === 'menu')
              await dispatch(deleteMenuRestaurante(deleteID.id));
            else await dispatch(deleteSubCategoria(deleteID.id));
            setDeleteID(null);
          }
        }}
      />
      <DialogPopUp
        open={openCrearSub}
        handleClose={handleCloseEdit}
        title={editId ? 'Crear Subcategoria' : 'Editar Subcategoria'}>
        <NewSubcategoriaForm
          handleClose={handleCloseEdit}
          defaultValues={
            editId
              ? subcategorias.find(e => e.id_subcategoria_producto === editId)
              : undefined
          }
        />
      </DialogPopUp>
      <DialogPopUp
        open={openCrearMenu}
        handleClose={handleCloseCrearMenu}
        title={editId ? 'Crear menu' : 'Editar Menu'}>
        <NewMenuForm
          handleClose={handleCloseCrearMenu}
          defaultValues={
            editId
              ? menuRestaurante.find(e => e.id_menu_restaurante === editId)
              : undefined
          }
        />
      </DialogPopUp>
    </>
  );
}

export default Menu;

const column = [
  {
    label: 'Imagen',
    key: ['producto', 'imagen', 'ruta_imagen'],
  },
  {
    label: 'Nombre',
    key: ['producto', 'nombre_producto'],
  },
  {
    label: 'Descripción',
    key: ['producto', 'descripcion'],
  },
  {
    label: 'Categoría',
    key: ['producto', 'categoria_producto', 'nombre_categoria'],
  },
  {
    label: 'Subcategoría',
    key: ['producto', 'subcategoria_producto', 'nombre_subcategoria'],
  },
  {
    label: 'Precio\u00A0(USD)',
    key: ['producto', 'precio'],
  },
  {
    label: '',
    key: ['id_menu_restaurante'],
  },
];
