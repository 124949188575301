import {createSlice} from '@reduxjs/toolkit';
import {metaDataToClient, metaDataType} from '../../../common/client-meta-data';
import {ModificadoresMenu} from '../../../services/dto/orden';

import {
  addUpdateProductIndirecta,
  agregarProductosIndirecta,
  CambiarMesero,
  cargarImpuestos,
  CreateClient,
  editOrdenDescuento,
  editOrdenMetodoPago,
  editOrdenPropina,
  facturaIndirecta,
  facturarDirecta,
  getMensajesDeCancelacion,
  getMenuSucursal,
  getOrdenMesas,
  getUbicaciones,
  initPrecuenta,
  loadCuenta,
  putPostMenuSucursal,
  setDescuentoPorLinea,
  updateProductosIndirecta,
  putPostEntidadReceptora,
  getEntidadReceptora,
} from './orden.thunks';
import {descuentoType, ordenSliceType, subcategoryType} from './orden.types';
import {actions} from './order.actions';

//initialState

export const ordenSliceInitialState = {
  orden: {
    metaData: {
      cantidadDeComenzales: null,
    },
    ubicacion: null,
    carrito: [],
    carritoAPI: [],
    shouldUpdate: false,
    clientes: [],
    clienteSeleccionado: null,
    infoCliente: null,
  },
  uiData: {
    ubicaciones: [],
    productos: [],
    subcategorias: [],
    mesas: [],
    loadingMesas: true,
    motivosCancelacion: [],
    mesaMode: 'normal',
    mesaA: null,
    mesaB: null,
    selectedMesaSeccion: null,
  },
  selectedSubcategory: null,
  mesaSeleccionada: null,
  cuenta: {
    descuentos: 0,
    impuestos: 0,
    propinaEfectiva: 0,
    descuentoMontoFijo: 0,
    propinas: 0,
    subtotal: 0,
    modificadores: 0,
    total: 0,
    propinasTotal: 0,
  },
  currentStep: 1,
  lastFacturaId: null,
} as ordenSliceType;

//slice

export const ordenSlice = createSlice({
  name: 'orden-slice',
  initialState: ordenSliceInitialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(getMenuSucursal.fulfilled, (state, action) => {
        const subcategorias = action.payload.map(
          element => element.subcategoria,
        );
        let subcategoriesObject: {
          [key: number]: subcategoryType;
        } = {};
        subcategorias.forEach(element => {
          if (subcategoriesObject[element.id_subcategoria_producto]) {
            return subcategoriesObject[element.id_subcategoria_producto]
              .cantidad++;
          }
          return (subcategoriesObject[element.id_subcategoria_producto] = {
            cantidad: 1,
            subcategory: element,
          });
        });
        const filteredSubcategories = [
          ...Object.values(subcategoriesObject),
        ].sort((a, b) => a.subcategory.orden - b.subcategory.orden);

        state.uiData.productos = action.payload.map(e => ({
          ...e,
          producto: {
            ...e.producto,
            precio_producto: Number(e.producto.precio_producto),
          },
          type: e.type === '1',
        }));
        state.uiData.subcategorias = filteredSubcategories;
        state.selectedSubcategory =
          filteredSubcategories[0]?.subcategory || null;
      })
      .addCase(putPostMenuSucursal.fulfilled, () => {})
      .addCase(getUbicaciones.fulfilled, (state, action) => {
        state.uiData.ubicaciones = action.payload;
      })
      .addCase(getOrdenMesas.pending, state => {
        state.uiData.loadingMesas = true;
      })
      .addCase(getOrdenMesas.fulfilled, (state, action) => {
        state.uiData.mesas = action.payload;
        state.uiData.loadingMesas = false;
      })
      .addCase(facturarDirecta.fulfilled, (state, action) => {
        state.lastFacturaId = action.payload.data.id_factura_sucursal;
      })
      .addCase(cargarImpuestos.fulfilled, (state, action) => {
        if (!state.orden.clienteSeleccionado) return;

        state.orden.clienteSeleccionado.impuestos = action.payload;
      })
      .addCase(editOrdenDescuento.fulfilled, (state, action) => {
        if (!state.orden.clienteSeleccionado) return;
        state.orden.clienteSeleccionado.descuentos = action.payload;
        state.orden.shouldUpdate = true;
        state.orden.clienteSeleccionado.descuentoType = descuentoType.global;
        const hayDescuento = action.payload.length > 0;
        state.orden.carrito = state.orden.carrito.map(element => {
          if (!state.orden.clienteSeleccionado) return element;
          if (state.orden.clienteSeleccionado.id !== element.clienteId)
            return element;

          if (
            element.producto.categoria_producto_id === 2 &&
            hayDescuento &&
            action.payload[0].tipo_pago_descuento_id === 2
          )
            return element;

          return {
            ...element,
            descuentoCant: hayDescuento ? element.cantidad : undefined,
            descuentoId: hayDescuento
              ? action.payload[0].id_tipo_descuento_restaurante
              : undefined,
          };
        });

        state.orden.carritoAPI = state.orden.carritoAPI.map(element => {
          if (!state.orden.clienteSeleccionado) return element;
          if (state.orden.clienteSeleccionado.id !== element.clienteId)
            return element;

          if (
            element.producto.categoria_producto_id === 2 &&
            hayDescuento &&
            action.payload[0].tipo_pago_descuento_id === 2
          )
            return element;
          return {
            ...element,
            descuentoCant: hayDescuento ? element.cantidad : undefined,
            descuentoId: hayDescuento
              ? action.payload[0].id_tipo_descuento_restaurante
              : undefined,
          };
        });
      })
      .addCase(editOrdenPropina.fulfilled, (state, action) => {
        state.orden.shouldUpdate = true;
        if (!state.orden.clienteSeleccionado) return;
        state.orden.clienteSeleccionado.propinas = action.payload;
      })
      .addCase(agregarProductosIndirecta.fulfilled, (state, action) => {})
      .addCase(updateProductosIndirecta.fulfilled, (state, action) => {
        state.orden.shouldUpdate = false;
      })
      .addCase(addUpdateProductIndirecta.fulfilled, (state, action) => {})
      .addCase(CambiarMesero.fulfilled, (state, action) => {})
      .addCase(editOrdenMetodoPago.fulfilled, (state, action) => {
        if (!state.orden.clienteSeleccionado) return;
        state.orden.shouldUpdate = true;
        state.orden.clienteSeleccionado.metodosPago = action.payload;
      })
      .addCase(facturaIndirecta.fulfilled, (state, action) => {
        state.lastFacturaId = action.payload.data.id_factura_sucursal;
      })
      .addCase(setDescuentoPorLinea.fulfilled, (state, action) => {})
      .addCase(loadCuenta.fulfilled, (state, action) => {
        state.orden.metaData.cantidadDeComenzales =
          action.payload.cuenta.ubicacion.cantidad_comensales ?? 0;
        state.orden.clientes =
          action.payload.cuenta.ubicacion.ubicacion.clientes_asignados_mesa.map(
            cliente => {
              const clienteMetaData = cliente.meta_data_cliente
                ? (JSON.parse(cliente.meta_data_cliente) as metaDataType)
                : undefined;

              return metaDataToClient(
                cliente,
                action.payload.finanzas,
                clienteMetaData,
                Number(cliente.estado),
              );
            },
          );
        if (state.orden.clientes.length > 0) {
          if (state.orden.clienteSeleccionado === null)
            state.orden.clienteSeleccionado = state.orden.clientes[0];
        }
        state.orden.carritoAPI =
          action.payload.cuenta.ubicacion.ubicacion.clientes_asignados_mesa.flatMap(
            client =>
              client.cliente.productos_cliente.map(productoCliente => {
                const {cliente_id, orden} = productoCliente;
                const {menu_restaurante} = orden;
                return {
                  clienteId: cliente_id,
                  cantidad: orden.cantidad,
                  id: orden.id_orden_producto_menu,
                  descuentoCant: orden.descuento_producto?.cantidad_producto,
                  descuentoId:
                    orden.descuento_producto?.tipo_descuento_restaurante_id,

                  cantidadCancelada: orden.cantidad_cancelada ?? undefined,
                  modificadores: orden.modificador_producto_menu.map(
                    ({
                      accion_asignada_modificador,
                      accion_a_modificador_id,
                      id_modificador_menu,
                      precio_mod_men,
                      menu_id,
                      id_modificador_p_ordenado,
                    }) => ({
                      accion_a_modificador_id,
                      accion_asignada_modificador,
                      id_modificador_menu,
                      menu_id,
                      id_modificador_p_ordenado,
                      precio_mod_men,
                    }),
                  ) as ({
                    id_modificador_p_ordenado?: number;
                  } & ModificadoresMenu)[],
                  metaData: {
                    codigo_orden_id: orden.codigo_orden_id,
                    cuenta_id: orden.cuenta_id,
                    estado_orden_id: orden.estado_orden_id,
                    id_orden_producto_menu: orden.id_orden_producto_menu,
                    cancelados: orden.motivo_cancelacion_producto,
                    created_at: orden.created_at,
                  },
                  observacion: orden.observaciones ?? undefined,
                  producto: {
                    restaurante_id: menu_restaurante.restaurante_id,
                    id_menu_sucursal: 1,
                    categoria_producto_id:
                      menu_restaurante.producto.categoria_producto
                        .id_categoria_producto,
                    cantidad_producto: orden.cantidad,
                    categoria: menu_restaurante.producto.categoria_producto,
                    estado_producto_global:
                      menu_restaurante.estado_producto_global,
                    estado_producto_local:
                      menu_restaurante.estado_producto_global,
                    menu_restaurante_id: menu_restaurante.id_menu_restaurante,
                    modificadores_menu: menu_restaurante.modificadores_menu,
                    producto: {
                      id_producto: menu_restaurante.producto.id_producto,
                      restaurante_id: menu_restaurante.restaurante_id,
                      nombre_producto:
                        menu_restaurante.producto.nombre_producto,
                      descripcion_producto:
                        menu_restaurante.producto.descripcion,
                      precio_producto: Number(orden.precio_unitario),
                      imagen: menu_restaurante.producto.imagen,
                    },
                    producto_id: menu_restaurante.producto.id_producto,
                    subcategoria_producto_id:
                      menu_restaurante.producto.subcategoria_producto
                        .id_subcategoria_producto,
                    subcategoria:
                      menu_restaurante.producto.subcategoria_producto,
                  },
                };
              }),
          );
      })
      .addCase(getMensajesDeCancelacion.fulfilled, (state, action) => {
        state.uiData.motivosCancelacion = action.payload;
      })
      .addCase(initPrecuenta.fulfilled, (state, action) => {})
      .addCase(CreateClient.fulfilled, (state, action) => {
        state.orden.clientes = action.payload.clients;
        if (action.payload.shoudSelect) {
          state.orden.clienteSeleccionado = action.payload.client;
        }
      })
      .addCase(putPostEntidadReceptora.fulfilled, (state, action) => {
        if (state.orden.clienteSeleccionado) {
          state.orden.clienteSeleccionado.entidad_receptora = action.payload;
        }
      })
      .addCase(getEntidadReceptora.fulfilled, (state, action) => {});
  },
});

export const {
  seleccionarUbicacion,
  editOrdenProducto,
  setPropinaEfectivo,
  setCurrentStep,
  resetOrdenSlice,
  calculateTotal,
  selectFilterSubcategory,
  calcularMetodosPagos,
  setCantidadMetodoPago,
  resetOrden,
  actualizarCliente,
  agregarObservacion,
  _setDescuentoPorLinea,
  seleccionarMesa,
  calculateCarrito,
  calculateImpuesto,
  calculatePropinas,
  seleccionarCliente,
  updateClienteArray,
  updateCarrito,
  updateMetaData,
  setUnirMesaMode,
  setMesaAyB,
  updateModificadoresProducto,
  setMesaSeccion,
  setInfoCliente,
} = ordenSlice.actions;

export default ordenSlice.reducer;
