import {Box, Card, Table, TableContainer} from '@mui/material';
import {ReactNode} from 'react';
import StatusFilterSearch from './status-filter-search';

type PropsTypes = {
  children: ReactNode;
};

const TableWrapper = ({children}: PropsTypes) => {
  return (
    <Box
      component={Card}
      raised
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        p: 2,
        width: '100%',
        pb: 8,
      }}>
      <StatusFilterSearch />
      <TableContainer sx={{mt: 2}}>
        <Table stickyHeader size="small" aria-label="data table component">
          {children}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableWrapper;
