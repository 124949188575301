import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import yup from '../../common/yup';

import {actualizarCliente} from '../../redux/slices/orden-slice';
import {CreateClient} from '../../redux/slices/orden-slice/orden.thunks';
import {clienteStoreTypePrimary} from '../../redux/slices/orden-slice/orden.types';
import {useAppDispatch} from '../../redux/store';

export type propType = {
  handleOnClose: () => void;
  edit?: editClientPayload;
  onCreate?: (client: clienteStoreTypePrimary) => void;
};

export type editClientPayload = {
  name: string;
  id: string | number;
  email?: string;
};

const ClientForm = ({handleOnClose, edit, onCreate}: propType) => {
  const [name, setClienteName] = useState(edit?.name ?? '');
  const [email, setClienteEmail] = useState(edit?.email ?? '');
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
      }}>
      <Typography sx={{fontSize: '24px', fontWeight: 'bold'}}>
        {edit?.name ? 'Editar cliente' : 'Nuevo cliente'}
      </Typography>
      <TextField
        autoComplete="off"
        fullWidth
        required
        label="Nombre"
        sx={{maxWidth: '90%'}}
        value={name}
        onChange={e => {
          setClienteName(e.target.value.toUpperCase());
        }}
        variant="outlined"
      />
      <TextField
        autoComplete="off"
        fullWidth
        label="Email (opcional)"
        sx={{maxWidth: '90%'}}
        value={email}
        error={yup.string().email().isValidSync(email) === false}
        onChange={e => {
          setClienteEmail(e.target.value);
        }}
        variant="outlined"
      />

      <Stack
        direction="row"
        sx={{width: '100%', justifyContent: 'space-around'}}>
        {!edit && (
          <Button
            fullWidth
            onClick={async () => {
              const client = await dispatch(
                CreateClient({name: name, email, shouldSelect: true}),
              ).unwrap();

              if (onCreate && client.client) onCreate(client.client);
              handleOnClose();
            }}
            sx={{maxWidth: '40%'}}
            variant="contained"
            color="secondary"
            size="large">
            Omitir paso
          </Button>
        )}
        <Button
          disabled={!name}
          fullWidth
          onClick={async () => {
            if (edit) {
              dispatch(actualizarCliente({id: edit.id, data: {name, email}}));
            } else {
              const client = await dispatch(
                CreateClient({name, email, shouldSelect: true}),
              ).unwrap();

              if (onCreate && client.client) onCreate(client.client);
            }
            handleOnClose();
          }}
          sx={{maxWidth: '40%'}}
          variant="contained"
          color="primary"
          size="large">
          {edit ? 'Guardar' : 'Añadir'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ClientForm;
