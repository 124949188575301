import {Box} from '@mui/material';
import {memo} from 'react';
import isRoleAllowed from '../../common/isRoleAllowed';
import {
  editOrdenDescuento,
  setDescuentoPorLinea,
} from '../../redux/slices/orden-slice/orden.thunks';
import {descuentoType} from '../../redux/slices/orden-slice/orden.types';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import CustomButton from '../custom-button/custom-button';

type PropsTypes = {
  handleOpen: () => void;
};

const List = ({handleOpen}: PropsTypes) => {
  const dispatch = useAppDispatch();

  const stateDescuentos = useAppSelector(
    state => state.finanzasSlice.descuentos,
  );
  const selectedDescuentos = useAppSelector(
    state => state.ordenSlice.orden.clienteSeleccionado?.descuentos,
  );
  const tipoDescuento = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.descuentoType,
  );

  const canUseDiscount = !isRoleAllowed([0], [12]);
  return (
    <Box
      sx={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
      }}>
      {stateDescuentos.map(value => {
        const selected =
          !!selectedDescuentos?.find(
            element =>
              element.id_tipo_descuento_restaurante ===
              value.id_tipo_descuento_restaurante,
          ) && tipoDescuento !== descuentoType.producto;
        const disabled =
          (selectedDescuentos && selectedDescuentos?.length > 0 && !selected) ||
          tipoDescuento === descuentoType.producto;
        let name = `${value.descripcion_tipo_descuento}`;
        if (value.tipo_pago_descuento_id === 8) {
          name = `($${value.cantidad}) ${name}`;
        } else {
          name = `${name} (${value.porcentaje}%)`;
        }
        return (
          <Box key={value.id_tipo_descuento_restaurante} sx={{mb: 0.5, mr: 2}}>
            <CustomButton
              disabled={disabled || canUseDiscount}
              isselected={selected}
              name={name}
              addEm={() => {
                console.log('aplicando descuento');
                dispatch(
                  editOrdenDescuento({
                    type: 'togglear',
                    id: value.id_tipo_descuento_restaurante,
                  }),
                );
              }}
            />
          </Box>
        );
      })}

      <Box sx={{mb: 0.5, mr: 2}}>
        <CustomButton
          disabled={
            (selectedDescuentos &&
              selectedDescuentos.length > 0 &&
              tipoDescuento !== descuentoType.producto) ||
            canUseDiscount
          }
          isselected={tipoDescuento === descuentoType.producto}
          name={`DESCUENTO POR LINEA`}
          addEm={() => {
            if (tipoDescuento !== descuentoType.producto) return handleOpen();
            dispatch(setDescuentoPorLinea({products: [], productsAPI: []}));
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(List);
