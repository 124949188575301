import {LoadingButton} from '@mui/lab';
import {Button, Stack, TableCell} from '@mui/material';
import {useState} from 'react';
import getNestedValue from '../../common/getNestedValue';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PageviewIcon from '@mui/icons-material/Pageview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';

type TableRowButtonType = {
  OnEdit?: (arg: number) => void;
  onDelete?: (arg: number) => void;
  onView?: (arg: number) => void;
  onPreload?: (arg: number) => void;
  onExport?: (arg: number) => void;
  onAnular?: (arg: number) => Promise<void>;
  row: any;
  column: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  };
};

const TableRowButton = ({
  OnEdit,
  onView,
  onDelete,
  onExport,
  onAnular,
  onPreload,
  row,
  column,
}: TableRowButtonType) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <TableCell align="left">
      <Stack spacing={0.5}>
        {onView && (
          <Button
            size="small"
            onClick={() => onView(getNestedValue(row, column.key))}
            variant="outlined"
            startIcon={<PageviewIcon />}>
            Ver
          </Button>
        )}
        {OnEdit && (
          <Button
            size="small"
            onClick={() => OnEdit(getNestedValue(row, column.key))}
            variant="outlined"
            startIcon={<EditIcon />}>
            Editar
          </Button>
        )}
        {onDelete && (
          <LoadingButton
            size="small"
            onClick={async () => {
              setIsLoading(true);
              await onDelete(getNestedValue(row, column.key));
              setIsLoading(false);
            }}
            variant="outlined"
            loading={isLoading}
            startIcon={<DeleteForeverIcon />}>
            Borrar
          </LoadingButton>
        )}
        {onExport && (
          <LoadingButton
            size="small"
            onClick={() => onExport(getNestedValue(row, column.key))}
            variant="outlined"
            loading={isLoading}
            startIcon={<FileDownloadIcon />}>
            Exportar
          </LoadingButton>
        )}
        {onAnular && (
          <LoadingButton
            size="small"
            onClick={async () => {
              setIsLoading(true);
              await onAnular(getNestedValue(row, column.key));
              setIsLoading(false);
            }}
            variant="outlined"
            loading={isLoading}
            startIcon={<BookmarkRemoveIcon />}>
            Anular
          </LoadingButton>
        )}
        {onPreload && (
          <Button
            size="small"
            onClick={() => onPreload(getNestedValue(row, column.key))}
            variant="outlined"
            startIcon={<PageviewIcon />}>
            Pre-Cargar
          </Button>
        )}
      </Stack>
    </TableCell>
  );
};

export default TableRowButton;
