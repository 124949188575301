import {memo, useState} from 'react';
import isBoolean from '../../common/isBoolean';
import {errorToast} from '../../common/toast';
import {RestauranteBox} from '../../components/restaurante-box/restaurante-box';
import {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
} from '../../redux/slices/restaurante-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import RestauranteCrearModal from './restaurante-crear-modal';

const RestauranteList = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const EmpleadoAsignadoSucursal = useAppSelector(
    state => state.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );
  const restaurantes = useAppSelector(
    state => state.restauranteSlice.restauranteEmpleados,
  );

  const handleSelectRestaurante = (id_restaurante: number) => {
    if (EmpleadoAsignadoSucursal) {
      dispatch(seleccionarEmpleadoAsignadoSucursal(null));
    }
    dispatch(seleccionarRestaurante(id_restaurante));
    return;
  };

  return (
    <>
      {restaurantes.map(({restaurante, status}) => {
        const hasImage = restaurante.imagen.length
          ? restaurante.imagen.find(imagen => imagen.tipo_imagen === 'logo')
              ?.ruta_imagen
          : '';

        const {nombre, id} = restaurante;
        const isDisabled = !isBoolean(status);
        return (
          <RestauranteBox
            key={id}
            disabled={isDisabled}
            onClick={() => {
              if (isDisabled) {
                errorToast(
                  'Estas desactivado en este restaurante, Contacta al administrador.',
                );
                return;
              }
              handleSelectRestaurante(id);
            }}
            hasImage={hasImage}
            nombre={nombre}
          />
        );
      })}
      <RestauranteBox onClick={() => setOpen(true)} />
      <RestauranteCrearModal state={open} handleClose={() => setOpen(false)} />
    </>
  );
};

export default memo(RestauranteList);
