import {LoadingButton} from '@mui/lab';
import {Box, Stack} from '@mui/material';
import {useState, memo} from 'react';
import isOrdenIndirecta from '../../common/isOrdenIndirecta';
import PriceText from '../../components/price-text/price-text';
import {setCurrentStep} from '../../redux/slices/orden-slice';
import {
  addUpdateProductIndirecta,
  facturaIndirecta,
  facturarDirecta,
  getPrecuentaPDF,
} from '../../redux/slices/orden-slice/orden.thunks';

import {useAppDispatch, useAppSelector} from '../../redux/store';

function PriceCounter() {
  const [facturarLoading, setFacturarLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const dispatch = useAppDispatch();

  const ubicacion = useAppSelector(store => store.ordenSlice.orden.ubicacion);
  const currentStep = useAppSelector(state => state.ordenSlice.currentStep);

  const metodoPago = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.metodosPago,
  );
  const propina = useAppSelector(
    store => store.ordenSlice.cuenta.propinasTotal,
  );

  const propinas = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.propinas,
  );
  const propinaEfectivo = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.propinaEfectivo,
  );
  const subTotal = useAppSelector(store => store.ordenSlice.cuenta.subtotal);
  const descuento = useAppSelector(store => store.ordenSlice.cuenta.descuentos);

  const descuentoMontoFijo = useAppSelector(
    store => store.ordenSlice.cuenta.descuentoMontoFijo,
  );

  const descuentoTotal = descuento + descuentoMontoFijo;
  const impuesto = useAppSelector(store => store.ordenSlice.cuenta.impuestos);
  const total = useAppSelector(store => store.ordenSlice.cuenta.total);

  const carrito = useAppSelector(state => state.ordenSlice.orden.carrito);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        pt: 2,
      }}>
      {currentStep === 3 && (
        <Stack
          spacing={1}
          sx={{
            width: '50%',
            display: 'flex',
            alignItems: 'center',
          }}>
          {isOrdenIndirecta() && (
            <LoadingButton
              loading={pdfLoading}
              onClick={async () => {
                setPdfLoading(true);
                await dispatch(addUpdateProductIndirecta());
                await dispatch(getPrecuentaPDF());
                setPdfLoading(false);
              }}
              sx={{
                width: '100px',
                height: '40px',
                fontSize: '0.7rem',
                fontWeight: 'bold',
              }}
              variant="contained"
              color="secondary">
              VER PDF
            </LoadingButton>
          )}
          <LoadingButton
            loading={facturarLoading}
            onClick={async () => {
              setFacturarLoading(true);
              setTimeout(async () => {
                try {
                  const isIndirecta = isOrdenIndirecta();
                  if (isIndirecta) await dispatch(facturaIndirecta()).unwrap();
                  else await dispatch(facturarDirecta()).unwrap();
                  setFacturarLoading(false);
                  dispatch(setCurrentStep(4));
                } catch (error) {
                  console.error(error);
                  setFacturarLoading(false);
                }
              }, 1000);
            }}
            sx={{
              height: '40px',
              fontSize: '0.7rem',
              fontWeight: 'bold',
              width: '100px',
            }}
            variant="contained"
            disabled={
              !propinas ||
              !metodoPago ||
              !ubicacion ||
              metodoPago.restante != 0 ||
              metodoPago.zeroMetodo ||
              metodoPago.metodos.length < 1 ||
              (propinas.some(prop => prop.tipo_pago_propina_id === 2) &&
                !propinaEfectivo)
            }
            color="primary">
            FACTURAR
          </LoadingButton>
        </Stack>
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
        {currentStep === 2 ? (
          <>
            <PriceText text="sub-total:" grow number={subTotal} />
          </>
        ) : (
          <>
            <PriceText text="sub-total:" number={subTotal} />
            <PriceText text="descuento:" number={descuentoTotal} />
            <PriceText text="sub-total:" number={subTotal - descuentoTotal} />
            <PriceText text="impuesto:" number={impuesto} />
            {!!propina && <PriceText text="propina:" number={propina} />}
            <PriceText text="total:" grow number={total} />
            {!!propina && (
              <PriceText text="total con propina:" number={total + propina} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default memo(PriceCounter);
