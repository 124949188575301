import {MobileDateTimePicker} from '@mui/lab';
import {Button, Stack, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import {formatDate} from '../../../../common/formatTime';
import DialogPopUp from '../../../../components/dialog-pop-up/dialog-pop-up';
import {refetch, setDate} from '../../../../redux/slices/reportes-slice';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';

const DateRangeContainer = () => {
  const [open, setOpen] = useState(false);
  const fechas = useAppSelector(store => store.reportes.fecha);

  const dispatch = useAppDispatch();
  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      spacing={2}
      alignItems="center"
      sx={{width: '100%', mb: 2}}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        width={{xs: '90%', md: '50%'}}
        sx={{
          border: '1px solid black',
          borderRadius: '15px',
          height: '30px',
        }}>
        <Typography noWrap fontSize="10px">
          Desde
        </Typography>
        <Typography noWrap fontSize="12px" fontWeight="bold">
          {formatDate(fechas.fecha_inicio)}
        </Typography>
        <Typography noWrap fontSize="10px">
          Hasta
        </Typography>
        <Typography noWrap fontSize="12px" fontWeight="bold">
          {formatDate(fechas.fecha_final)}
        </Typography>
      </Stack>
      <Box width={{xs: '90%', md: '50%'}}>
        <Button
          onClick={() => setOpen(true)}
          sx={{height: '30px', fontSize: '14px'}}
          variant="contained"
          fullWidth
          color="secondary">
          Cambiar rango de fechas
        </Button>
      </Box>
      <DialogPopUp
        PaperProps={{
          sx: {
            minWidth: '60%',
          },
        }}
        open={open}
        handleClose={() => setOpen(false)}
        title="Seleccione las fechas">
        <Stack
          direction={{
            xs: 'column',
          }}
          alignItems="center"
          spacing={2}>
          <Stack direction="column" spacing={2} width="100%">
            <MobileDateTimePicker
              label="Fecha inicio"
              ampm
              ampmInClock
              value={fechas.fecha_inicio}
              onChange={date => {
                if (!date) return;
                dispatch(setDate({type: 'inicial', date: date}));
              }}
              disableFuture
              renderInput={params => <TextField {...params} />}
            />
            <MobileDateTimePicker
              label="Fecha final"
              ampm
              minDate={fechas.fecha_inicio}
              minDateTime={fechas.fecha_inicio}
              ampmInClock
              value={fechas.fecha_final}
              onChange={date => {
                if (!date) return;
                dispatch(setDate({type: 'final', date: date}));
              }}
              disableFuture
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
          <Button
            sx={{height: '50px', width: '50%'}}
            variant="contained"
            color="secondary"
            onClick={async () => {
              dispatch(refetch());
              setOpen(false);
            }}>
            Filtrar
          </Button>
        </Stack>
      </DialogPopUp>
    </Stack>
  );
};

export default DateRangeContainer;
