import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import PersonsForm from './persons-form';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
  mesaId: number;
  closeOnNext?: boolean;
};

const ComenzalesModal = ({
  state,
  handleClose,
  mesaId,
  closeOnNext = false,
}: PropsTypes) => {
  return (
    <DialogPopUp
      PaperProps={{
        sx: {
          minWidth: '30%',
          height: 400,
          position: 'relative',
        },
      }}
      open={state}
      handleClose={handleClose}
      title="">
      <PersonsForm
        mesaId={mesaId}
        handleClose={handleClose}
        closeOnNext={closeOnNext}
      />
    </DialogPopUp>
  );
};

export default ComenzalesModal;
