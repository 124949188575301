import axios from 'axios';
import {getError} from './errorHandling';
import {msgSuccess} from './toast';

export const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

export const sendImage = async options => {
  const {
    urlImagen,
    authHeader,
    image,
    type,
    modelo,
    id_registro,
    setIsLoading,
  } = options;

  if (image.file) {
    const formData = new FormData();
    let urltransform;
    let successTxt;

    if (image.id_imagen) {
      formData.append('id_registro', image.id_imagen);
      urltransform = `${urlImagen}/${image.id_imagen}`;
      formData.append('tipo_imagen', type);
      formData.append('_method', 'PUT');
      successTxt = `${type} actualizado.`;
    } else {
      formData.append('id_registro', id_registro);
      urltransform = `${urlImagen}`;
      formData.append('tipo_imagen', type);
      successTxt = `${type} creado.`;
    }

    formData.append('modelo', modelo);
    formData.append('imagen', image.file, image.file.name);

    axios
      .post(urltransform, formData, authHeader)
      .then(response => {
        msgSuccess(
          `${response.statusText ? response.statusText : `${successTxt}`}`,
        );
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        getError(error);
      });
  } else {
  }
  return;
};

export const sendImage2 = async options => {
  const {urlImagen, authHeader, image, type, modelo, id_registro} = options;

  if (image) {
    const formData = new FormData();
    let urltransform;
    let successTxt;
    formData.append('id_registro', id_registro);
    urltransform = `${urlImagen}/${id_registro}`;
    formData.append('tipo_imagen', type);
    formData.append('_method', 'PUT');
    successTxt = `${type} actualizado.`;
    formData.append('modelo', modelo);
    formData.append('imagen', image, image.name);

    axios
      .post(urltransform, formData, authHeader)
      .then(response => {
        msgSuccess(
          `${response.statusText ? response.statusText : `${successTxt}`}`,
        );
      })
      .catch(error => {
        getError(error);
      });
  } else {
  }
  return;
};

export const convertURltoFile = async (object, setState) => {
  const {ruta_imagen, tipo_imagen} = object;

  const dataUrl = await toDataURL(ruta_imagen);
  const fileData = dataURLtoFile(dataUrl, `${tipo_imagen}.jpg`);
  setState({
    ...object,
    file: fileData,
    preview: ruta_imagen,
  });
};
