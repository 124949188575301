import {Grid, Typography} from '@mui/material';
import {useEffect, useMemo} from 'react';
import {selectedCurrrentSeccion} from '../../redux/slices/comanda-slice/comanda.thunk';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import ComandaItem from '../comanda-item';

const ComandaList = () => {
  const dispatch = useAppDispatch();

  const comandas = useAppSelector(store => store.comandaSlice.comadas);

  const isComander =
    useAppSelector(store => store.restauranteSlice.cargoEmpleado?.id_cargo) ===
    9;
  const secciones = useAppSelector(
    store => store.restauranteUtilsSlice.sucursalSecciones,
  );
  const areaSecciones = useAppSelector(
    store => store.restauranteUtilsSlice.empleadoAsignadosSeccion,
  );
  const seccionesFiltered = isComander
    ? [...secciones].filter(element =>
        areaSecciones.some(
          area => area.seccion_id === element.id_seccion_sucursal,
        ),
      )
    : secciones;

  const currentSelectedSeccion = useAppSelector(
    store => store.comandaSlice.currentSelectedSeccion,
  );

  useEffect(() => {
    if (currentSelectedSeccion) return;
    if (seccionesFiltered.length) {
      const seccion = seccionesFiltered[0];
      if (seccion)
        dispatch(selectedCurrrentSeccion(seccion.id_seccion_sucursal));
    }
  }, [secciones]);

  const sortedCommand = useMemo(() => {
    return [...comandas].sort(
      (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
    );
  }, [comandas]);

  if (isComander && seccionesFiltered.length < 1)
    return (
      <Grid item sx={{textAlign: 'center', width: '100%'}}>
        <Typography variant="body1">
          No tienes ninguna sección asignada, contacta con el administrador.
        </Typography>
      </Grid>
    );

  if (!comandas.length)
    return (
      <Grid item sx={{textAlign: 'center', width: '100%', mt: 5}}>
        <Typography variant="body1">
          No tiene comandas activas en{' '}
          {currentSelectedSeccion
            ? currentSelectedSeccion.nombre_seccion
            : 'en esta sección'}
          .
        </Typography>
      </Grid>
    );

  return (
    <Grid container spacing={2} item>
      {sortedCommand.map(comanda => (
        <ComandaItem key={comanda.id_codigo_orden} comanda={comanda} />
      ))}
    </Grid>
  );
};

export default ComandaList;
