import {RouteProps, Route} from 'react-router-dom';
import DashboardLayout from '../../layouts/main-layout';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {Redirect} from 'react-router-dom';
import EmailVerificacion from '../../pages/email-verification/EmailVerificacion';
import isWhiteListed from '../../common/isWhiteListed';
import isRouteAllowed from '../../common/isRouteAllowed';
import {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
} from '../../redux/slices/restaurante-slice';

type propType = {
  componente: React.ComponentType<any>;
  hideSideBar?: boolean;
  needSelectedRestaurant?: boolean;
} & RouteProps;

const PrivateRoute = function ({
  componente: Component,
  hideSideBar = false,
  needSelectedRestaurant = true,
  ...rest
}: propType) {
  const userState = useAppSelector(store => store.userSlice);
  const dispatch = useAppDispatch();

  const restauranteSeleccionado = useAppSelector(
    store => store.restauranteSlice.restauranteEmpleadoSeleccionado,
  );
  const cargoId = useAppSelector(
    store => store.restauranteSlice.cargoEmpleado?.id_cargo,
  );

  const empleadoAsignadoSucursalSeleccionado = useAppSelector(
    state => state.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  //redirect if not logged in
  if (!userState.isLogged) return <Redirect to="/login" />;

  // redirect if email is not verified
  if (!userState.userDetail?.email_verified_at) return <EmailVerificacion />;

  //redirect if a restaurante y sucursal is not selected.
  if (
    needSelectedRestaurant &&
    isWhiteListed() &&
    !restauranteSeleccionado &&
    !empleadoAsignadoSucursalSeleccionado
  )
    return <Redirect to="/" />;

  //console.log('cargoId: ', cargoId);

  // redirect comander
  if (cargoId === 9 && window.location.pathname !== '/comandas') {
    return <Redirect to="/comandas" />;
  }

  if (cargoId === 10 && window.location.pathname === '/orden')
    return <Redirect to="/reportes/balance-actual" />;

  //redirect route shouldn't ifudis roles involved
  if (!isRouteAllowed()) {
    dispatch(seleccionarRestaurante(null));
    dispatch(seleccionarEmpleadoAsignadoSucursal(null));
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <DashboardLayout
          needSelectedRestaurant={needSelectedRestaurant}
          hideSidebar={hideSideBar}>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  );
};

export default PrivateRoute;
