import React, {useState} from 'react';
import {
  Container,
  styled,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  sendEmailPing,
  validateEmailUser,
} from '../../redux/slices/user-slice/user.thunks';
import {useHistory} from 'react-router-dom';
import {Logout} from '../../redux/slices/user-slice/user.thunks';

const RootStyle = styled(Page)(({theme}) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: 'center',
}));

const EmailVerificacion = function () {
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');
  const userState = useAppSelector(store => store.userSlice);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await dispatch(validateEmailUser(pin));
    setIsLoading(false);
  };

  const handleReenviarPin = async () => {
    if (!userState.userDetail?.email) return;
    setIsLoading(true);
    await dispatch(sendEmailPing(userState.userDetail?.email));
    setIsLoading(false);
  };

  const welcomeMessage = () => {
    return `Hola ${
      userState.userDetail?.id_sexo === 2 ? `bienvenida` : `bienvenido`
    }, ${userState.userDetail?.name} ${userState.userDetail?.lastname}.`;
  };

  const handleLogout = () => {
    dispatch(Logout());
    history.replace('/login');
  };

  return (
    <RootStyle title="Verificación de correo electrónico || IFUDIS">
      <Container maxWidth="xl">
        <form onSubmit={handleSubmit}>
          <Box
            sx={{pb: 1}}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Logo width={240} height={240} />
          </Box>
          <Box sx={{pb: 0}}>
            <Typography variant="h4">{welcomeMessage()}</Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Necesitamos que introduzca el código de verificación que
                enviamos al correo para poder verificar su correo:{' '}
                <span style={{fontWeight: 'bold'}}>
                  {userState.userDetail?.email}
                </span>{' '}
                con su cuenta de IFUDIS.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{pb: 2, pt: 2}}>
                <TextField
                  type="text"
                  label="Codigo de verificacion"
                  helperText="Puede copiar y cortar desde su correo."
                  value={pin}
                  onChange={e => setPin(e.target.value)}
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={!(pin.trim().length > 0)}>
                Verificarme
              </LoadingButton>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="center"
                sx={{color: 'text.secondary', mt: 3}}>
                Reenviar codigo &nbsp;
                <a
                  style={{cursor: 'pointer', color: 'black'}}
                  onClick={handleReenviarPin}
                  href="#">
                  Aqui!
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleLogout}
                size="small"
                color="secondary"
                sx={{mt: 5}}
                variant="outlined">
                Salir del sistema
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </RootStyle>
  );
};

export default EmailVerificacion;
