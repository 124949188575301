import {createSlice} from '@reduxjs/toolkit';
import {actions} from './inventario.actions';
import {
  cargarInventario,
  getInventarioByDate,
  getLastestInventario,
  postInventarioSucursal,
  seleccionarCategoriaInsumo,
} from './inventario.thunks';
import {inventarioSliceType} from './inventario.types';

export const initialState = {
  editable: {inventario: []},
  inventarioPasado: null,
  selectedInsumoSubcategory: 1,
  isLastestInventario: false,
  inventarioNuevoDate: null,
  inventarioPasadoDate: null,
  fechaDeUltimoInventario: null,
} as inventarioSliceType;

export const inventarioSlice = createSlice({
  name: 'inventario-slice',
  initialState: initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(getLastestInventario.fulfilled, (state, action) => {
        const date = new Date(action.payload.fecha_hora_inventario);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        state.inventarioNuevoDate = currentDate;
        state.inventarioPasadoDate = date;
        state.inventarioPasado = action.payload;
        state.isLastestInventario = true;
        state.fechaDeUltimoInventario = date;
      })
      .addCase(seleccionarCategoriaInsumo.fulfilled, (state, action) => {
        state.selectedInsumoSubcategory = action.payload;
      })
      .addCase(cargarInventario.fulfilled, (state, action) => {
        state.editable.inventario = action.payload;
      })
      .addCase(postInventarioSucursal.fulfilled, (state, action) => {})
      .addCase(getInventarioByDate.fulfilled, (state, action) => {
        state.inventarioPasado = action.payload;
        const fecha = new Date(action.payload?.fecha_hora_inventario ?? '');
        if (fecha.valueOf() === state.fechaDeUltimoInventario?.valueOf()) {
          state.isLastestInventario = true;
        } else {
          state.isLastestInventario = false;
        }
      });
  },
});

export const {actualizarCantidad, setDates, resetInventarioSlice} =
  inventarioSlice.actions;

export default inventarioSlice.reducer;
