import {LoadingButton} from '@mui/lab';
import {
  Stack,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Divider,
  useMediaQuery,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {getEmpleadosSucursalBySucursalID} from '../../redux/slices/empleados-slice/empleados.thunks';
import {
  CambiarMesero,
  getOrdenMesas,
} from '../../redux/slices/orden-slice/orden.thunks';

import {useAppSelector, useAppDispatch} from '../../redux/store';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
type PropsTypes = {
  handleClose: () => void;
  cuentaId?: number;
};

const CambiarMeseroModal = ({handleClose, cuentaId}: PropsTypes) => {
  const sucursal = useAppSelector(
    store =>
      store.restauranteSlice.empleadoAsignadoSucursalSeleccionado?.sucursal_id,
  );
  const dispatch = useAppDispatch();

  const [loading, setloading] = useState(false);

  const empleados = useAppSelector(
    store => store.empleadoSlice.empleadoSucursales,
  );

  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (!sucursal) return;
    dispatch(getEmpleadosSucursalBySucursalID(sucursal));
  }, []);

  const handleSubmit = async () => {
    if (!empleadoSeleccionado || !cuentaId) return;
    setloading(true);
    await dispatch(CambiarMesero({empleadoId: empleadoSeleccionado, cuentaId}));
    await dispatch(getOrdenMesas());
    setloading(false);
    handleClose();
  };

  const sm = useMediaQuery('(max-width:550px)');

  return (
    <DialogPopUp
      PaperProps={{
        sx: {
          minWidth: '31%',
          height: sm ? '100%' : undefined,
          position: 'relative',
        },
      }}
      open={true}
      fullScreen={sm}
      handleClose={handleClose}
      title="Cambio de host de la mesa">
      <Stack
        direction="column"
        spacing={1}
        gap={1}
        sx={{width: '100%', height: '100%'}}>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'left',
          }}>
          Selecciona empleado
        </Typography>
        <Divider sx={{width: '100%'}} />
        <Box height="90%" overflow="scroll">
          <FormControl component="fieldset" variant="standard">
            {empleados
              // ?.filter(
              //   emp => emp.empleado.cargo_id === 2 || emp.empleado.cargo_id === 3,
              // )
              .map(({empleado, id_empleado_asignado_sucursal}) => {
                const isSelected =
                  empleadoSeleccionado === id_empleado_asignado_sucursal;
                return (
                  <Box
                    key={id_empleado_asignado_sucursal}
                    component={Stack}
                    gap={1}
                    spacing={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: '100%',
                    }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            if (isSelected)
                              return setEmpleadoSeleccionado(null);
                            setEmpleadoSeleccionado(
                              id_empleado_asignado_sucursal,
                            );
                          }}
                          checked={isSelected}
                        />
                      }
                      label={`${empleado.name} ${empleado.lastname}`}
                    />
                  </Box>
                );
              })}
          </FormControl>
        </Box>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            fullWidth
            disabled={!empleadoSeleccionado}
            onClickCapture={handleSubmit}
            loading={loading}
            sx={{maxWidth: '200px', alignSelf: 'center'}}
            variant="contained"
            color="secondary">
            Guardar cambios
          </LoadingButton>
        </Box>
      </Stack>
    </DialogPopUp>
  );
};

export default CambiarMeseroModal;
