import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {useMemo, useState} from 'react';
import {fCurrency} from '../../common/formatNumber';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {shallowEqual} from 'react-redux';
import dayjs from 'dayjs';
import {setDates} from '../../redux/slices/compras-slice';
import {getComprasSucursal} from '../../redux/slices/compras-slice/compras.thunks';
import DatePicker from '../date-picker';
import AddIcon from '@mui/icons-material/Add';
import NewProveedorForm from '../new-proveedor-form/new-proveedor-form';

function ComprasBottomBar() {
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openLast, setOpenLast] = useState<boolean>(false);

  const comprasSucursal = useAppSelector(
    store => store.comprasSlice.comprasSucursal,
  );
  const selectedCategoria = useAppSelector(
    store => store.comprasSlice.selectedCategoriaInsumo,
  );

  const totalDeCompras = useMemo(
    () =>
      comprasSucursal
        .filter(element => element.categoria_insumo_id === selectedCategoria)
        .reduce((acc, curr) => acc + curr.monto_compra, 0),
    [selectedCategoria, comprasSucursal],
  );

  const dates = useAppSelector(
    store => ({
      inicio: store.comprasSlice.fecha_inicio,
      final: store.comprasSlice.fecha_final,
    }),
    shallowEqual,
  );
  const [openCrear, setOpenCrear] = useState(false);

  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        color: 'white',
        px: {
          xs: 2,
          lg: 5,
        },
      }}>
      <Stack
        direction="row"
        sx={{display: 'flex', alignItems: 'center'}}
        spacing={2}>
        <Stack direction="column" sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="body1" sx={{fontWeight: 'bold'}}>
            Desde
          </Typography>
          <Typography
            onClick={() => setOpenNew(true)}
            variant="body1"
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            {dayjs(dates.inicio).format('DD/MM/YY')} <ChangeCircleIcon />
          </Typography>
        </Stack>
        <Stack direction="column" sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="body1" sx={{fontWeight: 'bold'}}>
            Hasta
          </Typography>
          <Typography
            onClick={() => setOpenLast(true)}
            variant="body1"
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            {dayjs(dates.final).format('DD/MM/YY')} <ChangeCircleIcon />
          </Typography>
        </Stack>
      </Stack>
      <Button
        sx={{m: 1, maxWidth: '220px', fontWeight: 'bold'}}
        variant="outlined"
        onClick={() => setOpenCrear(true)}
        fullWidth
        startIcon={<AddIcon />}>
        Agregar proveedor
      </Button>
      <Stack
        direction="row"
        spacing={1}
        sx={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="body1">Total de compras</Typography>
        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
          {fCurrency(totalDeCompras)}
        </Typography>
      </Stack>
      <DialogPopUp
        open={openNew}
        handleClose={() => setOpenNew(false)}
        title="Seleccione la fecha de inicio.">
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            <DatePicker
              label="Fecha"
              inputFormat="dd/MM/yyyy"
              disableFuture
              value={dates.inicio}
              onChange={date => {
                if (!date) return;
                dispatch(setDates({date: date, type: 'start'}));
              }}
              renderInput={params => <TextField {...params} />}
            />
            {/* <TextField
              label="Fecha "
              type="date"
              onChange={e =>
                dispatch(setDates({type: 'start', date: e.target.value}))
              }
              value={dates.inicio}
              sx={{width: '90%'}}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
          </Stack>
          <Button
            fullWidth
            onClick={() => setOpenNew(false)}
            sx={{maxHeight: '50px'}}
            variant="contained"
            color="secondary">
            Seleccionar
          </Button>
        </Stack>
      </DialogPopUp>
      <DialogPopUp
        open={openLast}
        handleClose={() => {
          dispatch(getComprasSucursal());
          setOpenLast(false);
        }}
        title="Seleccione la fecha de final.">
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            {/* <TextField
              label="Fecha "
              type="date"
              onChange={e =>
                dispatch(setDates({type: 'end', date: e.target.value}))
              }
              value={dates.final}
              sx={{width: '90%'}}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <DatePicker
              label="Fecha"
              inputFormat="dd/MM/yyyy"
              disableFuture
              value={dates.final}
              onChange={date => {
                if (!date) return;
                dispatch(setDates({date: date, type: 'end'}));
              }}
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
          <Button
            fullWidth
            onClick={() => {
              dispatch(getComprasSucursal());
              setOpenLast(false);
            }}
            sx={{maxHeight: '50px'}}
            variant="contained"
            color="secondary">
            Seleccionar
          </Button>
        </Stack>
      </DialogPopUp>
      <DialogPopUp
        open={openCrear}
        handleClose={() => setOpenCrear(false)}
        title="Crear proveedor">
        <NewProveedorForm
          type="crear"
          handleClose={() => setOpenCrear(false)}
        />
      </DialogPopUp>
    </Box>
  );
}

export default ComprasBottomBar;
