import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
} from '@mui/material';
import {useState} from 'react';
import {setDescuentoPorLinea} from '../../redux/slices/orden-slice/orden.thunks';

import {carritoType} from '../../redux/slices/orden-slice/orden.types';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {descuentoRestaurantType} from '../../services/dto/orden';
import CustomButton from '../custom-button/custom-button';
import NumericTextField from '../form-input/controlled-numeric-text-field';

export default function DescuentoPorLineaPopUp({
  handleClose,
}: {
  handleClose: VoidFunction;
}) {
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );
  const dispatch = useAppDispatch();
  const carrito = useAppSelector(store => store.ordenSlice.orden.carrito);
  const carritoAPI = useAppSelector(store => store.ordenSlice.orden.carritoAPI);
  const stateDescuentos = useAppSelector(
    state => state.finanzasSlice.descuentos,
  );

  const [selectedDescuento, setSelectedDescuento] =
    useState<descuentoRestaurantType | null>(null);

  const [selectedProducts, setSelectedProducts] = useState<carritoType[]>([]);
  const [selectedProductsAPI, setSelectedProductsAPI] = useState<carritoType[]>(
    [],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{width: '50%'}}>
          <FormGroup>
            <>
              {carrito
                .filter(carro => {
                  if (!clienteSeleccionado) return true;
                  return carro.clienteId === clienteSeleccionado.id;
                })
                .map(item => {
                  const selected = selectedProducts.some(
                    product => product.id === item.id,
                  );
                  const disabled =
                    selectedDescuento?.tipo_pago_descuento_id === 2 &&
                    item.producto.categoria_producto_id === 2;

                  return (
                    <Box
                      key={item.id}
                      component={Stack}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        height: '50px',
                      }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={disabled}
                            checked={selected}
                            onChange={e => {
                              if (selected)
                                return setSelectedProducts(prev =>
                                  prev.filter(element => element.id != item.id),
                                );
                              const copyItem = {...item};
                              copyItem.descuentoCant = copyItem.cantidad;
                              return setSelectedProducts(prev => [
                                ...prev,
                                copyItem,
                              ]);
                            }}
                            name={item.producto.producto.nombre_producto}
                          />
                        }
                        label={`${item.producto.producto.nombre_producto} x${item.cantidad}`}
                      />

                      {selected && item.cantidad > 1 && (
                        <NumericTextField
                          value={
                            selectedProducts.find(
                              element => element.id === item.id,
                            )?.descuentoCant ?? 0
                          }
                          onChange={value => {
                            const index = selectedProducts.findIndex(
                              product => product.id === item.id,
                            );
                            const newSelectedProducts = [...selectedProducts];
                            newSelectedProducts[index].descuentoCant = value;
                            setSelectedProducts(newSelectedProducts);
                          }}
                          sx={{width: '60px', height: '45px', mr: 2}}
                        />
                      )}
                    </Box>
                  );
                })}
            </>
            <>
              {carritoAPI
                .filter(carro => {
                  if (!clienteSeleccionado) return true;
                  return carro.clienteId === clienteSeleccionado.id;
                })
                .map(item => {
                  const selected = selectedProductsAPI.some(
                    product => product.id === item.id,
                  );
                  const disabled =
                    selectedDescuento?.tipo_pago_descuento_id === 2 &&
                    item.producto.categoria_producto_id === 2;

                  return (
                    <Box
                      key={item.id}
                      component={Stack}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        height: '50px',
                      }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={disabled}
                            checked={selected}
                            onChange={e => {
                              if (selected)
                                return setSelectedProductsAPI(prev =>
                                  prev.filter(element => element.id != item.id),
                                );
                              const copyItem = {...item};
                              copyItem.descuentoCant = copyItem.cantidad;
                              return setSelectedProductsAPI(prev => [
                                ...prev,
                                copyItem,
                              ]);
                            }}
                            name={item.producto.producto.nombre_producto}
                          />
                        }
                        label={`${item.producto.producto.nombre_producto} x${item.cantidad}`}
                      />

                      {selected && item.cantidad > 1 && (
                        <NumericTextField
                          maxValue={item.cantidad}
                          value={
                            selectedProductsAPI.find(
                              element => element.id === item.id,
                            )?.descuentoCant
                          }
                          onChange={value => {
                            const index = selectedProductsAPI.findIndex(
                              product => product.id === item.id,
                            );
                            const newSelectedProducts = [
                              ...selectedProductsAPI,
                            ];
                            newSelectedProducts[index].descuentoCant = value;
                            setSelectedProductsAPI(newSelectedProducts);
                          }}
                          sx={{width: '60px', height: '45px', mr: 2}}
                        />
                      )}
                    </Box>
                  );
                })}
            </>
          </FormGroup>
        </FormControl>
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            width: '50%',
          }}>
          {stateDescuentos
            .filter(
              value =>
                value.tipo_pago_descuento_id !== 8 &&
                value.tipo_pago_descuento_id !== 4,
            )
            .map(value => {
              const selected =
                selectedDescuento?.id_tipo_descuento_restaurante ===
                value.id_tipo_descuento_restaurante;

              const alcoholJubilado =
                (selectedProducts.some(
                  element => element.producto.categoria_producto_id === 2,
                ) ||
                  selectedProductsAPI.some(
                    element => element.producto.categoria_producto_id === 2,
                  )) &&
                value.tipo_pago_descuento_id === 2;

              const disabled =
                alcoholJubilado || (selectedDescuento !== null && !selected);

              let name = `${value.descripcion_tipo_descuento}`;
              if (value.tipo_pago_descuento_id === 8) {
                name = `($${value.cantidad}) ${name}`;
              } else {
                name = `${name} (${value.porcentaje}%)`;
              }

              return (
                <Box
                  key={value.id_tipo_descuento_restaurante}
                  sx={{mb: 0.5, mr: 2}}>
                  <CustomButton
                    disabled={disabled}
                    isselected={selected}
                    name={name}
                    addEm={() => {
                      if (selectedDescuento) return setSelectedDescuento(null);
                      setSelectedDescuento(value);
                    }}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
      <Grid
        item
        xs={12}
        sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
        <Button
          size="large"
          type="submit"
          onClick={() => {
            dispatch(
              setDescuentoPorLinea({
                products: selectedProducts.map(product => ({
                  ...product,
                  descuentoId: selectedDescuento?.id_tipo_descuento_restaurante,
                })),
                descuentoId: selectedDescuento?.id_tipo_descuento_restaurante,
                productsAPI: selectedProductsAPI.map(product => ({
                  ...product,
                  descuentoId: selectedDescuento?.id_tipo_descuento_restaurante,
                })),
              }),
            );
            handleClose();
          }}
          variant="contained"
          color="secondary">
          Guardar Cambios
        </Button>
      </Grid>
    </Box>
  );
}
