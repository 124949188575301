import {Box, Button, Card, Stack, Typography} from '@mui/material';
import {formatDate, formatDateTime} from '../../common/formatTime';
import useMediaQuery from '../../common/hooks/use-media-query';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {useState} from 'react';
import {useAppSelector} from '../../redux/store';
import dayjs from 'dayjs';
import DateModal from './date-modal';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {useHistory} from 'react-router-dom';

const Header = () => {
  const isDesktop = useMediaQuery('(min-width:962px)');
  const mobile = useMediaQuery('(min-width:585px)');
  const [open, setOpen] = useState<boolean>(false);
  const cierreSlice = useAppSelector(store => store.cierreSlice);
  const cajero = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado?.empleado_sucursal,
  );
  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );

  const history = useHistory();

  return (
    <Box
      raised
      component={Card}
      sx={{
        width: '100%',
        minHeight: !mobile ? '160px' : '80px',
        mb: 2,
        p: 1,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'flex-start',
          width: '100%',
          px: isDesktop ? 1 : 0,
        }}>
        <Stack direction={isDesktop ? 'row' : 'column'} spacing={1}>
          <Typography>
            Fecha:{' '}
            <span style={{fontWeight: 'bold', fontSize: '0.8rem'}}>
              {formatDate(new Date())}
            </span>
          </Typography>
          <Typography>
            Cajero:{' '}
            <span style={{fontWeight: 'bold', fontSize: '0.8rem'}}>
              {cajero?.name} {cajero?.lastname}
            </span>
          </Typography>
        </Stack>
        <Stack direction={isDesktop ? 'row' : 'column'} spacing={1}>
          <Typography>
            Turno:{' '}
            <span style={{fontWeight: 'bold', fontSize: '0.8rem'}}>
              {dayjs().locale('es').format('LT')}
            </span>
          </Typography>
          <Typography>
            Caja:{' '}
            <span style={{fontWeight: 'bold', fontSize: '0.8rem'}}>
              #{cajaEmpleado ? cajaEmpleado!.caja.numero_caja : ''}
            </span>
          </Typography>
        </Stack>
        <Stack direction={isDesktop ? 'row' : 'column'} spacing={1}>
          <Typography>
            Desde:{' '}
            <span
              style={{
                fontWeight: 'bold',
                fontSize: cierreSlice.fechaInicial ? '0.7rem' : '1rem',
                color: cierreSlice.fechaInicial ? 'black' : 'red',
              }}>
              {cierreSlice.fechaInicial
                ? formatDateTime(cierreSlice.fechaInicial)
                : 'Aún sin definir'}
            </span>
          </Typography>
          <Typography>
            Hasta:{' '}
            <span style={{fontWeight: 'bold', fontSize: '0.7rem'}}>
              {formatDateTime(cierreSlice.fechaFinal)}
            </span>
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={1}
        gap={1}>
        <Button
          fullWidth={isDesktop ? true : false}
          size="small"
          sx={{fontSize: '0.7rem', maxWidth: !mobile ? '75px' : '125px'}}
          color="secondary"
          onClick={() => setOpen(true)}
          variant="contained">
          {!isDesktop ? <DateRangeIcon /> : 'Cambiar fechas'}
        </Button>
        <Button
          fullWidth={isDesktop ? true : false}
          size="small"
          sx={{fontSize: '0.7rem', maxWidth: !mobile ? '75px' : '125px'}}
          color="secondary"
          onClick={() => history.push('/historial-cierre')}
          variant="contained">
          {!isDesktop ? <ReceiptIcon /> : 'Historial de cierres'}
        </Button>
      </Stack>
      <DateModal state={open} handleClose={() => setOpen(false)} />
    </Box>
  );
};

export default Header;
