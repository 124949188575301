import {useEffect, useState} from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {errorToast} from '../../common/toast';
import {imageType} from '../../services/dto/auth';
import {Avatar, Box, Button, Card, CardContent, Stack} from '@mui/material';

type propType = {
  state: File | imageType | null;
  setState: (file: File) => void;
  buttonLabel: string;
  perfil?: boolean;
};

const UploadImages = function ({
  state,
  setState,
  buttonLabel,
  perfil = false,
}: propType) {
  const [localImage, setLocalImage] = useState<string>('');

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number,
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            blob => {
              if (blob) {
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
              } else {
                reject(new Error('Blob generation failed'));
              }
            },
            file.type,
            1,
          );
        };
        img.src = event.target?.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const uploadAction = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;
    if (!event.target.files[0]) return;

    const file = event.target.files[0];

    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      return errorToast(
        'Archivo no soportado; Formatos soportados: .jpeg, .png, .jpg',
      );
    }

    if (file.size > 2076957 * 5)
      return errorToast('Archivo no puede superar los 10MB.');

    try {
      const resizedFile = await resizeImage(file, 1024, 1024); // Tamaño máximo deseado
      setState(resizedFile);
    } catch (error) {
      console.error('Error al redimensionar la imagen:', error);
      errorToast('Error al redimensionar la imagen.');
    }
  };

  useEffect(() => {
    if (!state) return;
    if (state instanceof File) {
      return setLocalImage(URL.createObjectURL(state));
    }
    return setLocalImage(state.ruta_imagen);
  }, [state]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '1rem',
          maxHeight: 400,
        }}
        direction={{xs: 'column', sm: 'row'}}
        spacing={{xs: 1, sm: 2, md: 4}}>
        {perfil ? (
          <Avatar
            alt={localImage}
            src={localImage}
            sx={{width: 200, height: 200, objectFit: 'contain'}}
          />
        ) : (
          localImage && (
            <Card raised>
              <CardContent>
                <img
                  alt={localImage}
                  src={localImage}
                  style={{
                    width: 300,
                    height: 300,
                    objectFit: 'contain',
                  }}
                />
              </CardContent>
            </Card>
          )
        )}
      </Stack>
      <div style={{flexGrow: 1}}></div>
      <Button
        variant="contained"
        color="secondary"
        component="label"
        endIcon={<PhotoCamera />}>
        {buttonLabel}
        <input
          type="file"
          style={{display: 'none'}}
          accept=".jpeg, .png, .jpg"
          onChange={uploadAction}
        />
      </Button>
    </Box>
  );
};

export default UploadImages;
