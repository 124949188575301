import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialCargo, initialEmpleadoRestaurante, initialState} from '.';
import {
  editableType,
  empleadosSliceType,
  updateCargoStoreType,
  updateEmpleadoRestauranteStoreType,
} from './empleados.types';

const actualizarCargo = (
  state: Draft<empleadosSliceType>,
  action: PayloadAction<updateCargoStoreType>,
) => {
  state.editable.cargo = {...state.editable.cargo, ...action.payload};
};

const resetEditable = (
  state: Draft<empleadosSliceType>,
  action: PayloadAction<editableType>,
) => {
  switch (action.payload) {
    case 'cargo':
      Object.assign(state.editable.cargo, initialCargo);
      break;
    case 'empleado':
      Object.assign(state.editable.empleado, initialEmpleadoRestaurante);
      break;
    default:
      break;
  }
};
const cargarCargo = (
  state: Draft<empleadosSliceType>,
  action: PayloadAction<number>,
) => {
  const cargo = state.cargos.find(cargo => cargo.id_cargo === action.payload);

  if (!cargo) return;

  state.editable.cargo.id_cargo = cargo.id_cargo;
  state.editable.cargo.nombre_cargo = cargo.nombre_cargo;
  state.editable.cargo.descripcion_cargo = cargo.descripcion_cargo;
  state.editable.cargo.porcentaje_descuento = 1;
  state.editable.cargo.permisos = cargo.permisos.map(
    permiso => permiso.id_permiso,
  );
};

const actualizarEmpleadoRestaurante = (
  state: Draft<empleadosSliceType>,
  action: PayloadAction<updateEmpleadoRestauranteStoreType>,
) => {
  state.editable.empleado = {
    ...state.editable.empleado,
    ...action.payload,
  };
};

const resetEmpleadoSlice = (state: Draft<empleadosSliceType>) => {
  return initialState;
};

export const actions = {
  actualizarCargo,
  resetEditable,
  cargarCargo,
  actualizarEmpleadoRestaurante,
  resetEmpleadoSlice,
};
