import * as React from 'react';
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {EntidadReceptora} from '../../../../redux/slices/orden-slice/orden.types';
import SearchIcon from '@mui/icons-material/Search';
import {getEntidadReceptora} from '../../../../redux/slices/orden-slice/orden.thunks';
import {useAppDispatch} from '../../../../redux/store';

type propsType = {
  tipo_receptor_id: number;
  handleReduxUpdate?: any | null;
  selectEntidadReceptora?: any | null;
  updateRedux?: Boolean;
};

const RucAutocomplete = function ({
  tipo_receptor_id,
  handleReduxUpdate,
  selectEntidadReceptora,
  updateRedux = false,
}: propsType) {
  const dispatch = useAppDispatch();

  const [value, setValue] = React.useState<EntidadReceptora | null>(null);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [suggestionsEnditadReceptora, setSuggestionsEnditadReceptora] =
    React.useState<EntidadReceptora[]>([]);

  // Fetch function
  const fetchEntidadReceptoraByMatch = async (buscador: string) => {
    if (buscador.length > 3) {
      setLoading(true);
      await dispatch(getEntidadReceptora({tipo_receptor_id, buscador}))
        .unwrap()
        .then(x => {
          setSuggestionsEnditadReceptora(x);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  return (
    <Autocomplete
      filterOptions={x => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No hemos encontrado registros."
      getOptionLabel={option =>
        `${
          option.ruc_receptor?.name ||
          option.info_cliente?.info_cliente?.nombre ||
          ''
        } - ${
          option.ruc_receptor?.ruc ||
          option.info_cliente?.info_cliente?.num_id ||
          ''
        }`
      }
      isOptionEqualToValue={(option, value1) =>
        option.ruc_receptor?.ruc === value1.ruc_receptor?.ruc ||
        option.info_cliente?.info_cliente?.num_id ===
          value1.info_cliente?.info_cliente?.num_id
      }
      onChange={(event: any, newValue: EntidadReceptora | null) => {
        if (newValue) {
          setValue(newValue);
          if (updateRedux) {
            handleReduxUpdate(newValue);
          }
          selectEntidadReceptora(newValue);
        } else {
          setValue(null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length >= 3) {
          fetchEntidadReceptoraByMatch(newInputValue);
        }
      }}
      renderInput={params => (
        <TextField
          label="RUC/Nombre"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
                <SearchIcon />
              </>
            ),
          }}
        />
      )}
      options={suggestionsEnditadReceptora}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid
              item
              sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
              <Typography variant="body2" color="text.secondary">
                {option.ruc_receptor
                  ? `${option.ruc_receptor.name} - ${option.ruc_receptor.ruc} DV ${option.ruc_receptor.dv}`
                  : `${option.info_cliente?.info_cliente?.nombre} - ${option.info_cliente?.info_cliente?.num_id}`}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
};

export default RucAutocomplete;
