import {Menu, Button, ButtonGroup, Paper, MenuItem} from '@mui/material';
import {useRef, useState} from 'react';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import ClientForm, {editClientPayload} from './client-form';
import {clienteStoreTypePrimary} from '../../redux/slices/orden-slice/orden.types';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import isRoleAllowed from '../../common/isRoleAllowed';

export default function SplitButton({
  options,
  showNewClientForm = false,
  onChange,
  selected,
  hideEdit = false,
  onCreate,
}: {
  options: clienteStoreTypePrimary[];
  hideEdit?: boolean;
  selected?: string | number;
  showNewClientForm?: boolean;
  onChange?: (id: string | number) => void;
  onCreate?: (client: clienteStoreTypePrimary) => void;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [newOpen, setNewOpen] = useState<boolean>(false);
  const [editCLientPayload, seteditCLientPayload] =
    useState<editClientPayload | null>(null);
  const handleClick = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleClintModalClose = () => {
    setNewOpen(false);
    seteditCLientPayload(null);
  };

  return (
    <>
      <ButtonGroup
        sx={{
          maxWidth: {
            xs: '170px',
            sm: '100%',
          },
        }}
        size="small"
        color="secondary"
        variant="contained"
        ref={anchorRef}
        aria-label="split button">
        <Button
          size="small"
          color="secondary"
          onClick={handleClick}
          sx={{
            whiteSpace: 'nowrap',
            fontSize: {
              xs: '0.7rem',
            },
          }}>
          {options.find(option => option.id === selected)?.name}
        </Button>
        {showNewClientForm && (
          <>
            <Button
              size="small"
              color="secondary"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select client name"
              aria-haspopup="menu"
              onClick={handleToggle}>
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: {
                    xs: '1rem',
                  },
                }}
              />
            </Button>
            {!hideEdit && (
              <Button
                size="small"
                color="secondary"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="edit client name"
                aria-haspopup="menu"
                onClick={() => {
                  const current = options.find(
                    element => element.id === selected,
                  );
                  if (!current) return;
                  seteditCLientPayload({
                    id: current.id,
                    name: current.name,
                    email: current.email,
                  });
                  setNewOpen(true);
                }}>
                <EditIcon
                  sx={{
                    fontSize: {
                      xs: '0.8rem',
                    },
                  }}
                />
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
      {showNewClientForm && (
        <Paper elevation={3}>
          <Menu open={open} anchorEl={anchorRef.current} onClose={handleClose}>
            {options.map(option => {
              const puedeVerModoPreCuenta =
                isRoleAllowed([0], []) && option.estado === 0;
              return (
                <MenuItem
                  sx={{
                    color: puedeVerModoPreCuenta ? '#4463fc' : 'black',
                    fontWeight: puedeVerModoPreCuenta ? 'bold' : 'normal',
                  }}
                  key={option.id}
                  selected={option.id === selected}
                  onClick={() => {
                    onChange?.(option.id);
                    setOpen(false);
                  }}>
                  {option.name}
                </MenuItem>
              );
            })}
            <MenuItem onClick={() => setNewOpen(true)}>
              <Button variant="outlined" color="secondary">
                AÑADIR CUENTA
              </Button>
            </MenuItem>
          </Menu>
        </Paper>
      )}

      <DialogPopUp
        PaperProps={{
          sx: {
            minWidth: '30%',
            height: 400,
            position: 'relative',
          },
        }}
        open={newOpen}
        handleClose={handleClintModalClose}
        title="">
        <ClientForm
          onCreate={onCreate}
          handleOnClose={handleClintModalClose}
          edit={editCLientPayload ?? undefined}
        />
      </DialogPopUp>
    </>
  );
}
