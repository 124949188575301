import {carritoType} from '../redux/slices/orden-slice/orden.types';

export default function mergeCarrito(carrito: carritoType[]): carritoType[] {
  const blacklistIndex: number[] = [];
  const mergedCarrito: carritoType[] = [];
  carrito.forEach((item, index) => {
    let cantidad = item.cantidad;
    let descuentoCant = item.descuentoCant ?? 0;
    if (blacklistIndex.includes(index)) return;
    carrito.forEach((el, index2) => {
      if (
        index !== index2 &&
        item.observacion === el.observacion &&
        item.producto.menu_restaurante_id === el.producto.menu_restaurante_id &&
        item.descuentoId === el.descuentoId &&
        item.clienteId === el.clienteId &&
        item.modificadores.length === 0 &&
        el.modificadores.length === 0
      ) {
        blacklistIndex.push(index2);
        cantidad += el.cantidad;
        if (el.descuentoCant) descuentoCant += el.descuentoCant;
      }
    });
    mergedCarrito.push({
      ...item,
      cantidad,
      descuentoCant: descuentoCant || undefined,
    });
  });
  return mergedCarrito;
}
