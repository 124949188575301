import {Box, Grid} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {MesaSucursal} from '../../services/dto/orden';
import MesaWrapper from './mesa-wrapper';
import BottomText from './bottom-text';
import UpperText from './upper-text';
import {useEffect, useMemo, useState} from 'react';
import {
  seleccionarMesa,
  setCurrentStep,
  setMesaAyB,
  setUnirMesaMode,
} from '../../redux/slices/orden-slice';
import {cargarCuenta} from '../../redux/slices/orden-slice/orden.thunks';
import {getTimer} from '../../common/formatTime';
import isRoleAllowed from '../../common/isRoleAllowed';
import ComenzalesModal from './comenzales-modal';
import UnirDividirModal from './unir-dividir-modal';
import {msgWarn} from '../../common/toast';
import CambiarMeseroModal from './cambiar-mesero-modal';
import useBrowserNotification from '../../common/hooks/use-browser-notification';
import NotificacionesModal from './notificaciones-modal';
import MoverProductosModal from './mover-productos-modal';

type PropsTypes = {
  mesa: MesaSucursal;
};

function MesasItem({mesa}: PropsTypes) {
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = useState<boolean>(false);
  const [modalNotification, setModalNotification] = useState<boolean>(false);
  const [cambiarMeseroModal, setcambiarMeseroModal] = useState<boolean>(false);
  const [modalStateUnirDividir, setModalStateUnirDividir] =
    useState<boolean>(false);
  const [modalStateMoverProducto, setModalStateMoverProducto] =
    useState<boolean>(false);
  const {handleNotification} = useBrowserNotification();

  const [cuentaId, setCuentaId] = useState<undefined | number>(0);
  const mesaMode = useAppSelector(store => store.ordenSlice.uiData.mesaMode);
  const mesaA = useAppSelector(store => store.ordenSlice.uiData.mesaA);
  const mesaB = useAppSelector(store => store.ordenSlice.uiData.mesaB);
  const empleadoAsignadoSucursalSeleccionado = useAppSelector(
    store => store.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  const getColor = () => {
    if (!mesa.cuenta) return 'gray';
    switch (mesa.estado_mesa) {
      case 0:
        return 'green';
      case 1:
        return 'gray';
      case 2:
        if (!isRoleAllowed([0], [])) return 'green';
        return 'blue';
      default:
        return 'gray';
    }
  };

  const color = getColor();

  const handleSelectMesa = async () => {
    if (mesaMode === 'unirMesa') {
      if (!mesaA) {
        if (mesa.clientes_asignados_mesa.length === 0)
          return msgWarn('Esta mesa no tiene clientes.');
        return dispatch(
          setMesaAyB({mesaID: mesa.id_mesa_sucursal, which: 'A'}),
        );
      }
      if (!mesaB) {
        if (mesa.estado_mesa === 1) setModalState(true);
        if (mesaA === mesa.id_mesa_sucursal) return;
        dispatch(setMesaAyB({mesaID: mesa.id_mesa_sucursal, which: 'B'}));
      }
      setModalStateUnirDividir(true);
      return;
    }

    if (mesaMode === 'cambiarMesero') {
      setCuentaId(mesa.cuenta?.id_cuenta);
      setcambiarMeseroModal(true);
      return;
    }

    if (mesaMode === 'moverProducto') {
      if (!mesaA) {
        if (mesa.clientes_asignados_mesa.length === 0)
          return msgWarn('Esta mesa debe tener al menos 1 cliente.');
        return dispatch(
          setMesaAyB({mesaID: mesa.id_mesa_sucursal, which: 'A'}),
        );
      }
      if (!mesaB) {
        if (mesaA === mesa.id_mesa_sucursal) return;
        if (mesa.clientes_asignados_mesa.length === 0)
          return msgWarn('Esta mesa debe tener al menos 1 cliente.');
        dispatch(setMesaAyB({mesaID: mesa.id_mesa_sucursal, which: 'B'}));
      }
      setModalStateMoverProducto(true);
      return;
    }

    if (
      mesa.cuenta &&
      mesa.cuenta.notifications &&
      mesa.cuenta.notifications.length
    ) {
      return setModalNotification(true);
    }

    if (mesa.estado_mesa === 1) return setModalState(true);
    if (!mesa.cuenta) return setModalState(true);
    dispatch(seleccionarMesa(mesa.id_mesa_sucursal));
    await dispatch(cargarCuenta(mesa.cuenta!.id_cuenta));
    dispatch(setCurrentStep(2));
  };

  const total = useMemo(
    () =>
      mesa.clientes_asignados_mesa.reduce(
        (acc, curr) =>
          acc +
          curr.cliente.productos_cliente.reduce(
            (acc, curr) => acc + curr.orden.total_a_pagar,
            0,
          ),
        0,
      ),
    [mesa],
  );

  const shouldDisable = () => {
    if (
      isRoleAllowed([0], [3]) ||
      mesa.estado_mesa === 1 ||
      !mesa.cuenta ||
      !empleadoAsignadoSucursalSeleccionado
    )
      return false;

    return (
      mesa.cuenta.host_id !==
      empleadoAsignadoSucursalSeleccionado.id_empleado_asignado_sucursal
    );
  };

  const isDividirSelected = (): boolean => {
    if (mesa.id_mesa_sucursal === mesaA) return true;
    if (mesa.id_mesa_sucursal === mesaB) return true;
    return false;
  };

  const handleCloseDividirModal = () => {
    setModalStateUnirDividir(false);
    dispatch(setMesaAyB({mesaID: null, which: 'A'}));
    dispatch(setMesaAyB({mesaID: null, which: 'B'}));
    dispatch(setUnirMesaMode('normal'));
  };

  const handleCloseMoverProductoModal = () => {
    setModalStateMoverProducto(false);
    dispatch(setMesaAyB({mesaID: null, which: 'A'}));
    dispatch(setMesaAyB({mesaID: null, which: 'B'}));
    dispatch(setUnirMesaMode('normal'));
  };

  useEffect(() => {
    if (mesa.cuenta && mesa.cuenta.notifications) {
      if (mesa.cuenta.notifications.length && !shouldDisable()) {
        /*mesa.cuenta.notifications.forEach(value => {
          handleNotification(
            `Notificación de mesa #${mesa.numero_mesa}`,
            value.observaciones,
            value.id_notification,
            ev => {
              dispatch(deleteNotificationMesa(value.id_notification));
              const idsNotification = JSON.parse(
                localStorage.getItem('browsernotificationsid') || '[]',
              ) as Array<number>;
              const index = idsNotification.indexOf(value.id_notification);
              if (index !== -1) {
                idsNotification.splice(index, 1);
              }
              localStorage.setItem(
                'browsernotificationsid',
                JSON.stringify(idsNotification),
              );
            },
          ).finally();
        });*/
      }
    }
  }, [mesa.cuenta]);

  return (
    <Grid key={mesa.id_mesa_sucursal} xs={6} sm={4} md={3} lg={2} item>
      <MesaWrapper
        hasNotification={
          mesa.cuenta
            ? mesa.cuenta.notifications
              ? mesa.cuenta.notifications.length > 0
              : false
            : false
        }
        isSelected={isDividirSelected()}
        color={color}
        handleOnClick={handleSelectMesa}
        disabled={shouldDisable()}>
        <UpperText
          clientName={
            mesa.clientes_asignados_mesa.length
              ? mesa.clientes_asignados_mesa[0].cliente.nombre_apellido
              : undefined
          }
          apodo={mesa.apodo ? mesa.apodo.nombre : undefined}
          mesaNumber={mesa.numero_mesa}
          hostname={mesa.cuenta?.nombre_host}
          qtyAccountsOpen={mesa.clientes_asignados_mesa.length}
          color={
            mesa.cuenta && mesa.cuenta.notifications
              ? mesa.cuenta.notifications.length > 0
                ? 'yellow'
                : color
              : color
          }
        />
        <Box sx={{flex: 1}} />
        <BottomText
          qtyClients={`${mesa.cantidad_comensales ?? 0}/${
            mesa.cantidad_sillas
          }`}
          time={getTimer(mesa.cuenta?.created_at)}
          subTotal={total}
          color={
            mesa.cuenta && mesa.cuenta.notifications
              ? mesa.cuenta.notifications.length > 0
                ? 'yellow'
                : color
              : color
          }
        />
      </MesaWrapper>
      {cambiarMeseroModal && (
        <CambiarMeseroModal
          handleClose={() => {
            setcambiarMeseroModal(false);
            dispatch(setUnirMesaMode('normal'));
            setCuentaId(undefined);
          }}
          cuentaId={cuentaId}
        />
      )}
      <UnirDividirModal
        state={modalStateUnirDividir}
        handleClose={handleCloseDividirModal}
      />
      <MoverProductosModal
        handleClose={handleCloseMoverProductoModal}
        state={modalStateMoverProducto}
      />
      <ComenzalesModal
        closeOnNext={mesaMode === 'unirMesa'}
        mesaId={mesa.id_mesa_sucursal}
        state={modalState}
        handleClose={() => setModalState(false)}
      />
      {mesa.cuenta &&
        mesa.cuenta.notifications &&
        !!mesa.cuenta.notifications.length && (
          <NotificacionesModal
            key={mesa.id_mesa_sucursal}
            notifications={mesa.cuenta?.notifications || []}
            handleClose={() => setModalNotification(false)}
            handleNext={async () => {
              setModalNotification(false);
              if (mesa.estado_mesa === 1) return setModalState(true);
              if (!mesa.cuenta) return setModalState(true);
              dispatch(seleccionarMesa(mesa.id_mesa_sucursal));
              await dispatch(cargarCuenta(mesa.cuenta!.id_cuenta));
              dispatch(setCurrentStep(2));
            }}
            state={modalNotification}
          />
        )}
    </Grid>
  );
}

export default MesasItem;
