import {BaseQueryFn} from '@reduxjs/toolkit/query';
import axios, {AxiosRequestConfig, AxiosError} from 'axios';
import {store} from '../redux/store';

const accessRtkQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
    },
    unknown,
    unknown
  > =>
  async ({url, method, data}) => {
    try {
      const accessToken = store.getState().userSlice.tokenDetails?.access_token;
      if (!accessToken) return {error: 'No access token'};
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        headers: {Authorization: `Bearer ${accessToken}`},
      });
      return {data: result.data};
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {status: err.response?.status, data: err.response?.data},
      };
    }
  };

export default accessRtkQuery;
