import yup from '../../../common/yup';

export const mesaSucursalSchema = yup
  .object({
    cantidad_sillas: yup.number().required(),
    numero_mesa: yup.number().required(),
    seccion_sucursal_id: yup.number(),
    //solo validar esto al momento de hacer la request, no en el form
    sucursal_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_mesa_sucursal: yup.number(),
    apodo_mesa_id: yup.number(),
  })
  .required();

export const seccionSucursalSchema = yup
  .object({
    nombre_seccion: yup.string().required(),
    tipo_seccion_id: yup.number().required(),
    subCategorias: yup.array(yup.number()).when('tipo_seccion_id', {
      is: 3,
      then: s => s.strip(),
      otherwise: s => s.required(),
    }),
    categoria_id: yup.number().when('tipo_seccion_id', {
      is: 3,
      then: s => s.strip(),
      otherwise: s => s.required(),
    }),
    //solo validar esto al momento de hacer la request, no en el form
    sucursal_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_seccion_sucursal: yup.number(),
  })
  .required();
