import {Box, Card, Grid, Slide} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import useMediaQuery from '../../common/hooks/use-media-query';
import FacturaViewer from '../../components/factura-viewer/factura-viewer';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import FilterBar from './filter-bar';
import HeaderBar from './header-bar';
import TableContainer from './table-container';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {fetchFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';
import Pagination from '@mui/material/Pagination';

const MobileView = () => {
  const [isFacturaView, setIsFacturaView] = useState<boolean>(false);
  const containerRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width:960px)');

  const facturaSeleccionada = useAppSelector(
    store => store.diarioVentasSlice.factura_seleccionada,
  );

  useEffect(() => {
    if (facturaSeleccionada) return setIsFacturaView(true);
    return setIsFacturaView(false);
  }, [facturaSeleccionada]);

  const page = useAppSelector(
    store => store.diarioVentasSlice.pagination.current,
  );
  const total = useAppSelector(
    store => store.diarioVentasSlice.pagination.total,
  );

  const dispatch = useAppDispatch();
  const handleChange = async (_: any, value: number) => {
    dispatch(startLoading());
    await dispatch(fetchFactura({page: value, perPage: 50}));
    dispatch(stopLoading());
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        height: '90vh',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        mt: -1.5,
      }}>
      <Box
        sx={{
          width: '100%',
          height: '15%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        {!isFacturaView && (
          <Box
            raised
            component={Card}
            sx={{
              width: '100%',
              minHeight: 'calc(90vh - 15%)',
              overflowY: 'auto',
              pt: isDesktop ? 0 : 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}>
            <FilterBar />
            <HeaderBar />

            <Box
              sx={{
                minHeight: 'calc(100%) - 40px',
                height: '100%',
                maxHeight: 'calc(100%) - 58px',
                overflowY: 'auto',
                p: 2,
              }}>
              <TableContainer />
              <Grid container justifyContent={'center'}>
                <Pagination
                  page={page}
                  count={total}
                  color={'primary'}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
      <Slide container={containerRef.current} direction="up" in={isFacturaView}>
        <Box
          raised
          component={Card}
          sx={{
            position: 'absolute',
            top: '7%',
            left: '5%',
            right: '5%',
            minHeight: '90%',
            maxHeight: '100%',
            overflowY: 'auto',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
          <FacturaViewer />
        </Box>
      </Slide>
    </Box>
  );
};

export default MobileView;
