import {FormControlLabel, Switch, SwitchProps} from '@mui/material';

import {Control, Controller} from 'react-hook-form';

export type ControllerProps = {
  name: string;
  control: Control<any, any>;
};
type propType = {
  controllerProps: ControllerProps;
} & SwitchProps;

export function ControlledSwitch({
  controllerProps: {control, name},
  ...props
}: propType) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({field, fieldState}) => (
        <FormControlLabel
          control={
            <Switch
              {...props}
              checked={field.value}
              onChange={(_, value) => field.onChange(value)}
              color="primary"
            />
          }
          labelPlacement="start"
          label={props.title ?? ''}
        />
      )}
    />
  );
}
