import {TableCell, TableRow} from '@mui/material';

type PropsTypes = {
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
};

const EmptyTableRow = ({columns}: PropsTypes) => {
  return (
    <TableRow hover>
      {columns.map((_, index) => (
        <TableCell key={index} align="left">
          ---
        </TableCell>
      ))}
    </TableRow>
  );
};

export default EmptyTableRow;
