import {useEffect} from 'react';
import Pagetemplate from '../../../layouts/page-template/Pagetemplate';
import {getReporteSucursal} from '../../../redux/slices/reporte-restaurante-slice/reportes.thunks';
import {startLoading, stopLoading} from '../../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import DetailsLayout from './details';

const RestauranteDetails = () => {
  const dispatch = useAppDispatch();

  const selectedSucursal = useAppSelector(
    store => store.reporteRestaurante.selectedSucursalID,
  );

  const refetch = useAppSelector(store => store.reportes.fecha.refetch);
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      await dispatch(getReporteSucursal());
      dispatch(stopLoading());
    };
    fetchData();
  }, [selectedSucursal, refetch]);
  return (
    <Pagetemplate
      isLoading={isLoading}
      title="Detalles de restaurante || IFUDIS"
      soloLayout>
      <DetailsLayout />
    </Pagetemplate>
  );
};

export default RestauranteDetails;
