import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {ChangeEvent} from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

const TableHelper = ({
  setOpenCrear,
  title,
  onSearch,
  searchTerm,
  totalRows,
  handleOnSearch,
}: {
  title?: string;
  setOpenCrear?: (arg: boolean) => void;
  onSearch?: (row: any, searchTerm: string) => any;
  searchTerm: string;
  totalRows: number;
  handleOnSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <Stack
    width="100%"
    direction={{
      xs: 'column',
      sm: 'row',
    }}
    sx={{display: 'flex', alignItems: 'center'}}
    spacing={2}>
    {setOpenCrear && (
      <Button
        onClick={() => setOpenCrear(true)}
        sx={{m: 1, maxWidth: '220px', fontWeight: 'bold'}}
        variant="contained"
        fullWidth
        startIcon={<AddIcon />}>
        {title !== 'Menú del restaurante'
          ? `Agregar ${title}`
          : 'Agregar nuevo producto'}
      </Button>
    )}
    <Box flex={1} />
    {onSearch ? (
      <>
        <Typography
          sx={{
            fontWeight: searchTerm.trim().length ? 'bold' : 'normal',
          }}
          variant="body2">{`${
          searchTerm.trim().length
            ? `Registros encontrados`
            : `Total de registros`
        }: ${totalRows}`}</Typography>
        <TextField
          value={searchTerm}
          label="Buscar"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={handleOnSearch}
        />
      </>
    ) : (
      <>
        <Typography variant="body2">{`Total de registros: ${totalRows}`}</Typography>
      </>
    )}
  </Stack>
);

export default TableHelper;
