import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Icon} from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import {alpha} from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from './MenuPopover';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {invitacion} from '../../services/dto/auth';
import {
  acceptInvitationUser,
  deleteNotificationMesa,
} from '../../redux/slices/user-slice/user.thunks';
import {MesaSucursal, Notifications} from '../../services/dto/orden';
import useBrowserNotification from '../../common/hooks/use-browser-notification';

function renderContent(notification: invitacion) {
  const title = (
    <Typography variant="subtitle1">
      {`${notification.restaurante.nombre}`}
      <Typography
        component="span"
        variant="body2"
        sx={{
          color: 'text.secondary',
        }}>
        &nbsp;
        {`  te ha invitado a formar parte de su equipo, con el cargo de  ${notification.cargo.nombre_cargo}.`}
      </Typography>
    </Typography>
  );

  const logo = notification.restaurante.imagenes?.filter(
    value => value.tipo_imagen === 'logo',
  )[0];

  return {
    avatar: (
      <img
        alt={notification.restaurante.nombre}
        src={logo ? logo.ruta_imagen : '/static/logo.svg'}
      />
    ),
    title,
  };
}

type notifyItemPropType = {
  handleAction: (code: string, action: number) => void;
  notification: invitacion;
};

var NotificationItem = function ({
  notification,
  handleAction,
}: notifyItemPropType) {
  const {avatar, title} = renderContent(notification);
  const {codigo_confirmacion} = notification;

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.empleado_validado && {
          bgcolor: 'action.selected',
        }),
      }}>
      <ListItemAvatar>
        <Avatar sx={{bgcolor: 'background.neutral', width: 100, height: 100}}>
          {avatar}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}>
            <Box sx={{mr: 0.5, my: 2.5, width: 16, height: 16}} />
            <Button
              sx={{mx: 0.8}}
              variant="contained"
              color="primary"
              onClick={() => handleAction(codigo_confirmacion, 1)}>
              Aceptar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAction(codigo_confirmacion, 0)}>
              Rechazar
            </Button>
          </Typography>
        }
      />
    </ListItemButton>
  );
};

type notifyItemMesaPropType = {
  handleAction: (id_notification: number) => void;
  notification: Notifications;
};

var NotificationItemMesa = function ({
  notification,
  handleAction,
}: notifyItemMesaPropType) {
  const {id_notification, observaciones} = notification;

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        px: 2.5,
        mt: '1px',
      }}>
      <Grid container>
        <Grid item xs={10}>
          <ListItemText
            primary={
              <Typography sx={{fontSize: '12px'}}>{observaciones}</Typography>
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{
              mt: 0.5,
              color: 'text.disabled',
            }}>
            <IconButton
              sx={{mx: 0.8}}
              color="primary"
              size={'small'}
              onClick={() => handleAction(id_notification)}>
              <Icon icon={checkmarkFill} width={20} height={20} />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
    </ListItemButton>
  );
};

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const userState = useAppSelector(store => store.userSlice);
  const mesas = useAppSelector<MesaSucursal[]>(
    store => store.ordenSlice.uiData.mesas,
  );
  const dispatch = useAppDispatch();
  const invitaciones = userState?.userDetail?.invitaciones;
  const [open, setOpen] = useState(false);
  const [mesaNotificacion, setMesaNotificacion] = useState<Notifications[]>([]);

  const [totalUnRead, settotalUnRead] = useState(0);

  const {handleNotification} = useBrowserNotification();

  useEffect(() => {
    settotalUnRead(
      invitaciones?.filter(item => item.empleado_validado === 0).length || 0,
    );
  }, [invitaciones]);

  /*useEffect(() => {
        if (mesas.length) {
            mesas.forEach(mesa => {
                if (mesa.cuenta) {
                    if (mesa.cuenta.notifications.length) {
                        mesa.cuenta.notifications.forEach(value => {
                            /!*mesaNotificacion.push(value);
                            settotalUnRead(totalUnRead + 1);*!/
                            handleNotification(
                                'Notificación de mesa',
                                value.observaciones,
                                value.id_notification,
                                ev => {
                                    dispatch(deleteNotificationMesa(value.id_notification))
                                    const idsNotification = (JSON.parse(localStorage.getItem('browsernotificationsid') || '[]') as Array<number>)
                                    const index = idsNotification.indexOf(value.id_notification);
                                    if (index !== -1) {
                                        idsNotification.splice(index, 1);
                                    }
                                    localStorage.setItem('browsernotificationsid', JSON.stringify(idsNotification));
                                }
                            ).finally()
                        });
                    }
                }
            });
        }
    }, [mesas]);*/

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleAction = async (code: string, action: number) => {
    await dispatch(acceptInvitationUser({accion: action, code}));
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: theme =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity,
              ),
          }),
        }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 360}}>
        <Box sx={{display: 'flex', alignItems: 'center', py: 2, px: 2.5}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="subtitle1">Notificaciones</Typography>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              Tienes {totalUnRead} notificaciones sin leer.
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{height: {xs: 340, sm: 'auto'}}}>
          {mesaNotificacion && mesaNotificacion.length ? (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{py: 1, px: 2.5, typography: 'overline'}}>
                  Notificaciones
                </ListSubheader>
              }>
              {mesaNotificacion.map((value, index) => (
                <NotificationItemMesa
                  key={index}
                  notification={value}
                  handleAction={id_notification => {
                    const aux = mesaNotificacion.filter(
                      mesa => mesa.id_notification !== id_notification,
                    );
                    setMesaNotificacion(aux);
                    dispatch(deleteNotificationMesa(id_notification));
                    settotalUnRead(totalUnRead - 1);
                  }}
                />
              ))}
            </List>
          ) : (
            ''
          )}
          {invitaciones && invitaciones.length ? (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{py: 1, px: 2.5, typography: 'overline'}}>
                  Invitaciones
                </ListSubheader>
              }>
              {invitaciones.map((notification, index) => (
                <NotificationItem
                  key={index}
                  notification={notification}
                  handleAction={handleAction}
                />
              ))}
            </List>
          ) : (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{py: 2.5, px: 2.5, typography: 'overline'}}>
                  No tienes invitaciones
                </ListSubheader>
              }
            />
          )}
        </Scrollbar>

        <Divider />

        <Box sx={{p: 2.5}}></Box>
      </MenuPopover>
    </>
  );
}
