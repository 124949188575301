import AccordionTab from '../../components/accordion';
import BackSendButtons from './back-send';
import ContainerBox from './container';
import DesgloseForm from './desglose-form';
import MetodoPagoForm from './metodo-pago-form';

const MobileView = () => {
  return (
    <ContainerBox>
      <AccordionTab title="DESGLOSE DE EFECTIVO">
        <DesgloseForm />
      </AccordionTab>
      <AccordionTab title="MÉTODOS DE PAGO">
        <MetodoPagoForm />
      </AccordionTab>
      <BackSendButtons />
    </ContainerBox>
  );
};

export default MobileView;
