import {Grid} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import InsumoForm from '../../components/insumo-form';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {useAppDispatch, useAppSelector} from '../../redux/store';

import {
  deleteInsumo,
  fetchCategoriaInsumo,
  fetchInsumoSucursal,
  fetchSubCategoriaInsumo,
  fetchUnidadMedida,
} from '../../redux/slices/insumo-slice/insumo.thunks';
import {cargarInsumo} from '../../redux/slices/insumo-slice';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import CrudTable from '../../components/crud-table';
import getNestedValue from '../../common/getNestedValue';
import sortArrayObject from '../../common/sortArrayObject';
import {LoadingButton} from '@mui/lab';

function Insumos() {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('nombre_insumo');
  const [arrayKeys, setArrayKeys] = useState<string[]>([
    'insumo',
    'nombre_insumo',
  ]);

  const [openCrear, setOpenCrear] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [idToEliminar, setIdToEliminar] = useState(0);
  const dispatch = useAppDispatch();
  const insumos = useAppSelector(store => store.insumoSlice.insumos);
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const columnsInsumo = [
    {
      label: 'Productos',
      key: ['insumo', 'nombre_insumo'],
    },
    {
      label: 'Categoria',
      key: ['insumo', 'categoria_insumo', 'nombre_categoria_insumo'],
    },
    {
      label: 'Sub-categoria',
      key: ['insumo', 'sub_categoria_insumo', 'nombre_sub_categoria_insumo'],
    },
    {
      label: 'Codigo',
      key: ['id_insumo_sucursal'],
    },
    {
      label: 'Unidad de medida',
      key: ['insumo', 'unidad_medida', 'nombre_unidad_medida'],
    },
    {
      label: 'Costo\u00A0(USD)',
      key: ['insumo', 'costo'],
    },
    {
      label: '',
      key: ['insumo_id'],
    },
  ];

  const fetchAllScreenData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(fetchInsumoSucursal());
    await dispatch(fetchCategoriaInsumo());
    await dispatch(fetchSubCategoriaInsumo());
    await dispatch(fetchUnidadMedida());
    dispatch(stopLoading());
  }, []);

  useEffect(() => {
    fetchAllScreenData();
  }, []);

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['insumo', 'nombre_insumo'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, [
        'insumo',
        'categoria_insumo',
        'nombre_categoria_insumo',
      ])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, [
        'insumo',
        'sub_categoria_insumo',
        'nombre_sub_categoria_insumo',
      ])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  const handleEliminar = (id: number) => {
    setIdToEliminar(id);
    setOpenEliminar(true);
  };

  const deleteinsumo = async (arg: number) => {
    setOpenEliminar(false);
    dispatch(startLoading());
    const find = [...insumos].find(value => value.insumo_id === arg);
    if (find) await dispatch(deleteInsumo(find.id_insumo_sucursal));
    setIdToEliminar(0);
    await dispatch(fetchInsumoSucursal());
    dispatch(stopLoading());
  };

  return (
    <Pagetemplate isLoading={isLoading} title="Insumos || IFUDIS" soloLayout>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <CrudTable
            title="Insumos"
            rows={[...insumos].sort((a, b) =>
              sortArrayObject(a, b, arrayKeys, order),
            )}
            columns={columnsInsumo}
            setOpenCrear={() => setOpenCrear(true)}
            OnEdit={async id => {
              dispatch(cargarInsumo(id));
              setOpenEdit(true);
            }}
            onSearch={handleOnSearch}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
            onDelete={handleEliminar}
          />
        </Grid>
      </Grid>
      <DialogPopUp
        open={openCrear}
        handleClose={() => setOpenCrear(false)}
        title="Crear insumo">
        <InsumoForm handleClose={() => setOpenCrear(false)} type="crear" />
      </DialogPopUp>
      <DialogPopUp
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        title="Editar insumo">
        <InsumoForm handleClose={() => setOpenEdit(false)} type="actualizar" />
      </DialogPopUp>
      <DialogPopUp
        open={openEliminar}
        handleClose={() => setOpenEliminar(false)}
        title={'Eliminar Insumo'}>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <LoadingButton
              variant="contained"
              onClick={() => deleteinsumo(idToEliminar)}
              color="secondary">
              Confirmar
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogPopUp>
    </Pagetemplate>
  );
}

export default Insumos;
