import CustomTableBody from './table-body';
import CustomTableFooter from './table-footer';
import CustomTableHeader from './table-header';
import TableWrapper from './table-wrapper';

const RestaurantesTable = () => {
  return (
    <TableWrapper>
      <CustomTableHeader />
      <CustomTableBody />
      <CustomTableFooter />
    </TableWrapper>
  );
};

export default RestaurantesTable;
