import {SyntheticEvent, useState} from 'react';
import {Stack, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {actualizarLogin} from '../../redux/slices/user-slice';

type PropsTypes = {
  handleOnSubmit: (email: string) => Promise<void>;
};

const SendPinForm = ({handleOnSubmit}: PropsTypes) => {
  const userData = useAppSelector(store => store.userSlice.editable.login);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (e: SyntheticEvent) => {
    if (!userData.email) return;
    e.preventDefault();
    setLoading(true);
    await handleOnSubmit(userData.email);
    setLoading(false);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} direction="column" gap={1}>
      <Typography variant="h4" gutterBottom>
        Recuperar contraseña
      </Typography>
      <Typography sx={{color: 'text.secondary'}} gutterBottom>
        Ingresa tu correo electrónico y te enviaremos un código PIN para
        recuperar tu contraseña.
      </Typography>
      <Typography sx={{color: 'text.secondary'}}>
        Si no recibes el correo electrónico en unos minutos, puedes contactarte
        con el administrador del sistema.
      </Typography>
      <TextField
        required
        type="email"
        variant="outlined"
        label="Correo electrónico"
        fullWidth
        margin="normal"
        value={userData.email}
        onChange={e => dispatch(actualizarLogin({email: e.target.value}))}
      />
      <LoadingButton
        disabled={userData.email?.trim().length === 0}
        loading={loading}
        loadingPosition="start"
        type="submit"
        variant="contained"
        color="primary"
        fullWidth>
        {loading ? 'Enviando correo...' : 'Restablecer contraseña'}
      </LoadingButton>
    </Stack>
  );
};

export default SendPinForm;
