import {Avatar, Button, Chip, Stack, Switch} from '@mui/material';
import {fCurrency} from '../../common/formatNumber';
import getNestedValue from '../../common/getNestedValue';
import isBoolean from '../../common/isBoolean';
import roundMoney from '../../common/roundMoney';
import {CategoriasProveedor} from '../../services/dto/compras';
import {subcategoriaRelacion} from '../../services/dto/menu';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useAppSelector} from '../../redux/store';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Modificador} from '../../services/dto/modificadores';
dayjs.extend(customParseFormat);

type PropsTypes = {
  keyArray: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  };
  valueArray: any;
  id_registro: string;
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
  OnEdit?: (arg: number) => void;
  onSwitch?: (arg: boolean, id: number) => void;
  onAddCompra?: (id_proveedor: number) => void;
  onEditCompra?: (id_compra: number) => void;
  onSwitchRender?: (id: number) => {disabled: boolean};
};

const TableGetContent = ({
  keyArray,
  valueArray,
  id_registro,
  columns,
  OnEdit,
  onSwitch,
  onSwitchRender,
  onAddCompra,
  onEditCompra,
}: PropsTypes) => {
  switch (keyArray.label) {
    case 'Compras': {
      const selectedCategoria = useAppSelector(
        store => store.comprasSlice.selectedCategoriaInsumo,
      );

      const filteredComprasSucursal = useAppSelector(
        store => store.comprasSlice.filteredCompras,
      )
        .slice()
        .sort(
          (a, b) =>
            dayjs(a.fecha_hora_compra, 'YYYY-MM-DD HH:mm a').valueOf() -
            dayjs(b.fecha_hora_compra, 'YYYY-MM-DD HH:mm a').valueOf(),
        );

      const purchases = filteredComprasSucursal
        .filter(element => {
          return element.proveedor_id == valueArray.id_proveedor;
        })
        .map(element => {
          return {
            id: element.id_compra_sucursal,
            amount: element.monto_compra,
            estado_compra: element.estado_compra,
          };
        });

      const canBuyMore = valueArray.categorias_proveedor.some(
        (element: any) => element.categoria_insumo_id == selectedCategoria,
      ) as boolean;

      return (
        <Stack
          direction="row"
          spacing={1}
          sx={{display: 'flex', alignItems: 'center'}}>
          {purchases.map((purchase, index) => (
            <Chip
              key={purchase + index.toString()}
              label={`${fCurrency(purchase.amount)}`}
              variant="filled"
              color="secondary"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                backgroundColor:
                  purchase.estado_compra.id_estado_compra === 1
                    ? 'green'
                    : 'red',
              }}
              clickable
              onClick={() => {
                if (onEditCompra) onEditCompra(purchase.id);
              }}
            />
          ))}
          {onAddCompra && canBuyMore ? (
            <Button
              color="secondary"
              size="small"
              onClick={() => onAddCompra(valueArray[id_registro])}>
              <AddCircleOutlineIcon />
            </Button>
          ) : undefined}
        </Stack>
      );
    }

    case 'Compra total(USD)': {
      const filteredComprasSucursal = useAppSelector(
        store => store.comprasSlice.filteredCompras,
      );

      const compras = filteredComprasSucursal.filter(element => {
        return element.proveedor_id == valueArray.id_proveedor;
      });

      const total = compras.reduce((a, b) => a + b.monto_compra, 0);

      return fCurrency(total);
    }

    case 'Subcategorias':
      return (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
          }}>
          {valueArray.subCategorias.map(
            (subcategoria: subcategoriaRelacion) => (
              <Chip
                sx={{
                  mr: 0.5,
                  mb: 0.5,
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                key={subcategoria.id_subcategoria_producto}
                label={subcategoria.nombre_subcategoria}
              />
            ),
          )}
        </Stack>
      );
    case 'Categorias':
      return (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
          }}>
          {valueArray.categorias_proveedor.map(
            (categorias_proveedor: CategoriasProveedor, index: number) => (
              <Chip
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 'bold',
                  mr: 0.5,
                  mb: 0.5,
                }}
                key={categorias_proveedor.id_categoria_proveedor}
                label={
                  categorias_proveedor.categoria_insumo.nombre_categoria_insumo
                }
              />
            ),
          )}
        </Stack>
      );

    case 'Estatus':
      if (!onSwitch) return null;
      const values = onSwitchRender
        ? onSwitchRender(valueArray[id_registro])
        : undefined;
      return (
        <Switch
          disabled={values?.disabled}
          checked={isBoolean(getNestedValue(valueArray, keyArray.key))}
          onChange={() =>
            onSwitch(
              !isBoolean(getNestedValue(valueArray, keyArray.key)),
              valueArray[id_registro],
            )
          }
          color="primary"
        />
      );
    case 'Imagen':
      return (
        <Avatar
          sx={{
            width: 55,
            height: 55,
            objectFit: 'contain',
            cursor: 'pointer',
          }}
          variant="rounded"
          alt="imagen-product"
          src={getNestedValue(valueArray, keyArray.key)}
          onClick={() => {
            if (OnEdit)
              return OnEdit(
                getNestedValue(valueArray, columns[columns.length - 1].key),
              );
            return;
          }}
        />
      );
    case 'Accion':
      return (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
          }}>
          {(valueArray as Modificador).acciones.map((accion, index: number) => (
            <Chip
              sx={{
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 'bold',
                mr: 0.5,
                mb: 0.5,
              }}
              key={accion.id_accion_modificador}
              label={accion.nombre_accion_modificador}
            />
          ))}
        </Stack>
      );
    case 'Precio\u00A0(USD)':
      return fCurrency(getNestedValue(valueArray, keyArray.key));
    case 'Monto\u00A0(USD)':
      return fCurrency(getNestedValue(valueArray, keyArray.key));
    case 'Monto Total\u00A0(USD)':
      return fCurrency(getNestedValue(valueArray, keyArray.key));
    case 'Costo\u00A0(USD)':
      return fCurrency(getNestedValue(valueArray, keyArray.key));
    case '%':
      return `${roundMoney(getNestedValue(valueArray, keyArray.key))}%`;
    case 'Actual':
      const actualValueRow = getNestedValue(valueArray, keyArray.key);
      const minimoValueRow = getNestedValue(valueArray, [
        'min_max',
        'cant_min',
      ]);
      if (parseInt(actualValueRow) > -1) {
        var intActual = parseInt(actualValueRow);
        if (parseInt(minimoValueRow) > -1) {
          const intMinimo = parseInt(minimoValueRow);
          if (intActual - 6 < intMinimo) {
            return <strong style={{color: 'red'}}>{actualValueRow}</strong>;
          }
        }
      }
      return getNestedValue(valueArray, keyArray.key);
    default:
      return getNestedValue(valueArray, keyArray.key);
  }
};

export default TableGetContent;
