import {Box, Stack, TextField} from '@mui/material';
import AccordionTab from '../../components/accordion';
import BackSendButtons from './back-send';
import ContainerBox from './container';
import DesgloseForm from './desglose-form';
import MetodoPagoForm from './metodo-pago-form';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {setObservaciones} from '../../redux/slices/cierre-slice';

const DesktopView = () => {
  const observaciones = useAppSelector(
    state => state.cierreSlice.observaciones,
  );
  const dispatch = useAppDispatch();
  return (
    <ContainerBox>
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          flexDirection: 'row',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}>
        <AccordionTab width="45%" title="DESGLOSE DE EFECTIVO">
          <DesgloseForm />
        </AccordionTab>
        <AccordionTab width="45%" title="MÉTODOS DE PAGO">
          <MetodoPagoForm />
        </AccordionTab>
        <AccordionTab width="95%" title="Observaciones">
          <Box sx={{width: '100%'}} py={2}>
            <TextField
              fullWidth
              multiline
              value={observaciones}
              onChange={e => dispatch(setObservaciones(e.target.value))}
            />
          </Box>
        </AccordionTab>
        <Box sx={{width: '100%'}}>
          <BackSendButtons />
        </Box>
      </Stack>
    </ContainerBox>
  );
};

export default DesktopView;
