import {putPost} from '../../redux/slices/menu-slice/menu.types';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useEffect} from 'react';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import UserSearch from '../user-search';

import {MultiSelector2} from '../multi-selector/MultiSelector';
import {
  actualizarEmpleadoRestaurante,
  resetEditable,
} from '../../redux/slices/empleados-slice';
import {putPostEmpleado} from '../../redux/slices/empleados-slice/empleados.thunks';
import {listOfRolesInOrderID} from '../../common/isRouteAllowed';

type propsTypes = {
  type: putPost;
  handleClose: VoidFunction;
};

function NewEmpleadoForm({type, handleClose}: propsTypes) {
  const dispatch = useAppDispatch();
  const cargoId = useAppSelector(
    store => store.restauranteSlice.cargoEmpleado?.id_cargo,
  );
  const isLoading = useAppSelector(state => state.uiSlice.isLoading);
  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    await dispatch(putPostEmpleado(type))
      .unwrap()
      .then(x => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  const empleado = useAppSelector(
    store => store.empleadoSlice.editable.empleado,
  );

  const cargos = useAppSelector(store => store.empleadoSlice.cargos);

  const sucursales = useAppSelector(state => state.restauranteSlice.sucursales);

  useEffect(() => {
    return () => {
      dispatch(resetEditable('empleado'));
    };
  }, []);

  const isAbleToModify = (
    cargoIdUser: number | undefined,
    cargoIdEmpleado: number | null,
  ): boolean => {
    if (!cargoIdUser || !cargoIdEmpleado) return false;
    const userRank = listOfRolesInOrderID.indexOf(cargoIdUser);
    const empleadoRank = listOfRolesInOrderID.indexOf(cargoIdEmpleado);
    if (userRank > empleadoRank) return true;
    return false;
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        {type === 'crear' ? (
          <Grid item xs={12}>
            <Card raised>
              <CardContent
                style={{
                  textAlign: 'center',
                }}>
                <Stack spacing={3}>
                  <Stack direction={{xs: 'column'}} spacing={2}>
                    <UserSearch
                      onSelected={user => {
                        if (!user) return;
                        dispatch(
                          actualizarEmpleadoRestaurante({usuario_id: user.id}),
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de cargos</InputLabel>
                  <Select
                    value={empleado.cargo_id?.toString()}
                    label="cargo"
                    disabled={isAbleToModify(cargoId, empleado.cargo_id)}
                    onChange={e => {
                      dispatch(
                        actualizarEmpleadoRestaurante({
                          cargo_id: parseInt(e.target.value),
                        }),
                      );
                    }}>
                    {cargos
                      .filter(element => {
                        if (!cargoId) return false;
                        return listOfRolesInOrderID
                          .slice(listOfRolesInOrderID.indexOf(cargoId))
                          .includes(element.id_cargo);
                      })
                      .sort(
                        (a, b) =>
                          listOfRolesInOrderID.indexOf(a.id_cargo) -
                          listOfRolesInOrderID.indexOf(b.id_cargo),
                      )
                      .map(element => (
                        <MenuItem
                          key={element.id_cargo}
                          value={element.id_cargo}>
                          {element.nombre_cargo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <MultiSelector2
                  selectedData={empleado.sucursales || []}
                  sourceData={sucursales}
                  type="sucursal"
                  title="Sucursal"
                  fullWidth
                  disabled={isAbleToModify(cargoId, empleado.cargo_id)}
                  onChange={value => {
                    dispatch(
                      actualizarEmpleadoRestaurante({sucursales: value}),
                    );
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoading}
            //   disabled={!(formData.usuario_id > 0 && formData.cargo_id > 0)}
          >
            Guardar cambios
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewEmpleadoForm;
