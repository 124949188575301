import {Box} from '@mui/material';
import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import NavBarLabel from '../../components/navbar-labels/navbar-labels';
import {useAppSelector} from '../../redux/store';

const SectionLabel = () => {
  const location = useLocation();
  const currentStep = useAppSelector(store => store.ordenSlice.currentStep);

  const dimentions = {
    xs: 'none',
    sm: 'block',
  };

  const getSectionName = useCallback(() => {
    switch (location.pathname) {
      case '/orden': {
        switch (currentStep) {
          case 1:
            return <NavBarLabel dimensions={dimentions} text="MESAS" />;
          case 6:
            return <NavBarLabel dimensions={dimentions} text="CIERRES" />;
          default:
            return <NavBarLabel dimensions={dimentions} text="ORDENES" />;
        }
      }
      case '/reportes/balance-actual': {
        return <NavBarLabel dimensions={dimentions} text="BALANCE ACTUAL" />;
      }
      case '/reportes/ventas-articulos': {
        return <NavBarLabel dimensions={dimentions} text="Por artículos" />;
      }
      case '/reportes/ventas-metodo-pago': {
        return (
          <NavBarLabel dimensions={dimentions} text="Por método de pago" />
        );
      }
      case '/reportes/ventas-categorias': {
        return <NavBarLabel dimensions={dimentions} text="Por categoría" />;
      }
      case '/reportes/ventas-empleados': {
        return (
          <NavBarLabel dimensions={dimentions} text="Por empleado/usuario" />
        );
      }

      case '/reportes/ventas-horas': {
        return <NavBarLabel dimensions={dimentions} text="Por horas" />;
      }
      case '/reportes/ventas-mesas': {
        return <NavBarLabel dimensions={dimentions} text="Por mesa" />;
      }
      case '/reportes/ventas-descuentos': {
        return <NavBarLabel dimensions={dimentions} text="Descuentos" />;
      }
      case '/reportes/ventas-cortesias': {
        return (
          <NavBarLabel dimensions={dimentions} text="Cortesias/Invitaciones" />
        );
      }
      case '/reportes/articulos-borrados': {
        return (
          <NavBarLabel dimensions={dimentions} text="Articulos borrados" />
        );
      }

      case '/comandas': {
        return <NavBarLabel dimensions={dimentions} text="COMANDAS" />;
      }
      case '/diario-ventas': {
        return <NavBarLabel dimensions={dimentions} text="DIARIO DE VENTAS" />;
      }
      case '/insumos': {
        return <NavBarLabel dimensions={dimentions} text="INSUMOS" />;
      }
      case '/inventario': {
        return <NavBarLabel dimensions={dimentions} text="INVENTARIO" />;
      }
      case '/compras': {
        return <NavBarLabel dimensions={dimentions} text="COMPRAS" />;
      }
      case '/menu': {
        return <NavBarLabel dimensions={dimentions} text="MENÚ" />;
      }
      case '/empleados': {
        return <NavBarLabel dimensions={dimentions} text="EMPLEADOS" />;
      }
      case '/caja': {
        return <NavBarLabel dimensions={dimentions} text="CAJA" />;
      }
      case '/administracion/restaurante': {
        return (
          <NavBarLabel dimensions={dimentions} text="RESTAURANTE & SUCUSALES" />
        );
      }
      case '/administracion/mercadeo': {
        return <NavBarLabel dimensions={dimentions} text="MERCADEO" />;
      }
      case '/administracion/finanzas': {
        return <NavBarLabel dimensions={dimentions} text="FINANZAS" />;
      }
      case '/administracion/seccion': {
        return <NavBarLabel dimensions={dimentions} text="SECCIONES" />;
      }
      case '/administracion/mesas': {
        return <NavBarLabel dimensions={dimentions} text="MESAS" />;
      }
      default:
        return null;
    }
  }, [location.pathname, currentStep, dimentions]);

  return <Box sx={{mr: 2, display: 'inline-flex'}}>{getSectionName()}</Box>;
};

export default SectionLabel;
