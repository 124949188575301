import {Typography, useTheme} from '@mui/material';
import './navbar-label.css';

type PropsTypes = {
  text?: string;
  hovertext?: string;
  onClick?: () => void;
  dimensions?: {
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
  };
};

function NavBarLabel({
  text,
  hovertext,
  dimensions = {
    xs: 'none',
    md: 'block',
  },
  ...rest
}: PropsTypes) {
  const theme = useTheme();
  return (
    <Typography
      noWrap
      sx={{
        display: dimensions,
        textAlign: 'left',
        maxWidth: '250px',
        cursor: 'pointer',
        fontSize: '0.8rem',
        borderRadius: '10px',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        px: 2,
        py: 0.2,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
      {...rest}>
      <span className={`${hovertext && 'text_original'} box`}>{text}</span>
      {hovertext && <span className="text_cambio box">{hovertext}</span>}
    </Typography>
  );
}

export default NavBarLabel;
