import {Grid, Tooltip, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

type restauranteBoxType = {
  hasImage?: string | undefined;
  nombre?: string | undefined;
  onClick?: () => void | undefined;
  disabled?: boolean;
};

export const RestauranteBox = ({
  hasImage,
  nombre,
  onClick,
  disabled,
}: restauranteBoxType) => {
  return (
    <Tooltip
      color={disabled ? 'red' : undefined}
      title={disabled ? 'Estas desactivado en este restaurante' : nombre ?? ''}>
      <Grid
        onClick={onClick}
        item
        sx={{
          cursor: 'pointer',
          position: 'relative',
          border: '5px solid transparent',
          '&:hover > span': {
            border: disabled
              ? '5px solid rgb(255, 41, 41)'
              : '5px solid rgb(245, 152, 35)',
            padding: '5px',
          },
          '&:hover > div': {
            border: disabled
              ? '5px solid rgb(255, 41, 41)'
              : '5px solid rgb(245, 152, 35)',
          },

          height: {
            xs: 90,
            md: 120,
          },
          width: {
            xs: '100%',
            md: '100%',
          },
          maxWidth: {
            xs: 90,
            md: 120,
          },
          overflow: 'hidden',
        }}>
        {hasImage ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              borderRadius: '15px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              transition: 'all 400ms ease-in-out',
            }}>
            <img
              style={{
                filter: `grayscale(${disabled ? '60%' : ' 0%'})`,
                borderRadius: '8px',
                width: '100%',
                height: '100%',
                objectFit: 'fill',
              }}
              src={`${hasImage}`}
              alt={`${nombre}`}
            />
          </div>
        ) : (
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#9f9f9f',
              backgroundColor: 'gray',
              borderRadius: '15%',
              width: '100%',
              height: '100%',
              textTransform: 'uppercase',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              display: 'flex',
              transition: 'all 200ms ease-in-out',
              padding: '5px',
            }}>
            {nombre ? `${nombre}` : <AddIcon fontSize="large" />}
          </span>
        )}
      </Grid>
    </Tooltip>
  );
};

type sucursalBoxPropsTypes = {
  nombre: string;
  onClick: () => void;
  isselected?: number;
};

export const SucursalesBox = (props: sucursalBoxPropsTypes) => {
  const {nombre, isselected} = props;
  return (
    <Tooltip title={nombre}>
      <Grid
        {...props}
        item
        sx={{
          cursor: 'pointer',
          position: 'relative',
          pr: 1,
          pb: 1,
          border: '5px solid transparent',
          '&:hover > span': {
            border: '5px solid rgb(245, 152, 35)',
            padding: '5px',
          },
          '&:hover > img': {
            border: '5px solid rgb(245, 152, 35)',
          },
          transition: 'all 200ms ease-in-out',
          height: {
            xs: 90,
            md: 120,
          },
          width: {
            xs: '100%',
            md: '100%',
          },
          maxWidth: {
            xs: 90,
            md: 120,
          },
          minWidth: {
            xs: 90,
            md: 120,
          },
          overflow: 'hidden',
        }}>
        <Typography
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#9f9f9f',
            backgroundColor: 'gray',
            borderRadius: '15%',
            width: '100%',
            height: '100%',
            textTransform: 'uppercase',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
            transition: 'all 200ms ease-in-out',
            padding: '5px',
            border: `${isselected ? '5px solid rgb(245, 152, 35)' : ''}`,
          }}>
          {nombre}
        </Typography>
      </Grid>
    </Tooltip>
  );
};
