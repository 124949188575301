// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

type propType = {
  width?: number;
  height?: number;
  sx?: {};
};

export default function Logo({sx, width = 80, height = 80}: propType) {
  return (
    <Box
      component="img"
      alt="logo-svg-logoempresa"
      src="/static/logo.svg"
      sx={{width, height, ...sx}}
    />
  );
}
