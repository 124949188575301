import {Box, Button, Stack, Typography} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useMediaQuery from '../../common/hooks/use-media-query';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {useMemo} from 'react';
import {postInventarioSucursal} from '../../redux/slices/inventario-slice/inventario.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {fCurrency} from '../../common/formatNumber';

function InventarioBottomBar() {
  const sm = useMediaQuery('(max-width:600px)');
  const inventario = useAppSelector(
    store => store.inventarioSlice.editable.inventario,
  );

  const dispatch = useAppDispatch();
  const isLastestInventario = useAppSelector(
    store => store.inventarioSlice.isLastestInventario,
  );
  const inventarioPasado = useAppSelector(
    store => store.inventarioSlice.inventarioPasado,
  );

  const selectedCategory = useAppSelector(
    store => store.inventarioSlice.selectedInsumoSubcategory,
  );

  const inventarioNewTotal = useMemo(
    () =>
      inventario
        .filter(
          element =>
            element.insumo.insumo.categoria_insumo_id === selectedCategory,
        )
        .reduce((a, b) => a + b.insumo.insumo.costo * (b.cantidad ?? 0), 0),
    [inventario, selectedCategory],
  );

  const inventarioOldTotal = useMemo(
    () =>
      inventarioPasado?.insumos_inventariados
        .filter(
          element => element.insumo.categoria_insumo_id === selectedCategory,
        )
        .reduce((a, b) => a + b.insumo.costo * b.cantidad, 0),
    [inventarioPasado, selectedCategory],
  );

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        color: 'white',
        px: {
          xs: 2,
          lg: 5,
        },
      }}>
      <Stack direction="row" sx={{display: 'flex', alignItems: 'center'}}>
        <Button
          variant="contained"
          color="inherit"
          onClick={async () => {
            dispatch(startLoading());
            await dispatch(postInventarioSucursal());
            dispatch(stopLoading());
          }}
          sx={{
            mr: 1,
            fontSize: '10px',
          }}>
          {sm ? <SaveIcon /> : 'GUARDAR CAMBIOS'}
        </Button>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography variant="body1" sx={{mr: 1}}>
          Total:{' '}
        </Typography>
        <Typography variant="body2">
          {' '}
          {isLastestInventario ? 'Ultimo' : ''}
        </Typography>
        <Typography sx={{maxWidth: 100, fontWeight: 'bold'}} variant="body2">
          {fCurrency(inventarioOldTotal ? inventarioOldTotal : 0)}
        </Typography>
        <Typography variant="body1">Nuevo</Typography>
        <Typography sx={{maxWidth: 100, fontWeight: 'bold'}} variant="body2">
          {fCurrency(inventarioNewTotal)}
        </Typography>
      </Stack>
    </Box>
  );
}

export default InventarioBottomBar;
