import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {fCurrency} from '../../common/formatNumber';
import useMediaQuery from '../../common/hooks/use-media-query';
import {useAppSelector} from '../../redux/store';
import {ModificadoresMenu} from '../../services/dto/orden';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
  modificadores: ModificadoresMenu[];
  selectedModificadores: ModificadoresMenu[];
  onChange: (modificadores: ModificadoresMenu[]) => void;
  onSubmit: VoidFunction;
  productName: string;
};

const ModificatorsModal = ({
  state,
  handleClose,
  selectedModificadores,
  onChange,
  modificadores,
  onSubmit,
  productName,
}: PropsTypes) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const md = useMediaQuery('(max-width:1020px)');
  const sm = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    gridRef.current?.addEventListener('mousewheel', e => {
      //@ts-ignore
      if (gridRef.current) gridRef.current.scrollLeft -= e.wheelDelta;
    });
  }, []);

  const acciones = useAppSelector(
    store => store.modificadorSlice.accionesModificador,
  );

  const filteredAccion = useMemo(
    () =>
      acciones.filter(e =>
        modificadores.some(
          modi =>
            modi.accion_asignada_modificador.accion.id_accion_modificador ===
            e.id_accion_modificador,
        ),
      ),

    [acciones],
  );

  const [selectedAccion, setSelectedAccion] = useState<number | null>(null);

  useEffect(() => {
    if (
      !filteredAccion ||
      !Array.isArray(filteredAccion) ||
      !filteredAccion.length
    )
      return;
    setSelectedAccion(filteredAccion[0]?.id_accion_modificador ?? null);
  }, [filteredAccion]);

  return (
    <DialogPopUp
      open={state}
      fullScreen={sm}
      PaperProps={{
        style: {
          width: md ? '100%' : '60%',
          maxWidth: 'none',
        },
      }}
      handleClose={handleClose}
      title={`Modificar Menu de ${productName}`}
      noPadding>
      <form
        style={{
          height: '100%',
        }}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleClose();
        }}>
        <Stack
          direction="column"
          sx={{
            height: '100%',
          }}>
          <Box
            ref={gridRef}
            sx={{
              widht: '100%',
              height: '110px',
              px: 2,
              py: 1.7,
              overflowX: 'auto',
            }}>
            <Grid
              spacing={2}
              container
              sx={{
                height: '100%',
                display: 'flex',
                alignContent: md ? 'flex-start' : 'center',
              }}
              direction="column">
              {filteredAccion.map((element, index) => (
                <Grid key={index} item>
                  <Card
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '10px',
                      border: '1px solid #e1ddddff',
                    }}
                    raised>
                    <CardActionArea
                      sx={{
                        height: md ? '65px' : '80px',
                        width: '140px',
                        bgcolor:
                          selectedAccion === element.id_accion_modificador
                            ? '#f6a721'
                            : undefined,
                      }}
                      onClick={() =>
                        setSelectedAccion(element.id_accion_modificador)
                      }>
                      <CardContent
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            lineHeight: 0.9,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}>
                          {element.nombre_accion_modificador}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              flex: '1 1 auto',
              bgcolor: '#acacaca0',
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              p: 2,
            }}>
            <Grid
              spacing={1}
              container
              sx={{
                overflowY: 'auto',
                display: 'flex',
                alignContent: 'flex-start',
                justifyContent: 'flex-start',
                height: 'calc(100% - 60px);',
              }}
              direction="row">
              {modificadores
                .filter(
                  e =>
                    e.accion_asignada_modificador.accion
                      .id_accion_modificador === selectedAccion,
                )
                .map((element, index) => {
                  const isSelected = !!selectedModificadores.find(
                    e => e.id_modificador_menu === element.id_modificador_menu,
                  );

                  const shouldShowPrice =
                    element.accion_asignada_modificador.accion
                      .id_accion_modificador !== 2 &&
                    element.accion_asignada_modificador.accion
                      .id_accion_modificador !== 3;

                  return (
                    <Grid key={index} item>
                      <Card
                        onClick={() => {
                          if (isSelected) {
                            const copy = selectedModificadores
                              .slice()
                              .filter(
                                e =>
                                  e.id_modificador_menu !=
                                  element.id_modificador_menu,
                              );
                            onChange(copy);
                            return;
                          }
                          const copy = selectedModificadores.slice();
                          copy.push(element);
                          onChange(copy);
                        }}
                        raised>
                        <CardActionArea
                          sx={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            width: md ? '110px' : '140px',
                            height: '100px',
                            borderRadius: '10px',
                            border: '1px solid #e1ddddff',
                            bgcolor: isSelected ? '#f6a721' : undefined,
                          }}>
                          <CardContent
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}>
                            <Typography
                              sx={{
                                fontSize: '0.9rem',
                                lineHeight: 0.9,
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                pb: 1,
                              }}>
                              {
                                element.accion_asignada_modificador.modificador
                                  .nombre_modificador
                              }
                            </Typography>
                            {shouldShowPrice && (
                              <Typography
                                sx={{
                                  fontSize: '0.8rem',
                                  lineHeight: 0.9,
                                  textTransform: 'uppercase',
                                }}>
                                (
                                {fCurrency(
                                  element.accion_asignada_modificador
                                    .modificador.precio,
                                )}
                                $)
                              </Typography>
                            )}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>

            <Box
              sx={{
                pt: 2,
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Button
                sx={{width: '50%', height: '100%'}}
                type="submit"
                onClick={onSubmit}
                variant="contained"
                color="primary">
                Continuar
              </Button>
            </Box>
          </Box>
        </Stack>
      </form>
    </DialogPopUp>
  );
};

export default ModificatorsModal;
