import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../services';
import {
  descuentoRestaurantType,
  impuestoRestaurantType,
  metodoPagoRestaurantType,
  propinaRestaurantType,
} from '../../../services/dto/orden';
import {RootState} from '../../store';
import {baseData, finanzasSliceType, typeOfEditable} from './finanzas.types';
import * as yup from 'yup';
import {
  descuentoSchema,
  impuestoSchema,
  metodoPagoSchema,
  propinaSchema,
} from './finanzas.schemas';

export const fetchImpuestos = createAsyncThunk<
  impuestoRestaurantType[],
  undefined,
  {state: RootState}
>('finanzas/fetchImpuestos', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');

  const response = await api.getTipoImpuestoRestaurante(
    restauranteSeleccionado.id,
  );

  if (!response) return thunkAPI.rejectWithValue('error al obtener impuestos');

  return response.data;
});

export const fetchMetodosDePago = createAsyncThunk<
  metodoPagoRestaurantType[],
  undefined,
  {state: RootState}
>('finanzas/fetchMetodosDePago', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');

  const response = await api.getMetodoPagoRestaurante(
    restauranteSeleccionado.id,
  );

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener los metodos de pago');

  return response.data;
});

export const fetchDescuentos = createAsyncThunk<
  descuentoRestaurantType[],
  undefined,
  {state: RootState}
>('finanzas/fetchDescuentos', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');

  const response = await api.getTipoDescuentoRestaurante(
    restauranteSeleccionado.id,
  );

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener los descuentos');

  return response.data;
});

export const fetchPropinas = createAsyncThunk<
  propinaRestaurantType[],
  undefined,
  {state: RootState}
>('finanzas/fetchPropinas', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');

  const response = await api.getTipoPropinaRestaurante(
    restauranteSeleccionado.id,
  );

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener las propinas');

  return response.data;
});

export const putPostPropina = createAsyncThunk<
  propinaRestaurantType[],
  {} & yup.InferType<typeof propinaSchema>,
  {state: RootState}
>('finanzas/putPostPropina', async (data, thunkAPI) => {
  const {
    finanzasSlice: {propinas},
    restauranteSlice: {restauranteEmpleadoSeleccionado},
  } = thunkAPI.getState();

  const body = await propinaSchema.validate(
    {
      ...data,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
    },
    {stripUnknown: true, context: {request: true}},
  );
  const copy = [...propinas];
  if (!body.id_tipo_propina_restaurante) {
    const response = await api.postTipoPropinaRestaurante(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear propina');
    copy.push(response.data);
  }
  return copy;
});

export const putPostDescuentos = createAsyncThunk<
  descuentoRestaurantType[],
  {
    editId?: number;
  } & yup.InferType<typeof descuentoSchema>,
  {state: RootState}
>('finanzas/putPostDescuentos', async (data, thunkAPI) => {
  const {
    finanzasSlice: {descuentos},
    restauranteSlice: {restauranteEmpleadoSeleccionado},
  } = thunkAPI.getState();

  const body = await descuentoSchema.validate(
    {
      ...data,
      id_tipo_descuento_restaurante: data.editId,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
    },
    {stripUnknown: true, context: {request: true}},
  );
  const copy = [...descuentos];
  if (!body.id_tipo_descuento_restaurante) {
    const response = await api.postTipoDescuentoRestaurante(body);
    if (!response)
      return thunkAPI.rejectWithValue('error while creating modificador');
    copy.push(response.data);
  } else {
    const response = await api.putTipoDescuentoRestaurante(
      body,
      body.id_tipo_descuento_restaurante,
    );
    if (!response)
      return thunkAPI.rejectWithValue('error while updating modificador');
    const index = descuentos.findIndex(
      element =>
        element.id_tipo_descuento_restaurante ===
        body.id_tipo_descuento_restaurante,
    )!;
    copy[index] = response.data;
  }
  return copy;
});

export const putPostMetodoPago = createAsyncThunk<
  metodoPagoRestaurantType[],
  {
    editId?: number;
  } & yup.InferType<typeof metodoPagoSchema>,
  {state: RootState}
>('finanzas/putPostMetodoPago', async (data, thunkAPI) => {
  const {
    finanzasSlice: {metodosPago},
    restauranteSlice: {restauranteEmpleadoSeleccionado},
  } = thunkAPI.getState();

  const body = await metodoPagoSchema.validate(
    {
      ...data,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
    },
    {stripUnknown: true, context: {request: true}},
  );
  const copy = [...metodosPago];
  if (!body.id_metodo_pago_restaurante) {
    const response = await api.postMetodoPagoRestaurante(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear metodoPago');
    copy.push(response.data);
  }
  return copy;
});

export const putPostImpuesto = createAsyncThunk<
  impuestoRestaurantType[],
  {
    editId?: number;
  } & yup.InferType<typeof impuestoSchema>,
  {state: RootState}
>('finanzas/putPostImpuesto', async (data, thunkAPI) => {
  const {
    finanzasSlice: {impuestos},
    restauranteSlice: {restauranteEmpleadoSeleccionado},
  } = thunkAPI.getState();
  const body = await impuestoSchema.validate(
    {
      ...data,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
    },
    {stripUnknown: true, context: {request: true}},
  );
  const copy = [...impuestos];
  if (!body.id_tipo_impuesto_restaurante) {
    const response = await api.postTipoImpuestoRestaurante(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear impuesto');
    copy.push(response.data);
  }
  return copy;
});

export const fetchBaseData = createAsyncThunk<
  baseData,
  undefined,
  {state: RootState}
>('finanzas/fetchBaseData', async (data, thunkAPI) => {
  const responses = await Promise.all([
    api.getTipoPropina(),
    api.getTipoDescuento(),
    api.getTipoImpuesto(),
    api.getTipoMetodoPago(),
  ]);

  let isNull = false;
  responses.forEach(response => {
    if (!response) {
      isNull = true;
    }
  });
  if (isNull) return thunkAPI.rejectWithValue('error al obtener datos');

  const baseData = {
    propina: responses[0]!.data,
    descuentos: responses[1]!.data,
    impuesto: responses[2]!.data,
    metodoPago: responses[3]!.data,
  };
  return baseData;
});

export const deleteFinanza = createAsyncThunk<
  Partial<finanzasSliceType>,
  {id: number; type: typeOfEditable},
  {state: RootState}
>('finanzas/deleteFinanza', async (data, thunkAPI) => {
  const {
    finanzasSlice: {descuentos, impuestos, propinas, metodosPago},
  } = thunkAPI.getState();
  switch (data.type) {
    case 'descuento':
      const responseDescuento = await api.deleteTipoDescuentoRestaurante(
        data.id,
      );
      if (!responseDescuento)
        return thunkAPI.rejectWithValue('error al eliminar descuento');
      return {
        descuentos: descuentos.filter(
          e =>
            e.id_tipo_descuento_restaurante !=
            responseDescuento.data.id_tipo_descuento_restaurante,
        ),
      };
    case 'impuesto':
      const responseImpuesto = await api.deleteTipoImpuestoRestaurante(data.id);
      if (!responseImpuesto)
        return thunkAPI.rejectWithValue('error al eliminar impuesto');
      return {
        impuestos: impuestos.filter(
          e =>
            e.id_tipo_impuesto_restaurante !=
            responseImpuesto.data.id_tipo_impuesto_restaurante,
        ),
      };
    case 'metodoPago':
      const responseMetodoPago = await api.deleteMetodoPagoRestaurante(data.id);
      if (!responseMetodoPago)
        return thunkAPI.rejectWithValue('error al eliminar metodo de pago');
      return {
        metodosPago: metodosPago.filter(
          e =>
            e.id_metodo_pago_restaurante !=
            responseMetodoPago.data.id_metodo_pago_restaurante,
        ),
      };
    case 'propina':
      const responsePropina = await api.deleteTipoPropinaRestaurante(data.id);
      if (!responsePropina)
        return thunkAPI.rejectWithValue('error al eliminar propina');
      return {
        propinas: propinas.filter(
          e =>
            e.id_tipo_propina_restaurante !=
            responsePropina.data.id_tipo_propina_restaurante,
        ),
      };
    default:
      return {};
  }
});
