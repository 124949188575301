import {useState} from 'react';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Button,
} from '@mui/material';

type propsTypes = {
  password: string;
  setPassword: (newPassword: string) => void;
  verifyPassword: string;
  setVerifyPassword: (newPassword: string) => void;
  handleSubmitPassword: (event: React.FormEvent<HTMLFormElement>) => void;
};

const PasswordForm = function ({
  password,
  setPassword,
  verifyPassword,
  setVerifyPassword,
  handleSubmitPassword,
}: propsTypes) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);
  return (
    <Grid
      component="form"
      onSubmit={handleSubmitPassword}
      container
      spacing={4}
      sx={{mt: 2}}>
      <Grid item xs={12} sm={12}>
        <Card raised>
          <CardContent
            style={{
              textAlign: 'center',
            }}>
            <Box sx={{pb: 5}}>
              <Typography variant="h6">Cambio de contraseña</Typography>
            </Box>
            <Stack spacing={3} sx={{mx: 2.5}}>
              <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  label="Contraseña"
                  autoComplete="new-password"
                  value={password || ''}
                  required
                  onChange={e => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(prev => !prev)}>
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  type={showPasswordVerify ? 'text' : 'password'}
                  label="Verificar contraseña"
                  value={verifyPassword || ''}
                  required
                  onChange={e => setVerifyPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPasswordVerify(prev => !prev)}>
                          <Icon
                            icon={showPasswordVerify ? eyeFill : eyeOffFill}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Grid item xs={12}>
                <Button
                  style={{maxWidth: '200px'}}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={
                    !(
                      password.trim().length > 0 &&
                      verifyPassword.trim().length > 0
                    )
                  }>
                  Cambiar contraseña
                </Button>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PasswordForm;
