import {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {Stack, TextField, IconButton, InputAdornment, Box} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {actualizarLogin} from '../../redux/slices/user-slice';
import useMediaQuery from '../../common/hooks/use-media-query';
import {loginUser} from '../../redux/slices/user-slice/user.thunks';
import isNullish from '../../common/isNullish';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const userState = useAppSelector(state => state.userSlice);
  const login = useAppSelector(state => state.userSlice.editable.login);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const padding = isDesktop ? 10 : 0;
  const handleShowPassword = () => setShowPassword(show => !show);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await dispatch(loginUser());
    setIsLoading(false);
  };

  if (userState.isLogged) return <Redirect to="/" />;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{pl: padding}}>
      <Stack spacing={3} sx={{mb: 3}}>
        <TextField
          fullWidth
          autoComplete="email"
          type="email"
          label="Correo electrónico"
          required
          onChange={e => dispatch(actualizarLogin({email: e.target.value}))}
          value={login.email || ''}
          InputProps={{
            style: {backgroundColor: 'white'},
          }}
        />
        <TextField
          fullWidth
          autoComplete="password"
          type={showPassword ? 'text' : 'password'}
          label="Contraseña"
          onChange={e => dispatch(actualizarLogin({password: e.target.value}))}
          required
          value={login.password || ''}
          InputProps={{
            style: {backgroundColor: 'white'},
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="secondary"
        loadingPosition="start"
        loading={isLoading}
        disabled={isNullish(login)}>
        {isLoading ? 'Iniciando sesión ...' : 'Iniciar sesión'}
      </LoadingButton>
    </Box>
  );
}
