import {Box, Button} from '@mui/material';
import {useEffect} from 'react';
import {seleccionarUbicacion} from '../../redux/slices/orden-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

function TabUbicaciones() {
  const ubicaciones = useAppSelector(
    store => store.ordenSlice.uiData.ubicaciones,
  );
  const id_tipo_ubicacion = useAppSelector(
    store => store.ordenSlice.orden.ubicacion?.id_tipo_ubicacion,
  );

  const ubicacion = useAppSelector(store => store.ordenSlice.orden.ubicacion);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(seleccionarUbicacion(2));
  }, [ubicaciones]);

  useEffect(() => {
    if (!ubicacion) {
      dispatch(seleccionarUbicacion(2));
    }
  }, [ubicacion]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '30px',
      }}>
      {ubicaciones.map(element => {
        const isselected = id_tipo_ubicacion == element.id_tipo_ubicacion;
        return (
          <Button
            key={element.id_tipo_ubicacion}
            sx={{width: '49%', fontSize: '0.7rem'}}
            onClick={() =>
              dispatch(seleccionarUbicacion(element.id_tipo_ubicacion))
            }
            variant={isselected ? 'contained' : 'outlined'}
            color={isselected ? 'primary' : 'secondary'}>
            {element.nombre_ubicacion}
          </Button>
        );
      })}
    </Box>
  );
}

export default TabUbicaciones;
