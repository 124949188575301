import {Grid, Stack, Box} from '@mui/material';
import {memo, useEffect, useMemo} from 'react';
import {setMesaSeccion} from '../../redux/slices/orden-slice';
import {getOrdenMesas} from '../../redux/slices/orden-slice/orden.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import DropdownSecciones from '../dropdown-menu';
import MesasItem from '../mesas-item';
import EmptyScreen from './empty-screen';

function MesasList() {
  const mesas = useAppSelector(store => store.ordenSlice.uiData.mesas);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const sub = setInterval(() => dispatch(getOrdenMesas()), 15 * 1000);
    return () => clearInterval(sub);
  }, []);

  const selectedSeccion = useAppSelector(
    store => store.ordenSlice.uiData.selectedMesaSeccion,
  );

  const secciones = useAppSelector(
    store => store.restauranteUtilsSlice.sucursalSecciones,
  );
  const filteredSecciones = useMemo(
    () => secciones.filter(e => e.tipo_seccion.id_tipo_seccion === 3),
    [secciones],
  );

  return (
    <Stack sx={{width: '100%', height: '100%'}}>
      <Box sx={{alignSelf: 'flex-end', mr: 2}}>
        <DropdownSecciones
          data={filteredSecciones.sort((a, b) =>
            a.nombre_seccion.localeCompare(b.nombre_seccion),
          )}
          selectedId={selectedSeccion?.id_seccion_sucursal}
          idKey="id_seccion_sucursal"
          valueKey={['nombre_seccion']}
          fallBackMessage="Sin seccion Seleccionada"
          onClickSelected={() => {
            dispatch(setMesaSeccion(null));
          }}
          onSelect={seccion => {
            dispatch(setMesaSeccion(seccion));
          }}
        />
      </Box>
      <Grid container spacing={2} sx={{p: 1.5, pb: 10}}>
        {mesas.length ? (
          [...mesas]
            .filter(mesa => {
              if (mesa.deleted_at !== null) return false;
              if (!selectedSeccion) return true;
              return (
                mesa.seccion_sucursal_id ===
                selectedSeccion?.id_seccion_sucursal
              );
            })
            .sort((a, b) => a.numero_mesa - b.numero_mesa)
            .map((mesa, index) => (
              <MesasItem
                key={mesa.id_mesa_sucursal + index.toString()}
                mesa={mesa}
              />
            ))
        ) : (
          <EmptyScreen />
        )}
      </Grid>
    </Stack>
  );
}

export default memo(MesasList);
