import {
  Box,
  Collapse,
  FormControlLabel,
  Grid,
  List,
  Switch,
} from '@mui/material';
import {memo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import CartItem from '../cart-item/cart-item';
import {TransitionGroup} from 'react-transition-group';

function CartList() {
  const dispatch = useAppDispatch();

  const carrito = useAppSelector(store => store.ordenSlice.orden.carrito);
  const carritoAPI = useAppSelector(store => store.ordenSlice.orden.carritoAPI);
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );

  const [checked, setChecked] = useState(false);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: '800px',
        maxHeight: '90%',
      }}>
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked);
              }}
            />
          }
          label="¿Mostrar fecha de pedido?"
        />
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'space-around',
          }}>
          <TransitionGroup>
            {[...carrito, ...carritoAPI]
              .filter(element => {
                if (!clienteSeleccionado) return true;
                return element.clienteId === clienteSeleccionado.id;
              })
              .map((element, index) => {
                return (
                  <Collapse key={element.id}>
                    <CartItem item={element} showDate={checked} />
                  </Collapse>
                );
              })}
          </TransitionGroup>
        </List>
      </Box>
    </Grid>
  );
}

export default memo(CartList);
