import {useCallback, useEffect} from 'react';
import NewCaja from '../../components/new-caja/new-caja';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {
  getCajas,
  getCajasEmpleado,
} from '../../redux/slices/restaurante-utils-slice/restaurante-utils.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

function NewCajaPage() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);

  const sucursalSeleccionada = useAppSelector(
    store => store.restauranteSlice.sucursalSeleccionada,
  );

  const fetchAllScreenData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(getCajas());
    await dispatch(getCajasEmpleado());
    dispatch(stopLoading());
  }, [sucursalSeleccionada]);

  useEffect(() => {
    fetchAllScreenData();
  }, [sucursalSeleccionada]);

  return (
    <Pagetemplate isLoading={isLoading} title="Cajas || IFUDIS" soloLayout>
      <NewCaja />
    </Pagetemplate>
  );
}

export default NewCajaPage;
