import {Box} from '@mui/material';
import CustomButton from '../custom-button/custom-button';
import NumericTextField from '../form-input/controlled-numeric-text-field';
import TitleText from '../title-text/title-text';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {setPropinaEfectivo} from '../../redux/slices/orden-slice';
import {useEffect} from 'react';
import {editOrdenPropina} from '../../redux/slices/orden-slice/orden.thunks';

function PropinasTab() {
  const propinas = useAppSelector(store => store.finanzasSlice.propinas);
  const selectedPropinas = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.propinas,
  );
  const dispatch = useAppDispatch();
  const propinaEfectivo = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.propinaEfectivo,
  );

  const efectivoSeleccionado = !!selectedPropinas?.find(
    element => element.tipo_pago_propina_id == 2,
  );

  useEffect(() => {
    if (!selectedPropinas?.some(element => element.tipo_pago_propina_id == 2)) {
      dispatch(setPropinaEfectivo(0));
    }
  }, [selectedPropinas]);

  return (
    <>
      <Box sx={{my: 1}}>
        <TitleText disableMb text="PROPINA" />
      </Box>
      <Box sx={{display: 'flex', flexWrap: 'wrap', mb: 1}}>
        <>
          {propinas.map(value => {
            const isEfectivo = value.tipo_pago_propina_id == 2;
            const name = isEfectivo ? 'Monto fijo' : `${value.porcentaje}%`;
            const selected = !!selectedPropinas?.find(
              element =>
                element.id_tipo_propina_restaurante ===
                value.id_tipo_propina_restaurante,
            );
            const disabled =
              selectedPropinas && selectedPropinas?.length > 0 && !selected;
            return (
              <Box
                sx={{mr: 2, mb: 0.5}}
                key={value.id_tipo_propina_restaurante}>
                <CustomButton
                  name={name}
                  disabled={disabled}
                  isselected={selected}
                  addEm={() => {
                    dispatch(
                      editOrdenPropina({
                        type: 'togglear',
                        id: value.id_tipo_propina_restaurante,
                      }),
                    );
                  }}
                />
              </Box>
            );
          })}
          {efectivoSeleccionado && (
            <NumericTextField
              autoFocus
              style={{
                color: 'black',
                border: '0.5px solid #000',
                borderRadius: '15px',
                textAlign: 'center',
                fontSize: '1.2rem',
                height: '3rem',
                width: '6rem',
              }}
              value={propinaEfectivo}
              type="money"
              size="small"
              minValue={0}
              onChange={value => dispatch(setPropinaEfectivo(value))}
            />
          )}
        </>
      </Box>
    </>
  );
}

export default PropinasTab;
