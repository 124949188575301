import {useAppDispatch, useAppSelector} from '../../redux/store';
import {useCallback, useEffect, useState} from 'react';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import SucursalForm from './SucursalForm';
import {
  cargarSucursal,
  getSucursales,
  putPostSucursal,
} from '../../redux/slices/restaurante-slice/restaurente.thunks';
import {
  actualizarSucursal,
  resetSucursal,
} from '../../redux/slices/restaurante-slice';
import CrudTable from '../crud-table';
import getNestedValue from '../../common/getNestedValue';
import sortArrayObject from '../../common/sortArrayObject';

function SucursalTable() {
  const dispatch = useAppDispatch();
  const [openCrear, setOpenCrear] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const sucursales = useAppSelector(state => state.restauranteSlice.sucursales);

  const handleOnEdit = (id: number) => {
    dispatch(cargarSucursal(id));
    setOpenEditar(true);
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
    dispatch(resetSucursal());
  };

  const handleCloseUpdate = () => {
    setOpenEditar(false);
    dispatch(resetSucursal());
  };

  useEffect(() => {
    dispatch(getSucursales());
  }, []);

  const column = [
    {
      label: 'Nombre',
      key: ['descripcion_ubicacion'],
    },
    {
      label: 'Horarios',
      key: ['descripcion_horario'],
    },
    {
      label: 'Estatus',
      key: ['estado_sucursal'],
    },
    {
      label: '',
      key: ['id_sucursal'],
    },
  ];

  const handleSwitch = async (newVal: boolean, id: number) => {
    await dispatch(cargarSucursal(id));
    await dispatch(actualizarSucursal({estado_sucursal: newVal}));
    await dispatch(putPostSucursal('actualizar'));
  };

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('descripcion_ubicacion');
  const [arrayKeys, setArrayKeys] = useState<string[]>([
    'descripcion_ubicacion',
  ]);

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['descripcion_ubicacion'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, ['descripcion_horario'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, ['estado_sucursal'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  return (
    <>
      <CrudTable
        setOpenCrear={setOpenCrear}
        title="Sucursales"
        columns={column}
        rows={[...sucursales].sort((a, b) =>
          sortArrayObject(a, b, arrayKeys, order),
        )}
        OnEdit={handleOnEdit}
        onSwitch={handleSwitch}
        onSearch={handleOnSearch}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <DialogPopUp
        open={openCrear}
        handleClose={handleCloseCrear}
        title="Crear sucursal">
        <SucursalForm type="crear" handleClose={handleCloseCrear} />
      </DialogPopUp>
      <DialogPopUp
        open={openEditar}
        handleClose={handleCloseUpdate}
        title="Editar sucursal">
        <SucursalForm type="actualizar" handleClose={handleCloseUpdate} />
      </DialogPopUp>
    </>
  );
}

export default SucursalTable;
