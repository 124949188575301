import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import DividirUnirForm from './dividir-unir-form';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};

const UnirDividirModal = ({state, handleClose}: PropsTypes) => {
  return (
    <DialogPopUp
      PaperProps={{
        sx: {
          minWidth: '31%',
          height: 400,
          position: 'relative',
        },
      }}
      open={state}
      handleClose={handleClose}
      title="">
      <DividirUnirForm handleClose={handleClose} />
    </DialogPopUp>
  );
};

export default UnirDividirModal;
