import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {compraSucursalSchema} from '../../redux/slices/compras-slice';
import {putPostCompraSucursal} from '../../redux/slices/compras-slice/compras.thunks';

import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {compraSucursal} from '../../services/dto/compras';
import ControlledDatePicker from '../form-input/controlled-date-picker';
import {ControlledNumericTextField} from '../form-input/controlled-numeric-text-field';
import ControlledSelector from '../form-input/controlled-selector';
import {useCallback, useEffect, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import getNestedValue from '../../common/getNestedValue';

type propsTypes = {
  defaultValues?: compraSucursal;
  handleClose: VoidFunction;
  proveedorId?: number;
  categoriaInsumoId?: number;
};

function ComprasForm({
  handleClose,
  defaultValues,
  proveedorId,
  categoriaInsumoId,
}: propsTypes) {
  const estadosDeCompra = useAppSelector(
    store => store.comprasSlice.estadoDeCompra,
  );

  const dispatch = useAppDispatch();
  const insumos = useAppSelector(store => store.insumoSlice.insumos);

  const proveedores = useAppSelector(store => store.comprasSlice.proveedores);

  const {control, handleSubmit, formState, getValues, setValue} = useForm({
    mode: 'onBlur',
    defaultValues: {
      estado_compra_id: defaultValues?.estado_compra_id ?? '',
      categoria_insumo_id:
        defaultValues?.categoria_insumo_id ?? categoriaInsumoId ?? '',
      fecha_hora_compra: defaultValues?.fecha_hora_compra ?? '',
      monto_compra: defaultValues?.monto_compra ?? '',
      proveedor_id: defaultValues?.proveedor_id ?? proveedorId,
      subtotal: defaultValues
        ? defaultValues.desglose
          ? Array.isArray(JSON.parse(defaultValues.desglose))
            ? null
            : JSON.parse(defaultValues.desglose).subtotal
          : null
        : null,
      impuestos: defaultValues
        ? defaultValues.desglose
          ? Array.isArray(JSON.parse(defaultValues.desglose))
            ? null
            : JSON.parse(defaultValues.desglose).impuestos
          : null
        : null,
      items: defaultValues
        ? defaultValues.desglose
          ? !Array.isArray(JSON.parse(defaultValues.desglose))
            ? (JSON.parse(defaultValues.desglose).items as any[]).map(
                value => ({...value, count: value.count ? value.count : 1}),
              )
            : (JSON.parse(defaultValues.desglose) as any[]).map(value => ({
                ...value,
                count: value.count ? value.count : 1,
              }))
          : []
        : [],
    },
    resolver: yupResolver(compraSucursalSchema),
  });

  const [indexes, setIndexes] = useState<Array<number>>([]);
  const [counter, setCounter] = useState(0);

  const addItem = () => {
    setIndexes(prevIndexes => [...prevIndexes, 1]);
  };

  const removeItem = (index: number) => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter((_, i) => i !== index)]);
    setValue('items', [...getValues('items').filter((_, i) => i !== index)]);
  };

  const onSubmit = async (data: any) => {
    dispatch(startLoading());
    await dispatch(
      putPostCompraSucursal({
        ...data,
        editId: defaultValues?.id_compra_sucursal,
      }),
    )
      .unwrap()
      .then(() => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (defaultValues && defaultValues.desglose) {
      const aux = JSON.parse(defaultValues.desglose);
      if (Array.isArray(aux))
        aux.forEach(() => {
          addItem();
        });
      else {
        aux.items.forEach(() => {
          addItem();
        });
      }
    }
  }, [defaultValues]);

  const updateSubtotal = useCallback(() => {
    const aux = getValues('items');
    let suma = 0;
    aux.forEach((value: any) => {
      suma = suma + value.price * (value.count ?? 1);
    });
    setValue('subtotal', suma);
  }, []);

  const updateTotal = useCallback(() => {
    const subtotal = getValues('subtotal') || 0;
    const impuesto = getValues('impuestos') || 0;
    if (subtotal || impuesto) setValue('monto_compra', subtotal + impuesto);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <ControlledDatePicker
          label="Fecha"
          inputFormat="dd/MM/yyyy"
          disableFuture
          controllerProps={{control, name: 'fecha_hora_compra'}}
        />

        <ControlledSelector
          sourceData={estadosDeCompra}
          title="Estado de compra"
          controllerProps={{control, name: 'estado_compra_id'}}
          idKey="id_estado_compra"
          valueKey={['nombre_estado_compra']}
          fullWidth
        />

        {proveedorId === undefined && (
          <ControlledSelector
            sourceData={proveedores}
            title="Proveedor"
            controllerProps={{control, name: 'proveedor_id'}}
            idKey="id_proveedor"
            valueKey={['nombre_proveedor']}
            fullWidth
          />
        )}
        <Card raised={false}>
          <CardContent
            sx={{
              border: '0.5px solid #c3c3c3c3',
              borderRadius: '15px',
              width: '100%',
              padding: '10px',
              position: 'relative',
            }}>
            <Typography sx={{fontWeight: '700'}}>Items de la compra</Typography>
            {(indexes.length || '') && (
              <Grid container spacing={1} mt={1}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Descripción
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Precio
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Cant.
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} justifyContent={'end'} mt={1}>
              {indexes.map((_, index) => {
                const fieldName = `items[${index}]`;

                return (
                  <Grid
                    container
                    item
                    mb={1}
                    alignContent={'end'}
                    alignItems={'end'}
                    spacing={1}
                    xs={12}
                    key={index}>
                    <Grid item xs={6}>
                      <Controller
                        name={`items.${index}.name`}
                        control={control}
                        render={({field, fieldState, formState}) => {
                          field.value =
                            [...insumos].find(
                              item => item['insumo_id'] === field.value,
                            ) ?? null;
                          return (
                            <Autocomplete
                              {...field}
                              options={insumos}
                              sx={{
                                width: '100%',
                              }}
                              noOptionsText="No existen registros."
                              onChange={(_, value) => {
                                field.onChange(
                                  getNestedValue(value, ['insumo_id']),
                                );
                              }}
                              getOptionLabel={option =>
                                option ? option.insumo.nombre_insumo : ''
                              }
                              freeSolo
                              disableClearable
                              size={'small'}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant={'standard'}
                                  multiline
                                  size={'small'}
                                  margin={'none'}
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      lineHeight: 1.2,
                                      fontSize: '13px',
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize',
                                    },
                                  }}
                                  error={!!fieldState?.error?.message}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ControlledNumericTextField
                        fullWidth
                        variant={'standard'}
                        onChange={() => updateSubtotal()}
                        sx={{
                          '& .MuiInputBase-input': {
                            lineHeight: 1.2,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                          },
                        }}
                        controllerProps={{
                          control,
                          name: `${fieldName}.price`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ControlledNumericTextField
                        fullWidth
                        minValue={1}
                        variant={'standard'}
                        onChange={() => updateSubtotal()}
                        sx={{
                          '& .MuiInputBase-input': {
                            lineHeight: 1.2,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                          },
                        }}
                        controllerProps={{
                          control,
                          name: `${fieldName}.count`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        aria-label="delete"
                        size={'small'}
                        sx={{
                          padding: 0,
                        }}
                        onClick={removeItem(index)}
                        color={'error'}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
              <Button size={'small'} variant={'outlined'} onClick={addItem}>
                Agregar Item
              </Button>
              <Grid
                spacing={1}
                container
                alignItems={'end'}
                alignContent={'end'}
                item
                xs={12}>
                <Grid item xs={7}>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    SubTotal
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <ControlledNumericTextField
                    fullWidth
                    onChange={() => updateTotal()}
                    controllerProps={{
                      control,
                      name: 'subtotal',
                    }}
                    variant={'standard'}
                    sx={{
                      '& .MuiInputBase-input': {
                        lineHeight: 1.2,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                      },
                      marginBottom: '-7px',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                spacing={1}
                container
                alignItems={'end'}
                alignContent={'end'}
                item
                xs={12}>
                <Grid item xs={7}>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Impuestos
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <ControlledNumericTextField
                    fullWidth
                    onChange={() => updateTotal()}
                    controllerProps={{
                      control,
                      name: 'impuestos',
                    }}
                    variant={'standard'}
                    sx={{
                      '& .MuiInputBase-input': {
                        lineHeight: 1.2,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                      },
                      marginBottom: '-7px',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ControlledNumericTextField
          fullWidth
          label="Monto"
          controllerProps={{
            control,
            name: 'monto_compra',
          }}
        />
        <Stack direction="row" spacing={2}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            disabled={Object.values(formState.errors).length > 0}>
            Guardar cambios
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}

export default ComprasForm;
