import {TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

type TableHeaderType = {
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  sortHandler?: (key: string, allKeys: string[]) => void;
  columnBlacklisted?: string[];
};

const TableHeader = ({
  columns,
  order,
  orderBy,
  sortHandler,
  columnBlacklisted,
}: TableHeaderType) => {
  return (
    <TableHead>
      <TableRow hover>
        {columns.map((el, index) => (
          <TableCell
            key={index}
            align={el.label === 'Precio\u00A0(USD)' ? 'left' : 'left'}
            sx={{fontWeight: 'bold'}}>
            {order &&
            orderBy &&
            sortHandler &&
            !columnBlacklisted?.includes(el.label) &&
            index !== columns.length - 1 ? (
              <TableSortLabel
                active={orderBy === el.key[el.key.length - 1]}
                direction={order === 'asc' ? 'desc' : 'asc'}
                onClick={() => {
                  if (sortHandler)
                    return sortHandler(el.key[el.key.length - 1], el.key);
                  return;
                }}>
                {el.label}
              </TableSortLabel>
            ) : (
              `${el.label}`
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
