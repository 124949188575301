import {MobileDatePickerProps} from '@mui/lab';
import {TextField} from '@mui/material';
import {useState} from 'react';
import {Controller} from 'react-hook-form';
import DatePicker from '../date-picker';
import {ControllerProps} from './controlled-text-field';

type propType = {
  controllerProps: ControllerProps;
  helperText?: string;
} & Omit<MobileDatePickerProps<Date>, 'value' | 'onChange' | 'renderInput'>;

export default function ControlledDatePicker({
  controllerProps: {control, name},
  ...props
}: propType) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({field: {ref, ...field}, fieldState, formState}) => {
        return (
          <DatePicker
            {...field}
            {...props}
            onChange={date => {
              field.onChange(date);
            }}
            onOpen={() => setIsOpen(true)}
            onClose={() => {
              setIsOpen(false);
              field.onBlur();
            }}
            renderInput={params => (
              <TextField
                {...params}
                helperText={props.helperText}
                error={!isOpen && !!fieldState.error}
                onBlur={field.onBlur}
              />
            )}
          />
        );
      }}
    />
  );
}
