import {finanzasSliceType} from '../redux/slices/finanzas-slice/finanzas.types';
import {
  clienteStoreTypePrimary,
  descuentoType,
  metodoPagoType,
} from '../redux/slices/orden-slice/orden.types';
import {
  descuentoRestaurantType,
  impuestoRestaurantType,
  propinaRestaurantType,
} from '../services/dto/orden';
import {ClientesAsignadosMesa} from '../services/dto/orden-indirecta';

export type metaDataType = {
  descuentoType: descuentoType;
  metodoPago: {
    metodo_pago_restaurante_id: number;
    cantidad: number;
  }[];
  tipo_descuento: number[];
  tipo_impuesto: number[];
  tipo_propina: number[];
  propina_efectivo: number | null;
};

export function clientToMetaData(
  cliente: clienteStoreTypePrimary,
): metaDataType {
  const propinas = cliente.propinas.map(
    element => element.id_tipo_propina_restaurante,
  );
  const metodoPago = cliente.metodosPago.metodos.map(element => ({
    metodo_pago_restaurante_id: element.metodoPago.id_metodo_pago_restaurante,
    cantidad: Number(element.cantidad?.toFixed(2)) ?? 0,
  }));
  const descuento = cliente.descuentos.map(
    element => element.id_tipo_descuento_restaurante,
  );
  const impuesto = cliente.impuestos.map(
    element => element.id_tipo_impuesto_restaurante,
  );
  return {
    descuentoType: cliente.descuentoType,
    metodoPago,
    tipo_descuento: descuento,
    tipo_impuesto: impuesto,
    tipo_propina: propinas,
    propina_efectivo: cliente.propinaEfectivo,
  };
}

export function metaDataToClient(
  client: ClientesAsignadosMesa,
  finanzas: finanzasSliceType,
  metaData?: metaDataType,
  estado?: number,
): clienteStoreTypePrimary {
  const descuentos: descuentoRestaurantType[] =
    (metaData?.tipo_descuento
      ?.map(element => {
        const descuento = finanzas.descuentos.find(
          element2 => element2.id_tipo_descuento_restaurante === element,
        );
        if (!descuento) return null;
        return descuento;
      })
      .filter(element => element !== null) as descuentoRestaurantType[]) ?? [];

  const propinas: propinaRestaurantType[] =
    (metaData?.tipo_propina
      ?.map(element => {
        const propina = finanzas.propinas.find(
          element2 => element2.id_tipo_propina_restaurante === element,
        );
        if (!propina) return null;
        return propina;
      })
      .filter(element => element != null) as propinaRestaurantType[]) ?? [];

  const impuestos: impuestoRestaurantType[] =
    (metaData?.tipo_impuesto
      ?.map(element => {
        const impuesto = finanzas.impuestos.find(
          element2 => element2.id_tipo_impuesto_restaurante === element,
        );
        if (!impuesto) return null;
        return impuesto;
      })
      .filter(element => element != null) as impuestoRestaurantType[]) ?? [];

  const metodos: metodoPagoType[] =
    (metaData?.metodoPago
      ?.map(element => {
        const metodo = finanzas.metodosPago.find(
          element2 =>
            element2.id_metodo_pago_restaurante ===
            element.metodo_pago_restaurante_id,
        );
        if (!metodo) return null;
        return {
          cantidad: parseFloat(element.cantidad.toFixed(2)),
          metodoPago: metodo,
        };
      })
      .filter(element => element != null) as metodoPagoType[]) ?? [];

  return {
    email: client.cliente.email ?? undefined,
    id: client.cliente_id,
    descuentoType: metaData?.descuentoType ?? descuentoType.none,
    name: client.cliente.nombre_apellido,
    propinaEfectivo: metaData?.propina_efectivo ?? null,
    descuentos,
    propinas,
    estado,
    impuestos,
    metodosPago: {cantidad: 0, restante: 0, zeroMetodo: false, metodos},
  };
}
