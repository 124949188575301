import {memo, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Drawer} from '@mui/material';
import NavSection from './NavSection';
import sidebarConfig from './SidebarConfig';
import {RootStyle} from './sidebar-styles';
import SideBarLogoProfile from './side-bar-logo-profile';

type propType = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

function DashboardSidebar({isOpenSidebar, onCloseSidebar}: propType) {
  const {pathname} = useLocation();

  useEffect(() => {
    if (isOpenSidebar) return onCloseSidebar();
  }, [pathname]);

  return (
    <RootStyle>
      <Drawer
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: {width: 280, bgcolor: 'background.default', pb: 1},
        }}>
        <SideBarLogoProfile>
          <NavSection navConfig={sidebarConfig} />
        </SideBarLogoProfile>
      </Drawer>
    </RootStyle>
  );
}

export default memo(DashboardSidebar);
