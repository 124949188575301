import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialRestaurante, initialState, initialSucursalState} from '.';

import {
  restauranteSliceType,
  updateRestauranteStoretype,
  updateSucursalStoreType,
} from './restaurante.types';

const seleccionarRestaurante = (
  state: Draft<restauranteSliceType>,
  action: PayloadAction<number | null>,
) => {
  state.cajaEmpleado = null;
  if (!action.payload) {
    state.restauranteEmpleadoSeleccionado = null;
    state.sucursalesEmpleadoAsignado = [];
    state.cargoEmpleado = null;
    state.empleadoAsignadoSucursalSeleccionado = null;
    return;
  }

  const empleadoRestaurante = state.restauranteEmpleados.find(
    element => element.restaurante.id === action.payload,
  );

  if (!empleadoRestaurante) return;
  const restaurante = empleadoRestaurante?.restaurante;
  state.cargoEmpleado = empleadoRestaurante?.cargo;
  if (restaurante.empleado_asignado_sucursal.length === 1) {
    state.sucursalesEmpleadoAsignado = restaurante.empleado_asignado_sucursal;
    state.empleadoAsignadoSucursalSeleccionado =
      restaurante.empleado_asignado_sucursal[0];
  } else {
    state.sucursalesEmpleadoAsignado = restaurante.empleado_asignado_sucursal;
  }
  state.restauranteEmpleadoSeleccionado = restaurante;
};

const actualizarUserSliceData = (state: Draft<restauranteSliceType>) => {
  if (state.restauranteEmpleadoSeleccionado) {
    state.restauranteEmpleadoSeleccionado = state.restauranteEmpleados.find(
      element =>
        element.restaurante.id === state.restauranteEmpleadoSeleccionado?.id,
    )!.restaurante;
    state.sucursalesEmpleadoAsignado =
      state.restauranteEmpleadoSeleccionado.empleado_asignado_sucursal;
  }

  if (state.empleadoAsignadoSucursalSeleccionado)
    state.empleadoAsignadoSucursalSeleccionado =
      state.sucursalesEmpleadoAsignado.find(
        element =>
          element.id_empleado_asignado_sucursal ===
          state.empleadoAsignadoSucursalSeleccionado
            ?.id_empleado_asignado_sucursal,
      )!;
};

const seleccionarEmpleadoAsignadoSucursal = (
  state: Draft<restauranteSliceType>,
  action: PayloadAction<number | null>,
) => {
  state.cajaEmpleado = null;
  if (!action.payload) {
    state.empleadoAsignadoSucursalSeleccionado = null;
    return;
  }
  if (!state.restauranteEmpleadoSeleccionado) return;
  const empleadoAsignadoSucursal =
    state.restauranteEmpleadoSeleccionado.empleado_asignado_sucursal.find(
      element => element.id_empleado_asignado_sucursal === action.payload,
    );
  if (!empleadoAsignadoSucursal) return;
  state.empleadoAsignadoSucursalSeleccionado = empleadoAsignadoSucursal;
};

const actualizarRestaurante = (
  state: Draft<restauranteSliceType>,
  action: PayloadAction<updateRestauranteStoretype>,
) => {
  state.editable.restaurante = {
    ...state.editable.restaurante,
    ...action.payload,
  };
};

const actualizarSucursal = (
  state: Draft<restauranteSliceType>,
  action: PayloadAction<updateSucursalStoreType>,
) => {
  state.editable.sucursal = {...state.editable.sucursal, ...action.payload};
};

const resetRestaurante = (state: Draft<restauranteSliceType>) => {
  Object.assign(state.editable.restaurante, initialRestaurante);
  Object.assign(state.editable.restauranteCopy, initialRestaurante);
};

const resetSucursal = (state: Draft<restauranteSliceType>) => {
  Object.assign(state.editable.sucursal, initialSucursalState);
};

const ResetRestauranteSlice = (state: Draft<restauranteSliceType>) => {
  return initialState;
};

export const actions = {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
  actualizarRestaurante,
  actualizarSucursal,
  resetRestaurante,
  resetSucursal,
  ResetRestauranteSlice,
  actualizarUserSliceData,
};
