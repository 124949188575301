import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  Stack,
  CardContent,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Chip,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import UploadImages from '../../components/upload-images/UploadImages';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  actualizarUser,
  actualizarUserImages,
} from '../../redux/slices/user-slice';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {dateFromNow, formatDateTime} from '../../common/formatTime';
import DatePicker from '../../components/date-picker';

dayjs.extend(relativeTime);

const ProfileForm = function ({
  isLoading,
  handleSubmit,
}: {
  isLoading: boolean;
  handleSubmit: any;
}) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(store => store.userSlice.editable.user);
  const userDetails = useAppSelector(store => store.userSlice.userDetail);
  const userImages = useAppSelector(
    store => store.userSlice.editable.userImages,
  );

  const cargo_ifudis = useAppSelector(store => store.userSlice.cargo_ifudis);

  const isDisable = () => {
    if (!user.name || !user.nick || !user.lastname) return true;

    if (
      user.name.trim().length > 0 &&
      user.lastname.trim().length > 0 &&
      user.nick.trim().length > 0
    )
      return false;
  };

  const getEmailVerify = (verify: string | null) => {
    if (verify && new Date(verify)) {
      return (
        <Chip
          label="Verificado"
          clickable
          color="success"
          deleteIcon={<DoneIcon />}
        />
      );
    }
    return (
      <Chip
        label="Pendiente"
        clickable
        color="warning"
        deleteIcon={<HourglassEmptyIcon />}
      />
    );
  };

  if (!userDetails) return <Redirect to="/login" />;

  return (
    <Grid component="form" container spacing={4} onSubmit={handleSubmit}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <UploadImages
          state={userImages.foto_perfil}
          setState={file => {
            dispatch(actualizarUserImages({foto_perfil: file}));
          }}
          buttonLabel="Subir foto de perfil"
          perfil
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card raised>
          <CardContent
            style={{
              textAlign: 'center',
            }}>
            <Box sx={{pb: 5}}>
              <Typography variant="h6">Generales</Typography>
            </Box>

            <Stack spacing={3}>
              <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={user.name || ''}
                  onChange={e =>
                    dispatch(actualizarUser({name: e.target.value}))
                  }
                  required
                />

                <TextField
                  fullWidth
                  label="Apellidos"
                  value={user.lastname || ''}
                  onChange={e =>
                    dispatch(actualizarUser({lastname: e.target.value}))
                  }
                  required
                />
              </Stack>
              <TextField
                fullWidth
                label="Correo electrónico"
                value={userDetails.email || ''}
                disabled
              />
              <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  label="Cédula"
                  value={user.dni || ''}
                  onChange={e =>
                    dispatch(actualizarUser({dni: e.target.value}))
                  }
                />
                <TextField
                  fullWidth
                  label="Nick"
                  value={user.nick || ''}
                  onChange={e =>
                    dispatch(
                      actualizarUser({nick: e.target.value.toLowerCase()}),
                    )
                  }
                  required
                />
              </Stack>

              <Stack spacing={3}>
                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{minWidth: 120}}>
                    <InputLabel>Sexo</InputLabel>
                    <Select
                      value={user.id_sexo || ''}
                      onChange={e =>
                        dispatch(
                          actualizarUser({
                            id_sexo: parseInt(
                              e.target.value?.toString() || '3',
                            ),
                          }),
                        )
                      }
                      label="Sexo">
                      <MenuItem value={1}>Masculino</MenuItem>
                      <MenuItem value={2}>Femenino</MenuItem>
                      <MenuItem value={3}>Prefiero no decir</MenuItem>
                    </Select>
                  </FormControl>
                  <DatePicker
                    // views={['year', 'month', 'day']}
                    label="Fecha de nacimiento"
                    // format="YYYY-MM-DD"
                    onChange={date => {
                      dispatch(
                        actualizarUser({fecha_nacimiento: date || undefined}),
                      );
                    }}
                    value={user.fecha_nacimiento}
                    renderInput={params => <TextField {...params} />}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Grid
              item
              xs={12}
              style={{display: 'flex', justifyContent: 'center'}}
              sx={{my: 2.5}}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
                loading={isLoading}
                disabled={isDisable()}>
                Actualizar generales
              </LoadingButton>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card raised style={{minHeight: '500px'}}>
          <CardContent>
            <Box
              sx={{pb: 2}}
              style={{
                textAlign: 'center',
              }}>
              <Typography variant="h6">Cuenta</Typography>
            </Box>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}>
              <ContainerBox>
                <Box>
                  <Typography variant="body2">Fecha de registro:</Typography>
                </Box>
                <div style={{flexGrow: 1}} />
                <Box>
                  <Typography variant="body2">
                    {formatDateTime(userDetails.created_at)}
                  </Typography>
                </Box>
              </ContainerBox>
              <ContainerBox>
                <Box>
                  <Typography variant="body2">
                    Antiguedad de la cuenta:
                  </Typography>
                </Box>
                <Box style={{flexGrow: 1}} />
                <Box>
                  <Typography variant="body2">
                    {dateFromNow(userDetails.created_at)}
                  </Typography>
                </Box>
              </ContainerBox>
              <ContainerBox>
                <Box sx={{pb: 2}}>
                  <Typography variant="body2">Última modificación:</Typography>
                </Box>
                <Box style={{flexGrow: 1}} />
                <Box sx={{pb: 2}}>
                  <Typography variant="body2">
                    {dateFromNow(userDetails.updated_at)}
                  </Typography>
                </Box>
              </ContainerBox>
              <ContainerBox>
                <Box sx={{pb: 2}}>
                  <Typography variant="body2">Correo electrónico:</Typography>
                </Box>
                <Box style={{flexGrow: 1}} />
                <Box sx={{pb: 2}}>
                  {getEmailVerify(userDetails.email_verified_at)}
                </Box>
              </ContainerBox>
              {cargo_ifudis?.id_cargo !== 4 && (
                <ContainerBox>
                  <Box sx={{pb: 2}}>
                    <Typography variant="body2">
                      Cuenta de empleado IFUDIS:
                    </Typography>
                  </Box>
                  <Box style={{flexGrow: 1}} />
                  <Box sx={{pb: 2}}>
                    <Chip
                      label={`${cargo_ifudis?.nombre}`}
                      clickable
                      color="success"
                      deleteIcon={<DoneIcon />}
                    />
                  </Box>
                </ContainerBox>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProfileForm;

const ContainerBox = function ({children}: {children: React.ReactNode}) {
  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      spacing={{xs: 1, sm: 2, md: 4}}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      sx={{my: 1.5}}>
      {children}
    </Stack>
  );
};
