import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  Stack,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import ControlledSelector from '../form-input/controlled-selector';
import {useCallback, useEffect, useState} from 'react';
import {ControlledNumericTextField} from '../form-input/controlled-numeric-text-field';
import {LoadingButton} from '@mui/lab';
import {msgWarn} from '../../common/toast';
import {moveProduct} from '../../redux/slices/orden-slice/orden.thunks';
import React from 'react';
import {ControlledTextField} from '../form-input/controlled-text-field';
import {api} from '../../services';

type PropsTypes = {
  handleClose: () => void;
};

type FormProps = {
  clienteA: number | undefined;
  productosA: Array<{
    id_orden_producto_menu: number;
    selected: boolean;
    nombre: string;
    count: number;
    total: number;
  }>;
  clienteB: number | undefined;
  nombres: string;
};
const MoverProductoForm = ({handleClose}: PropsTypes) => {
  const mesaA = useAppSelector(store => store.ordenSlice.uiData.mesaA);
  const mesaB = useAppSelector(store => store.ordenSlice.uiData.mesaB);
  const empleadoAsignadoSucursalSeleccionado = useAppSelector(
    store => store.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );
  const dispatch = useAppDispatch();
  const clientesMesaA = useAppSelector(store =>
    store.ordenSlice.uiData.mesas.find(mesa => mesa.id_mesa_sucursal === mesaA),
  )?.clientes_asignados_mesa;
  const clientesMesaB = useAppSelector(store =>
    store.ordenSlice.uiData.mesas.find(mesa => mesa.id_mesa_sucursal === mesaB),
  )?.clientes_asignados_mesa;

  const [indexes, setIndexes] = useState<Array<number>>([]);

  const [loading, setloading] = useState(false);

  const {control, handleSubmit, formState, getValues, setValue, watch} =
    useForm<FormProps>({
      mode: 'onBlur',
      defaultValues: {
        clienteA: undefined,
        productosA: [],
        clienteB: undefined,
        nombres: '',
      },
    });

  const clienteA = watch('clienteA', undefined);

  useEffect(() => {
    if (clienteA && clientesMesaA) {
      const find = clientesMesaA.find(
        cliente => cliente.cliente_id === clienteA,
      );
      setIndexes([]);
      setValue('productosA', []);
      if (find) {
        setValue(
          'productosA',
          find.cliente.productos_cliente.map(produc => ({
            id_orden_producto_menu: produc.orden.id_orden_producto_menu,
            selected: false,
            count: 1,
            nombre: produc.orden.menu_restaurante.producto.nombre_producto,
            total: produc.orden.cantidad,
          })),
        );
        find.cliente.productos_cliente.forEach(() => {
          setIndexes(prevIndexes => [...prevIndexes, 1]);
        });
      }
    }
    if (!clienteA) {
      setIndexes([]);
      setValue('productosA', []);
    }
  }, [clienteA]);

  const submit = useCallback(async (data: FormProps) => {
    if (clientesMesaB && clientesMesaA) {
      const clienteDestino = clientesMesaB.find(
        cliente => cliente.cliente_id === data.clienteB,
      );
      const clienteOrigen = clientesMesaA.find(
        cliente => cliente.cliente_id === data.clienteA,
      );
      if (clienteOrigen) {
        const max = clienteOrigen.cliente.productos_cliente.reduce<number>(
          (acc, current) => {
            return acc + current.orden.cantidad;
          },
          0,
        );
        const count = data.productosA
          .filter(value => value.selected)
          .reduce<number>((previousValue, currentValue) => {
            return previousValue + currentValue.count;
          }, 0);
        if (count >= max)
          return msgWarn(
            'El cliente de la mesa origen debe quedar al menos con un producto.',
          );
        if (!count) return msgWarn('Debe elegir al menos un producto a mover.');
        if (clienteDestino) {
          const productos = data.productosA.filter(value => value.selected);
          const mesa_asignada_id = clienteDestino.id_mesa_asignada;
          const cuenta_id = clienteDestino.cuenta_id;
          const meta_data_cliente = clienteDestino.meta_data_cliente;
          const request = {
            productos,
            cliente_envia_id: data.clienteA,
            mesa_asignada_id,
            cuenta_id,
            meta_data_cliente,
          };
          setloading(true);
          await dispatch(moveProduct(request));
          handleClose();
        } else if (!clienteDestino && data.nombres) {
          const requestCuenta = {
            cantidad_comensales: 1,
            mesa_sucursal_id: mesaB,
            sucursal_id: empleadoAsignadoSucursalSeleccionado?.sucursal_id,
            restaurante_id:
              empleadoAsignadoSucursalSeleccionado?.sucursal.restaurante_id,
            tipo_ubicacion_id: 2,
            clientes: [
              {
                nombre_apellido: data.nombres,
                productos: [],
              },
            ],
          };
          setloading(true);
          const response = await api.postFacturaIndirectaInit(requestCuenta);
          setloading(false);
          if (!response) return msgWarn('Error al crear nuevo cliente.');
          const productos = data.productosA.filter(value => value.selected);
          const cuenta_id = response.data.id_cuenta;
          const request = {
            productos,
            cliente_envia_id: data.clienteA,
            cuenta_id,
            cliente: {
              nombre_apellido: data.nombres,
            },
            meta_data_cliente: null,
          };
          setloading(true);
          await dispatch(moveProduct(request));
          handleClose();
        }
      }
    }
    setloading(false);
  }, []);

  return (
    <Stack
      direction="column"
      spacing={1}
      gap={1}
      mb={2}
      sx={{width: '100%', minHeight: '100%', position: 'relative'}}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2} overflow="scroll">
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 'bold',
                my: 2,
                textAlign: 'center',
              }}>
              Mesa Origen
            </Typography>

            <ControlledSelector
              sourceData={clientesMesaA || []}
              title="Cliente"
              controllerProps={{control, name: 'clienteA'}}
              idKey="cliente_id"
              valueKey={['cliente_id']}
              getOptionLabel={opt => (opt ? opt.cliente.nombre_apellido : '')}
              fullWidth
            />

            {indexes.map((_, index) => {
              const fieldName = `productosA[${index}]`;
              return (
                <Grid container spacing={2} mt={1} key={index}>
                  <Grid item xs={9}>
                    <Controller
                      render={({field}) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={(_, value) => field.onChange(value)}
                            />
                          }
                          label={
                            watch(`productosA.${index}.nombre`) +
                            ' (' +
                            watch(`productosA.${index}.total`) +
                            ')'
                          }
                        />
                      )}
                      control={control}
                      name={`productosA.${index}.selected`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {watch(`productosA.${index}.selected`) ? (
                      <ControlledNumericTextField
                        fullWidth
                        minValue={1}
                        maxValue={watch(`productosA.${index}.total`)}
                        variant={'standard'}
                        controllerProps={{
                          control,
                          name: `${fieldName}.count`,
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 'bold',
                my: 2,
                textAlign: 'center',
              }}>
              Mesa Destino
            </Typography>
            {clientesMesaB && clientesMesaB.length ? (
              <ControlledSelector
                sourceData={clientesMesaB || []}
                title="Cliente"
                controllerProps={{control, name: 'clienteB'}}
                idKey="cliente_id"
                valueKey={['cliente_id']}
                getOptionLabel={opt => (opt ? opt.cliente.nombre_apellido : '')}
                fullWidth
              />
            ) : (
              <React.Fragment>
                <ControlledTextField
                  fullWidth
                  controllerProps={{control, name: 'nombres'}}
                  label={'Nombre y Apellido'}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} my={2}>
          <Grid item>
            <LoadingButton
              fullWidth
              loading={loading}
              disabled={
                !watch('clienteA') ||
                (!watch('clienteB') && !watch('nombres')) ||
                !watch('productosA').length
              }
              sx={{maxWidth: '200px', alignSelf: 'center'}}
              variant="contained"
              type={'submit'}
              color="secondary">
              Aceptar
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default MoverProductoForm;
