import useMediaQuery from '../../common/hooks/use-media-query';

function TwoBubbles() {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  return true ? (
    <>
      <svg
        style={{
          display: 'inline-block',
          position: 'absolute',
          right: '-15%',
          top: '-15%',
          zIndex: -2,
          width: '800',
          height: '800',
          maxWidth: '40%',
          maxHeight: '100%',
        }}>
        <circle
          cx="350"
          cy="350"
          r="340"
          style={{
            stroke: 'none',
            fill: 'rgba(247,173,33, 0.7)',
          }}
        />
      </svg>
      <svg
        style={{
          display: 'inline-block',
          position: 'absolute',
          right: '-23%',
          top: '0',
          zIndex: -2,
          width: '1200',
          height: '1200',
          maxWidth: '60%',
          maxHeight: '100%',
        }}>
        <circle
          cx="550"
          cy="550"
          r="540"
          style={{
            stroke: 'none',
            fill: 'rgba(247,173,33, 0.3)',
          }}
        />
      </svg>
    </>
  ) : (
    <></>
  );
}

export default TwoBubbles;
