import {Button, Stack, TextField} from '@mui/material';
import {shallowEqual} from 'react-redux';
import DatePicker from '../../components/date-picker';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import {setDates} from '../../redux/slices/inventario-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};

const InventarioNuevoModal = ({state, handleClose}: PropsTypes) => {
  const dispatch = useAppDispatch();

  const dates = useAppSelector(
    store => ({
      nueva: store.inventarioSlice.inventarioNuevoDate,
      pasada: store.inventarioSlice.inventarioPasadoDate,
    }),
    shallowEqual,
  );

  return (
    <DialogPopUp
      open={state}
      handleClose={handleClose}
      title="Seleccione la fechas de este nuevo inventario.">
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2}>
          <DatePicker
            label="Fecha nueva"
            disableFuture
            value={dates.nueva}
            onChange={date => {
              if (!date) return;
              dispatch(setDates({data: date, type: 'nueva'}));
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Stack>
        <Button
          fullWidth
          onClick={handleClose}
          sx={{maxHeight: '50px'}}
          variant="contained"
          color="secondary">
          Seleccionar
        </Button>
      </Stack>
    </DialogPopUp>
  );
};

export default InventarioNuevoModal;
