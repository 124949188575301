import {Stack} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import DateRangeContainer from './date-range';
import DetailsLayout from './details-wrapper';
import IngresosBox from './ingresos-box';
import RestauranteInfo from './restaurante-info';
import {
  selectedRestauranteSelector,
  setSelectedSucursal,
} from '../../../../redux/slices/reporte-restaurante-slice';
import {Redirect} from 'react-router-dom';
import {useEffect} from 'react';

const RestaurantDetails = () => {
  const dispatch = useAppDispatch();
  const selectedRestaurante = useAppSelector(selectedRestauranteSelector);
  const selectedSucursal = useAppSelector(
    store => store.reporteRestaurante.selectedSucursalID,
  );

  if (!selectedRestaurante)
    return <Redirect to="/administracion-ifudis/restaurantes" />;

  useEffect(() => {
    if (selectedSucursal) return;
    dispatch(
      setSelectedSucursal(
        selectedRestaurante.sucursales.length
          ? selectedRestaurante.sucursales[0].id_sucursal
          : null,
      ),
    );
  }, []);

  return (
    <DetailsLayout>
      <Stack direction={{xs: 'column', lg: 'row'}} sx={{width: '100%'}}>
        <RestauranteInfo balance={selectedRestaurante} />
        <Stack direction="column" sx={{flex: 2, mt: {xs: 5, md: 0}}}>
          {/* <Stack
            width="100%"
            direction={{xs: 'column-reverse', lg: 'row'}}
            alignItems="center"
            spacing={1}
            justifyContent={{xs: 'center', lg: 'flex-end'}}>
          </Stack> */}
          <DateRangeContainer />
          <Stack
            direction="row"
            sx={{width: '100%'}}
            spacing={1}
            justifyContent={{xs: 'center'}}>
            <IngresosBox />
            {/* <IngresosInfo /> */}
          </Stack>
        </Stack>
      </Stack>
    </DetailsLayout>
  );
};

export default RestaurantDetails;
