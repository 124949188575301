import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, Link, Container, Typography} from '@mui/material';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import RegisterForm from './RegisterForm';
import useMediaQuery from '../../common/hooks/use-media-query';
import TwoBubbles from '../../components/two-bubbles/two-bubbles';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  position: 'relative',
  overflow: 'hidden',
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const isDesktop = useMediaQuery('(min-width: 963px)');
  const dynamicSpacing = isDesktop ? -8 : -4;
  const logoDimensions = isDesktop ? 350 : 150;
  const padding = isDesktop ? 10 : 0;
  return (
    <RootStyle title="¡Regístrate ahora! || IFUDIS">
      <AuthLayout>
        ¿Ya tienes una cuenta? &nbsp;
        <Link
          underline="none"
          variant="subtitle1"
          component={RouterLink}
          to="/login">
          ¡Entra aquí!
        </Link>
      </AuthLayout>

      <TwoBubbles />
      <Container maxWidth="sm">
        <ContentStyle>
          {isDesktop ? (
            <Logo
              sx={{mb: dynamicSpacing}}
              width={logoDimensions}
              height={logoDimensions}
            />
          ) : (
            <></>
          )}
          <Box sx={{mb: 2, pl: padding}}>
            <Typography variant="h4" gutterBottom>
              Empiece absolutamente gratis.
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
              Siempre gratis. No se necesita tarjeta de crédito.
            </Typography>
          </Box>
          <RegisterForm />
        </ContentStyle>
      </Container>
      <Box sx={{flexGrow: 1}} />
    </RootStyle>
  );
}
