import {createAsyncThunk} from '@reduxjs/toolkit';
import {msgSuccess} from '../../../common/toast';
import {api} from '../../../services';
import {
  tipoBannerRestaurante,
  tipoDeBanners,
} from '../../../services/dto/marketing';
import {thunkAPiConfig} from '../user-slice/user.types';
import toIso86 from '../../../common/toIso86';
import {imageType} from '../../../services/dto/auth';
import {marketingSchema} from './marketing.schemas';
import yup from '../../../common/yup';

export const postPutBannerRestaurante = createAsyncThunk<
  null,
  {
    editId?: number;
    image: File | imageType | null;
  } & yup.InferType<typeof marketingSchema>,
  thunkAPiConfig
>('marketing/postputMarketing', async (data, thunkAPI) => {
  const {
    restauranteSlice: {restauranteEmpleadoSeleccionado},
  } = thunkAPI.getState();

  const body = await marketingSchema.validate(
    {
      ...data,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
      id_banner: data.editId,
      fecha_inicio: data.fecha_inicio.toISOString(),
      fecha_final: data.fecha_final?.toISOString(),
    },
    {stripUnknown: true, context: {request: true}},
  );

  const form = new FormData();

  form.append('fecha_inicio', toIso86(body.fecha_inicio));

  if (body.fecha_final) {
    form.append('fecha_final', toIso86(body.fecha_final));
  }

  form.append('orden_banners', body.orden_banners.toString());
  form.append('restaurante_id', body.restaurante_id!.toString());

  if (data.image instanceof File) {
    form.append('banner', data.image, data.image.name);
  }

  body.sucursales.forEach((sucursal, index) => {
    form.append(`sucursales[${index}]`, sucursal.toString());
  });

  if (!body.id_banner) {
    const response = await api.postTipoBannerRestaurante(form);
    if (!response) return thunkAPI.rejectWithValue('error al crear el banner');
    thunkAPI.dispatch(getTipoBannersRestaurante());
    msgSuccess('Banner creado con exito');
    return null;
  } else {
    const response = await api.putTipoBannerRestaurante(body.id_banner, form);
    if (!response)
      return thunkAPI.rejectWithValue('error al actualizar el banner');
    thunkAPI.dispatch(getTipoBannersRestaurante());
    msgSuccess('Banner actualizado con exito');
    return null;
  }
});

export const getTipoBanners = createAsyncThunk<
  tipoDeBanners[],
  undefined,
  thunkAPiConfig
>('marketing/getTipoBanners', async (data, thunkAPI) => {
  const response = await api.getTipoBanner();
  if (!response)
    return thunkAPI.rejectWithValue('error al obtener los tipos de banners');
  return response.data;
});

export const getTipoBannersRestaurante = createAsyncThunk<
  tipoBannerRestaurante[],
  undefined,
  thunkAPiConfig
>('marketing/getTipoBannersRestaurante', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('restaurante no seleccionado');
  const response = await api.getTipoBannerRestaurante(
    restauranteSeleccionado.id,
  );
  if (!response)
    return thunkAPI.rejectWithValue('error al obtener los tipos de banners');
  return response.data;
});

export const deleteBanner = createAsyncThunk<null, number, thunkAPiConfig>(
  'marketing/getTipoBanners',
  async (data, thunkAPI) => {
    const response = await api.deleteTipoBannerRestaurante(data);
    if (!response)
      return thunkAPI.rejectWithValue('error al eliminar el banner');
    thunkAPI.dispatch(getTipoBannersRestaurante());
    msgSuccess('Banner eliminado con exito');
    return null;
  },
);
