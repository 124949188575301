import getNestedValue from './getNestedValue';

export type options = 'agregar' | 'togglear' | 'eliminar' | 'clear';

type propType<T extends object> = {
  array: T[];
  baseArray: T[];
  type: options;
  key: (keyof T)[];
  id: any;
};

export default function addDelTogg<T extends object>({
  array,
  key,
  type,
  id,
  baseArray,
}: propType<T>) {
  let copyArray = [...array];
  const element = baseArray.find(elem => getNestedValue(elem, key) === id);
  if (!element) return copyArray;
  switch (type) {
    case 'agregar':
      copyArray.push(element);
      break;
    case 'eliminar':
      copyArray = copyArray.filter(elem => getNestedValue(elem, key) !== id);
      break;
    case 'togglear':
      const index = copyArray.findIndex(
        elem => getNestedValue(elem, key) === id,
      );
      if (index === -1) {
        copyArray.push(element);
      } else {
        copyArray.splice(index, 1);
      }
      break;
    case 'clear':
      copyArray = [];
      break;
    default:
      break;
  }

  return copyArray;
}
