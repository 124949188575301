import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';

import isOrdenIndirecta from '../../common/isOrdenIndirecta';
import {clienteStoreTypePrimary} from '../../redux/slices/orden-slice/orden.types';
import {useAppSelector} from '../../redux/store';
import NumericTextField from '../form-input/controlled-numeric-text-field';
import SplitButton from '../pre-cuenta-wrapper/split-button';
import {carritoMovementType} from './mover-cuenta-modal';

export type propType = {
  client: clienteStoreTypePrimary | null;
  setClient: React.Dispatch<
    React.SetStateAction<clienteStoreTypePrimary | null>
  >;
  setSelectedProducts: React.Dispatch<
    React.SetStateAction<carritoMovementType[]>
  >;
  selectedProducts: carritoMovementType[];
};

const ListProductosCheckbox = ({
  client,
  setClient,
  selectedProducts,
  setSelectedProducts,
}: propType) => {
  const carritoAPI = useAppSelector(store => store.ordenSlice.orden.carritoAPI);

  const clientes = useAppSelector(store => store.ordenSlice.orden.clientes);

  return (
    <FormControl component="fieldset" variant="standard" sx={{width: '50%'}}>
      <FormGroup>
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <SplitButton
              hideEdit
              onChange={id => {
                setSelectedProducts([]);
                setClient(clientes.find(element => element.id === id) ?? null);
              }}
              showNewClientForm={isOrdenIndirecta()}
              onCreate={client => {
                setClient(client);
              }}
              selected={client?.id}
              options={clientes}
            />
          </Box>
          <Box
            component={Stack}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%',
              height: '50px',
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedProducts.length === carritoAPI.length}
                  onChange={e => {
                    if (selectedProducts.length === carritoAPI.length)
                      return setSelectedProducts([]);
                    setSelectedProducts(
                      carritoAPI
                        .filter(e => e.clienteId === client?.id)
                        .map(element => ({
                          ...element,
                          moveCant: element.cantidad,
                        })),
                    );
                  }}
                  name="Marcar todo"
                />
              }
              label={`Marcar todo`}
            />
          </Box>
          <Divider />
        </>
        <>
          {carritoAPI
            .filter(carro => {
              if (!client) return false;
              return carro.clienteId === client.id;
            })
            .map(item => {
              const selected = selectedProducts.some(
                product => product.id === item.id,
              );
              return (
                <Box
                  key={item.id}
                  component={Stack}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    height: '50px',
                    my: 1,
                  }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        //disabled={disabled}
                        checked={selected}
                        onChange={e => {
                          if (selected)
                            return setSelectedProducts(prev =>
                              prev.filter(element => element.id != item.id),
                            );
                          return setSelectedProducts(prev => [
                            ...prev,
                            {...item, moveCant: item.cantidad},
                          ]);
                        }}
                        name={item.producto.producto.nombre_producto}
                      />
                    }
                    label={`${item.producto.producto.nombre_producto} x${item.cantidad}`}
                  />

                  {selected && item.cantidad > 1 && (
                    <NumericTextField
                      maxValue={item.cantidad}
                      value={
                        selectedProducts.find(element => element.id === item.id)
                          ?.moveCant
                      }
                      onChange={value => {
                        const index = selectedProducts.findIndex(
                          product => product.id === item.id,
                        );
                        const newSelectedProducts = [...selectedProducts];
                        newSelectedProducts[index].moveCant = value;
                        setSelectedProducts(newSelectedProducts);
                      }}
                      sx={{width: '60px', height: '45px', mr: 2}}
                    />
                  )}
                </Box>
              );
            })}
        </>
      </FormGroup>
    </FormControl>
  );
};

export default ListProductosCheckbox;
