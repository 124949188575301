import {useCallback, useEffect} from 'react';
import useMediaQuery from '../../common/hooks/use-media-query';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {
  resetDiarioSlice,
  seleccionarFactura,
  setFacturaCaja,
} from '../../redux/slices/diario-ventas-slice';
import {fetchFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import DesktopView from './desktop-view';
import MobileView from './mobile-view';

function DiarioVentas() {
  const isDesktop = useMediaQuery('(min-width:962px)');

  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );

  const sucursalSeleccionada = useAppSelector(
    store => store.restauranteSlice.sucursalSeleccionada,
  );
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const estadoFactura = useAppSelector(
    store => store.diarioVentasSlice.estado_factura,
  );

  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(fetchFactura());
    dispatch(stopLoading());
  }, []);

  useEffect(() => {
    dispatch(resetDiarioSlice());
    return () => {
      dispatch(seleccionarFactura(null));
    };
  }, []);

  useEffect(() => {
    if (!cajaEmpleado) dispatch(setFacturaCaja(false));
    fetchData();
  }, [sucursalSeleccionada, estadoFactura]);

  return (
    <Pagetemplate
      isLoading={isLoading}
      title="Diario de ventas || IFUDIS"
      soloLayout>
      {isDesktop ? <DesktopView /> : <MobileView />}
    </Pagetemplate>
  );
}

export default DiarioVentas;
