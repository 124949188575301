import {Button, Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {formatDateTime} from '../../common/formatTime';
import {useAppSelector} from '../../redux/store';
import DataRangeDiarioVentas from './date-range';

const FilterBar = () => {
  const facturaSeleccionada = useAppSelector(
    store => store.diarioVentasSlice.factura_seleccionada,
  );
  const history = useHistory();
  const fechas = useAppSelector(
    store => ({
      fecha_inicial: store.diarioVentasSlice.fecha_hora_inicial,
      fecha_final: store.diarioVentasSlice.fecha_hora_final,
    }),
    shallowEqual,
  );

  return (
    <Stack sx={{pb: '10px', px: 2}}>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between">
        <Box width="25%">
          <Button
            sx={{height: '38px', mt: 1}}
            variant="outlined"
            color="secondary"
            onClick={() => history.push('/orden')}>
            Atras
          </Button>
        </Box>

        <Box width="25%">
          <Typography
            sx={{
              fontSize: '0.7rem',
              lineHeight: 0.9,
            }}>
            Desde: {formatDateTime(fechas.fecha_inicial)}
          </Typography>
        </Box>
        <Box width="25%" display="flex" justifyContent="flex-end">
          <Typography
            sx={{
              textAlign: 'right',
              fontSize: '0.7rem',
              lineHeight: 0.9,
            }}>
            Hasta: {formatDateTime(fechas.fecha_final)}
          </Typography>
        </Box>
        <Box width="25%" display="flex" justifyContent="flex-end">
          <DataRangeDiarioVentas />
        </Box>
      </Stack>
      <Stack width="100%" alignItems="center">
        <Typography fontWeight="bold">
          {facturaSeleccionada ? `FACTURA ${facturaSeleccionada}` : ``}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FilterBar;
