import {createSlice} from '@reduxjs/toolkit';
import {actions} from './finanzas.actions';
import {
  deleteFinanza,
  fetchBaseData,
  fetchDescuentos,
  fetchImpuestos,
  fetchMetodosDePago,
  fetchPropinas,
  putPostDescuentos,
  putPostImpuesto,
  putPostMetodoPago,
  putPostPropina,
} from './finanzas.thunks';
import {finanzasSliceType} from './finanzas.types';

//initialState

export const initialState = {
  descuentos: [],
  impuestos: [],
  metodosPago: [],
  propinas: [],
  baseData: {descuentos: [], impuesto: [], propina: [], metodoPago: []},
} as finanzasSliceType;

//slice

export const finanzasSlice = createSlice({
  name: 'finazas-slice',
  initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(fetchDescuentos.fulfilled, (state, action) => {
        state.descuentos = action.payload;
      })
      .addCase(fetchImpuestos.fulfilled, (state, action) => {
        state.impuestos = action.payload;
      })
      .addCase(fetchMetodosDePago.fulfilled, (state, action) => {
        state.metodosPago = action.payload;
      })
      .addCase(fetchPropinas.fulfilled, (state, action) => {
        state.propinas = action.payload;
      })
      .addCase(fetchBaseData.fulfilled, (state, action) => {
        state.baseData = action.payload;
      })
      .addCase(putPostDescuentos.fulfilled, (state, action) => {
        state.descuentos = action.payload;
      })
      .addCase(putPostImpuesto.fulfilled, (state, action) => {
        state.impuestos = action.payload;
      })
      .addCase(putPostMetodoPago.fulfilled, (state, action) => {
        state.metodosPago = action.payload;
      })
      .addCase(putPostPropina.fulfilled, (state, action) => {
        state.propinas = action.payload;
      })
      .addCase(deleteFinanza.fulfilled, (state, action) => {
        state.descuentos = action.payload.descuentos || state.descuentos;
        state.impuestos = action.payload.impuestos || state.impuestos;
        state.metodosPago = action.payload.metodosPago || state.metodosPago;
        state.propinas = action.payload.propinas || state.propinas;
      });
  },
});

export const {resetFinanzasSlice} = finanzasSlice.actions;

export default finanzasSlice.reducer;
