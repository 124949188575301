import {Stack} from '@mui/material';
import FilterButton from '../../components/filter-button/filter-button';
import {seleccionarCategoriaInsumo} from '../../redux/slices/inventario-slice/inventario.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';

const CategoriaBarFilter = () => {
  const dispatch = useAppDispatch();
  const selectedCategoriaInsumo = useAppSelector(
    store => store.inventarioSlice.selectedInsumoSubcategory,
  );
  return (
    <Stack direction="row" spacing={2} mb={2}>
      <Stack direction="row" spacing={1} flexGrow={1}>
        <FilterButton
          label="ALIMENTOS"
          onClick={value => dispatch(seleccionarCategoriaInsumo(1))}
          isselected={selectedCategoriaInsumo === 1}
        />
        <FilterButton
          label="BEBIDAS"
          onClick={value => dispatch(seleccionarCategoriaInsumo(2))}
          isselected={selectedCategoriaInsumo === 2}
        />
        <FilterButton
          label="OTROS"
          isselected={selectedCategoriaInsumo === 3}
          onClick={value => dispatch(seleccionarCategoriaInsumo(3))}
        />
      </Stack>
    </Stack>
  );
};

export default CategoriaBarFilter;
