import {Helmet} from 'react-helmet-async';
import {forwardRef} from 'react';
// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

type propType = {
  children: React.ReactNode;
  title: string;
};

// eslint-disable-next-line react/display-name
const Page = forwardRef(({children, title = '', ...props}: propType, ref) => (
  <Box ref={ref} {...props}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </Box>
));

export default Page;
