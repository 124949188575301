import {Box, Button, Stack, Typography} from '@mui/material';
import {
  seleccionarMesa,
  setCurrentStep,
  updateMetaData,
} from '../../redux/slices/orden-slice';
import {CreateClient} from '../../redux/slices/orden-slice/orden.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import NumericTextField from '../form-input/controlled-numeric-text-field';

const PersonsForm = ({
  mesaId,
  closeOnNext = false,
  handleClose,
}: {
  mesaId: number;
  closeOnNext?: boolean;
  handleClose: () => void;
}) => {
  const cantidadComenzales = useAppSelector(
    store => store.ordenSlice.orden.metaData.cantidadDeComenzales,
  );
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <Typography sx={{fontSize: '24px', fontWeight: 'bold'}}>
        CANTIDAD DE COMENSALES
      </Typography>
      <NumericTextField
        type="normal"
        onChange={value => {
          dispatch(updateMetaData({cantidadDeComenzales: value ?? null}));
        }}
        disableDecimals
        value={cantidadComenzales ?? undefined}
        style={{
          width: '70px',
          height: '60px',
          borderRadius: '10px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '24px',
        }}
      />
      <Stack
        direction="row"
        sx={{width: '100%', justifyContent: 'space-between'}}>
        <Button
          fullWidth
          onClick={() => {
            if (closeOnNext) return handleClose();
            dispatch(updateMetaData({cantidadDeComenzales: 1}));
            dispatch(CreateClient({shouldSelect: true, name: 'CLIENTE 1'}));
            dispatch(seleccionarMesa(mesaId));
            dispatch(setCurrentStep(2));
          }}
          sx={{width: '28%'}}
          variant="contained"
          color="secondary"
          size="large">
          Omitir
        </Button>
        <Button
          fullWidth
          onClick={() => {
            if (closeOnNext) return handleClose();
            dispatch(CreateClient({shouldSelect: true, name: 'CLIENTE 1'}));
            dispatch(seleccionarMesa(mesaId));
            dispatch(setCurrentStep(2));
          }}
          disabled={!cantidadComenzales}
          sx={{width: '65%'}}
          variant="contained"
          color="primary"
          size="large">
          Siguiente
        </Button>
      </Stack>
    </Box>
  );
};

export default PersonsForm;
