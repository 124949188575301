import {memo, useMemo, useState} from 'react';
import {Box, Stack} from '@mui/material';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import SectionLabel from './section-label';
import LogoIcon from './logo';
import MenuIcon from './menu-icon';
import SucursalSelectModal from '../../components/sucursal-select-modal';
import {RootStyle, ToolbarStyle} from './nav-bar-styles';
import RestauranteSucursalLabel from '../../components/restaurante-sucursal-navlabel/restaurante-sucursal-navlabel';
import DropdownSecciones from '../../components/dropdown-menu';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {setMesaSeccion} from '../../redux/slices/orden-slice';
import {useLocation} from 'react-router-dom';

type PropsTypes = {
  onOpenSidebar: () => void;
  hideSideBar?: boolean;
  needSelectedRestaurant?: boolean;
};

function NavBar({
  onOpenSidebar,
  hideSideBar = false,
  needSelectedRestaurant,
}: PropsTypes) {
  const [open, setOpen] = useState<boolean>(false);

  const secciones = useAppSelector(
    store => store.restauranteUtilsSlice.sucursalSecciones,
  );
  const filteredSecciones = useMemo(
    () => secciones.filter(e => e.tipo_seccion.id_tipo_seccion === 3),
    [secciones],
  );
  const selectedSeccion = useAppSelector(
    store => store.ordenSlice.uiData.selectedMesaSeccion,
  );
  const dispatch = useAppDispatch();

  const currentStep = useAppSelector(store => store.ordenSlice.currentStep);

  const location = useLocation();
  return (
    <RootStyle>
      <ToolbarStyle>
        {!hideSideBar && <MenuIcon onOpenSidebar={onOpenSidebar} />}
        <LogoIcon />
        <SectionLabel />
        {/* <Searchbar /> */}
        <Box sx={{flexGrow: 1}} />
        <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
          {/* <LanguagePopover /> */}
          {currentStep === 1 && location.pathname === '/orden' && (
            <DropdownSecciones
              style="button"
              data={filteredSecciones.sort((a, b) =>
                a.nombre_seccion.localeCompare(b.nombre_seccion),
              )}
              selectedId={selectedSeccion?.id_seccion_sucursal}
              idKey="id_seccion_sucursal"
              valueKey={['nombre_seccion']}
              fallBackMessage="Sin seccion Seleccionada"
              onClickSelected={() => {
                dispatch(setMesaSeccion(null));
              }}
              onSelect={seccion => {
                dispatch(setMesaSeccion(seccion));
              }}
            />
          )}
          {needSelectedRestaurant && (
            <RestauranteSucursalLabel setOpen={setOpen} />
          )}

          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
      <SucursalSelectModal state={open} handleClose={() => setOpen(false)} />
    </RootStyle>
  );
}

export default memo(NavBar);
