import {IconButton} from '@mui/material';
import menuAlt3 from '@iconify/icons-dashicons/menu-alt3';
import {useAppSelector} from '../../redux/store';
import isRoleAllowed from '../../common/isRoleAllowed';
import {Icon} from '@iconify/react';

interface PropsTypes {
  onOpenSidebar: () => void;
}

const MenuIcon = ({onOpenSidebar}: PropsTypes) => {
  const restauranteSeleccionado = useAppSelector(
    state => state.restauranteSlice.restauranteEmpleadoSeleccionado,
  );

  const empleadoAsignadoSucursalSeleccionado = useAppSelector(
    state => state.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  if (!isRoleAllowed([0], [9])) return null;

  return (
    restauranteSeleccionado &&
    empleadoAsignadoSucursalSeleccionado && (
      <IconButton onClick={onOpenSidebar} sx={{mr: 2, color: 'text.primary'}}>
        <Icon icon={menuAlt3} />
      </IconButton>
    )
  );
};

export default MenuIcon;
