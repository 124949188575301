import Waves from '../waves/waves';
import {SpinnerContainer, SpinnerOverlay} from './with-spinner.styles';

export const Spinner = function () {
  return (
    <SpinnerOverlay>
      <Waves />
      <SpinnerContainer />
    </SpinnerOverlay>
  );
};
