import {createSlice} from '@reduxjs/toolkit';
import {formatDateTime} from '../../../common/formatTime';
import {Actions} from './restaurante-utils.actions';
import {
  deleteCajaEmpleado,
  deleteEmpleadoAsignadoSeccion,
  getApodosMesa,
  getCajas,
  getCajasEmpleado,
  getEmpleadoAsignadoSeccion,
  getMesaSucursal,
  getSeccionSucursal,
  getTipoSeccion,
  postCaja,
  postCajaEmpleado,
  postEmpleadoAsignadoSeccion,
  putPostMesaSucursal,
  putPostSeccionSucursal,
} from './restaurante-utils.thunks';
import {restauranteUtilsStoreType} from './restaurante-utils.types';

export const initialSeccionSucursalState = {
  nombre_seccion: null,
  subCategorias: [],
  id_seccion_sucursal: null,
  categoria_id: null,
  tipoSeccion: null,
};

export const initialCajaEmpleado = {
  caja_sucursal_id: null,
  asignador_sucursal_id: null,
  empleado_sucursal_id: null,
  fecha_asignacion: null,
  id_caja_empleado: null,
};

export const initialCajaState = {
  numero_caja: null,
  sucursal_id: null,
  id_caja_sucursal: null,
};

export const initialEmpleadoAsignadoSeccion = {
  empleado_sucursal_id: null,
  seccion_id: null,
};

export const initialState = {
  editable: {
    caja: initialCajaState,
    cajaEmpleado: initialCajaEmpleado,
    empleadoAsignadoSeccion: initialEmpleadoAsignadoSeccion,
  },
  sucursalSecciones: [],
  cajaEmpleados: [],
  cajas: [],
  mesasSucursal: [],
  tipoSeccion: [],
  empleadoAsignadosSeccion: [],
  apodosMesa: [],
} as restauranteUtilsStoreType;

//schema

export const restauranteUtilsSLice = createSlice({
  name: 'restaurante-utils-slice',
  initialState,
  reducers: {...Actions},
  extraReducers: builder => {
    builder
      .addCase(getSeccionSucursal.fulfilled, (state, action) => {
        state.sucursalSecciones = action.payload;
      })

      .addCase(putPostSeccionSucursal.fulfilled, (state, action) => {
        state.sucursalSecciones = action.payload;
      })
      .addCase(getMesaSucursal.fulfilled, (state, action) => {
        state.mesasSucursal = action.payload.map(element => ({
          ...element,
          estado_mesa_boolean: element.deleted_at === null,
        }));
      })
      .addCase(getApodosMesa.fulfilled, (state, action) => {
        state.apodosMesa = action.payload;
      })
      .addCase(putPostMesaSucursal.fulfilled, (state, action) => {})
      .addCase(getCajas.fulfilled, (state, action) => {
        state.cajas = action.payload;
      })
      .addCase(getCajasEmpleado.fulfilled, (state, action) => {
        state.cajaEmpleados = action.payload.map(element => ({
          date: formatDateTime(element.fecha_asignacion, true),
          ...element,
        }));
      })
      .addCase(postCaja.fulfilled, (state, action) => {})
      .addCase(postCajaEmpleado.fulfilled, (state, action) => {})
      .addCase(deleteCajaEmpleado.fulfilled, (state, action) => {})
      .addCase(getEmpleadoAsignadoSeccion.fulfilled, (state, action) => {
        state.empleadoAsignadosSeccion = action.payload;
      })
      .addCase(postEmpleadoAsignadoSeccion.fulfilled, (state, action) => {})
      .addCase(deleteEmpleadoAsignadoSeccion.fulfilled, (state, action) => {})
      .addCase(getTipoSeccion.fulfilled, (state, action) => {
        state.tipoSeccion = action.payload;
      });
  },
});

export const {
  resetEditable,
  actualizarCaja,
  ResetRestauranteUtilsSlice,
  actualizarCajaEmpleado,
  actualizarEmpleadoAsignadoSeccion,
} = restauranteUtilsSLice.actions;

export default restauranteUtilsSLice.reducer;
