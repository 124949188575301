import {Stack, Typography} from '@mui/material';

type priceTextProps = {
  text: string;
  number: number;
  grow?: boolean;
};

function PriceText({text, number, grow = false}: priceTextProps) {
  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        mb: 0.7,
        width: '100%',
        textAlign: 'right',
      }}>
      <Typography
        sx={{
          fontSize: {
            xs: grow ? '1rem' : '0.8rem',
            sm: grow ? '1rem' : '0.8rem',
          },
          fontWeight: 'bold',
          textTransform: 'uppercase',
          mr: 1,
        }}>
        {text}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: grow ? '1rem' : '0.8rem',
            sm: grow ? '1rem' : '0.8rem',
          },
          width: '120px',
          borderRadius: '15px',
          backgroundColor: '#dbdbdb',
          color: '#333333',
          py: 0.2,
          pl: 2,
          pr: 1.2,
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}>
        {`$${number.toFixed(2)}`}
      </Typography>
    </Stack>
  );
}

export default PriceText;
