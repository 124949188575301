import {LoadingButton} from '@mui/lab';
import {Stack, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {memo, useState} from 'react';
import ComandasConfirmDialog from './comanda-confirm-dialog';
import {useLocation} from 'react-router-dom';

type PropsTypes = {
  orden: number;
  estado: boolean;
  ubicacion: string;
  numeroMesa?: number;
  apodoMesa?: string;
};

const ComandaHeading = ({
  estado,
  orden,
  ubicacion,
  numeroMesa,
  apodoMesa,
}: PropsTypes) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isHistorial = useLocation().pathname === '/historial-comandas';
  return (
    <Stack direction="row" spacing={0.5} sx={{width: '100%'}}>
      <ComandasConfirmDialog
        state={open}
        setLoading={setLoading}
        handleClose={() => setOpen(false)}
        orden={orden}
      />
      <Typography
        noWrap
        sx={{
          backgroundColor: '#3C3C3C',
          borderRadius: '5px',
          height: '35px',
          flex: !isHistorial && ubicacion === 'PARA LLEVAR' ? 1 : 2,
          textAlign: 'center',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '0.9rem',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {apodoMesa
          ? `${apodoMesa} #${numeroMesa}`
          : numeroMesa
          ? `Mesa #${numeroMesa}`
          : 'DIRECTA'}
      </Typography>
      {!isHistorial && ubicacion === 'PARA LLEVAR' && (
        <Typography
          sx={{
            backgroundColor: '#f59823',
            borderRadius: '5px',
            height: '35px',
            flex: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '0.9rem',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          LLEVAR
        </Typography>
      )}
      {!isHistorial && (
        <LoadingButton
          loading={loading}
          onClick={() => setOpen(true)}
          sx={{
            flex: 1,
            height: '35px',
            backgroundColor: estado ? 'green' : 'red',
            borderRadius: '5px',
            fontSize: '0.9rem',
            '&:hover': {
              backgroundColor: estado ? 'lime' : 'darkred',
            },
          }}
          variant="contained"
          fullWidth>
          <CheckCircleIcon sx={{fontSize: '1.8rem'}} />
        </LoadingButton>
      )}
    </Stack>
  );
};

export default memo(ComandaHeading);
