import {ListItem, ListItemText, Stack, Typography} from '@mui/material';
import {useMemo} from 'react';
import {fCurrency} from '../../common/formatNumber';
import {carritoType} from '../../redux/slices/orden-slice/orden.types';
import {useAppSelector} from '../../redux/store';

type PropsTypes = {
  item: carritoType;
};

const ItemResumido = ({item}: PropsTypes) => {
  const descuentos = useAppSelector(store => store.finanzasSlice.descuentos);
  const descuento = descuentos.find(
    descuento =>
      descuento.id_tipo_descuento_restaurante === item.descuentoId &&
      descuento.tipo_pago_descuento_id !== 8 &&
      descuento.tipo_pago_descuento_id !== 4,
  );

  let descuentoTotal: number | undefined;

  const precioModificador = useMemo(
    () =>
      item.modificadores.reduce(
        (a, b) => a + b.accion_asignada_modificador.modificador.precio,
        0,
      ),
    [item],
  );

  if (
    item.descuentoId != undefined &&
    item.descuentoCant != undefined &&
    descuento != undefined
  )
    descuentoTotal =
      ((item.producto.producto.precio_producto + precioModificador) *
        item.descuentoCant *
        descuento.porcentaje) /
      100;

  return (
    <ListItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 0.5,
        overflow: 'hidden',
      }}>
      <Stack width="100%">
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between">
          <ListItemText
            sx={{mb: 0}}
            primary={`${item.producto.producto.nombre_producto.toUpperCase()}`}
            primaryTypographyProps={{
              style: {
                fontSize: '1rem',
                lineHeight: 1,
                fontWeight: 'bold',
                textTransform: 'capitalize',
                wordBreak: 'break-word',
              },
            }}
            secondary={`${fCurrency(
              item.producto.producto.precio_producto,
            )} x ${item.cantidad}`}
            secondaryTypographyProps={{
              style: {
                fontSize: '0.7rem',
                lineHeight: 1,
                fontWeight: 'bold',
                wordBreak: 'break-word',
              },
            }}
          />
          <Typography
            sx={{
              fontSize: '0.9rem',
              lineHeight: 1,
              fontWeight: 'bold',
              textAlign: 'right',
            }}>
            {fCurrency(item.producto.producto.precio_producto * item.cantidad)}
          </Typography>
        </Stack>

        {[...item.modificadores]
          .sort(
            (a, b) =>
              b.accion_asignada_modificador.modificador.precio -
              a.accion_asignada_modificador.modificador.precio,
          )
          .map(
            ({
              accion_asignada_modificador: {modificador, accion},
              id_modificador_menu,
            }) => {
              if (!modificador.precio) return null;
              const shouldShowPrice =
                accion.id_accion_modificador !== 2 &&
                accion.id_accion_modificador !== 3;
              if (!shouldShowPrice) return null;
              return (
                <Stack
                  key={id_modificador_menu}
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <ListItemText
                    secondary={`${accion.nombre_accion_modificador} - ${modificador.nombre_modificador}`}
                    secondaryTypographyProps={{
                      style: {
                        fontSize: '0.7rem',
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        lineHeight: 1,
                        wordBreak: 'break-word',
                        textTransform: 'uppercase',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      lineHeight: 1,
                      fontWeight: 'normal',
                      textAlign: 'right',
                      color: 'gray',
                      textTransform: 'uppercase',
                    }}>
                    {`(${fCurrency(modificador.precio)})`}
                  </Typography>
                </Stack>
              );
            },
          )}
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between">
          {descuentoTotal && (
            <>
              <Typography
                sx={{
                  fontSize: '0.7rem',
                  lineHeight: 1,
                  fontWeight: 'normal',
                  textAlign: 'right',
                  color: 'red',
                  textTransform: 'uppercase',
                }}>
                Descuento:
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.7rem',
                  lineHeight: 1,
                  fontWeight: 'normal',
                  textAlign: 'right',
                  color: 'red',
                }}>{`(-${fCurrency(descuentoTotal)})`}</Typography>
            </>
          )}
        </Stack>
      </Stack>

      {/* <Stack>
       
        {descuentoTotal && (
          <Typography
            sx={{
            
            }}>{``}</Typography>
        )}
      </Stack> */}
    </ListItem>
  );
};

export default ItemResumido;
