import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import NewEmpleado from '../../components/new-empleado/new-empleado';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {useCallback, useEffect} from 'react';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {
  getCargos,
  getEmpleados,
} from '../../redux/slices/empleados-slice/empleados.thunks';
import {getSucursales} from '../../redux/slices/restaurante-slice/restaurente.thunks';

function Empleados() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);

  const fetchAllScreenData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(getEmpleados());
    await dispatch(getSucursales());
    await dispatch(getCargos());
    dispatch(stopLoading());
  }, []);

  useEffect(() => {
    fetchAllScreenData();
  }, []);

  return (
    <Pagetemplate isLoading={isLoading} title="Empleados || IFUDIS" soloLayout>
      <NewEmpleado />
    </Pagetemplate>
  );
}

export default Empleados;
