import {Stack} from '@mui/material';
import TitleText from '../title-text/title-text';
import {useState} from 'react';
import DescuentoPorLineaModal from './descuento-linea-modal';
import List from './list';

function DescuentoList() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack>
      <TitleText text="descuento" />
      <List handleOpen={() => setOpen(true)} />
      <DescuentoPorLineaModal state={open} handleClose={() => setOpen(false)} />
    </Stack>
  );
}

export default DescuentoList;
