import {
  Card,
  CardContent,
  Grid,
  TextField,
  Stack,
  Switch,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {putPost} from '../../redux/slices/menu-slice/menu.types';
import {updateSucursalStoreType} from '../../redux/slices/restaurante-slice/restaurante.types';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {actualizarSucursal} from '../../redux/slices/restaurante-slice';
import {putPostSucursal} from '../../redux/slices/restaurante-slice/restaurente.thunks';
import isNullish from '../../common/isNullish';
import {useEffect} from 'react';
import NumericTextField from '../form-input/controlled-numeric-text-field';
import {fetchImpuestos} from '../../redux/slices/finanzas-slice/finanzas.thunks';

type propsTypes = {
  type: putPost;
  handleClose?: VoidFunction;
};

const SucursalForm = function ({type, handleClose}: propsTypes) {
  const dispatch = useAppDispatch();
  const sucursal = useAppSelector(
    state => state.restauranteSlice.editable.sucursal,
  );
  const isLoading = useAppSelector(state => state.uiSlice.isLoading);
  const impuestos = useAppSelector(store => store.finanzasSlice.impuestos);

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    await dispatch(putPostSucursal(type))
      .unwrap()
      .then(x => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  const actualizar = (data: updateSucursalStoreType) => {
    dispatch(actualizarSucursal(data));
  };

  const shouldPass = () => {
    if (type == 'crear') {
      const {estado_sucursal, sucursal_id, restaurante_id, ...rest} = sucursal;
      return isNullish(rest);
    }
    return isNullish(sucursal);
  };

  useEffect(() => {
    actualizar({estado_sucursal: true});
    dispatch(fetchImpuestos());
  }, []);

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Nombre"
                  value={sucursal.descripcion_ubicacion || ''}
                  onChange={e =>
                    actualizar({
                      descripcion_ubicacion: e.target.value.toUpperCase(),
                    })
                  }
                  required
                />
                <TextField
                  fullWidth
                  multiline
                  label="Horario"
                  value={sucursal.descripcion_horario || ''}
                  onChange={e =>
                    actualizar({
                      descripcion_horario: e.target.value.toUpperCase(),
                    })
                  }
                  required
                  helperText="Este campo se utilizará para mostrar el horario de operación."
                />
                <TextField
                  fullWidth
                  multiline
                  label="RUC Sucursal"
                  value={sucursal.ruc_sucursal || ''}
                  onChange={e =>
                    actualizar({
                      ruc_sucursal: e.target.value.toUpperCase(),
                    })
                  }
                  required
                />
                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <NumericTextField
                    label="Latitud"
                    value={sucursal.latitud}
                    disabled
                    onChange={value => {
                      actualizar({latitud: value});
                    }}
                    required
                  />
                  <NumericTextField
                    label="Longitud"
                    disabled
                    value={sucursal.longitud}
                    onChange={value => {
                      actualizar({longitud: value});
                    }}
                    required
                  />
                </Stack>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!impuestos.length}
                      checked={
                        impuestos.length > 0 &&
                        (sucursal.cobra_impuesto === 1 ? true : false)
                      }
                      onChange={e => {
                        actualizar({cobra_impuesto: e.target.checked ? 1 : 0});
                      }}
                      inputProps={{'aria-label': 'impuestos'}}
                    />
                  }
                  label="¿Cobra impuestos?"
                />
                <FormHelperText>
                  Los impuesto se cobrarán automáticamente en la facturación si
                  este campo esta activado.
                </FormHelperText>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoading}
            disabled={shouldPass()}>
            Guardar Cambios
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SucursalForm;
