import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../services';
import fileDownload from 'js-file-download';
import {
  reporteArticulosBorrados,
  reporteBalanceActual,
  reporteContador,
  reporteDeVentasPorMesa,
  reporteDeVentasPorTiempo,
  reporteDeVentasPorUsuario,
  reporteFacturasAnuladas,
  reporteVentasPorArticulo,
  reporteVentasPorCategoria,
  reporteVentasPorDescuento,
  reporteVentasPorMetodoPago,
  reporteVentasPorSeccion,
  reporteVentasPorSubCategoria,
} from '../../../services/dto/reportes';
import {RootState} from '../../store';

export const getReporteBalanceActual = createAsyncThunk<
  reporteBalanceActual,
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReporteBalanceActual', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteBalanceActual: {
        isInventarioFilterActive,
        fecha_final: fecha_final_reporte,
        fecha_inicio: fecha_inicio_reporte,
      },
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_inventario_inicial: isInventarioFilterActive
      ? fecha_inicio_reporte.toISOString()
      : fecha_inicio.toISOString(),
    fecha_inventario_final: isInventarioFilterActive
      ? fecha_final_reporte.toISOString()
      : fecha_final.toISOString(),
    fecha_inicial: fecha_inicio.toISOString(),
    fecha_final: fecha_final.toISOString(),
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
  };

  const response = await api.postReporteBalanceActual(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response;
});

export const getReporteVentasPorArticulo = createAsyncThunk<
  reporteVentasPorArticulo,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/getReporteVentasPorArticulo',
  async (data, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
        reporteVentasPorArticulo: {isGlobal},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: !isGlobal
        ? empleadoAsignadoSucursalSeleccionado.sucursal_id
        : undefined,
    };

    const response = await api.postReporteVentasPorArticulo(body);

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    return response;
  },
);

export const downloadReporteVentasPorArticulo = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReporteVentasPorArticulo',
  async (data, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    };

    const response = await api.downloadReporteVentasPorArticulo(body);

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    fileDownload(
      response,
      `ventas_por_articulos_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
    );

    return true;
  },
);

export const getReporteArticulosBorrados = createAsyncThunk<
  reporteArticulosBorrados[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReporteArticulosBorrados', async (arg, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteArticulosBorrados: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.postReporteArticulosBorrados(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response.data;
});

export const downloadReporteArticulosBorrados = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReporteArticulosBorrados',
  async (arg, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    };
    const response = await api.downloadReporteArticulosBorrados(body);

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');
    const filename = `articulos_borrados_${body.fecha_hora_final}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`;
    fileDownload(response, filename);

    return true;
  },
);

export const getReportePorMetodoPago = createAsyncThunk<
  reporteVentasPorMetodoPago,
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReportePorMetodoPago', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorMetodoPago: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.postReporteDeVentasPorMetodoPago(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response;
});

export const downloadReportePorMetodoPago = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReportePorMetodoPago',
  async (data, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
        reporteVentasPorMetodoPago: {isGlobal},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: isGlobal
        ? empleadoAsignadoSucursalSeleccionado.sucursal_id
        : undefined,
    };

    const response = await api.downloadReporteDeVentasPorMetodoPago(body);

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    fileDownload(
      response,
      `ventas_metodo_pago_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
    );

    return true;
  },
);

export const getReportePorCategoria = createAsyncThunk<
  reporteVentasPorCategoria[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReportePorCategoria', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorCatergoria: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.postReporteVentasPorCategoria(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response;
});

export const downloadReportePorCategoria = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReportePorCategoria',
  async (data, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
        reporteVentasPorCatergoria: {isGlobal},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: isGlobal
        ? empleadoAsignadoSucursalSeleccionado.sucursal_id
        : undefined,
    };

    const response = await api.downloadReporteVentasPorCategoria(body);

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    fileDownload(
      response,
      `ventas_categoria_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
    );

    return true;
  },
);

export const getReportePorSubCategoria = createAsyncThunk<
  reporteVentasPorSubCategoria[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReportePorSubCategoria', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
  };

  const response = await api.postReporteVentasPorSubCategoria(body);
  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response;
});

export const getReporteVentasPorDescuentos = createAsyncThunk<
  reporteVentasPorDescuento[],
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/getReporteVentasPorDescuentos',
  async (arg, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    };

    const response = await api.postReporteVentasPorDescuento(body);
    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    return response.data;
  },
);

export const downloadReporteVentasPorDescuentos = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReporteVentasPorDescuentos',
  async (arg, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    };

    const response = await api.downloadReporteVentasPorDescuento(body);
    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    fileDownload(
      response,
      `descuentos_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
    );

    return true;
  },
);

export const getReporteFacturasAnuladas = createAsyncThunk<
  reporteFacturasAnuladas[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReporteFacturasAnuladas', async (arg, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteFactorasAnuladas: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };
  const response = await api.postReporteFacturasAnuladas(body);
  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response.data;
});

export const downloadReporteFacturasAnuladas = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReporteFacturasAnuladas',
  async (arg, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
        reporteFactorasAnuladas: {isGlobal},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: !isGlobal
        ? empleadoAsignadoSucursalSeleccionado.sucursal_id
        : undefined,
    };
    const response = await api.downloadReporteFacturasAnuladas(body);
    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    fileDownload(
      response,
      `factural_anuladas_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
    );

    return true;
  },
);

export const downloadReportePorSubCategoria = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>(
  'reporteBalanceActual/downloadReportePorSubCategoria',
  async (data, thunkAPI) => {
    const {
      reportes: {
        fecha: {fecha_inicio, fecha_final},
        reporteVentasPorSubCatergoria: {isGlobal},
      },
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const body = {
      fecha_hora_inicial: fecha_inicio.toISOString(),
      fecha_hora_final: fecha_final.toISOString(),
      restaurante_id:
        empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
      sucursal_id: isGlobal
        ? empleadoAsignadoSucursalSeleccionado.sucursal_id
        : undefined,
    };

    const response = await api.downloadReporteVentasPorSubCategoria(body);
    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    fileDownload(
      response,
      `ventas_subcategoria_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
    );

    return true;
  },
);

export const getReportePorTiempo = createAsyncThunk<
  reporteDeVentasPorTiempo[],
  'hora' | 'dia',
  {state: RootState}
>('reporteBalanceActual/getReportePorTiempo', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorTiempo: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
    type: data,
  };

  const response = await api.postReporteVentasPorTiempo(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response;
});

export const downloadReportePorTiempo = createAsyncThunk<
  any,
  'hora' | 'dia',
  {state: RootState}
>('reporteBalanceActual/downloadReportePorTiempo', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorTiempo: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
    type: data,
  };

  const response = await api.downloadReporteVentasPorTiempo(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  fileDownload(
    response,
    `ventas_por_${data}_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
  );

  return true;
});

export const getReportePorUsuario = createAsyncThunk<
  reporteDeVentasPorUsuario[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReportePorUsuario', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorUsuario: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.postReporteVentasPorUsuario(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response.data;
});

export const downloadReportePorUsuario = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>('reporteBalanceActual/downloadReportePorUsuario', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorUsuario: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.downloadReporteVentasPorUsuario(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  fileDownload(
    response,
    `ventas_usuario_empleado_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
  );

  return true;
});

export const getReportePorMesa = createAsyncThunk<
  reporteDeVentasPorMesa[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReportePorMesa', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorMesa: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.postReporteVentasPorMesa(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response.data;
});

export const getReportePorSeccion = createAsyncThunk<
  reporteVentasPorSeccion[],
  undefined,
  {state: RootState}
>('reporteBalanceActual/getReportePorSeccion', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
      reporteVentasPorSeccion: {isGlobal},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: !isGlobal
      ? empleadoAsignadoSucursalSeleccionado.sucursal_id
      : undefined,
  };

  const response = await api.postReporteVentasPorSeccion(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  return response;
});

export const getReporteContadores = createAsyncThunk<
  reporteContador,
  {startDate: Date; endDate: Date; timezone: string},
  {state: RootState}
>(
  'reporteBalanceActual/getReporteContadores',
  async ({startDate, endDate, timezone}, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();
    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

    const data = {
      fecha_inicial: startDate.toISOString(),
      fecha_final: endDate.toISOString(),
      sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
      timezone,
    };
    const response = await api.gerReporteContadores(data);

    if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

    return response;
  },
);

export const downloadReportePorMesa = createAsyncThunk<
  any,
  undefined,
  {state: RootState}
>('reporteBalanceActual/downloadReportePorMesa', async (data, thunkAPI) => {
  const {
    reportes: {
      fecha: {fecha_inicio, fecha_final},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  const body = {
    fecha_hora_inicial: fecha_inicio.toISOString(),
    fecha_hora_final: fecha_final.toISOString(),
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
  };

  const response = await api.downloadReporteVentasPorMesa(body);

  if (!response) return thunkAPI.rejectWithValue('error al obtener reporte');

  fileDownload(
    response,
    `ventas_mesa_${body.fecha_hora_inicial}_${body.fecha_hora_final}_${body.restaurante_id}.xlsx`,
  );

  return true;
});
