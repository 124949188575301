import {Button} from '@mui/material';
import useMediaQuery from '../../common/hooks/use-media-query';

type customButtonProps = {
  name: string;
  addEm: Function;
  isselected?: boolean;
  noBorderRadius?: boolean;
  disabled?: boolean;
};

function CustomButton({
  name,
  isselected = false,
  addEm,
  noBorderRadius = false,
  disabled = false,
}: customButtonProps) {
  const isDesktop = useMediaQuery('(min-width: 750px)');
  return (
    <Button
      sx={{
        borderBottomLeftRadius: noBorderRadius ? 0 : undefined,
        borderBottomRightRadius: noBorderRadius ? 0 : undefined,
        height: '40px',
        fontSize: '0.7rem',
        fontWeight: 'bold',
      }}
      fullWidth
      disabled={disabled}
      size={isDesktop ? 'medium' : 'small'}
      color={isselected ? 'primary' : 'secondary'}
      onClick={() => addEm()}
      variant={isselected ? 'contained' : 'outlined'}>
      {name}
    </Button>
  );
}

export default CustomButton;
