import {useAppSelector} from '../../redux/store';
import {memo} from 'react';
import ItemCompleto from './item-completo';
import ItemResumido from './item-resumido';
import {carritoType} from '../../redux/slices/orden-slice/orden.types';

type cartItemProps = {
  item: carritoType;
  showDate: boolean;
};

function CartItem({item, showDate}: cartItemProps) {
  const currentStep = useAppSelector(state => state.ordenSlice.currentStep);

  if (currentStep === 2)
    return <ItemCompleto item={item} showDate={showDate} />;

  return <ItemResumido item={item} />;
}

export default memo(CartItem);
