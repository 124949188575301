// material
import {styled} from '@mui/material/styles';
import {Typography} from '@mui/material';
// components
import Logo from '../components/Logo';
import useMediaQuery from '../common/hooks/use-media-query';
//

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({theme}) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 7, 0, 7),
  },
}));

interface authLayoutProps {
  children: React.ReactNode;
}

// ----------------------------------------------------------------------

export default function AuthLayout({children}: authLayoutProps) {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  return (
    <HeaderStyle>
      {!isDesktop ? <Logo /> : <></>}
      <Typography
        variant="body2"
        sx={{
          mt: {md: -2},
        }}>
        {children}
      </Typography>
    </HeaderStyle>
  );
}
