import {useState} from 'react';
import {useGetUserBySearchTermQuery} from '../../redux/queries/search-user-query';
import Autocomplete from '@mui/material/Autocomplete';
import {userType} from '../../services/dto/auth';
import {TextField} from '@mui/material';
import {useDebouncedValue} from '../../common/hooks/use-debounced-value';

type propType = {
  defaultsearchTerm?: string;
  defaultUser?: userType | null;
  onSelected: (value: userType | null) => void;
  delay?: number;
};

export default function UserSearch({
  onSelected,
  defaultUser = null,
  defaultsearchTerm = '',
  delay = 250,
}: propType) {
  const [searchTerm, setSearchTerm] = useState(defaultsearchTerm);

  const debauncedSearchTerm = useDebouncedValue({
    value: searchTerm,
    delay,
  });

  const isValidSearchTerm = () => debauncedSearchTerm.trim().length > 0;

  const {isLoading, data} = useGetUserBySearchTermQuery(debauncedSearchTerm, {
    skip: !isValidSearchTerm(),
  });

  const [selectedValue, setselectedValue] = useState<userType | null>(
    defaultUser,
  );

  return (
    <Autocomplete
      noOptionsText="Escriba para buscar opciones..."
      loading={isLoading}
      loadingText="Buscando resultados.."
      autoHighlight
      options={data?.data || []}
      getOptionLabel={(option: userType) =>
        typeof option === 'string'
          ? option
          : `${option.name} ${option.lastname}`
      }
      filterOptions={x => {
        if (searchTerm.trim().length > 0) return x;
        return [];
      }}
      autoComplete
      includeInputInList
      value={selectedValue}
      onChange={(event, newValue) => {
        setselectedValue(newValue);
        onSelected(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue || '');
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Buscar por correo electrónico..."
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const {id, name, lastname, email} = option;
        return (
          <li value={id} {...props}>
            {`${name} ${lastname} - ${email}`}
          </li>
        );
      }}
    />
  );
}
