import {Box, List, ListItem, ListItemText} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type propType = {
  handleClickListItem: (event: React.MouseEvent<HTMLElement>) => void;
  text: string;
  show: boolean;
};

const BorderedButton = ({text, handleClickListItem, show}: propType) => {
  return (
    <List
      component="nav"
      sx={{
        bgcolor: 'background.paper',
        p: 0,
        borderRadius: '5px',
        color: 'black',
        display: show ? 'flex' : 'none',
      }}>
      <Box
        sx={{
          border: '1px solid gray',
          borderRadius: '5px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ListItem button onClick={handleClickListItem}>
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              fontSize: '0.7rem',
              fontWeight: 'bold',
            }}
          />
          <KeyboardArrowDownIcon sx={{fontSize: '1.2rem', ml: 0.25}} />
        </ListItem>
      </Box>
    </List>
  );
};

export default BorderedButton;
