import useMediaQuery from '../../common/hooks/use-media-query';

function Waves() {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  return (
    <svg
      style={{
        display: 'inline-block',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
      viewBox={`0 0 ${isDesktop ? '350' : '150'} 350`}
      preserveAspectRatio="xMinYMin meet">
      <path
        d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"
        style={{
          stroke: 'none',
          fill: 'rgba(247,173,33, 0.6)',
        }}
      />
    </svg>
  );
}

export default Waves;
