import {
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Tab,
  Tabs,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import {useState} from 'react';
import './stylerestaurant.css';

const StatusFilterSearch = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      spacing={1}
      gap={1}
      sx={{width: '100%'}}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Status Filters"
        className="tabs-restaurant">
        <Tab label="Todos" />
        <Tab label="Activos" />
        <Tab label="Por aprobar" />
        <Tab label="Inactivos" />
      </Tabs>
      <Stack sx={{minHeight: '40px'}}>
        <FormControl>
          <TextField
            fullWidth
            size="small"
            value=""
            label="Buscar restaurante"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={() => console.log()}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default StatusFilterSearch;
