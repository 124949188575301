import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialState} from '.';
import {comandaSliceType} from './comanda.type';

const resetComandas = (state: Draft<comandaSliceType>) => {
  state.comadas = [];
  state.lastComandaId = 0;
};

const onHistoryDateChanged = (
  state: Draft<comandaSliceType>,
  action: PayloadAction<Date>,
) => {
  state.historyDate = action.payload;
};

const resetComandaSlice = (state: Draft<comandaSliceType>) => {
  Object.assign(state, initialState);
};

export const actions = {
  resetComandaSlice,
  onHistoryDateChanged,
  resetComandas,
};
