import PreCuentaWrapper from '../../components/pre-cuenta-wrapper/pre-cuenta-wrapper';
import {useAppDispatch} from '../../redux/store';
import {setCurrentStep} from '../../redux/slices/orden-slice';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import CategoriesList from '../../components/categories-list/categories-list';
import ProductList from '../../components/product-list/product-list';
import {resetSlice} from '../../redux/slices/orden-slice/orden.thunks';
import {useCallback} from 'react';
import useCalculateOrden from '../../common/hooks/use-calculate-order';
import RightContainer from './right-container';
import OrdenWrapper from './orden-wrapper';

function CategoriasProductos() {
  const dispatch = useAppDispatch();

  const handleAtras = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(resetSlice());
    dispatch(stopLoading());
    return dispatch(setCurrentStep(1));
  }, []);

  useCalculateOrden();

  return (
    <OrdenWrapper>
      <PreCuentaWrapper handleAtras={handleAtras} />
      <RightContainer>
        <CategoriesList />
        <ProductList />
      </RightContainer>
    </OrdenWrapper>
  );
}

export default CategoriasProductos;
