import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import useMediaQuery from '../../common/hooks/use-media-query';
import {MobileDatePicker} from '@mui/lab';
import {formatDate} from '../../common/formatTime';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';

type PropsTypes = {
  date: Date;
  onChange: (date: Date) => void;
};

const DateSelector = ({date, onChange}: PropsTypes) => {
  const [open, setOpen] = useState<boolean>(false);
  const sm = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        height: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column-reverse',
      }}>
      <Typography
        variant="body2"
        sx={{
          backgroundColor: 'white',
          borderRadius: '5px',
          px: 3,
          mt: 1,
          py: 0.2,
          fontWeight: 'bold',
          fontSize: '0.7rem',
        }}>
        Comandas de {formatDate(date)}
      </Typography>
      <Button
        fullWidth={sm ? true : false}
        size="small"
        sx={{fontSize: '0.7rem', borderRadius: '5px'}}
        color="secondary"
        onClick={() => setOpen(true)}
        variant="contained">
        {sm ? <DateRangeIcon /> : 'Cambiar Fecha'}
      </Button>
      <DialogPopUp
        PaperProps={{
          sx: {
            minWidth: '40%',
          },
        }}
        open={open}
        handleClose={() => setOpen(false)}
        title="Seleccione fecha">
        <Stack direction="column" spacing={2}>
          <Stack direction="column" spacing={2} flex={1}>
            <MobileDatePicker
              label="Fecha"
              value={date}
              onChange={date => {
                if (!date) return;
                onChange(date);
              }}
              disableFuture
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
          <Button
            sx={{maxHeight: '50px', maxWidth: '100%'}}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={async () => {
              setOpen(false);
            }}>
            Filtrar
          </Button>
        </Stack>
      </DialogPopUp>
    </Box>
  );
};

export default DateSelector;
