import {Stack, Typography} from '@mui/material';
import {memo} from 'react';
import {fCurrency} from '../../common/formatNumber';

type PropsTypes = {
  text: string;
  number: number;
};

const BottomText = ({text, number}: PropsTypes) => {
  return (
    <Stack spacing={1} py={1}>
      <Typography
        noWrap
        color="primary"
        sx={{
          p: 0.7,
          textTransform: 'uppercase',
          backgroundColor: 'rgba(245, 152, 35,0.2)',
          fontWeight: 'bold',
          borderRadius: '5px',
          width: '100%',
          textAlign: 'center',
        }}>
        {text}
      </Typography>
      <Typography
        noWrap
        color="primary"
        sx={{
          fontSize: '1.1rem',
          p: 0.7,
          color: '#000',
          textTransform: 'uppercase',
          backgroundColor: '#ebebeb',
          fontWeight: 'bold',
          borderRadius: '5px',
          width: '100%',
          textAlign: 'center',
        }}>
        {fCurrency(number)}
      </Typography>
    </Stack>
  );
};

export default memo(BottomText);
