import {Box, ListItem, ListItemText, Stack, Typography} from '@mui/material';

import {ChangeEvent, useEffect, useState} from 'react';
import isBoolean from '../../common/isBoolean';
import {
  agregarObservacion,
  editOrdenProducto,
  updateModificadoresProducto,
} from '../../redux/slices/orden-slice';
import {carritoType} from '../../redux/slices/orden-slice/orden.types';
import {useAppDispatch} from '../../redux/store';
import {ModificadoresMenu} from '../../services/dto/orden';
import MenuCartItem from '../menu-cart-item/menu-cart-item';

import ModificatorsModal from './modificators-modal';
import ObservacionModal from './observacion-modal';
import dayjs from 'dayjs';

type PropsTypes = {
  item: carritoType;
  showDate: boolean;
};

const ItemCompleto = ({item, showDate}: PropsTypes) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openModificador, setOpenModificador] = useState<boolean>(false);
  const handleCloseObservacion = () => {
    setObservacion(item.observacion || '');
    setOpen(false);
  };

  const [observacion, setObservacion] = useState<string>(
    item.observacion || '',
  );

  const [selectedModificadores, setSelectedModificadores] = useState<
    ModificadoresMenu[]
  >(item.modificadores);

  const handleChangeObservacion = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setObservacion(e.target.value);
  };

  const handleOpenModificador = () => {
    setOpenModificador(true);
  };

  const handleCloseModificador = () => {
    setSelectedModificadores(item.modificadores);
    setOpenModificador(false);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    const isLocalProduct = !item.metaData;
    if (!isLocalProduct) return;
    const hasRequiredModificator = item.producto.modificadores_menu.some(e =>
      isBoolean(e.accion_asignada_modificador.modificador.required),
    );
    const alreadyHaveModificators = !!item.modificadores.length;
    if (hasRequiredModificator && !alreadyHaveModificators)
      setOpenModificador(true);
  }, []);

  return (
    <Box sx={{width: '100%', display: 'flex'}}>
      <ObservacionModal
        state={open}
        observacion={observacion}
        handleOnChange={handleChangeObservacion}
        handleClose={handleCloseObservacion}
        editObservation={obs =>
          dispatch(agregarObservacion({id: item.id, observacion: obs}))
        }
      />

      <ModificatorsModal
        productName={item.producto.producto.nombre_producto}
        modificadores={item.producto.modificadores_menu ?? []}
        onChange={ids => setSelectedModificadores(ids)}
        onSubmit={() => {
          dispatch(
            updateModificadoresProducto({
              id: item.id,
              data: selectedModificadores,
            }),
          );
        }}
        selectedModificadores={selectedModificadores}
        state={openModificador}
        handleClose={handleCloseModificador}
      />
      <ListItem
        sx={{
          overflow: 'hidden',
          py: 0.5,
        }}>
        <Stack direction="column" sx={{maxWidth: '80%'}}>
          <ListItemText
            primary={item.producto.producto.nombre_producto.toUpperCase()}
            primaryTypographyProps={{
              style: {
                fontSize: '1rem',
                lineHeight: 1,
                fontWeight: 'bold',
                textTransform: 'capitalize',
                wordBreak: 'break-word',
              },
            }}
            secondary={observacion}
            secondaryTypographyProps={{
              style: {
                fontSize: '0.7rem',
                lineHeight: 1,
                fontWeight: 'bold',
                fontStyle: 'italic',
                wordBreak: 'break-word',
              },
            }}
          />
          {showDate ? (
            <Typography
              noWrap
              align={'left'}
              textAlign={'left'}
              sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
              {item.metaData && item.metaData.created_at
                ? dayjs(item.metaData.created_at).format('YYYY-MM-DD hh:mm A')
                : ''}
            </Typography>
          ) : (
            ''
          )}

          {selectedModificadores.map(modificador => {
            return (
              <ListItemText
                key={modificador.id_modificador_menu}
                secondary={`${modificador?.accion_asignada_modificador.accion.nombre_accion_modificador} - ${modificador?.accion_asignada_modificador.modificador.nombre_modificador}`}
                secondaryTypographyProps={{
                  style: {
                    fontSize: '0.7rem',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    lineHeight: 1,
                    wordBreak: 'break-word',
                    textTransform: 'uppercase',
                  },
                }}
              />
            );
          })}
        </Stack>
        <Box sx={{flexGrow: 1}} />
        <Box
          sx={{
            mr: 0.5,
            width: '25px',
            height: '25px',
            backgroundColor: '#DBDBDB',
            borderRadius: '5px',
            textAlign: 'center',
            display: 'flex',
            fontSize: '0.9rem',
            lineHeight: 1,
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {item.cantidad}
        </Box>
        {!item.metaData && (
          <MenuCartItem
            addMore={() =>
              dispatch(
                editOrdenProducto({
                  type: 'agregar',
                  id: item.id,
                }),
              )
            }
            getLess={() =>
              dispatch(
                editOrdenProducto({
                  type: 'restar',
                  id: item.id,
                }),
              )
            }
            deleteEm={() =>
              dispatch(
                editOrdenProducto({
                  type: 'eliminar',
                  id: item.id,
                }),
              )
            }
            observacion={item.observacion || ''}
            onModificador={
              item.producto.modificadores_menu.length
                ? handleOpenModificador
                : undefined
            }
            edit={() => setOpen(true)}
          />
        )}
      </ListItem>
    </Box>
  );
};

export default ItemCompleto;
