import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {useAppDispatch, useAppSelector} from '../../redux/store';
import CartList from '../cart-list/cart-list';
import PriceCounter from '../price-counter/price-counter';
import SplitButton from './split-button';

import TotalizarButtons from '../totalizarGrupo';
import isOrdenIndirecta from '../../common/isOrdenIndirecta';
import {seleccionarCliente} from '../../redux/slices/orden-slice';

type PropsTypes = {
  handleAtras: () => void;
};

const Precuenta = ({handleAtras}: PropsTypes) => {
  const currentStep = useAppSelector(state => state.ordenSlice.currentStep);
  const clientes = useAppSelector(state => state.ordenSlice.orden.clientes);
  const clienteSeleccionado = useAppSelector(
    state => state.ordenSlice.orden.clienteSeleccionado,
  );
  const dispatch = useAppDispatch();
  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );
  const mesaSeleccionada = useAppSelector(
    store => store.ordenSlice.mesaSeleccionada,
  );
  const isMobile = useMediaQuery('(max-width: 899px)');
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
      raised>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}>
        <Box
          sx={{
            height: '15px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            mb: 1,
          }}>
          <Box
            sx={{
              height: '15px',
              display: 'flex',
              justifyContent: 'space-between',
              width: isMobile ? '100%' : undefined,
              gap: '10px',
              alignItems: 'center',
              mb: 1,
            }}>
            {currentStep !== 4 && (
              <Button
                onClick={() => handleAtras()}
                sx={{
                  // mr: 1,

                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                variant="contained"
                size="small"
                color="primary">
                volver
              </Button>
            )}
            <Box>
              <SplitButton
                onChange={id => {
                  dispatch(seleccionarCliente(id));
                }}
                showNewClientForm={isOrdenIndirecta()}
                selected={clienteSeleccionado?.id}
                options={clientes}
              />
            </Box>
          </Box>
          {!isMobile && (
            <Typography
              noWrap
              sx={{
                maxWidth: '155px',
                fontSize: '0.8rem',
                lineHeight: 0.9,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}>
              {mesaSeleccionada
                ? `mesa: #${mesaSeleccionada.numero_mesa}`
                : `caja: #${cajaEmpleado?.caja.numero_caja}`}
            </Typography>
          )}
        </Box>
        <CartList />
        <PriceCounter />
        {currentStep && <TotalizarButtons />}
      </CardContent>
    </Card>
  );
};

export default Precuenta;
