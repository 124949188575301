import {
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  actualizarCajaEmpleado,
  resetEditable,
} from '../../redux/slices/restaurante-utils-slice';
import {useEffect} from 'react';
import {getEmpleadosSucursalBySucursalID} from '../../redux/slices/empleados-slice/empleados.thunks';
import {postCajaEmpleado} from '../../redux/slices/restaurante-utils-slice/restaurante-utils.thunks';

function NewCajaEmpleadoForm({handleClose}: {handleClose: VoidFunction}) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.uiSlice.isLoading);
  const cajas = useAppSelector(store => store.restauranteUtilsSlice.cajas);
  const cajaEmpleado = useAppSelector(
    store => store.restauranteUtilsSlice.editable.cajaEmpleado,
  );
  const sucursal = useAppSelector(
    store => store.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );
  const empleados = useAppSelector(
    store => store.empleadoSlice.empleadoSucursales,
  );

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    await dispatch(postCajaEmpleado())
      .unwrap()
      .then(x => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!sucursal) return;
    dispatch(getEmpleadosSucursalBySucursalID(sucursal.sucursal_id));
    return () => {
      dispatch(resetEditable('cajaEmpleado'));
    };
  }, []);

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel>Número de caja</InputLabel>
                  <Select
                    value={cajaEmpleado.caja_sucursal_id?.toString()}
                    label="Número de caja"
                    onChange={e => {
                      dispatch(
                        actualizarCajaEmpleado({
                          caja_sucursal_id: parseInt(e.target.value),
                        }),
                      );
                    }}>
                    {cajas.map(element => (
                      <MenuItem
                        value={element.id_caja_sucursal}
                        key={element.id_caja_sucursal}>
                        {element.numero_caja}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Empleado asignado a sucursal</InputLabel>
                  <Select
                    value={cajaEmpleado.empleado_sucursal_id?.toString()}
                    label="cargo"
                    onChange={e => {
                      dispatch(
                        actualizarCajaEmpleado({
                          empleado_sucursal_id: parseInt(e.target.value),
                        }),
                      );
                    }}>
                    {empleados.map(element => (
                      <MenuItem
                        value={element.id_empleado_asignado_sucursal}
                        key={element.id_empleado_asignado_sucursal}>
                        {`${element.empleado.name} ${element.empleado.lastname}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoading}>
            Guardar Cambios
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewCajaEmpleadoForm;
