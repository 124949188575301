import {
  Autocomplete,
  TextField,
  AutocompleteProps,
  FilterOptionsState,
} from '@mui/material';
import {Controller} from 'react-hook-form';

import getNestedValue from '../../common/getNestedValue';
import {ControllerProps} from './controlled-text-field';
import {TextFieldProps} from '@mui/material/TextField/TextField';

type propType<T extends object> = {
  sourceData: T[];
  filteredData?: T[];
  valueKey: (keyof T)[];
  idKey: keyof T;
  controllerProps: ControllerProps;
  multiple?: boolean;
  helperText?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  filterByInputLabel?: boolean;
  filter?: (options: T[], state: FilterOptionsState<T>) => T[];
  getOptionLabel?: (opt?: T) => string;
  inputProps?: TextFieldProps;
  handleOnChange?: (data: any) => void;
} & Omit<
  AutocompleteProps<T, true, false, false>,
  'renderInput' | 'multiple' | 'options' | 'filterOptions' | 'getOptionLabel'
>;

export default function ControlledSelector<T extends object>({
  sourceData,
  filteredData,
  idKey,
  valueKey,
  filter,
  helperText,
  getOptionLabel,
  controllerProps: ControllerProps,
  multiple = false,
  variant = 'outlined',
  filterByInputLabel = true,
  inputProps,
  handleOnChange = data => console.log('on change selector'),
  ...props
}: propType<T>) {
  const getDataArray = () => {
    if (filteredData) return filteredData;
    return sourceData;
  };

  return (
    <Controller
      name={ControllerProps.name}
      control={ControllerProps.control}
      defaultValue={multiple ? [] : ''}
      render={({field, fieldState}) => {
        if (multiple)
          field.value =
            (field.value as any[]).map(element =>
              sourceData.find(item => item[idKey] === element),
            ) ?? [];
        else {
          field.value =
            sourceData.find(item => item[idKey] === field.value) ?? null;
        }
        return (
          //@ts-ignore
          <Autocomplete
            sx={{
              width: props.fullWidth ? '100%' : 300,
            }}
            disableCloseOnSelect={multiple}
            {...props}
            {...field}
            onChange={(_, data) => {
              if (multiple && Array.isArray(data)) {
                field.onChange(
                  data.map(element => element[idKey]) as unknown as number[],
                );
                return;
              }
              field.onChange(getNestedValue(data, [idKey]));
              handleOnChange(data);
            }}
            //@ts-ignore
            multiple={multiple}
            options={getDataArray()}
            getOptionLabel={option => {
              if (getOptionLabel) return getOptionLabel(option);
              if (option) return getNestedValue(option, valueKey);
              return '';
            }}
            noOptionsText="No existen registros."
            filterOptions={
              filter
                ? (options, state) => {
                    let opts = filter(options, state);
                    if (filterByInputLabel)
                      opts = opts.filter(element => {
                        if (!state.inputValue) return true;
                        return state
                          .getOptionLabel(element)
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase());
                      });
                    return opts;
                  }
                : undefined
            }
            renderInput={params => (
              <TextField
                {...inputProps}
                {...params}
                helperText={helperText}
                label={props.title}
                variant={variant ? variant : 'outlined'}
                error={!!fieldState?.error?.message}
              />
            )}
          />
        );
      }}
    />
  );
}
