import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {actions} from './compras.actions';
import {
  getComprasSucursal,
  getEstadoDeCompra,
  getProveedores,
  putPostCompraSucursal,
  putPostProveedor,
} from './compras.thunks';
import {comprasSliceType} from './compras.types';
import * as yup from 'yup';

export const initialState = {
  estadoDeCompra: [],
  proveedores: [],
  filteredProveedores: [],
  fecha_inicio: dayjs().startOf('month').toDate(),
  fecha_final: dayjs().toDate(),
  comprasSucursal: [],
  selectedCategoriaInsumo: 1,
  filteredCompras: [],
  total_compras: 0,
  editable: {
    compraSucursal: {
      estado_compra_id: null,
      proveedor_id: null,
      fecha_hora_compra: null,
      monto_compra: null,
      id_compra_sucursal: null,
    },
    proveedor: {
      id_proveedor: null,
      nombre_proveedor: null,
      ruc: null,
      telefono: null,
      correo: null,
      categorias: [],
    },
  },
} as comprasSliceType;

//schemas
export const compraSucursalSchema = yup
  .object({
    proveedor_id: yup.number().required(),
    estado_compra_id: yup.number().required(),
    categoria_insumo_id: yup.number().required(),
    fecha_hora_compra: yup
      .mixed()
      .when('$request', request =>
        request ? yup.string().required() : yup.date().required(),
      ),
    monto_compra: yup.number().required(),
    subtotal: yup.number().notRequired().nullable(true),
    impuestos: yup.number().notRequired().nullable(true),
    sucursal_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    id_compra_sucursal: yup.number(),
    items: yup.array().of(
      yup.object().shape({
        name: yup.number().required(),
        price: yup.number().required(),
        count: yup.number().required().min(1),
      }),
    ),
    desglose: yup.string(),
  })
  .required();

export const comprasSlice = createSlice({
  name: 'compras-slice',
  initialState: initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(getProveedores.fulfilled, (state, action) => {
        state.proveedores = action.payload;
      })
      .addCase(getEstadoDeCompra.fulfilled, (state, action) => {
        state.estadoDeCompra = action.payload;
      })
      .addCase(getComprasSucursal.fulfilled, (state, action) => {
        state.comprasSucursal = action.payload;
      })
      .addCase(putPostCompraSucursal.fulfilled, (state, action) => {
        state.comprasSucursal = action.payload;
      })
      .addCase(putPostProveedor.fulfilled, (state, action) => {});
  },
});

export const {
  resetEditables,
  resetComprasSlice,
  filterCompras,
  setDates,
  cargarPreveedor,
  actualizarProveedor,
  selectCategoria,
} = comprasSlice.actions;

export default comprasSlice.reducer;
