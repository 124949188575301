import {LoadingButton} from '@mui/lab';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, {useEffect} from 'react';
import {actualizarInsumo, resetEditable} from '../../redux/slices/insumo-slice';
import {putPostInsumo} from '../../redux/slices/insumo-slice/insumo.thunks';
import {putPost} from '../../redux/slices/menu-slice/menu.types';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import NumericTextField from '../form-input/controlled-numeric-text-field';

type propsTypes = {
  type: putPost;
  handleClose: VoidFunction;
};
export default function InsumoForm({handleClose, type}: propsTypes) {
  const handleSubmitted = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    await dispatch(putPostInsumo(type))
      .unwrap()
      .then(() => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };
  const dispatch = useAppDispatch();
  const insumo = useAppSelector(store => store.insumoSlice.editable.insumo);

  const isLoading = useAppSelector(store => store.uiSlice.isLoading);

  const categoriasInsumo = useAppSelector(
    store => store.insumoSlice.categoriaInsumos,
  );
  const subCategoriasInsumo = useAppSelector(
    store => store.insumoSlice.subCategoriaInsumos,
  );
  const unidadesDeMedida = useAppSelector(
    store => store.insumoSlice.unidadMedida,
  );

  useEffect(() => {
    return () => {
      dispatch(resetEditable('insumo'));
    };
  }, []);

  return (
    <form onSubmit={handleSubmitted}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={insumo.nombre || ''}
                  onChange={e => {
                    dispatch(actualizarInsumo({nombre: e.target.value}));
                  }}
                  required
                />
                <NumericTextField
                  fullWidth
                  type="money"
                  label="Costo"
                  value={insumo.costo}
                  required
                  onChange={value => {
                    dispatch(actualizarInsumo({costo: value}));
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel>Unidad de medida</InputLabel>
                  <Select
                    value={insumo.unidad_medida_id || ''}
                    onChange={value => {
                      if (!value.target.value) return;
                      const number = parseInt(value.target.value.toString());
                      if (isNaN(number)) return;
                      dispatch(
                        actualizarInsumo({
                          unidad_medida_id: number,
                        }),
                      );
                    }}
                    label="Unidad de medida">
                    {unidadesDeMedida.map(element => (
                      <MenuItem
                        value={element.id_unidad_medida}
                        key={element.id_unidad_medida}>
                        {element.nombre_unidad_medida}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Categoria Insumo</InputLabel>
                  <Select
                    value={insumo.categoria_insumo_id}
                    onChange={value => {
                      if (!value.target.value) return;
                      const number = parseInt(value.target.value.toString());
                      if (isNaN(number)) return;
                      dispatch(
                        actualizarInsumo({
                          categoria_insumo_id: number,
                        }),
                      );
                    }}
                    label="Categoria Insumo">
                    {categoriasInsumo.map(element => (
                      <MenuItem
                        value={element.id_categoria_insumo}
                        key={element.id_categoria_insumo}>
                        {element.nombre_categoria_insumo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Sub-categoria Insumo</InputLabel>
                  <Select
                    disabled={!insumo.categoria_insumo_id}
                    value={insumo.sub_categoria_insumo_id}
                    onChange={value => {
                      if (!value.target.value) return;
                      const number = parseInt(value.target.value.toString());
                      if (isNaN(number)) return;
                      dispatch(
                        actualizarInsumo({
                          sub_categoria_insumo_id: number,
                        }),
                      );
                    }}
                    label="Subcategoria Insumo">
                    {subCategoriasInsumo
                      .filter(element => {
                        if (!insumo.categoria_insumo_id) return true;
                        return (
                          element.categoria_insumo_id ==
                          insumo.categoria_insumo_id
                        );
                      })
                      .map(element => (
                        <MenuItem
                          value={element.id_sub_categoria_insumo}
                          key={element.id_sub_categoria_insumo}>
                          {element.nombre_sub_categoria_insumo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoading}
            disabled={
              !(
                insumo.nombre &&
                insumo.costo &&
                insumo.unidad_medida_id &&
                insumo.categoria_insumo_id &&
                insumo.sub_categoria_insumo_id
              )
            }>
            Guardar Cambios
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
