import {createAsyncThunk} from '@reduxjs/toolkit';

import {api} from '../../../services';
import {
  AccionModificador,
  Modificador,
} from '../../../services/dto/modificadores';
import {RootState} from '../../store';
import * as yup from 'yup';
import {modificatorSchema} from './modificadores.schema';
import isBoolean from '../../../common/isBoolean';

export const fetchAccionesModificador = createAsyncThunk<
  AccionModificador[],
  undefined,
  {state: RootState}
>('modificadores/fetchAccionesModificador', async (data, thunkAPI) => {
  const response = await api.getAccionModificador();

  if (!response)
    return thunkAPI.rejectWithValue('error while fetching accion modificador');

  return response.data;
});

export const fetchModificador = createAsyncThunk<
  Modificador[],
  undefined,
  {state: RootState}
>('modificadores/fetchModificador', async (data, thunkAPI) => {
  const {
    restauranteSlice: {restauranteEmpleadoSeleccionado},
  } = thunkAPI.getState();

  if (!restauranteEmpleadoSeleccionado)
    return thunkAPI.rejectWithValue('no hay restaurante seleccionado');

  const response = await api.getModificador(restauranteEmpleadoSeleccionado.id);

  if (!response)
    return thunkAPI.rejectWithValue('error while fetching modificador');

  return response.data;
});

export const putPostModificador = createAsyncThunk<
  Modificador[],
  {
    editId?: number;
  } & yup.InferType<typeof modificatorSchema>,
  {state: RootState}
>('modificadores/putPostModificador', async (data, thunkAPI) => {
  const {
    restauranteSlice: {restauranteEmpleadoSeleccionado},
    modificadorSlice: {modificadores},
  } = thunkAPI.getState();

  const body = await modificatorSchema.validate(
    {
      ...data,
      id_modificador: data.editId,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
    },
    {stripUnknown: true, context: {request: true}},
  );

  const copy = [...modificadores];
  if (!body.id_modificador) {
    const response = await api.postModificador(body);
    if (!response)
      return thunkAPI.rejectWithValue('error while creating modificator');
    copy.push(response.data);
  } else {
    const response = await api.putModificador(body, body.id_modificador!);
    if (!response)
      return thunkAPI.rejectWithValue('error while updating modificator');
    const index = modificadores.findIndex(
      element => element.id_modificador === body.id_modificador,
    )!;
    copy[index] = response.data;
  }
  return copy;
});

export const deleteModificador = createAsyncThunk<
  Modificador[],
  number,
  {state: RootState}
>('modificadores/deleteModificador', async (data, thunkAPI) => {
  const {
    modificadorSlice: {modificadores},
  } = thunkAPI.getState();

  const response = await api.deleteModificador(data);

  if (!response)
    return thunkAPI.rejectWithValue('error while deleting modificador');

  return modificadores.filter(
    e => e.id_modificador != response.data.id_modificador,
  );
});

export const switchModificador = createAsyncThunk<
  Modificador[],
  number,
  {state: RootState}
>('modificadores/deleteModificador', async (data, thunkAPI) => {
  const {
    modificadorSlice: {modificadores},
  } = thunkAPI.getState();

  const modificador = modificadores.find(e => e.id_modificador === data);
  if (!modificador)
    return thunkAPI.rejectWithValue('error while switching modificador');

  const modificadorData: yup.InferType<typeof modificatorSchema> = {
    ...modificador,
    acciones: modificador.acciones.map(e => e.id_accion_modificador),
    required: !isBoolean(modificador.required),
  };

  const body = await modificatorSchema.validate(modificadorData, {
    stripUnknown: true,
    context: {request: true},
  });

  const response = await api.putModificador(body, data);

  if (!response)
    return thunkAPI.rejectWithValue('error while switching modificador');

  const index = modificadores.findIndex(e => e.id_modificador === data);

  const copy = [...modificadores];
  copy[index] = response.data;
  return copy;
});
