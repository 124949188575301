import {Typography} from '@mui/material';

type titleTextProps = {text: string; disableMb?: boolean};

const TitleText = ({text, disableMb = false}: titleTextProps) => {
  return (
    <Typography
      sx={{
        fontSize: '0.7rem',
        width: '100%',
        borderRadius: '15px',
        backgroundColor: '#797979',
        color: 'white',
        py: 0.2,
        mb: disableMb ? 0 : 1.5,
        pl: 1,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}>
      {text}
    </Typography>
  );
};

export default TitleText;
