import {alpha, styled} from '@mui/material/styles';
import {AppBar, Toolbar} from '@mui/material';
const APPBAR_MOBILE = 44;

export const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(15px)',
  WebkitBackdropFilter: 'blur(15px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  width: `100%`,
  height: APPBAR_MOBILE,
  display: 'flex',
  alignItems: 'space-evenly',
  justifyContent: 'center',
}));

export const ToolbarStyle = styled(Toolbar)(() => ({
  minHeight: APPBAR_MOBILE,
}));
