import {SyntheticEvent, useState} from 'react';
import {Link, Stack, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useAppDispatch} from '../../redux/store';
import {validateRecoverPassword} from '../../redux/slices/user-slice/user.thunks';

type PropsTypes = {
  handleOnResendCode: () => Promise<void>;
};

const PinCodeForm = ({handleOnResendCode}: PropsTypes) => {
  const [pin, setPin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [verifypassword, setVerifyPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(validateRecoverPassword({password: password, pin: pin}));
    setLoading(false);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} direction="column" gap={1}>
      <Typography variant="h4" gutterBottom>
        Recuperar contraseña
      </Typography>
      <Typography sx={{color: 'text.secondary'}} gutterBottom>
        Ingresa el código PIN de seguridad que llego a tu correo electrónico.
      </Typography>

      <TextField
        type="text"
        variant="outlined"
        label="código PIN de seguridad"
        fullWidth
        value={pin}
        onChange={e => setPin(e.target.value)}
      />
      <TextField
        type="password"
        variant="outlined"
        label="Nueva contraseña"
        fullWidth
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <TextField
        type="password"
        variant="outlined"
        label="Repetir nueva contraseña"
        fullWidth
        value={verifypassword}
        onChange={e => setVerifyPassword(e.target.value)}
      />
      <LoadingButton
        disabled={
          !pin.trim().length ||
          !password.trim().length ||
          !verifypassword.trim().length ||
          verifypassword.trim() !== password.trim()
        }
        loading={loading}
        loadingPosition="start"
        type="submit"
        variant="contained"
        color="primary"
        fullWidth>
        {loading ? 'Verificando...' : 'Verificarme'}
      </LoadingButton>
      <Typography
        variant="body2"
        align="center"
        sx={{color: 'text.secondary', mt: 3}}>
        Reenviar codigo &nbsp;
        <Link
          sx={{cursor: 'pointer', color: 'black'}}
          onClick={handleOnResendCode}>
          Aqui!
        </Link>
      </Typography>
    </Stack>
  );
};

export default PinCodeForm;
