import {ReactNode, useState} from 'react';
import {styled} from '@mui/material/styles';
import DashboardNavbar from './Navbar';
import DashboardSidebar from './Sidebar';
const RootStyle = styled('div')({
  position: 'relative',
  overflow: 'hidden',
});

const MainStyle = styled('div')(() => ({
  overflow: 'auto',
  height: '100%',
  minHeight: '100vh',
  paddingTop: 54,
}));

// ----------------------------------------------------------------------

type propTypes = {
  children: ReactNode;
  hideSidebar?: boolean;
  needSelectedRestaurant?: boolean;
};

export default function DashboardLayout({
  children,
  hideSidebar = false,
  needSelectedRestaurant,
}: propTypes) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <RootStyle>
      <DashboardNavbar
        needSelectedRestaurant={needSelectedRestaurant}
        onOpenSidebar={() => setOpen(true)}
        hideSideBar={hideSidebar}
      />
      {!hideSidebar && (
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
