import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, Button, Typography, Container} from '@mui/material';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <RootStyle title="404 Página no encontrada | IFUDIS">
      <Container>
        <Box sx={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
          <div>
            <Typography variant="h3" paragraph>
              Lo sentimos, página no encontrada.
            </Typography>
          </div>
          <Typography sx={{color: 'text.secondary'}}>
            Lo sentimos, no pudimos encontrar la página que busca. Quizás ¿Ha
            escrito mal la URL? Asegúrese de revisar su ortografía.
          </Typography>

          <div>
            <Box
              component="img"
              src="/static/illustrations/illustration_404.svg"
              sx={{height: 260, mx: 'auto', my: {xs: 5, sm: 10}}}
            />
          </div>

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}>
            Toca Aqui para volver
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
