import {Box, Button, Typography} from '@mui/material';
import {getFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';
import {setCurrentStep} from '../../redux/slices/orden-slice';
import {resetSlice} from '../../redux/slices/orden-slice/orden.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

const FacturaEmitidaForm = () => {
  const dispatch = useAppDispatch();
  const lastFacturaId = useAppSelector(store => store.ordenSlice.lastFacturaId);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}>
          <Typography sx={{fontSize: '2rem', fontWeight: 'bold'}}>
            FACTURA EMITIDA
          </Typography>
          <Typography sx={{fontSize: '1rem', fontWeight: 'normal'}}>
            FACTURA EMITIDA
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '60%',
              pt: 2,
              justifyContent: 'center',
              height: '200px',
            }}>
            <Button
              onClick={() => {
                if (lastFacturaId === null) return;
                dispatch(
                  getFactura({
                    id_factura: lastFacturaId,
                    action: 'open',
                  }),
                );
              }}
              sx={{width: '100%', height: '30%'}}
              variant="outlined"
              color="secondary">
              VER FACTURA
            </Button>
          </Box>
        </Box>

        <Box sx={{pt: 3, pb: 10}}>
          <Button
            sx={{width: '200px', height: '50px'}}
            variant="contained"
            onClick={async () => {
              dispatch(startLoading());
              await dispatch(resetSlice());
              dispatch(setCurrentStep(1));
              dispatch(stopLoading());
            }}
            color="secondary">
            CERRAR
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FacturaEmitidaForm;
