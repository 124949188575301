import {TextField, TextFieldProps} from '@mui/material';
import {useEffect, useState} from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {ControllerProps} from './controlled-text-field';

type propType = {
  onChange?: (value?: number) => void;
  value?: number | null;
  maxValue?: number;
  minValue?: number;
  controlledValue?: number;
  type?: 'percentage' | 'money' | 'normal';
  delay?: number;
  disableDecimals?: boolean;
  allowNegative?: boolean;
  field?: ControllerRenderProps<FieldValues, string>;
  fieldState?: ControllerFieldState;
} & Omit<TextFieldProps, 'onChange' | 'type' | 'value'>;

export default function NumericTextField(tempProps: propType) {
  const {
    onChange: originalOnChange,
    maxValue,
    minValue,
    disableDecimals = false,
    allowNegative = false,
    type = 'normal',
    value,
    field,
    fieldState,
    controlledValue,
    delay = 0,
    ...props
  } = tempProps;

  const [input, setInput] = useState<number>(value ?? NaN);

  useEffect(() => {
    if (!delay && isNaN(input)) {
      if (originalOnChange) originalOnChange(undefined);
      return;
    }
    const timeout = setTimeout(() => {
      if (isNaN(input)) {
        if (originalOnChange) originalOnChange(undefined);
        return;
      }
      if (originalOnChange && input != value) originalOnChange(input);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [input]);

  useEffect(() => {
    if (value === input || value === NaN) return;
    setInput(value ?? NaN);
  }, [value]);

  const getValue = () => {
    if (field != undefined) return field.value;
    if (controlledValue && !isNaN(controlledValue)) return controlledValue;
    if (!isNaN(input)) return input;
    return undefined;
  };

  const getDecimalScale = () => {
    if (disableDecimals) return 0;
    switch (type) {
      case 'percentage':
        return 0;
      case 'money':
        return 2;
      default:
        return 3;
    }
  };

  const getPrefix = () => {
    switch (type) {
      case 'percentage':
        return '% ';
      case 'money':
        return '$ ';
      default:
        return undefined;
    }
  };

  return (
    //@ts-ignore
    <NumberFormat
      {...props}
      ref={field?.ref}
      onBlur={field?.onBlur}
      customInput={TextField}
      value={getValue()}
      autoComplete="off"
      type="text"
      step={0.01}
      error={!!fieldState?.error}
      allowNegative={allowNegative}
      isAllowed={values => {
        if (!values.floatValue) return true;
        if (maxValue && values.floatValue > maxValue) return false;
        if (minValue && values.floatValue < minValue) return false;
        return true;
      }}
      thousandSeparator
      prefix={getPrefix()}
      decimalScale={getDecimalScale()}
      onValueChange={values => {
        field?.onChange(values.floatValue);
        setInput(values.floatValue ?? NaN);
      }}
    />
  );
}

export const ControlledNumericTextField = ({
  controllerProps: {name, control},
  ...props
}: propType & {
  controllerProps: ControllerProps;
}) => (
  <Controller
    name={name}
    control={control}
    render={({field, fieldState}) => (
      <NumericTextField {...props} field={field} fieldState={fieldState} />
    )}
  />
);
