import {Button, Stack} from '@mui/material';
import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import CrudTable from '../../components/crud-table';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {
  calcularEfectivo,
  calcularMetodoPago,
  preCargarReporteCaja,
} from '../../redux/slices/cierre-slice';
import {
  deleteReporteCaja,
  getReporteCaja,
  postCierre2,
} from '../../redux/slices/cierre-slice/cierre.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

const HistorialCierre = () => {
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const reportesCaja = useAppSelector(store => store.cierreSlice.reporteCaja);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fetchAllScreenData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(getReporteCaja());
    dispatch(stopLoading());
  }, []);

  useEffect(() => {
    fetchAllScreenData();
  }, []);

  const columns = [
    {
      label: '# caja',
      key: ['caja', 'numero_caja'],
    },
    {
      label: 'Cajero',
      key: ['empleado_sucursal'],
    },
    {
      label: 'Fecha inicial',
      key: ['formated_fecha_inicial'],
    },
    {
      label: 'Fecha final',
      key: ['formated_fecha_final'],
    },
    {
      label: '',
      key: ['id_reporte_caja'],
    },
  ];

  return (
    <Pagetemplate
      isLoading={isLoading}
      title="Historial de cierres || IFUDIS"
      soloLayout>
      <Stack sx={{width: '100%', height: '50px'}} spacing={2} gap={2}>
        <Button
          sx={{alignSelf: 'flex-start'}}
          variant="contained"
          color="secondary"
          onClick={() => history.push('/cierre/z')}>
          Volver
        </Button>
      </Stack>
      <CrudTable
        title="Historial de cierres"
        columns={columns}
        onDelete={async id => {
          await dispatch(deleteReporteCaja(id));
        }}
        onPreload={id => {
          dispatch(preCargarReporteCaja({id}));
          history.push('/cierre/z');
        }}
        onView={id => {
          dispatch(preCargarReporteCaja({id, loadReporteID: true}));
          dispatch(calcularEfectivo());
          dispatch(calcularMetodoPago());
          dispatch(postCierre2({id}));
        }}
        rows={reportesCaja}
      />
    </Pagetemplate>
  );
};

export default HistorialCierre;
