import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

const MultiSelector = function ({
  sourceData,
  selectedData,
  setSelectedData,
  title = 'Tipos de cocinas',
  type = 'tipo_comida',
  fullWidth = false,
}: {
  sourceData: any[];
  selectedData: any[];
  setSelectedData: (value: any[]) => void;
  title?: string;
  type?: string;
  fullWidth: boolean;
}) {
  const handleChange = (event: SelectChangeEvent<any[]>) => {
    const {
      target: {value},
    } = event;
    setSelectedData(typeof value === 'string' ? value.split(',') : value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getItem = (type: string, value: any) => {
    switch (type) {
      case 'tipo_comida':
        return (
          <MenuItem key={value.id_tipo_comida} value={value.nombre_tipo_comida}>
            {value.nombre_tipo_comida}
          </MenuItem>
        );

      case 'permisos':
        return (
          <MenuItem key={value.id_permiso} value={value.nombre_permiso}>
            {value.nombre_permiso}
          </MenuItem>
        );

      case 'subcategorias':
        return (
          <MenuItem
            key={value.id_subcategoria_producto}
            value={value.nombre_subcategoria}>
            {value.nombre_subcategoria}
          </MenuItem>
        );

      default:
        return <MenuItem>Sin registros</MenuItem>;
    }
  };

  const getHelperText = (type: string) => {
    switch (type) {
      case 'tipo_comida':
        return (
          <FormHelperText>{`Aquí vas a poder seleccionar los tipos de cocinas.`}</FormHelperText>
        );

      case 'permisos':
        return (
          <FormHelperText>{`Aquí podras seleccionar los permisos.`}</FormHelperText>
        );

      case 'subcategorias':
        return (
          <FormHelperText>{`Aqui podras seleccionar las subcategorias`}</FormHelperText>
        );

      default:
        return null;
    }
  };

  return (
    <FormControl sx={{m: 1, width: fullWidth ? '100%' : 300}}>
      <InputLabel id="multiple-chip-label">{title}</InputLabel>
      <Select
        multiple
        value={selectedData}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={selected => (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}>
        {sourceData.length ? (
          sourceData.map(value => getItem(type, value))
        ) : (
          <MenuItem>Sin registros</MenuItem>
        )}
      </Select>
      {getHelperText(type)}
    </FormControl>
  );
};

export default MultiSelector;

export const MultiSelector2 = ({
  fullWidth = false,
  selectedData,
  sourceData,
  filteredData,
  title,
  onChange,
  disabled = false,
  type,
}: {
  fullWidth?: boolean;
  title: string;
  selectedData: number[];

  sourceData: any[];
  filteredData?: any[];
  onChange: (value: number[]) => void;
  disabled?: boolean;
  type:
    | 'subcategoria'
    | 'permisos'
    | 'sucursal'
    | 'categoria_insumo'
    | 'modificadores';
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<any[]>) => {
    const {
      target: {value},
    } = event;
    onChange(typeof value === 'string' ? value.split(',') : value);
  };

  const getItem = (value: any) => {
    switch (type) {
      case 'subcategoria':
        return (
          <MenuItem
            key={value.id_subcategoria_producto}
            value={value.id_subcategoria_producto}>
            {value.nombre_subcategoria}
          </MenuItem>
        );
      case 'permisos':
        return (
          <MenuItem key={value.id_permiso} value={value.id_permiso}>
            {value.nombre_permiso}
          </MenuItem>
        );
      case 'sucursal':
        return (
          <MenuItem key={value.id_sucursal} value={value.id_sucursal}>
            {value.descripcion_ubicacion}
          </MenuItem>
        );
      case 'categoria_insumo':
        return (
          <MenuItem
            key={value.id_categoria_insumo}
            value={value.id_categoria_insumo}>
            {value.nombre_categoria_insumo}
          </MenuItem>
        );
      case 'modificadores':
        return (
          <MenuItem key={value.id} value={value.id}>
            {value.nombre}
          </MenuItem>
        );
      default:
        return <MenuItem>Sin registros</MenuItem>;
    }
  };

  const getRenderValue = (selected: number[]) => {
    switch (type) {
      case 'subcategoria':
        return (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(value => {
              const subcategoria = sourceData.find(
                element => element.id_subcategoria_producto === value,
              );
              if (!subcategoria) return;
              return (
                <Chip key={value} label={subcategoria.nombre_subcategoria} />
              );
            })}
          </Box>
        );

      case 'permisos':
        return (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(value => {
              const permiso = sourceData.find(
                element => element.id_permiso === value,
              );
              if (!permiso) return;
              return <Chip key={value} label={permiso.nombre_permiso} />;
            })}
          </Box>
        );
      case 'sucursal':
        return (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(value => {
              const sucursal = sourceData.find(
                element => element.id_sucursal === value,
              );
              if (!sucursal) return;
              return (
                <Chip key={value} label={sucursal.descripcion_ubicacion} />
              );
            })}
          </Box>
        );
      case 'categoria_insumo':
        return (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(value => {
              const categoriaInsumo = sourceData.find(
                element => element.id_categoria_insumo === value,
              );
              if (!categoriaInsumo) return;
              return (
                <Chip
                  key={value}
                  label={categoriaInsumo.nombre_categoria_insumo}
                />
              );
            })}
          </Box>
        );
      case 'modificadores':
        return (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map(value => {
              const modificador = sourceData.find(
                element => element.id === value,
              );
              if (!modificador) return;
              return <Chip key={value} label={modificador.nombre} />;
            })}
          </Box>
        );

      default:
        break;
    }
  };

  const getDataArray = () => {
    if (filteredData) return filteredData;
    return sourceData;
  };

  return (
    <FormControl sx={{width: fullWidth ? '100%' : 300}}>
      <InputLabel id="multiple-chip-label">{title}</InputLabel>
      <Select
        multiple
        value={selectedData}
        onChange={handleChange}
        disabled={disabled}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={getRenderValue}
        MenuProps={MenuProps}>
        {getDataArray().length ? (
          getDataArray().map(value => getItem(value))
        ) : (
          <MenuItem>Sin registros</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
