import {useEffect} from 'react';

type propsTypes = {url: any};

export default function useExternalScripts({url}: propsTypes) {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.append(url);
    head?.appendChild(script);
    return () => {
      head?.removeChild(script);
    };
  }, [url]);
}
