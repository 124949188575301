import {createSlice} from '@reduxjs/toolkit';

import {UserStateType} from './user.types';
import {
  acceptInvitationUser,
  cargarUser,
  loginUser,
  Logout,
  refreshUser,
  registerUser,
  sendEmailPing,
  updatePasswordUser,
  updateUser,
  validateEmailUser,
  validateRecoverPassword,
} from './user.thunks';
import {actions} from './user.actions';

//initialState

export const initialState = {
  isLogged: false,
  tokenDetails: null,
  userDetail: null,
  cajaEmpleado: null,
  editable: {
    user: {
      dni: null,
      fecha_nacimiento: null,
      id_sexo: null,
      nick: null,
      name: null,
      lastname: null,
    },
    login: {
      email: null,
      password: null,
    },
    register: {
      dni: null,
      fecha_nacimiento: null,
      email: null,
      password: null,
      verifyPassword: null,
      name: null,
      lastname: null,
      nick: null,
      id_sexo: null,
      foto_perfil: null,
      foto_frontal_identificacion: null,
      foto_trasera_identificacion: null,
    },
    userImages: {
      foto_frontal_identificacion: null,
      foto_perfil: null,
      foto_trasera_identificacion: null,
    },
  },
  cargo_ifudis: null,
} as UserStateType;

//slice

export const UserSlice = createSlice({
  name: 'user-slice',
  initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        const {loginResponse, tokenResponse} = action.payload;
        state.isLogged = true;
        state.userDetail = loginResponse.data;
        state.tokenDetails = tokenResponse;
        state.cargo_ifudis = loginResponse.data.cargo_i;
      })
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.userDetail = action.payload.data;
        state.cargo_ifudis = action.payload.data.cargo_i;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        const {loginResponse, tokenResponse} = action.payload;
        state.isLogged = true;
        state.userDetail = loginResponse.data;
        state.tokenDetails = tokenResponse;
      })
      .addCase(validateEmailUser.fulfilled, (state, action) => {})
      .addCase(sendEmailPing.fulfilled, state => {})
      .addCase(updateUser.fulfilled, (state, action) => {})
      .addCase(updatePasswordUser.fulfilled, () => {})
      .addCase(acceptInvitationUser.fulfilled, (state, action) => {})
      .addCase(validateRecoverPassword.fulfilled, (state, action) => {})
      .addCase(cargarUser.fulfilled, (state, action) => {
        state.editable.user = action.payload.user;
        state.editable.userImages = action.payload.images;
      })
      .addCase(Logout.fulfilled, state => {});
  },
});

export const {
  actualizarLogin,
  actualizarRegister,
  actualizarUser,
  resetEditables,
  ResetUserSlice,
  actualizarUserImages,
} = UserSlice.actions;

export default UserSlice.reducer;
