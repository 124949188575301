import {createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';

import {actions} from './reportes.actions';
import {
  getReporteDeVentasRestaurante,
  getReporteSucursal,
} from './reportes.thunks';

import {initialStoreType} from './reportes.type';

//initialState

export const initialState = {
  restaurantes: [],
  selectedSucursalID: null,
  selectedRestauranteID: null,
  reporteSucursal: null,
} as initialStoreType;

//slice

export const reporteRestaurante = createSlice({
  name: 'reporte-restaurante',
  initialState: initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(getReporteDeVentasRestaurante.fulfilled, (state, action) => {
        state.restaurantes = action.payload;
      })
      .addCase(getReporteSucursal.fulfilled, (state, action) => {
        state.reporteSucursal = action.payload;
      });
  },
});

//selectors

export const selectedRestauranteSelector = createSelector(
  [
    (state: RootState) => state.reporteRestaurante.restaurantes,
    (state: RootState) => state.reporteRestaurante.selectedRestauranteID,
  ],
  (restaurante, selected) =>
    restaurante.find(r => r.id_restaurante === selected),
);

export const {setSelectedRestaurante, setSelectedSucursal} =
  reporteRestaurante.actions;

export default reporteRestaurante.reducer;
