import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {fCurrency} from '../../../../common/formatNumber';
import CustomPieChart from '../../../../components/custom-pie-chart/custom-pie-chart';
import {useAppSelector} from '../../../../redux/store';

const IngresosBox = () => {
  const balance = useAppSelector(
    store => store.reporteRestaurante.reporteSucursal?.balance,
  );

  if (!balance) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
        pt: 2,
        maxWidth: {xs: '90%'},
      }}>
      <Stack
        sx={{
          backgroundColor: '#ffbc6b',
          borderRadius: '15px',
          py: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
          mb: 1,
        }}>
        <Typography sx={{fontSize: '14px', fontWeight: 'bold'}}>
          Ingresos
        </Typography>
      </Stack>
      <TableContainer sx={{mb: 2}}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Alimentos:</TableCell>
              <TableCell>{fCurrency(balance.alimentos)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bebidas:</TableCell>
              <TableCell>
                {fCurrency(
                  (balance.bebidas_alcoholicas ?? 0) +
                    (balance.bebidas_no_alcoholicas ?? 0),
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Otros ingresos:</TableCell>
              <TableCell>{fCurrency(balance.otros)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ITBMS (TAX):</TableCell>
              <TableCell>{fCurrency(balance.impuestos)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{
          backgroundColor: true ? '#619c76' : '#EB5D2F',
          borderRadius: '15px',
          px: 5,
          py: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
        }}>
        <Typography
          sx={{
            fontSize: '0.9rem',
            fontWeight: 'bold',
            mr: 2,
            color: 'white',
          }}>
          Total de ingresos
        </Typography>
        <Typography
          sx={{
            fontSize: '0.9rem',
            fontWeight: 'bold',
            px: 3,
            backgroundColor: 'white',
            borderRadius: '15px',
          }}>
          {fCurrency(balance.total_ingresos)}
        </Typography>
      </Stack>
      <Box
        sx={{
          height: 300,
          width: 400,
        }}>
        <CustomPieChart
          width={150}
          height={150}
          innerRadius={40}
          outerRadius={60}
          data={[
            {name: 'Alimentos', value: balance.alimentos},
            {
              name: 'Bebidas',
              value:
                balance.bebidas_alcoholicas + balance.bebidas_no_alcoholicas,
            },
            {name: 'Otros', value: balance.otros},
          ]}
          tooltip
          COLORS={['#82ca9d', '#FFBB28', '#8c34eb']}
          legend
        />
      </Box>
    </Box>
  );
};

export default IngresosBox;
