import {Grid, Typography} from '@mui/material';

const EmptyScreen = () => {
  return (
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography
        variant="h6"
        sx={{
          fontSize: {
            xs: '10px',
            md: '18px',
          },
        }}>
        Aún no existen mesas para esta sucursal.
      </Typography>
    </Grid>
  );
};

export default EmptyScreen;
