import yup from '../../../common/yup';

export const entidadReceptoraSchema = yup.object({
  id: yup.number().nullable().notRequired(),
  tipo_receptor_id: yup
    .number()
    .nullable()
    .required('Tipo de receptor es requerido'),
  email: yup.string().email().notRequired(),
  sucursal_id: yup.number().nullable().notRequired(),
  info_cliente: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      //ESTE PARÁMETRO ES OPCIONAL Y SOLO SE ENVIARÁ CUANDO SE TENGA EL ID DE LA INFO. CLIENTE
      info_cliente_id: yup.string().nullable().notRequired(),
      num_id: yup.string().when('tipo_receptor_id', (value, schema) => {
        ///refactorzar valores a variables globales
        //Si el valor de “tipo_receptor_id” es 02 el campo de “num_id” es obligatorio.
        //El valor “num_id” corresponde al número identificador del cliente, ya sea un pasaporte,
        //cédula o un ruc internacional.
        // Si el valor de “tipo_receptor_id” es 04 el campo de “num_id” y el campo “pais_id”
        //son obligatorios. El valor de “pais_id” corresponde al país de procedencia de la
        //entidad_receptora extranjera. Ya sea persona o jurídica.
        if (value === 2 || value == 4) return schema.required();
        return schema;
      }),
      nombre: yup.string().when('tipo_receptor_id', (value, schema) => {
        if (value == 4) return schema.required();
        return schema;
      }),
      pais_id: yup.string().when('tipo_receptor_id', (value, schema) => {
        ///refactorzar valores a variables globales
        // Si el valor de “tipo_receptor_id” es 04 el campo de “num_id” y el campo “pais_id”
        //son obligatorios. El valor de “pais_id” corresponde al país de procedencia de la
        //entidad_receptora extranjera. Ya sea persona o jurídica.
        if (value == 4) return schema.required();
        return schema;
      }),
      email: yup.string().email().notRequired(),
    }),
  ruc_receptor: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      id: yup.number().nullable().notRequired(),
      tipo_persona_id: yup
        .number()
        .when('tipo_receptor_id', (value, schema) => {
          ///refactorzar valores a variables globales
          // Si el valor de “tipo_receptor_id” es 01 o 03 el objeto de “ruc_receptor” es obligatorio.
          if (value === 1 || value == 3) return schema.required();
          return schema;
        }),
      ruc: yup.string().when('tipo_receptor_id', (value, schema) => {
        ///refactorzar valores a variables globales
        // Si el valor de “tipo_receptor_id” es 01 o 03 el objeto de “ruc_receptor” es obligatorio.
        if (value === 1 || value == 3) return schema.required();
        return schema;
      }),
      dv: yup.number().when('tipo_receptor_id', (value, schema) => {
        ///refactorzar valores a variables globales
        // Si el valor de “tipo_receptor_id” es 01 o 03 el objeto de “ruc_receptor” es obligatorio.
        if (value === 1 || value == 3) return schema.required();
        return schema;
      }),
      name: yup.string().when('tipo_receptor_id', (value, schema) => {
        ///refactorzar valores a variables globales
        // Si el valor de “tipo_receptor_id” es 01 o 03 el objeto de “ruc_receptor” es obligatorio.
        if (value === 1 || value == 3) return schema.required();
        return schema;
      }),
      email: yup.string().email().notRequired(),
    }),
});
