import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import useMediaQuery from '../../common/hooks/use-media-query';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {
  calcularEfectivo,
  calcularMetodoPago,
  resetCierreSLice,
  setCierreType,
} from '../../redux/slices/cierre-slice';
import {cargarCierre} from '../../redux/slices/cierre-slice/cierre.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import DesktopView from './desktop-view';
import MobileView from './mobile-view';

const Cierre = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isDesktop = useMediaQuery('(min-width:962px)');
  const dispatch = useAppDispatch();
  const cierreSlice = useAppSelector(store => store.cierreSlice);
  const {type} = useParams<{type: string}>();

  const history = useHistory();

  useEffect(() => {
    if (type != 'z' && type != 'x') return history.replace('/');
    dispatch(setCierreType(type));
  }, [type]);

  useEffect(() => {
    if (cierreSlice.selectedReporteCaja) return;
    if (cierreSlice.efectivoDeclarado.length > 0) return;
    const initCierre = async () => {
      setIsLoading(true);
      await dispatch(cargarCierre());
      setIsLoading(false);
    };
    initCierre();
    return () => {
      dispatch(resetCierreSLice());
    };
  }, []);

  useEffect(() => {
    dispatch(calcularEfectivo());
  }, [cierreSlice.efectivoDeclarado]);

  useEffect(() => {
    dispatch(calcularMetodoPago());
  }, [cierreSlice.metodoPagoDeclarado, cierreSlice.efectivoDeclarado]);
  return (
    <Pagetemplate isLoading={isLoading} title="Cierres || IFUDIS" soloLayout>
      {isDesktop ? <DesktopView /> : <MobileView />}
    </Pagetemplate>
  );
};

export default Cierre;
