import Scrollbar from '../../components/Scrollbar';
import {Box, Link, Typography, Avatar} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import Logo from '../../components/Logo';
import {AccountStyle} from './sidebar-styles';
import {useAppSelector} from '../../redux/store';
import {ReactNode} from 'react';

type PropsTypes = {
  children: ReactNode;
};

const SideBarLogoProfile = ({children}: PropsTypes) => {
  const cargoEmpleado = useAppSelector(
    store => store.restauranteSlice.cargoEmpleado,
  );
  const userState = useAppSelector(store => store.userSlice);
  const {imagenes} = userState.userDetail!;
  const fotoPerfil = imagenes?.filter(
    value => value.tipo_imagen === 'foto_perfil',
  )[0];

  return (
    <Scrollbar
      sx={{
        '& .simplebar-content': {
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'space-around',
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box component={RouterLink} to="/" sx={{display: 'inline-flex'}}>
          <Logo width={65} height={65} />
        </Box>
      </Box>

      <Box sx={{mb: 1, mx: 2.5}}>
        <Link underline="none" component={RouterLink} to="/profile">
          <AccountStyle>
            <Avatar
              src={fotoPerfil ? fotoPerfil.ruta_imagen : ''}
              alt="photoURL"
            />
            <Box sx={{ml: 2}}>
              <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                {userState.userDetail?.nick ?? ''}
              </Typography>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                {cargoEmpleado?.nombre_cargo ?? ''}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      {children}
      <Box sx={{flexGrow: 1}} />
    </Scrollbar>
  );
};

export default SideBarLogoProfile;
