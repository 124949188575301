import {TableBody} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {setSelectedRestaurante} from '../../../../redux/slices/reporte-restaurante-slice';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import TableBodyRow from './table-body-row';

const CustomTableBody = () => {
  const history = useHistory();
  const restaurantes = useAppSelector(
    store => store.reporteRestaurante.restaurantes,
  );
  const dispatch = useAppDispatch();
  return (
    <>
      <TableBody>
        {restaurantes.map(restaurante => {
          return (
            <TableBodyRow
              key={restaurante.id_restaurante}
              restauranteNombre={restaurante.nombre}
              ventaAnterior={restaurante.monto_mes_anterior}
              ventaActual={restaurante.monto_mes_actual}
              onClickDetalle={() => {
                history.push(`/administracion-ifudis/restaurantes-details`);
                dispatch(setSelectedRestaurante(restaurante.id_restaurante));
              }}
            />
          );
        })}

        {/* <TableBodyRow
          restauranteNombre="Picnic"
          sucursal="Costa del este"
          ventaAnterior={5523}
          facturaAnterior={110}
          ventaActual={5523}
          facturaActual={110}
          acuerdoFijoMoney={200}
          acuerdoFijoPercent={0}
          onClickDetalle={() =>
            history.push(`/administracion-ifudis/restaurantes/${2}`)
          }
          onClickFacturar={() => {}}
        /> */}

        {/* emptyRow */}
        {/* <TableBodyRow />h */}
      </TableBody>
      {/* <TablePagination
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({from, to, count}) =>
          `${from}-${to} de ${count || 0}`
        }
        rowsPerPageOptions={[10, 25, 100]}
        //The total number of rows.
        count={100}
        rowsPerPage={15}
        page={1}
        // event: The event source of the callback.
        //page: The page selected.
        onPageChange={(
          event: React.MouseEvent<HTMLButtonElement> | null,
          page: number,
        ) => {}}
        //	Callback fired when the number of rows per page is changed.
        onRowsPerPageChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {}}
      /> */}
    </>
  );
};

export default CustomTableBody;
