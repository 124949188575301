import {Draft, PayloadAction} from '@reduxjs/toolkit';

import {initialState} from '.';
import {
  editableType,
  updateCategoriaInsumoStoreType,
  updateSubCategoriaInsumoStoreType,
} from './insumo.types';
import {insumoSliceType, updateInsumoStoreType} from './insumo.types';

const actualizarInsumo = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<updateInsumoStoreType>,
) => {
  state.editable.insumo = {...state.editable.insumo, ...action.payload};
};

const actualizarCategoriaInsumo = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<updateCategoriaInsumoStoreType>,
) => {
  state.editable.categoriaInsumo = {
    ...state.editable.categoriaInsumo,
    ...action.payload,
  };
};

const actualizarSubcategoriaInsumo = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<updateSubCategoriaInsumoStoreType>,
) => {
  state.editable.subCategoriaInsumo = {
    ...state.editable.subCategoriaInsumo,
    ...action.payload,
  };
};

const resetEditable = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<editableType>,
) => {
  switch (action.payload) {
    case 'insumo':
      Object.assign(state.editable.insumo, initialState.editable.insumo);
      break;
    case 'categoriaInsumo':
      Object.assign(
        state.editable.categoriaInsumo,
        initialState.editable.categoriaInsumo,
      );
      break;
    case 'subCategoriaInsumo':
      Object.assign(
        state.editable.subCategoriaInsumo,
        initialState.editable.subCategoriaInsumo,
      );
      break;
  }
};

const cargarInsumo = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<number>,
) => {
  const insumo = state.insumos.find(
    insumo => insumo.insumo_id === action.payload,
  );

  if (!insumo) return;

  state.editable.insumo.categoria_insumo_id = insumo.insumo.categoria_insumo_id;
  state.editable.insumo.costo = insumo.insumo.costo;
  state.editable.insumo.id_insumo = insumo.insumo.id_insumo;
  state.editable.insumo.nombre = insumo.insumo.nombre_insumo;
  state.editable.insumo.peso_unitario = insumo.insumo.peso_unitario;
  state.editable.insumo.restaurante_id = insumo.insumo.restaurante_id;
  state.editable.insumo.sub_categoria_insumo_id =
    insumo.insumo.sub_categoria_insumo_id;
  state.editable.insumo.sucursal_id = insumo.sucursal_id;
  state.editable.insumo.unidad_medida_id = insumo.insumo.unidad_medida_id;
  state.editable.insumo.id_insumo_sucursal = insumo.id_insumo_sucursal;
};

const cargarCategoriaInsumo = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<number>,
) => {
  const categoriaInsumo = state.categoriaInsumos.find(
    element => element.id_categoria_insumo === action.payload,
  );

  if (!categoriaInsumo) return;

  state.editable.categoriaInsumo.id_categoria_insumo =
    categoriaInsumo.id_categoria_insumo;
  state.editable.categoriaInsumo.nombre_categoria_insumo =
    categoriaInsumo.nombre_categoria_insumo;
};

const cargarSubcategoriaInsumo = (
  state: Draft<insumoSliceType>,
  action: PayloadAction<number>,
) => {
  const subcategoriaInusmo = state.subCategoriaInsumos.find(
    element => element.id_sub_categoria_insumo === action.payload,
  );

  if (!subcategoriaInusmo) return;

  state.editable.subCategoriaInsumo.id_sub_categoria_insumo =
    subcategoriaInusmo.id_sub_categoria_insumo;
  state.editable.subCategoriaInsumo.nombre_sub_categoria_insumo =
    subcategoriaInusmo.nombre_sub_categoria_insumo;
  state.editable.subCategoriaInsumo.categoria_insumo_id =
    subcategoriaInusmo.categoria_insumo_id;
};

const resetInsumoSlice = (state: Draft<insumoSliceType>) => {
  Object.assign(state, initialState);
};

export const actions = {
  actualizarInsumo,
  resetEditable,
  cargarInsumo,
  actualizarCategoriaInsumo,
  actualizarSubcategoriaInsumo,
  cargarCategoriaInsumo,
  cargarSubcategoriaInsumo,
  resetInsumoSlice,
};
