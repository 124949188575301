import {LoadingButton} from '@mui/lab';
import {Button, Stack} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {postCierre} from '../../redux/slices/cierre-slice/cierre.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

const BackSendButtons = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cierreSlice = useAppSelector(store => store.cierreSlice);
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const handleSubmit = async () => {
    dispatch(startLoading());
    await dispatch(postCierre());
    dispatch(stopLoading());
  };
  return (
    <Stack direction="row" spacing={4} m={4}>
      <Button
        sx={{minWidth: '150px'}}
        variant="outlined"
        color="secondary"
        onClick={() => history.push('/orden')}>
        ATRÁS
      </Button>
      <LoadingButton
        loading={isLoading}
        disabled={!cierreSlice.fechaInicial || !cierreSlice.fechaFinal}
        sx={{minWidth: '150px'}}
        variant="contained"
        color="secondary"
        onClick={handleSubmit}>
        ENVIAR
      </LoadingButton>
    </Stack>
  );
};

export default BackSendButtons;
