import {createApi} from '@reduxjs/toolkit/query/react';
import accessRtkQuery from '../../common/access-rtk-query';
import {userType} from '../../services/dto/auth';
import {DataWrapper} from '../../services/dto/common';

export const userApi = createApi({
  reducerPath: 'userApi',
  refetchOnMountOrArgChange: true,

  baseQuery: accessRtkQuery({
    baseUrl: `${process.env.REACT_APP_BACK_END}/api/`,
  }),
  endpoints: builder => ({
    getUserByName: builder.query<DataWrapper<userType[]>, string>({
      query: name => ({url: `usuario?name=${name}`, method: 'GET'}),
    }),
    getUserByLastName: builder.query<DataWrapper<userType[]>, string>({
      query: lastname => ({url: `usuario?lastname=${lastname}`, method: 'GET'}),
    }),
    getUserByEmail: builder.query<DataWrapper<userType[]>, string>({
      query: email => ({url: `usuario?email=${email}`, method: 'GET'}),
    }),
    getUserBySearchTerm: builder.query<DataWrapper<userType[]>, string>({
      query: searchTerm => ({
        url: `usuario?email=${searchTerm}&name=${searchTerm}&lastname=${searchTerm}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUserByNameQuery,
  useGetUserByLastNameQuery,
  useGetUserBySearchTermQuery,
} = userApi;
