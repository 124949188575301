import {store} from '../redux/store';

export const restrictedRoutes = [
  '/administracion-ifudis',
  '/administracion-ifudis/permisos',
  '/administracion-ifudis/comidas',
  '/administracion-ifudis/tipo-metodo-pagos',
  '/administracion-ifudis/tipo-propinas',
  '/administracion-ifudis/tipo-impuestos',
  '/administracion-ifudis/tipo-descuentos',
  '/administracion-ifudis/categoria-insumos',
  '/administracion-ifudis/proveedores',
  '/administracion-ifudis/restaurantes',
];

const isIfudisAllowed = (): boolean => {
  const cargo_ifudis = store.getState().userSlice.cargo_ifudis?.id_cargo;
  if (!cargo_ifudis) return false;
  // hay que buscar mejor forma de validar rutas especificas o mejorarlar el slug para que sea facil de verificar
  if (
    //restrictedRoutes.includes(window.location.pathname) &&
    cargo_ifudis !== 4
  )
    return true;
  return false;
};

export default isIfudisAllowed;
