import {
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import {formatDate} from '../../common/formatTime';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {useAppSelector} from '../../redux/store';
import {shallowEqual} from 'react-redux';
import {useState} from 'react';
import InventarioPasadoModal from './inventario-pasado-modal';
import InventarioNuevoModal from './inventario-nuevo-modal';
import useMediaQuery from '../../common/hooks/use-media-query';

type PropsTypes = {
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  sortHandler?: (key: string, allKeys: string[]) => void;
  columnBlacklisted?: string[];
};

const CustomTableHeader = ({
  columns,
  order,
  orderBy,
  sortHandler,
  columnBlacklisted,
}: PropsTypes) => {
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openLast, setOpenLast] = useState<boolean>(false);

  const sm = useMediaQuery('(max-width:390px)');

  const dates = useAppSelector(
    store => ({
      nueva: store.inventarioSlice.inventarioNuevoDate,
      pasada: store.inventarioSlice.inventarioPasadoDate,
    }),
    shallowEqual,
  );

  const isLastestInventario = useAppSelector(
    store => store.inventarioSlice.isLastestInventario,
  );

  const notShowInSm = ['Sub-categoria', 'Medidas', 'Costo\u00A0(USD)'];

  return (
    <TableHead>
      <InventarioPasadoModal
        state={openNew}
        handleClose={() => setOpenNew(false)}
      />
      <InventarioNuevoModal
        state={openLast}
        handleClose={() => setOpenLast(false)}
      />
      <TableRow hover>
        {columns.map((el, index) => {
          if (sm && notShowInSm.includes(el.label)) return null;

          return (
            <TableCell
              key={index}
              align={el.label === 'Precio\u00A0(USD)' ? 'left' : 'left'}
              sx={{fontWeight: 'bold'}}>
              {order &&
              orderBy &&
              sortHandler &&
              !columnBlacklisted?.includes(el.label) ? (
                <TableSortLabel
                  active={orderBy === el.key[el.key.length - 1]}
                  direction={order === 'asc' ? 'desc' : 'asc'}
                  onClick={() => {
                    if (sortHandler)
                      return sortHandler(el.key[el.key.length - 1], el.key);
                    return;
                  }}>
                  {el.label}
                </TableSortLabel>
              ) : (
                `${el.label}`
              )}
            </TableCell>
          );
        })}
        {!sm && (
          <TableCell
            align="center"
            sx={{cursor: 'pointer'}}
            onClick={() => setOpenNew(true)}>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                {isLastestInventario ? 'Último inventario' : ''}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {/*@ts-ignore */}
                {dates.pasada ? formatDate(dates.pasada) : 'No hay inventario'}
                <ChangeCircleIcon />
              </Typography>
            </Stack>
          </TableCell>
        )}

        <TableCell
          onClick={() => setOpenLast(true)}
          align="center"
          sx={{
            cursor: 'pointer',
          }}>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
              Nuevo inventario
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {/*@ts-ignore */}
              {formatDate(dates.nueva)}
              <ChangeCircleIcon />
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
