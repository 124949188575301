import {Button} from '@mui/material';

type PropsTypes = {
  label: string;
  isselected?: boolean;
  value?: any;
  onClick: (value: string) => void;
  size?: 'small' | 'medium' | 'large';
};

const FilterButton = ({
  label,
  isselected,
  onClick,
  value,
  size = 'medium',
}: PropsTypes) => {
  return (
    <Button
      size={size}
      fullWidth
      variant="contained"
      onClick={() => onClick(value || label)}
      color={`${isselected ? 'primary' : 'secondary'}`}>
      {label}
    </Button>
  );
};

export default FilterButton;
