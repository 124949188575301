import {ReactNode, useMemo} from 'react';
// material
import {CssBaseline} from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';

type PropsTypes = {
  children: ReactNode;
};

const ThemeProvider = ({children}: PropsTypes) => {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: {borderRadius: 8},
      typography,
      shadows,
      customShadows,
    }),
    [],
  );
  //@ts-ignore
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
