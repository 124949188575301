import {Nullable} from '../../../common/typescript-utility';
import {userType} from '../../../services/dto/auth';
import {
  descuentoRestaurantType,
  impuestoRestaurantType,
  infoCliente,
  MenuSucursal,
  MesaSucursal,
  metodoPagoRestaurantType,
  ModificadoresMenu,
  motivoDeCancelacion,
  propinaRestaurantType,
  SubcategoriaProducto,
  ubicationType,
} from '../../../services/dto/orden';
import {MotivoCancelacionProducto} from '../../../services/dto/orden-indirecta';
import {SeccionSucursal} from '../../../services/dto/restaurante';

export enum descuentoType {
  global,
  producto,
  none,
}

export type ordenMode =
  | 'normal'
  | 'unirMesa'
  | 'cambiarMesero'
  | 'moverProducto';

export type ordenSliceType = {
  uiData: {
    ubicaciones: ubicationType[];
    productos: MenuSucursal[];
    subcategorias: subcategoryType[];
    mesas: MesaSucursal[];
    motivosCancelacion: motivoDeCancelacion[];
    mesaMode: ordenMode;
    mesaA: number | null;
    mesaB: number | null;
    selectedMesaSeccion: SeccionSucursal | null;
    loadingMesas: boolean;
  };
  selectedSubcategory: SubcategoriaProducto | null;
  lastFacturaId: number | null;
  currentStep: number;
  mesaSeleccionada: MesaSucursal | null;
  cuenta: {
    propinaEfectiva: number;
    subtotal: number;
    impuestos: number;
    descuentos: number;
    descuentoMontoFijo: number;
    propinas: number;
    modificadores: number;
    propinasTotal: number;
    total: number;
  };
  orden: {
    metaData: {
      cantidadDeComenzales: number | null;
    };
    ubicacion: ubicationType | null;
    carrito: carritoType[];
    carritoAPI: carritoType[];
    clientes: clienteStoreTypePrimary[];
    clienteSeleccionado: clienteStoreTypePrimary | null;
    infoCliente: infoCliente | null;
    shouldUpdate: boolean;
  };
};

export type subcategoryType = {
  cantidad: number;
  subcategory: SubcategoriaProducto;
};

export type orderMetodoPago = {
  restante: number;
  cantidad: number;
  metodos: metodoPagoType[];
  zeroMetodo: boolean;
};

export type metodoPagoType = {
  cantidad?: number;
  metodoPago: metodoPagoRestaurantType;
};

export type carritoType = {
  producto: MenuSucursal;
  cantidad: number;
  observacion?: string;
  id: string | number;
  clienteId: string | number;
  descuentoId?: number;
  descuentoCant?: number;
  cantidadCancelada?: number;
  modificadores: ({
    id_modificador_p_ordenado?: number;
  } & ModificadoresMenu)[];
  metaData?: {
    id_orden_producto_menu: number;
    codigo_orden_id: number;
    estado_orden_id: number;
    cuenta_id: number;
    cantidadCancelada?: number;
    motivoDeCancelacion?: number;
    observacionBorrado?: string;
    cancelados: MotivoCancelacionProducto[];
    created_at?: Date;
  };
};

export type createClienteThunkPayload = {
  name?: string;
  ifudisUser?: userType;
};

export type fetchDataThunkType = {
  ubicaciones: ubicationType[];
  productos: MenuSucursal[];
};
//ENTIDAD RECEPTORA
export type RucReceptor = {
  tipo_persona_id?: number | null;
  ruc?: string | null;
  dv?: string | null;
  name?: string | null;
};

export type RucReceptorEntidadReceptora = {
  name?: number | null;
  dv?: number | null;
  ruc?: string | null;
  tipo_persona_id?: number | null;
  id?: number | null;
  email?: string | null;
};

export type InfoClienteEntidadReceptora = {
  info_cliente_id?: number | null;
  num_id?: number | null;
  nombre?: string | null;
  pais_id?: string | null;
  email?: string | null;
};

export type SearchEntidadReceptora = {
  tipo_receptor_id: number;
  buscador: string;
};

// export type EntidadReceptora = {
//   id?: number | null;
//   tipo_receptor_id: number | null;
//   email?: string | null;
//   sucursal_id?: number| null;
//   info_cliente_id?: InfoClienteEntidadReceptora | null;
//   ruc_receptor?: RucReceptorEntidadReceptora | null;
//   nombre?: string | null;
//   ///Este type es asi porque en backend viene asi.
//   info_cliente?: {
//     id?: number | null;
//     entidad_receptora_id?: number | null;
//     info_cliente: InfoClienteEntidadReceptora | null;
//   };

// }
export type RecEntidadR = {
  id: number;
};
// export type EntidadReceptora = {

//   ruc_receptor: {
//     name?: string | null;
//     dv?: number | null;
//     ruc?: string | null;
//     tipo_persona_id?: string | null;
//     id?: number | null;
//   };
//   info_cliente?: null | null;
//   sucursal_id?: number | null;
//   tipo_receptor_id?: number | null; //DEBE SER ENTERO
//   id?: number | null; //DEBE SER ENTERO
// };

export type EntidadReceptora = {
  id?: number | null;
  tipo_receptor_id: number | null;
  email?: string;
  sucursal_id?: number | null;
  info_cliente_id?: InfoClienteEntidadReceptora | null;
  ruc_receptor?: RucReceptorEntidadReceptora | null;

  ///Este type es asi porque en backend viene asi.
  info_cliente?: {
    id?: number | null;
    entidad_receptora_id?: number | null;
    info_cliente: InfoClienteEntidadReceptora | null;
    nombre?: number;
  };
};
//FIN ENTIDAD RECEPTORA
export type clienteStoreTypePrimary = {
  name: string;
  id: string | number;
  email?: string;
  metodosPago: orderMetodoPago;
  descuentos: descuentoRestaurantType[];
  propinas: propinaRestaurantType[];
  impuestos: impuestoRestaurantType[];
  propinaEfectivo: number | null;
  descuentoType: descuentoType;
  estado?: number;
  id_razon_social?: number;
  entidad_receptora?: EntidadReceptora | null;
};

export type clienteStoreType = Nullable<clienteStoreTypePrimary>;

export type updatedClienteStoreType = Partial<clienteStoreTypePrimary>;
