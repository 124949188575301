import {useAppDispatch} from '../../redux/store';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {anularFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
  anularFacID: number | null;
};

const AnularConfirmDialog = ({state, handleClose, anularFacID}: PropsTypes) => {
  const dispatch = useAppDispatch();

  const handleClickCompleted = async () => {
    if (anularFacID) await dispatch(anularFactura(anularFacID));
    handleClose();
  };

  return (
    <Dialog
      open={state}
      onClose={handleClose}
      aria-labelledby="comanda-title"
      aria-describedby="descripcion">
      <DialogTitle id="comanda-title">
        {'¿Estas seguro que deseas anular esta factura?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="descripcion">
          {'Esta acción no se puede deshacer.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleClickCompleted} autoFocus>
          Anular
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnularConfirmDialog;
