import {Box, Grid} from '@mui/material';
import {useCallback, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import ComandaList from '../../components/comanda-list';
import DropdownMenu from '../../components/dropdown-menu';
import OrdenTemplate from '../../layouts/orden-layout/orden-layout';
import {
  onHistoryDateChanged,
  resetComandas,
} from '../../redux/slices/comanda-slice';
import {
  getComandas,
  selectedCurrrentSeccion,
} from '../../redux/slices/comanda-slice/comanda.thunk';
import {
  getEmpleadoAsignadoSeccion,
  getSeccionSucursal,
} from '../../redux/slices/restaurante-utils-slice/restaurante-utils.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import DateSelector from './date-selector';

export default function Comanda() {
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const dispatch = useAppDispatch();

  const isHistorial = useLocation().pathname === '/historial-comandas';

  const isComander =
    useAppSelector(store => store.restauranteSlice.cargoEmpleado?.id_cargo) ===
    9;

  const currentSelectedSeccion = useAppSelector(
    store => store.comandaSlice.currentSelectedSeccion,
  );
  const historialDate = useAppSelector(store => store.comandaSlice.historyDate);
  const sucursal = useAppSelector(
    store => store.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  const secciones = useAppSelector(
    store => store.restauranteUtilsSlice.sucursalSecciones,
  );

  const areaSecciones = useAppSelector(
    store => store.restauranteUtilsSlice.empleadoAsignadosSeccion,
  );

  const filteredSeccions = useMemo(() => {
    return [...secciones].filter(element => {
      if (element.tipo_seccion.id_tipo_seccion === 3) return false;
      if (isComander && !isHistorial) {
        areaSecciones.some(
          area => area.seccion_id === element.id_seccion_sucursal,
        );
      }
      return true;
    });
  }, [secciones, isHistorial]);

  useEffect(() => {
    if (currentSelectedSeccion) return;

    if (filteredSeccions.length)
      dispatch(
        selectedCurrrentSeccion(filteredSeccions[0].id_seccion_sucursal),
      );
  }, [filteredSeccions]);

  useEffect(() => {
    if (!currentSelectedSeccion || isHistorial) return;
    const sub = setInterval(() => {
      dispatch(getComandas({playNotify: true}));
    }, 15 * 1000);
    return () => {
      clearInterval(sub);
    };
  }, [currentSelectedSeccion, isHistorial]);

  const fetchInitialData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(getEmpleadoAsignadoSeccion(true));
    await dispatch(getSeccionSucursal());
    await dispatch(getComandas({isHistorial}));
    dispatch(stopLoading());
  }, [currentSelectedSeccion, isHistorial]);

  useEffect(() => {
    fetchInitialData();
  }, [currentSelectedSeccion, sucursal, isHistorial, historialDate]);

  return (
    <OrdenTemplate isLoading={isLoading} title="Comandas">
      <Grid
        container
        spacing={0.5}
        sx={{
          height: '100%',
          overflowY: 'auto',
          display: 'flex',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          pb: 5,
          px: 1,
        }}>
        <Grid
          xs={12}
          item
          sx={{
            px: 1,
            pt: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            {isHistorial && (
              <DateSelector
                onChange={date => {
                  dispatch(onHistoryDateChanged(date));
                }}
                date={historialDate}
              />
            )}
          </Box>
          {filteredSeccions.length > 1 && (
            <DropdownMenu
              notConditionalShow
              data={filteredSeccions}
              selectedId={currentSelectedSeccion?.id_seccion_sucursal}
              idKey="id_seccion_sucursal"
              valueKey={['nombre_seccion']}
              onSelect={seccion => {
                dispatch(resetComandas());
                dispatch(selectedCurrrentSeccion(seccion.id_seccion_sucursal));
              }}
            />
          )}
        </Grid>
        <ComandaList />
      </Grid>
    </OrdenTemplate>
  );
}
