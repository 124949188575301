import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Stack, Link, Container, Typography, Box} from '@mui/material';
// components
import Page from '../../components/Page';
import LoginForm from './LoginForm';
import TwoBubbles from '../../components/two-bubbles/two-bubbles';
import Logo from '../../components/Logo';
import useMediaQuery from '../../common/hooks/use-media-query';
import {useState} from 'react';
import RecoverPassword from './recover-password';
// import AuthSocial from '../../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  position: 'relative',
  overflow: 'hidden',
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const dynamicSpacing = isDesktop ? -8 : -4;
  const logoDimensions = isDesktop ? 350 : 150;
  const padding = isDesktop ? 10 : 0;
  const [recoverPasswordState, setRecoverPassword] = useState<boolean>(false);

  const handleOpenRecoverPassword = () => {
    setRecoverPassword(true);
  };

  const handleCloseRecoverPassword = () => {
    setRecoverPassword(false);
  };

  return (
    <RootStyle title="Iniciar sesión || IFUDIS">
      <TwoBubbles />
      <Container maxWidth="sm">
        <ContentStyle>
          <Logo
            sx={{mb: dynamicSpacing}}
            width={logoDimensions}
            height={logoDimensions}
          />
          <Stack sx={{mb: 5, pl: padding}}>
            <Typography variant="h4" gutterBottom>
              Iniciar sesión en ifudis
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
              Ingrese sus datos a continuación.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}
          <LoginForm />
          <Typography
            variant="body1"
            align="center"
            sx={{mt: 3, pl: padding, cursor: 'pointer'}}>
            ¿No recuerdas tu contraseña? &nbsp;
            <Link variant="subtitle1" onClick={handleOpenRecoverPassword}>
              ¡Recuperala aquí!
            </Link>
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{mt: 3, pl: padding, cursor: 'pointer'}}>
            ¿No tienes una cuenta? &nbsp;
            <Link variant="subtitle1" component={RouterLink} to="register">
              ¡Registrate aquí!
            </Link>
          </Typography>
        </ContentStyle>
        {recoverPasswordState && (
          <RecoverPassword handleClose={handleCloseRecoverPassword} />
        )}
      </Container>
      <Box sx={{flexGrow: 1}} />
    </RootStyle>
  );
}
