import {createSlice} from '@reduxjs/toolkit';
import {actions} from './empleado.actions';
import {
  CargarEmpleado,
  getCargos,
  getPermisos,
  putPostCargo,
  putPostEmpleado,
  getEmpleados,
  getEmpleadosSucursal,
  deleteEmpleadoRestaurante,
  getEmpleadosSucursalBySucursalID,
} from './empleados.thunks';
import {empleadosSliceType} from './empleados.types';

export const initialCargo = {
  descripcion_cargo: null,
  id_cargo: null,
  nombre_cargo: null,
  porcentaje_descuento: null,
  permisos: null,
};

export const initialEmpleadoRestaurante = {
  cargo_id: null,
  restaurante_id: null,
  sucursales: [],
  usuario_id: null,
  empleado_id: null,
  status: null,
};

export const initialState = {
  cargos: [],
  empleadoRestaurante: [],
  empleadoSucursales: [],
  permisos: [],
  editable: {
    cargo: initialCargo,
    empleado: initialEmpleadoRestaurante,
  },
} as empleadosSliceType;

export const empleadosSlice = createSlice({
  name: 'empleados-slice',
  initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(getPermisos.fulfilled, (state, action) => {
        state.permisos = action.payload;
      })
      .addCase(getCargos.fulfilled, (state, action) => {
        state.cargos = action.payload;
      })
      .addCase(putPostCargo.fulfilled, (state, action) => {})
      .addCase(CargarEmpleado.fulfilled, (state, action) => {
        state.editable.empleado.cargo_id =
          action.payload.empleado.cargo.id_cargo;
        state.editable.empleado.empleado_id =
          action.payload.empleado.id_empleado;
        state.editable.empleado.restaurante_id =
          action.payload.empleado.restaurante.id_restaurante;
        state.editable.empleado.sucursales = action.payload.sucursales.map(
          element => element.sucursal_id,
        );
        state.editable.empleado.status = action.payload.empleado.status;
        state.editable.empleado.usuario_id = action.payload.empleado.usuario.id;
        state.empleadoSucursales = action.payload.sucursales;
      })
      .addCase(putPostEmpleado.fulfilled, (state, action) => {})
      .addCase(getEmpleados.fulfilled, (state, action) => {
        state.empleadoRestaurante = action.payload;
      })
      .addCase(getEmpleadosSucursal.fulfilled, (state, action) => {
        state.empleadoSucursales = action.payload;
      })
      .addCase(getEmpleadosSucursalBySucursalID.fulfilled, (state, action) => {
        state.empleadoSucursales = action.payload;
      })
      .addCase(deleteEmpleadoRestaurante.fulfilled, (state, action) => {});
  },
});

export const {
  actualizarCargo,
  resetEditable,
  cargarCargo,
  actualizarEmpleadoRestaurante,
  resetEmpleadoSlice,
} = empleadosSlice.actions;

export default empleadosSlice.reducer;
