import {createSelector, createSlice} from '@reduxjs/toolkit';
import {
  deleteModificador,
  fetchAccionesModificador,
  fetchModificador,
  putPostModificador,
} from './modificadores.thunk';
import {mappedModificador, modificadorSliceType} from './modificadores.type';
import {RootState} from '../../store';
import sortItBro from '../../../common/sortItBro';
export const initialState = {
  accionesModificador: [],
  modificadores: [],
} as modificadorSliceType;

//schema

export const modificadorSlice = createSlice({
  name: 'modificador-slice',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAccionesModificador.fulfilled, (state, action) => {
        state.accionesModificador = action.payload;
      })
      .addCase(fetchModificador.fulfilled, (state, action) => {
        state.modificadores = action.payload;
      })
      .addCase(putPostModificador.fulfilled, (state, action) => {
        state.modificadores = action.payload;
      })
      .addCase(deleteModificador.fulfilled, (state, action) => {
        state.modificadores = action.payload;
      });
  },
});

//selectors

export const filteredModificadores = createSelector(
  [(state: RootState) => state.modificadorSlice.modificadores],
  modificadores =>
    (
      [...modificadores].flatMap(element =>
        element.acciones.map(acc => ({
          id: acc.accion_a_modificador_id,
          nombre: element.nombre_modificador,
          accion: acc.nombre_accion_modificador,
          precio: element.precio,
          accionId: acc.id_accion_modificador,
        })),
      ) as mappedModificador[]
    ).sort((a, b) => sortItBro(a.accion, b.accion, 'desc')),
);

export const {} = modificadorSlice.actions;

export default modificadorSlice.reducer;
