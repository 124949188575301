// formData.append('name', register.name);
// formData.append('lastname', register.lastname);
// formData.append('password', register.password);
// formData.append('email', register.email);

// if (register.id_sexo) formData.append('id_sexo', register.id_sexo.toString());

// if (register.nick) formData.append('nick', register.nick);
// if (register.fecha_nacimiento)
//   formData.append('fecha_nacimiento', register.fecha_nacimiento.toISOString());

// if (register.dni) formData.append('dni', register.dni);

// if (register.foto_perfil) formData.append('foto_perfil', register.foto_perfil);

// if (register.foto_frontal_identificacion)
//   formData.append(
//     'foto_frontal_identificacion',
//     register.foto_frontal_identificacion,
//     register.foto_frontal_identificacion.name,
//   );

// if (register.foto_trasera_identificacion)
//   formData.append(
//     'foto_trasera_identificacion',
//     register.foto_trasera_identificacion,
//     register.foto_trasera_identificacion.name,
//   );

import yup from '../../../common/yup';

export const userRegisterSchema = yup
  .object({
    name: yup.string().required(),
    lastname: yup.string().required(),
    password: yup.string().required().min(8),
    verifyPassword: yup.string().oneOf([yup.ref('password'), null]),
    email: yup.string().email().required(),
    id_sexo: yup.number(),
    nick: yup.string(),
    fecha_nacimiento: yup
      .mixed()
      .when('$request', request =>
        request ? yup.string().required() : yup.date().required(),
      ),
    dni: yup.string(),
    // foto_perfil: yup.
    // restaurante_id: yup
    //   .number()
    //   .when('$request', (request, schema) =>
    //     request ? schema.required() : schema,
    //   ),
    // id_modificador: yup.number(),
  })
  .required();
