import {Box} from '@mui/material';
import BottomBar from '../../components/bottom-bar/bottom-bar';
import InventarioBottomBar from '../../components/bottom-bar/inventario-bottom-bar';
import {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {fetchCategoriaInsumo} from '../../redux/slices/insumo-slice/insumo.thunks';
import {
  cargarInventario,
  getLastestInventario,
} from '../../redux/slices/inventario-slice/inventario.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import CategoriaBarFilter from './categoria-bar-filter';
import InventarioTable from './inventario-table';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import getNestedValue from '../../common/getNestedValue';
import sortArrayObject from '../../common/sortArrayObject';

function Inventario() {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('nombre_insumo');
  const [arrayKeys, setArrayKeys] = useState<string[]>([
    'insumo',
    'insumo',
    'nombre_insumo',
  ]);
  const inventario = useAppSelector(
    store => store.inventarioSlice.editable.inventario,
  );
  const selectedCategoriaInsumo = useAppSelector(
    store => store.inventarioSlice.selectedInsumoSubcategory,
  );

  const isLoading = useAppSelector(store => store.uiSlice.isLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      await dispatch(cargarInventario());
      await dispatch(fetchCategoriaInsumo());
      await dispatch(getLastestInventario());
      dispatch(stopLoading());
    };
    fetchData();
  }, []);

  const columns = [
    {
      label: 'Productos',
      key: ['insumo', 'insumo', 'nombre_insumo'],
    },
    {
      label: 'Sub-categoria',
      key: [
        'insumo',
        'insumo',
        'sub_categoria_insumo',
        'nombre_sub_categoria_insumo',
      ],
    },
    {
      label: 'Medidas',
      key: ['insumo', 'insumo', 'unidad_medida', 'nombre_unidad_medida'],
    },
    {
      label: 'Costo\u00A0(USD)',
      key: ['insumo', 'insumo', 'costo'],
    },
  ];

  const rows = inventario.filter(element => {
    if (!selectedCategoriaInsumo) return true;
    return (
      element.insumo.insumo.categoria_insumo_id === selectedCategoriaInsumo
    );
  });

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['insumo', 'insumo', 'nombre_insumo'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, [
        'insumo',
        'insumo',
        'sub_categoria_insumo',
        'nombre_sub_categoria_insumo',
      ])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, [
        'insumo',
        'insumo',
        'unidad_medida',
        'nombre_unidad_medida',
      ])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  return (
    <Pagetemplate isLoading={isLoading} title="Inventario || IFUDIS" soloLayout>
      <CategoriaBarFilter />
      <InventarioTable
        title="Inventario"
        rows={rows.sort((a, b) => sortArrayObject(a, b, arrayKeys, order))}
        columns={columns}
        onSearch={handleOnSearch}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <Box mb={5} />
      <BottomBar>
        <InventarioBottomBar />
      </BottomBar>
    </Pagetemplate>
  );
}

export default Inventario;
