const useBrowserNotification = () => {
  function sendNotification(
    title: string,
    message: string,
    id: number,
    onclick: ((this: Notification, ev: Event) => any) | null,
  ) {
    const idsnotification = JSON.parse(
      localStorage.getItem('browsernotificationsid') || '[]',
    ) as Array<number>;
    const findid = idsnotification.find(value => value === id);
    if (!findid) {
      idsnotification.push(id);
      localStorage.setItem(
        'browsernotificationsid',
        JSON.stringify(idsnotification),
      );
      const notification = new Notification(title, {
        icon: '/favicon/android-chrome-192x192.png',
        body: `${message}`,
      });
      notification.onclick = onclick;
      notification.onclose = onclick;
    }
  }

  async function handleNotification(
    title: string,
    message: string,
    id: number,
    onclick: ((this: Notification, ev: Event) => any) | null,
  ) {
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications!');
    } else if (Notification.permission === 'granted') {
      sendNotification(title, message, id, onclick);
    } else if (Notification.permission !== 'denied') {
      await Notification.requestPermission(permission => {
        if (permission === 'granted') {
          sendNotification(title, message, id, onclick);
        }
      });
    }
  }

  return {
    handleNotification,
  };
};

export default useBrowserNotification;
