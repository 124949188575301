import {createSlice} from '@reduxjs/toolkit';
import {actions} from './insumo.actions';
import {
  deleteCategoriaInsumo,
  deleteSubcategoriaInsumo,
  fetchCategoriaInsumo,
  fetchInsumoSucursal,
  fetchSubCategoriaInsumo,
  fetchUnidadMedida,
  putPostCategoriaInsumo,
  putPostSubcategoriaInsumo,
} from './insumo.thunks';
import {insumoSliceType} from './insumo.types';

export const initialState = {
  insumos: [],
  editable: {
    insumo: {
      categoria_insumo_id: null,
      costo: null,
      id_insumo: null,
      nombre: null,
      peso_unitario: 1,
      restaurante_id: null,
      sub_categoria_insumo_id: null,
      sucursal_id: null,
      id_insumo_sucursal: null,
      unidad_medida_id: null,
    },
    categoriaInsumo: {
      id_categoria_insumo: null,
      nombre_categoria_insumo: null,
    },
    subCategoriaInsumo: {
      categoria_insumo_id: null,
      id_sub_categoria_insumo: null,
      nombre_sub_categoria_insumo: null,
    },
  },

  categoriaInsumos: [],
  subCategoriaInsumos: [],
  unidadMedida: [],
} as insumoSliceType;

export const insumoSlice = createSlice({
  name: 'insumo-slice',
  initialState: initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(fetchInsumoSucursal.fulfilled, (state, action) => {
        state.insumos = action.payload;
      })
      .addCase(fetchSubCategoriaInsumo.fulfilled, (state, action) => {
        state.subCategoriaInsumos = action.payload;
      })
      .addCase(fetchCategoriaInsumo.fulfilled, (state, action) => {
        state.categoriaInsumos = action.payload;
      })
      .addCase(fetchUnidadMedida.fulfilled, (state, action) => {
        state.unidadMedida = action.payload;
      })
      .addCase(putPostSubcategoriaInsumo.fulfilled, (state, action) => {})
      .addCase(putPostCategoriaInsumo.fulfilled, (state, action) => {})
      .addCase(deleteCategoriaInsumo.fulfilled, (state, action) => {})
      .addCase(deleteSubcategoriaInsumo.fulfilled, (state, action) => {});
  },
});

export const {
  actualizarInsumo,
  cargarInsumo,
  resetInsumoSlice,
  resetEditable,
  actualizarCategoriaInsumo,
  actualizarSubcategoriaInsumo,
  cargarCategoriaInsumo,
  cargarSubcategoriaInsumo,
} = insumoSlice.actions;

export default insumoSlice.reducer;
