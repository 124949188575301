import {useEffect, useCallback, lazy, Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';
import PrivateRoute from './components/private-route';
import {useAppDispatch, useAppSelector} from './redux/store';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import NotFound from './pages/404/Page404';
import Profile from './pages/profile/profile';
import Ordenes from './pages/ordenes';
import {
  fetchRestauranteAndSucursal,
  getRestaurantes,
} from './redux/slices/restaurante-slice/restaurente.thunks';
import MenuPage from './pages/menu/new-menu';
import Empleados from './pages/empleados/empleados';
import NewCajaPage from './pages/caja/caja';
import {refreshUser} from './redux/slices/user-slice/user.thunks';
import Comanda from './pages/comanda';
import Lobby from './pages/lobby';
import DiarioVentas from './pages/diario-ventas';
import Insumos from './pages/insumos';
import Inventario from './pages/inventario';
import Compras from './pages/compras';
import {Spinner} from './components/with-spinner/with-spinner.component';
import {resetOrden} from './redux/slices/orden-slice';
import {es} from 'date-fns/esm/locale';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from '@mui/lab';
import Cierre from './pages/cierre';
import {actualizarUserSliceData} from './redux/slices/restaurante-slice';
import {analytics} from './firebase/firebase-config';
import {logEvent, setUserProperties} from 'firebase/analytics';
import HistorialCierre from './pages/historial-cierre';
import useScrollToTop from './common/hooks/use-scroll-to-top';
import Restaurantes from './pages/administracion-ifudis/restaurantes';
import RestauranteDetails from './pages/administracion-ifudis/restaurantes/restaurante-details';
import Menu_sucursal from './pages/menu_sucursal';
import Clientes from './pages/clientes';
import Deudas from './pages/clientes/deudas';

import useExternalScripts from './hooks/useExternalScripts';
import useExternalScriptsWithUrl from './hooks/useExternalScriptsWithUrl';

const AdministracionRoot = lazy(() => import('./pages/administracion'));
const AdministracionIfudisRoot = lazy(
  () => import('./pages/administracion-ifudis'),
);
const ReportesRoot = lazy(() => import('./pages/reportes'));

export default function App() {
  const dispatch = useAppDispatch();
  const isLogged = useAppSelector(store => store.userSlice.isLogged);
  const userDetails = useAppSelector(store => store.userSlice.userDetail);
  const restauranteEmpleadoSeleccionado = useAppSelector(
    store => store.restauranteSlice.restauranteEmpleadoSeleccionado,
  );

  const sucursalEmpleadoSeleccionado = useAppSelector(
    store => store.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  const getLastestUserDetails = useCallback(() => {
    if (isLogged) return dispatch(refreshUser());
    return;
  }, []);

  const EXTERNAL_SCRIPT = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "lu2oxvou87");`;
  useExternalScripts({url: EXTERNAL_SCRIPT});
  const URL_SCRIPT = `https://www.googletagmanager.com/gtag/js?id=G-9GKKFM2574`;
  useExternalScriptsWithUrl({url: URL_SCRIPT});
  const GOOGLE_ANALITYCS_SCRIPT = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-9GKKFM2574');`;
  useExternalScripts({url: GOOGLE_ANALITYCS_SCRIPT});

  useEffect(() => {
    if (!userDetails) return;

    const fetch = async () => {
      await dispatch(getRestaurantes());
      await dispatch(actualizarUserSliceData());
    };
    fetch();
  }, [userDetails]);

  useEffect(() => {
    getLastestUserDetails();
  }, [getLastestUserDetails]);

  useEffect(() => {
    if (!userDetails) return;
    if (!restauranteEmpleadoSeleccionado || !sucursalEmpleadoSeleccionado)
      return;
    dispatch(fetchRestauranteAndSucursal());
    dispatch(resetOrden());

    setUserProperties(analytics, {
      user: userDetails,
      restaurante: restauranteEmpleadoSeleccionado.id,
      sucursal: sucursalEmpleadoSeleccionado.sucursal_id,
    });
  }, [restauranteEmpleadoSeleccionado, sucursalEmpleadoSeleccionado]);

  useEffect(() => {
    logEvent(analytics, 'Web App started...');
  }, []);

  useScrollToTop();

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <PrivateRoute exact path="/" componente={Lobby} />
          <PrivateRoute path="/profile" componente={Profile} />
          <PrivateRoute path="/diario-ventas" componente={DiarioVentas} />
          <PrivateRoute path="/compras" componente={Compras} />
          <PrivateRoute path="/insumos" componente={Insumos} />
          <PrivateRoute path="/inventario" componente={Inventario} />
          <PrivateRoute path="/orden" componente={Ordenes} />
          <PrivateRoute path="/menu" componente={MenuPage} />
          <PrivateRoute path="/menu-sucursal" componente={Menu_sucursal} />
          <PrivateRoute path="/empleados" componente={Empleados} />
          <PrivateRoute path="/caja" componente={NewCajaPage} />
          <PrivateRoute path="/cierre/:type" componente={Cierre} />
          <PrivateRoute path="/historial-cierre" componente={HistorialCierre} />
          <PrivateRoute path="/comandas" componente={Comanda} />
          <PrivateRoute path="/historial-comandas" componente={Comanda} />
          <PrivateRoute path="/clientes" componente={Clientes} />
          <PrivateRoute path="/deudas_clientes" componente={Deudas} />
          <PrivateRoute
            path="/administracion"
            componente={AdministracionRoot}
          />
          <PrivateRoute path="/reportes" componente={ReportesRoot} />
          <PrivateRoute
            hideSideBar
            needSelectedRestaurant={false}
            path="/administracion-ifudis/restaurantes"
            componente={Restaurantes}
          />
          <PrivateRoute
            hideSideBar
            needSelectedRestaurant={false}
            path="/administracion-ifudis/restaurantes-details"
            componente={RestauranteDetails}
          />
          <PrivateRoute
            path="/administracion-ifudis"
            componente={AdministracionIfudisRoot}
          />

          <PrivateRoute path="*" componente={NotFound} />
        </Switch>
      </Suspense>
    </LocalizationProvider>
  );
}
