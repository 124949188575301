import {useCallback, useEffect} from 'react';

import Menu from '../../components/new-menu/new-menu';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {
  getCategorias,
  getMenuRestaurante,
  getSubCategorias,
} from '../../redux/slices/menu-slice/menu.thunks';
import {fetchModificador} from '../../redux/slices/modificador-slice/modificadores.thunk';
import {getSucursales} from '../../redux/slices/restaurante-slice/restaurente.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

function MenuPage() {
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const dispatch = useAppDispatch();
  const fetchAllScreenData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(getMenuRestaurante());
    await dispatch(getCategorias());
    await dispatch(getSubCategorias());
    await dispatch(getSucursales());
    await dispatch(fetchModificador());
    dispatch(stopLoading());
  }, []);

  useEffect(() => {
    fetchAllScreenData();
  }, []);
  return (
    <Pagetemplate isLoading={isLoading} title="Menú || IFUDIS" soloLayout>
      <Menu />
    </Pagetemplate>
  );
}

export default MenuPage;
