import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {useCallback, useEffect, useState} from 'react';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {
  getMenuSucursal,
  putPostMenuSucursal,
} from '../../redux/slices/orden-slice/orden.thunks';
import CrudTable from '../../components/crud-table';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import {Card, CardContent, Grid, Stack} from '@mui/material';
import {useForm} from 'react-hook-form';
import yup from '../../common/yup';
import {menuSucursalSchema} from '../../redux/slices/menu-slice/menu.schemas';
import {yupResolver} from '@hookform/resolvers/yup';
import {ControlledNumericTextField} from '../../components/form-input/controlled-numeric-text-field';
import {ControlledSwitch} from '../../components/form-input/controlled-switch';
import {MenuSucursal} from '../../services/dto/orden';
import getNestedValue from '../../common/getNestedValue';
import {LoadingButton} from '@mui/lab';

const menu_sucursal = () => {
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const dispatch = useAppDispatch();

  const [openCrearMenu, setOpenCrearMenu] = useState(false);

  const [editId, setEditId] = useState<number | null>(null);

  const {control, handleSubmit, formState, setValue} = useForm<
    yup.InferType<typeof menuSucursalSchema>
  >({
    mode: 'onBlur',
    defaultValues: {
      type: false,
      max: null,
      min: null,
      actual: null,
    },
    resolver: yupResolver(menuSucursalSchema),
  });

  const fetchAllScreenData = useCallback(async () => {
    dispatch(startLoading());
    await Promise.all([dispatch(getMenuSucursal())]);
    dispatch(stopLoading());
  }, []);
  useEffect(() => {
    fetchAllScreenData();
  }, []);

  const handleOnEdit = (id: number) => {
    setEditId(id);
    const menu = menu_sucursal.find(menu => menu.id_menu_sucursal === id);
    if (menu) {
      setValue('min', menu.min_max ? menu.min_max.cant_min : null);
      setValue('max', menu.min_max ? menu.min_max.cant_max : null);
      setValue('actual', menu.min_max ? menu.min_max.cant_actual : null);
      setValue('type', !!menu.type);
      setSelectedMenu(menu);
      setOpenCrearMenu(true);
    }
  };

  const handleCloseCrearMenu = () => {
    setOpenCrearMenu(false);
    setEditId(null);
    setValue('min', null);
    setValue('max', null);
    setValue('type', false);
    setValue('actual', null);
  };

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['producto', 'nombre_producto'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const menu_sucursal = useAppSelector(
    state => state.ordenSlice.uiData.productos,
  );

  const [selectedMenu, setSelectedMenu] = useState<MenuSucursal | null>(null);

  const onSubmit = async (data: yup.InferType<typeof menuSucursalSchema>) => {
    if (selectedMenu) {
      dispatch(startLoading());
      let request: MenuSucursal = {
        ...selectedMenu,
        type: data.type,
        min_max: undefined,
      };

      if (data.min || data.max) {
        request = {
          ...request,
          min_max: {
            ...(selectedMenu.min_max || {
              cant_min: 0,
              cant_max: 0,
              cant_actual: 0,
            }),
            cant_min: data.min || 0,
            cant_max: data.max || 0,
            cant_actual: data.actual || 0,
          },
        };
      }
      await dispatch(
        putPostMenuSucursal({
          data: request,
          editId: selectedMenu.id_menu_sucursal,
        }),
      )
        .unwrap()
        .then(async () => {
          if (handleCloseCrearMenu) handleCloseCrearMenu();
          await Promise.all([dispatch(getMenuSucursal())]);
        })
        .catch(() => {});
      dispatch(stopLoading());
    }
  };
  return (
    <Pagetemplate
      isLoading={isLoading}
      title="Menú Sucursal || IFUDIS"
      soloLayout>
      <CrudTable
        columns={column}
        title="Inventario automático"
        OnEdit={handleOnEdit}
        rows={menu_sucursal}
        onSearch={handleOnSearch}
        onSwitch={(arg, id) => {
          console.log(arg, id);
        }}></CrudTable>
      <DialogPopUp
        open={openCrearMenu}
        handleClose={handleCloseCrearMenu}
        title={'Editar Producto'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card raised>
                <CardContent
                  style={{
                    textAlign: 'center',
                  }}>
                  <Stack spacing={3}>
                    <ControlledNumericTextField
                      label="Mínimo"
                      controllerProps={{control, name: 'min'}}
                      error
                    />
                    <ControlledNumericTextField
                      label="Máximo"
                      controllerProps={{control, name: 'max'}}
                      error
                    />
                    <ControlledNumericTextField
                      label="Actual"
                      controllerProps={{control, name: 'actual'}}
                      error
                    />
                    <ControlledSwitch
                      title="¿Mostrar en Menu Digital?"
                      controllerProps={{control, name: 'type'}}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              style={{display: 'flex', justifyContent: 'center'}}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
                loading={isLoading}
                disabled={!!Object.keys(formState.errors).length}>
                Guardar Cambios
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </DialogPopUp>
    </Pagetemplate>
  );
};

export default menu_sucursal;

const column = [
  {
    label: 'Imagen',
    key: ['producto', 'imagen', 'ruta_imagen'],
  },
  {
    label: 'Nombre',
    key: ['producto', 'nombre_producto'],
  },
  {
    label: 'Categoría',
    key: ['categoria', 'nombre_categoria'],
  },
  {
    label: 'Subcategoría',
    key: ['subcategoria', 'nombre_subcategoria'],
  },
  {
    label: 'Mínimo',
    key: ['min_max', 'cant_min'],
  },
  {
    label: 'Máximo',
    key: ['min_max', 'cant_max'],
  },
  {
    label: 'Actual',
    key: ['min_max', 'cant_actual'],
  },
  {
    label: '',
    key: ['id_menu_sucursal'],
  },
];
