import {matchPath, useLocation} from 'react-router-dom';
import {Box, List} from '@mui/material';
import {getRoutesArray} from '../../common/isRouteAllowed';
import {useAppSelector} from '../../redux/store';
import NavItem from './nav-item';

type navConfigTypes = {
  navConfig: (
    | {
        title: string;
        path: string;
        icon: JSX.Element;
        toolTip: string;
        children: {
          title: string;
          path: string;
          toolTip: string;
          icon: JSX.Element;
        }[];
      }
    | {
        title: string;
        path: string;
        icon: JSX.Element;
        toolTip: string;
        children?: undefined;
      }
  )[];
};

export default function NavSection({navConfig, ...other}: navConfigTypes) {
  const cargo_ifudis = useAppSelector(store => store.userSlice.cargo_ifudis);
  const {pathname} = useLocation();
  const match = (path: string) =>
    //@ts-ignore
    path ? !!matchPath({path, end: false}, pathname) : false;

  const userState = useAppSelector(store => store.userSlice);
  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );
  const cargoEmpleado = useAppSelector(
    store => store.restauranteSlice.cargoEmpleado,
  );
  return (
    <Box {...other}>
      {userState.userDetail?.email_verified_at && (
        <List disablePadding>
          {navConfig
            .filter(item => {
              if (item.path.includes('/cierre')) {
                if (!cajaEmpleado) {
                  if (
                    cargoEmpleado?.id_cargo !== 1 &&
                    cargoEmpleado?.id_cargo !== 8 &&
                    cargoEmpleado?.id_cargo !== 2
                  ) {
                    return false;
                  }
                }
              }
              if (
                item.path === '/administracion-ifudis' &&
                cargo_ifudis?.id_cargo === 4
              ) {
                return false;
              }
              return true;
            })
            .filter(item => {
              if (item.path === '/administracion-ifudis') return true;
              const routesArray = getRoutesArray();
              return routesArray.includes(item.path);
            })
            .map(item => (
              <NavItem key={item.title} item={item} active={match} />
            ))}
        </List>
      )}
    </Box>
  );
}
