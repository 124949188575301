import {Logout} from '../redux/slices/user-slice/user.thunks';
import {store} from '../redux/store';
import {errorToast} from './toast';
import {ApiResponse} from 'apisauce';
export const getError = async (res: ApiResponse<any, any>) => {
  if (res.ok) return;
  const error = res.originalError;

  try {
    if (error?.response?.data instanceof Blob) {
      error.response.data = JSON.parse(await error.response.data.text());
    }
    if (error.response?.data.message) {
      return errorToast(`${error.response.data.message}`);
    }

    if (error.response?.data?.error instanceof Array) {
      error.response.data.error.forEach((value: string) =>
        errorToast(`${value}`),
      );
    } else if (typeof error.response?.data?.error === 'string') {
      if (
        error.response.data.error === 'Usted no esta debidamente autenticado'
      ) {
        store.dispatch(Logout());
        return window.location.replace('./login');
      }
      return errorToast(`${error.response?.data?.error}`);
    } else {
      switch (res.problem) {
        case 'NETWORK_ERROR':
          return errorToast('No hay conexión a internet.');
        case 'TIMEOUT_ERROR':
          return errorToast('Tiempo de espera excedido.');
        default:
          return errorToast(`Error Interno.`);
      }
    }
    return;
  } catch (error) {
    return errorToast(`Error Interno.`);
  }
};
