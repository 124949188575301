import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../services';
import {inventarioSucursal} from '../../../services/dto/inventario';
import {RootState} from '../../store';
import {fetchInsumoSucursal} from '../insumo-slice/insumo.thunks';
import {inventarioItem} from './inventario.types';
import dayjs from 'dayjs';

export const getLastestInventario = createAsyncThunk<
  inventarioSucursal,
  undefined,
  {state: RootState}
>('inventario/getLastestInventario', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
  const response = await api.getInventarioSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
    1,
  );

  if (!response) return thunkAPI.rejectWithValue('error al obtener inventario');
  if (response.data.length < 1)
    return thunkAPI.rejectWithValue('No hay inventario');
  return response.data[0];
});

export const seleccionarCategoriaInsumo = createAsyncThunk<
  number | null,
  number | null,
  {state: RootState}
>('inventario/seleccionarCategoriaInsumo', async (data, thunkAPI) => {
  const {
    insumoSlice: {categoriaInsumos},
  } = thunkAPI.getState();

  if (!data) return null;

  if (!categoriaInsumos) return thunkAPI.rejectWithValue('No hay categorias');

  const categoriaInsumo = categoriaInsumos.find(
    element => element.id_categoria_insumo === data,
  );

  if (!categoriaInsumo) return thunkAPI.rejectWithValue('No hay categoria');

  return categoriaInsumo.id_categoria_insumo;
});

export const cargarInventario = createAsyncThunk<
  inventarioItem[],
  undefined,
  {state: RootState}
>('inventario/cargarInventario', async (data, thunkAPI) => {
  await thunkAPI.dispatch(fetchInsumoSucursal());
  await thunkAPI.dispatch(getLastestInventario());

  const {
    insumoSlice: {insumos},
    inventarioSlice: {inventarioPasado},
  } = thunkAPI.getState();

  if (!insumos) return thunkAPI.rejectWithValue('No hay insumos');

  const sameDay = (date: string | undefined) => {
    if (!date) return false;
    const fechaPasada = dayjs(date);
    const fechaActual = dayjs();
    return fechaPasada.isSame(fechaActual, 'day');
  };

  return insumos.map(insumo => {
    const insumoPasado = inventarioPasado?.insumos_inventariados.find(
      element => element.id_insumo_sucursal === insumo.id_insumo_sucursal,
    );

    return {
      insumo,
      cantidad:
        insumoPasado && sameDay(inventarioPasado?.fecha_hora_inventario)
          ? insumoPasado.cantidad
          : 0,
    };
  });
});

export const getInventarioByDate = createAsyncThunk<
  inventarioSucursal | null,
  undefined,
  {state: RootState}
>('inventario/getInventarioByDate', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    inventarioSlice: {inventarioPasadoDate},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  const response = await api.getInventarioSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
    1,
    inventarioPasadoDate?.toISOString(),
  );

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener unidades de medida');
  if (response.data.length === 0) return null;
  return response.data[0];
});

export const postInventarioSucursal = createAsyncThunk<
  null,
  undefined,
  {state: RootState}
>('inventario/postInventarioSucursal', async (data, thunkAPI) => {
  const {
    inventarioSlice,
    userSlice: {userDetail},
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  if (!userDetail) return thunkAPI.rejectWithValue('No hay usuario');
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  if (!inventarioSlice.inventarioNuevoDate)
    return thunkAPI.rejectWithValue('No hay fecha');

  const body = {
    fecha_hora_inventario: inventarioSlice.inventarioNuevoDate.toISOString(),
    usuario_crea:
      empleadoAsignadoSucursalSeleccionado.id_empleado_asignado_sucursal,
    aprobado_por:
      empleadoAsignadoSucursalSeleccionado.id_empleado_asignado_sucursal,
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    insumos: inventarioSlice.editable.inventario.map(item => ({
      id_insumo_sucursal: item.insumo.id_insumo_sucursal,
      costo_en_inventario: item.insumo.insumo.costo,
      cantidad: item.cantidad ?? 0,
      total: (item.cantidad || 0) * item.insumo.insumo.costo,
    })),
  };

  const response = await api.postInventarioSucursal(body);
  if (!response) return thunkAPI.rejectWithValue('Error al guardar inventario');
  thunkAPI.dispatch(getLastestInventario());
  return null;
});
