import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import {Link} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Tooltip from '@mui/material/Tooltip';

type floatingButtonProps = {
  color?: string;
  to?: string;
  icon?: string;
  action?: () => void;
};

const FloatingButton = function ({
  color,
  to,
  icon,
  action = () => {},
}: floatingButtonProps) {
  const getIcon = (type: string | undefined) => {
    switch (type) {
      case 'backSpace': {
        return <BackspaceIcon />;
      }
      default: {
        return <AddIcon />;
      }
    }
  };

  return (
    <Zoom in timeout={350} unmountOnExit>
      <Tooltip title={icon === 'backSpace' ? 'Atrás' : 'Agregar'} arrow>
        <Fab
          //@ts-ignore
          color={color ? color : 'secondary'}
          aria-label="floating-bottom"
          component={Link}
          to={to ? to : '#'}
          style={{
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={action}>
          {getIcon(icon)}
        </Fab>
      </Tooltip>
    </Zoom>
  );
};

export default FloatingButton;
