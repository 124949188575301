import {Draft} from '@reduxjs/toolkit';
import {initialState} from '.';
import {finanzasSliceType} from './finanzas.types';

const resetFinanzasSlice = (state: Draft<finanzasSliceType>) => {
  return initialState;
};

export const actions = {
  resetFinanzasSlice,
};
