import getNestedValue from './getNestedValue';
import sortItBro from './sortItBro';

const sortArrayObject = (
  a: any,
  b: any,
  arrayKeys: string[],
  order: 'asc' | 'desc',
) =>
  sortItBro(getNestedValue(a, arrayKeys), getNestedValue(b, arrayKeys), order);

export default sortArrayObject;
