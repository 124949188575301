import PreCuentaWrapper from '../../components/pre-cuenta-wrapper/pre-cuenta-wrapper';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {setCurrentStep} from '../../redux/slices/orden-slice';
import {
  addUpdateProductIndirecta,
  facturaIndirectaInit,
  resetSlice,
} from '../../redux/slices/orden-slice/orden.thunks';
import ClienteIfudisTab from '../../components/cliente-ifudis-tab/cliente-ifudis-tab';
import TabUbicaciones from '../../components/tab-ubicaciones/tab-ubicaciones';
import PropinasTab from '../../components/propinas-tab/propinas-tab';
import MetodoPagoList from '../../components/metodo-pago-list/metodo-pago-list';
import DescuentoList from '../../components/descuentos-list/descuentos-list';
import useCalculateOrden from '../../common/hooks/use-calculate-order';
import RightContainer from './right-container';
import OrdenWrapper from './orden-wrapper';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import MiscOrdenCambio from '../../components/misc-orden-cambio';

function Misc() {
  const dispatch = useAppDispatch();

  const shouldUpdate = useAppSelector(
    state => state.ordenSlice.orden.shouldUpdate,
  );

  const alreadyInit =
    useAppSelector(store => store.ordenSlice.orden.carritoAPI).length > 0;

  const carrito = useAppSelector(state => state.ordenSlice.orden.carrito);
  const handleAtras = async () => {
    if (shouldUpdate || carrito.length > 0) {
      dispatch(startLoading());
      if (!alreadyInit) await dispatch(facturaIndirectaInit());
      if (alreadyInit) await dispatch(addUpdateProductIndirecta());
      await dispatch(resetSlice());
      dispatch(setCurrentStep(1));
      dispatch(stopLoading());
    } else {
      dispatch(startLoading());
      await dispatch(resetSlice());
      dispatch(setCurrentStep(1));
      dispatch(stopLoading());
    }
  };

  const cajaActiva = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );
  const clienteSeleccinado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );

  useCalculateOrden();

  return (
    <OrdenWrapper>
      <PreCuentaWrapper handleAtras={handleAtras} />
      {cajaActiva && (
        <RightContainer p={1}>
          <TabUbicaciones />
          <ClienteIfudisTab />
          <DescuentoList />
          <PropinasTab />
          <MetodoPagoList />
          <MiscOrdenCambio />
        </RightContainer>
      )}
    </OrdenWrapper>
  );
}

export default Misc;
