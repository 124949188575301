import {Grid, Card, CardContent, Stack} from '@mui/material';
import ControlledSelector from '../form-input/controlled-selector';
import autoCompleteEntidadReceptoraSchema from './auto-complete-fields/auto-complete-entidad-receptora.schema';
import yup from '../../common/yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import RucAutocomplete from './auto-complete-fields/ruc_receptor/ruc-autocomplete';
import NameAutocomplete from './auto-complete-fields/ruc_receptor/name-autocomplete';
import NumIdAutocomplete from './auto-complete-fields/info_cliente/num-id-autocomplete';
import NombreAutocomplete from './auto-complete-fields/info_cliente/nombre-autocomplete';
import {getTipoReceptor} from '../../services';

type propsType = {
  tipo_receptor_id: number;
  handleReduxUpdate?: any | null;
  selectEntidadReceptora?: any | null;
};
interface TipoReceptor {
  id: number;
  name: string;
}
const EntidadReceptoraAutocomplete = function ({
  tipo_receptor_id,
  handleReduxUpdate,
  selectEntidadReceptora,
}: propsType) {
  const [tipoReceptorId, setTipoReceptorIdSelected] = useState(1);

  const [formNatural, setFormNatural] = useState(true);
  const [requestPais, setRequestPais] = useState(false);

  const {control, handleSubmit, formState, getValues, setValue} = useForm<
    yup.InferType<typeof autoCompleteEntidadReceptoraSchema>
  >({
    mode: 'onBlur',
    defaultValues: {
      tipo_receptor_id: '1',
    },
    resolver: yupResolver(autoCompleteEntidadReceptoraSchema),
  });
  const [tipoRecep, setTipoRecep] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  //SE ACTIVA CUANDO SE DEBAN MOSTRAR TODOS LOS TIPOS DE RECEPTOR
  // useEffect(() => {
  //   const fecthReceptor = async () => {
  //     try {
  //       const response = await getTipoReceptor();

  //       // Verifica si la respuesta es exitosa y tiene datos
  //       if (response.ok && response.data && Array.isArray(response.data)) {
  //         // Actualiza el estado con los países
  //         setTipoRecep(response.data);
  //       } else {
  //         // Si la respuesta no tiene datos válidos, imprime un mensaje de error
  //         console.error(
  //           'La respuesta de getCountryList no contiene datos válidos:',
  //           response,
  //         );
  //       }
  //       console.log ("TIPO R:", response)
  //     } catch (error) {
  //       console.error('Error al obtener países:', error);
  //     }
  //   };
  //   fecthReceptor();
  // }, []);

  useEffect(() => {
    const fecthReceptor = async () => {
      try {
        const response = await getTipoReceptor();
        if (response && response.data && Array.isArray(response.data)) {
          const filteredTipos = response.data.filter(
            //Tipo de receptor que se muestran en el select
            (tipo: TipoReceptor) =>
              tipo.id === 1 || tipo.id === 3 || tipo.id === 4 || tipo.id === 2,
          );
          setTipoRecep(filteredTipos);
        } else {
          console.error('Error al obtener los tipos de receptor:', response);
        }
      } catch (error) {
        console.error('Error al obtener los tipos de receptor:', error);
      }
    };

    fecthReceptor();
  }, []);

  //CONDICIÓN DE MUESTRA DE FORMULARIO SEGÚN TIPO DE RECEPTOR
  const setFormType = (data: any) => {
    const type = data.id;
    console.log('TIPO RECEPTOR SELECCIONADO:', type);
    localStorage.setItem('tipo de receptor seleccionado:', type);
    setTipoReceptorIdSelected(type);
    if (type === 1 || type === 3) {
      setFormNatural(true);
    }
    if (type === 2 || type === 4) {
      setFormNatural(true);
      if (type === 4) {
        setRequestPais(true);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card raised>
          <CardContent
            style={{
              textAlign: 'center',
            }}>
            <Stack spacing={3}>
              {/* This selector have control over form behavior */}
              <ControlledSelector
                sourceData={tipoRecep}
                controllerProps={{control, name: 'tipo_receptor_id'}}
                title="Tipo de Receptor"
                idKey="id"
                valueKey={['name']}
                fullWidth
                handleOnChange={setFormType}
              />
              {/* formNatural controls show/hide fields 
              for form Behavior */}
              {!formNatural && (
                <NameAutocomplete
                  tipo_receptor_id={tipoReceptorId}
                  handleReduxUpdate={handleReduxUpdate}
                  selectEntidadReceptora={selectEntidadReceptora}
                />
              )}
              {formNatural && (
                <RucAutocomplete
                  tipo_receptor_id={tipoReceptorId}
                  handleReduxUpdate={handleReduxUpdate}
                  selectEntidadReceptora={selectEntidadReceptora}
                />
              )}
              {!formNatural && (
                <NumIdAutocomplete
                  tipo_receptor_id={tipoReceptorId}
                  handleReduxUpdate={handleReduxUpdate}
                  selectEntidadReceptora={selectEntidadReceptora}
                />
              )}
              {!formNatural && (
                <NombreAutocomplete
                  tipo_receptor_id={tipoReceptorId}
                  handleReduxUpdate={handleReduxUpdate}
                  selectEntidadReceptora={selectEntidadReceptora}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EntidadReceptoraAutocomplete;
