import {Stack, Typography, useMediaQuery} from '@mui/material';
import NumericTextField from '../../components/form-input/controlled-numeric-text-field';
import {editarMetodoPago} from '../../redux/slices/cierre-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

interface PropsTypes {
  text: string;
  value: number;
  id: number;
  index: number;
  left?: boolean;
}

const MetodoPagoInput = ({text, value, left, id, index}: PropsTypes) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const dispatch = useAppDispatch();
  const totalEfectivo = useAppSelector(
    store => store.cierreSlice.efectivoTotal,
  );
  const isEfectivo = id === 3;
  return (
    <Stack
      flexDirection="row"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        py: 1,
        px: 2,
      }}>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '1.1rem',
        }}>
        {text}
      </Typography>
      <NumericTextField
        disabled={isEfectivo}
        variant="outlined"
        value={isEfectivo ? totalEfectivo : value}
        size="small"
        inputProps={{
          style: {height: 15, width: 65, fontSize: '1rem', textAlign: 'center'},
        }}
        onChange={value =>
          !isEfectivo && dispatch(editarMetodoPago({index, value}))
        }
      />
    </Stack>
  );
};

export default MetodoPagoInput;
