import {useCallback, useEffect, useState} from 'react';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import NewEmpleadoForm from './new-empleado-form';
import {useAppDispatch, useAppSelector} from '../../redux/store';

import {
  CargarEmpleado,
  deleteEmpleadoRestaurante,
  getCargos,
  getEmpleados,
  putPostEmpleado,
} from '../../redux/slices/empleados-slice/empleados.thunks';

import {getSucursales} from '../../redux/slices/restaurante-slice/restaurente.thunks';
import CrudTable from '../crud-table';
import getNestedValue from '../../common/getNestedValue';
import sortArrayObject from '../../common/sortArrayObject';
import ConfirmDialog from '../confirm-dialog';
import {listOfRolesInOrderID} from '../../common/isRouteAllowed';
import {actualizarEmpleadoRestaurante} from '../../redux/slices/empleados-slice';

function NewEmpleado() {
  const dispatch = useAppDispatch();
  const [openCrearEmpleado, setOpenCrearEmpleado] = useState(false);
  const [deleteID, setDeleteID] = useState<number | null>(null);
  const [openEditEmpleado, setOpenEditEmpleado] = useState(false);
  const empleados = useAppSelector(
    store => store.empleadoSlice.empleadoRestaurante,
  );

  const columnEmpleados = [
    {
      label: 'Nombre',
      key: ['usuario', 'name'],
    },
    {
      label: 'Apellido',
      key: ['usuario', 'lastname'],
    },
    {
      label: 'Correo',
      key: ['usuario', 'email'],
    },
    {
      label: 'Cargo',
      key: ['cargo', 'nombre_cargo'],
    },
    {
      label: 'Estatus',
      key: ['status'],
    },
    {
      label: '',
      key: ['id_empleado'],
    },
  ];

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('name');
  const [arrayKeys, setArrayKeys] = useState<string[]>(['usuario', 'name']);

  const handleCloseCrearProducto = () => {
    setOpenCrearEmpleado(false);
  };

  const handleCloseEditProducto = () => {
    setOpenEditEmpleado(false);
  };

  const handleEmpleadoOnDelete = async (id: number) => {
    setDeleteID(id);
  };

  const handleOnEditProducto = async (id: number) => {
    await dispatch(CargarEmpleado(id));
    setOpenEditEmpleado(true);
  };

  useEffect(() => {
    dispatch(getEmpleados());
    dispatch(getSucursales());
    dispatch(getCargos());
  }, []);

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['usuario', 'name'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, ['usuario', 'lastname'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, ['usuario', 'email'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getNestedValue(row, ['cargo', 'nombre_cargo'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  return (
    <>
      <CrudTable
        setOpenCrear={setOpenCrearEmpleado}
        title="Empleados"
        columns={columnEmpleados}
        rows={[...empleados].sort((a, b) =>
          sortArrayObject(a, b, arrayKeys, order),
        )}
        OnEdit={handleOnEditProducto}
        onDelete={handleEmpleadoOnDelete}
        onSearch={handleOnSearch}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
        onSwitchRender={id => {
          const empleado = empleados.find(e => e.id_empleado === id);
          if (!empleado?.cargo.id_cargo) return {disabled: true};
          return {
            disabled: !!listOfRolesInOrderID
              .slice(0, 1)
              .includes(empleado?.cargo.id_cargo),
          };
        }}
        onSwitch={async (bool, id) => {
          await dispatch(CargarEmpleado(id));
          await dispatch(actualizarEmpleadoRestaurante({status: bool ? 1 : 0}));
          await dispatch(putPostEmpleado('actualizar'));
        }}
      />
      <ConfirmDialog
        state={deleteID != null}
        handleClose={() => {
          setDeleteID(null);
        }}
        handleClickCompleted={async () => {
          if (deleteID) {
            await dispatch(deleteEmpleadoRestaurante(deleteID));
            setDeleteID(null);
          }
        }}
      />
      <DialogPopUp
        open={openCrearEmpleado}
        handleClose={handleCloseCrearProducto}
        title="Invitar empleado">
        <NewEmpleadoForm type="crear" handleClose={handleCloseCrearProducto} />
      </DialogPopUp>
      <DialogPopUp
        open={openEditEmpleado}
        handleClose={handleCloseEditProducto}
        title="Editar empleado">
        <NewEmpleadoForm
          type="actualizar"
          handleClose={handleCloseEditProducto}
        />
      </DialogPopUp>
    </>
  );
}

export default NewEmpleado;
