import {Draft} from '@reduxjs/toolkit';
import {initialState} from '.';
import {marketingStoreType} from './marketing.type';

const resetMarketingSlice = (state: Draft<marketingStoreType>) => {
  return initialState;
};

export const actions = {
  resetMarketingSlice,
};
