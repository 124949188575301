import {Button, Typography} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type propType = {
  handleClickListItem: (event: React.MouseEvent<HTMLElement>) => void;
  text: string;
  show: boolean;
};

function MenuButton<T>({handleClickListItem, show, text}: propType) {
  return (
    <Button
      sx={{
        display: show ? 'flex' : 'none',
        textAlign: 'left',
        maxWidth: '250px',
        px: 2,
        py: 0.2,
        borderRadius: '10px',
        height: '20px',
      }}
      variant="contained"
      color="secondary"
      onClick={handleClickListItem}>
      <Typography
        noWrap
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '0.7rem',
        }}>
        {text}
      </Typography>
      <KeyboardArrowDownIcon />
    </Button>
  );
}

export default MenuButton;
