import {useState} from 'react';

export function useCrudTable() {
  const [isOpen, setOpen] = useState(false);

  const [editId, setEditId] = useState<number | null>(null);

  const handleClose = () => {
    setOpen(false);
    setEditId(null);
  };

  const handleOpen = (id?: number) => {
    setEditId(id ?? null);
    setOpen(true);
  };

  return {isOpen, editId, setOpen, setEditId, handleClose, handleOpen};
}

// import {useCallback, useMemo, useState} from 'react';
// import getNestedValue from '../getNestedValue';
// import sortArrayObject from '../sortArrayObject';

// export function useCrudTable<T extends string>({
//   searchValues,
//   defaultOrderBy,
//   defaultArrayKeys,
//   data,
// }: {
//   searchValues?: (keyof T)[];
//   defaultOrderBy?: keyof T;
//   defaultArrayKeys?: (keyof T)[];
//   data: T[];
// }) {
//   const [isOpen, setOpen] = useState(false);

//   const [editId, setEditId] = useState<number | null>(null);

//   const [order, setOrder] = useState<'asc' | 'desc'>('desc');
//   const [orderBy, setOrderBy] = useState<keyof T>(
//     defaultOrderBy ?? ('' as any),
//   );
//   const [arrayKeys, setArrayKeys] = useState<(keyof T)[]>(
//     defaultArrayKeys ?? [],
//   );

//   const handleClose = () => {
//     setOpen(false);
//     setEditId(null);
//   };

//   const handleOpen = (id?: number) => {
//     setOpen(true);
//     setEditId(id ?? null);
//   };

//   const sortHandler = useCallback(
//     (key: keyof T, allKeys: (keyof T)[]) => {
//       setOrder(order === 'asc' ? 'desc' : 'asc');
//       setOrderBy(key);
//       setArrayKeys(allKeys);
//     },
//     [order, arrayKeys],
//   );

//   const handleOnSearch = useCallback(
//     (row: any, searchTerm: string) =>
//       getNestedValue(row, searchValues ?? [])
//         .toLowerCase()
//         .includes(searchTerm.toLowerCase()),
//     [],
//   );

//   const rows = useMemo(() => {
//     [...data].sort((a, b) => sortArrayObject(a, b, arrayKeys as any, order));
//   }, [data, order, orderBy, arrayKeys]);

//   return {
//     isOpen,
//     editId,
//     setOpen,
//     setEditId,
//     handleClose,
//     handleOpen,
//     sortHandler,
//     order,
//     orderBy,
//     arrayKeys,
//     handleOnSearch,
//     rows,
//   };
// }
