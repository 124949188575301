import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialState} from '.';
import {inventarioSliceType} from './inventario.types';

const actualizarCantidad = (
  state: Draft<inventarioSliceType>,
  action: PayloadAction<{id: number; value?: number}>,
) => {
  const index = state.editable.inventario.findIndex(
    element => element.insumo.id_insumo_sucursal === action.payload.id,
  );

  state.editable.inventario[index].cantidad = action.payload.value;
};

const setDates = (
  state: Draft<inventarioSliceType>,
  action: PayloadAction<{data: Date; type: 'nueva' | 'pasada'}>,
) => {
  action.payload.data.setHours(0, 0, 0, 0);
  if (action.payload.type === 'nueva') {
    state.inventarioNuevoDate = action.payload.data;
    return;
  }
  state.inventarioPasadoDate = action.payload.data;
};

const resetInventarioSlice = (state: Draft<inventarioSliceType>) => {
  Object.assign(state, initialState);
};

export const actions = {
  actualizarCantidad,
  setDates,
  resetInventarioSlice,
};
