import {Box, Button, Stack} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import useMediaQuery from '../../common/hooks/use-media-query';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {getFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';
import {seleccionarFactura} from '../../redux/slices/diario-ventas-slice';

const FacturaBar = () => {
  const isDesktop = useMediaQuery('(min-width:962px)');
  const facturaSeleccionada = useAppSelector(
    store => store.diarioVentasSlice.factura_seleccionada,
  );

  const dispatch = useAppDispatch();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      spacing={2}
      sx={{pb: '10px'}}>
      {!isDesktop ? (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => dispatch(seleccionarFactura(null))}>
          Cerrar
        </Button>
      ) : (
        <Box flex={1} />
      )}
      {facturaSeleccionada && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            dispatch(
              getFactura({id_factura: facturaSeleccionada, action: 'download'}),
            )
          }
          startIcon={<DownloadIcon />}>
          Descargar
        </Button>
      )}
    </Stack>
  );
};

export default FacturaBar;
