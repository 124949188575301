import {createSlice} from '@reduxjs/toolkit';

//types

export type uiStateType = {
  isLoading: boolean;
};

//initialState

const initialState = {isLoading: false} as uiStateType;

//slice

export const uiSlice = createSlice({
  name: 'ui-slice',
  initialState,
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    stopLoading: state => {
      state.isLoading = false;
    },
    ResetUiSlice: state => {
      return initialState;
    },
  },
});

export const {startLoading, stopLoading, ResetUiSlice} = uiSlice.actions;

export default uiSlice.reducer;
