import {createAsyncThunk} from '@reduxjs/toolkit';

import isNullish from '../../../common/isNullish';
import {msgSuccess} from '../../../common/toast';
import {api} from '../../../services';
import {userType} from '../../../services/dto/auth';
import {DataWrapper} from '../../../services/dto/common';
import {
  acceptInvitationUserThunkPayload,
  authUserData,
  cargarUserPayload,
  thunkAPiConfig,
  userFoto,
  userImagesType,
  userLoginTypePrimary,
  userStoreTypePrimary,
} from './user.types';
import resetReduxStore from '../../../common/resetReduxStores';
import {actualizarLogin} from '.';
import {userRegisterSchema} from './user.schemas';
import yup from '../../../common/yup';

export const loginUser = createAsyncThunk<
  authUserData,
  undefined,
  thunkAPiConfig
>('user/loginUser', async (data, thunkAPI) => {
  const {
    userSlice: {
      editable: {login: tempLogin},
    },
  } = thunkAPI.getState();
  if (isNullish(tempLogin))
    return thunkAPI.rejectWithValue('algun valor es nulo.');

  const login = tempLogin as userLoginTypePrimary;

  const loginResponse = await api.postLogin(login);
  if (!loginResponse) return thunkAPI.rejectWithValue('error al loguearse');

  const tokenResponse = await api.postToken(login);
  if (!tokenResponse) return thunkAPI.rejectWithValue('error al obtener token');

  return {loginResponse, tokenResponse} as authUserData;
});

export const refreshUser = createAsyncThunk<
  DataWrapper<userType>,
  undefined,
  thunkAPiConfig
>('user/refreshUser', async (_, thunkAPI) => {
  const {
    userSlice: {isLogged},
  } = thunkAPI.getState();
  if (!isLogged) return thunkAPI.rejectWithValue('usuario no logueado');
  const response = await api.getUser();
  if (!response) return thunkAPI.rejectWithValue('error al obtener usuario');
  return response;
});

export const registerUser = createAsyncThunk<
  authUserData,
  {fotos: userFoto} & yup.InferType<typeof userRegisterSchema>,
  thunkAPiConfig
>('user/registerUser', async (data, thunkAPI) => {
  const body = await userRegisterSchema.validate(
    {
      ...data,
      fecha_nacimiento: data.fecha_nacimiento.toISOString(),
    },
    {stripUnknown: true, context: {request: true}},
  );

  const formData = new FormData();

  formData.append('name', body.name);
  formData.append('lastname', body.lastname);
  formData.append('password', body.password);
  formData.append('email', body.email);

  if (body.id_sexo) formData.append('id_sexo', body.id_sexo.toString());

  if (body.nick) formData.append('nick', body.nick);
  if (body.fecha_nacimiento)
    formData.append('fecha_nacimiento', body.fecha_nacimiento);

  if (body.dni) formData.append('dni', body.dni);

  if (data.fotos.foto_perfil && data.fotos.foto_perfil instanceof File)
    formData.append('foto_perfil', data.fotos.foto_perfil);

  if (
    data.fotos.foto_frontal_identificacion &&
    data.fotos.foto_frontal_identificacion instanceof File
  )
    formData.append(
      'foto_frontal_identificacion',
      data.fotos.foto_frontal_identificacion,
      data.fotos.foto_frontal_identificacion.name,
    );

  if (
    data.fotos.foto_trasera_identificacion &&
    data.fotos.foto_trasera_identificacion instanceof File
  )
    formData.append(
      'foto_trasera_identificacion',
      data.fotos.foto_trasera_identificacion,
      data.fotos.foto_trasera_identificacion.name,
    );

  const login = {
    email: body.email,
    password: body.password,
  };

  const registerResponse = await api.postRegister(formData);
  if (!registerResponse)
    return thunkAPI.rejectWithValue('error al registrar el usuario');
  const loginResponse = await api.postLogin(login);
  if (!loginResponse) return thunkAPI.rejectWithValue('error al loguearse');
  const tokenResponse = await api.postToken(login);
  if (!tokenResponse) return thunkAPI.rejectWithValue('error al obtener token');
  return {loginResponse, tokenResponse} as authUserData;
});

export const validateEmailUser = createAsyncThunk<null, string, thunkAPiConfig>(
  'user/validateEmailUser',
  async (data, thunkAPI) => {
    const {
      userSlice: {userDetail},
    } = thunkAPI.getState();

    if (!userDetail) return thunkAPI.rejectWithValue('usuario no logueado');
    const validateResponse = await api.getEmailValidate(data, userDetail.email);
    if (!validateResponse)
      return thunkAPI.rejectWithValue('error al validar el email');
    thunkAPI.dispatch(refreshUser());
    return null;
  },
);

export const sendEmailPing = createAsyncThunk<null, string, thunkAPiConfig>(
  'user/sendEmailPing',
  async (data, thunkAPI) => {
    const response = await api.getResendEmailValidateCode(data);

    if (!response)
      return thunkAPI.rejectWithValue('error al reenviar el email');
    msgSuccess(`Se ha enviado el ping al correo: ${data}`);
    return null;
  },
);

export const validateRecoverPassword = createAsyncThunk<
  null,
  {password: string; pin: string},
  thunkAPiConfig
>('user/validateRecoverPassword', async (data, thunkAPI) => {
  const {
    userSlice: {
      editable: {login},
    },
  } = thunkAPI.getState();

  if (!login.email) return thunkAPI.rejectWithValue('invalid email');

  const body = {
    email: login.email,
    password: data.password,
    pin: data.pin,
  };

  const response = await api.postRecoverPassword(body);

  if (!response) return thunkAPI.rejectWithValue('error al verificar el pin');
  thunkAPI.dispatch(actualizarLogin({password: data.password}));
  await thunkAPI.dispatch(loginUser());
  return null;
});

export const updateUser = createAsyncThunk<null, undefined, thunkAPiConfig>(
  'user/updateUser',
  async (data, thunkAPI) => {
    const {
      userSlice: {
        editable: {user: tempUser, userImages},
        userDetail,
      },
    } = thunkAPI.getState();
    if (!userDetail) return thunkAPI.rejectWithValue('usuario no logueado');

    if (!tempUser.name || !tempUser.lastname || !tempUser.fecha_nacimiento)
      return thunkAPI.rejectWithValue('algun valor obligatorio es nulo.');

    const user = tempUser as userStoreTypePrimary;

    if (userImages.foto_perfil instanceof File) {
      const fotoPerfil = userDetail.imagenes.find(
        element => element.tipo_imagen === 'foto_perfil',
      );
      let urltransform: string | undefined;
      const formData = new FormData();
      if (fotoPerfil) {
        formData.append('id_registro', fotoPerfil.id_imagen.toString());
        urltransform = fotoPerfil.id_imagen.toString();
        formData.append('tipo_imagen', 'foto_perfil');
        formData.append('_method', 'PUT');
      } else {
        formData.append('id_registro', userDetail.id.toString());
        formData.append('tipo_imagen', 'foto_perfil');
      }

      formData.append('modelo', 'User');

      formData.append(
        'imagen',
        userImages.foto_perfil,
        userImages.foto_perfil.name,
      );

      await api.postImage(formData, urltransform);
    }

    if (userImages.foto_frontal_identificacion instanceof File) {
      const fotoFrontal = userDetail.imagenes.find(
        element => element.tipo_imagen === 'foto_frontal_identificacion',
      );
      let urltransform: string | undefined;
      const formData = new FormData();
      if (fotoFrontal) {
        formData.append('id_registro', fotoFrontal.id_imagen.toString());
        urltransform = fotoFrontal.id_imagen.toString();
        formData.append('tipo_imagen', 'foto_frontal_identificacion');
        formData.append('_method', 'PUT');
      } else {
        formData.append('id_registro', userDetail.id.toString());
        formData.append('tipo_imagen', 'foto_frontal_identificacion');
      }

      formData.append('modelo', 'User');

      formData.append(
        'imagen',
        userImages.foto_frontal_identificacion,
        userImages.foto_frontal_identificacion.name,
      );

      await api.postImage(formData, urltransform);
    }

    if (userImages.foto_trasera_identificacion instanceof File) {
      const fotoTrasera = userDetail.imagenes.find(
        element => element.tipo_imagen === 'foto_trasera_identificacion',
      );
      let urltransform: string | undefined;
      const formData = new FormData();
      if (fotoTrasera) {
        formData.append('id_registro', fotoTrasera.id_imagen.toString());
        urltransform = fotoTrasera.id_imagen.toString();
        formData.append('tipo_imagen', 'foto_trasera_identificacion');
        formData.append('_method', 'PUT');
      } else {
        formData.append('id_registro', userDetail.id.toString());
        formData.append('tipo_imagen', 'foto_trasera_identificacion');
      }

      formData.append('modelo', 'User');

      formData.append(
        'imagen',
        userImages.foto_trasera_identificacion,
        userImages.foto_trasera_identificacion.name,
      );

      await api.postImage(formData, urltransform);
    }

    const response = await api.putUser(userDetail.id, {
      ...user,
      dni: user.dni === userDetail.dni ? undefined : user.dni,
      nick: user.nick === userDetail.nick ? undefined : user.nick,
    });
    if (!response)
      return thunkAPI.rejectWithValue('error al actualizar usuario');

    msgSuccess('Usuario actualizado con éxito');
    thunkAPI.dispatch(refreshUser());
    return null;
  },
);

export const updatePasswordUser = createAsyncThunk<
  null,
  string,
  thunkAPiConfig
>('user/updatePassword', async (data, thunkAPI) => {
  const response = await api.postPassword(data);
  if (!response)
    return thunkAPI.rejectWithValue('error al actualizar password');
  thunkAPI.dispatch(refreshUser());
  msgSuccess(`Contraseña actualizada con éxito.`);
  return null;
});

export const acceptInvitationUser = createAsyncThunk<
  null,
  acceptInvitationUserThunkPayload,
  thunkAPiConfig
>('user/acceptInvitationUser', async (data, thunkAPI) => {
  const invitationResponse = await api.postInvitacion(data.code, data.accion);
  if (!invitationResponse) return thunkAPI.rejectWithValue('error al aceptar');
  thunkAPI.dispatch(refreshUser());
  return null;
});

export const deleteNotificationMesa = createAsyncThunk<null, number>(
  'user/deleteNotificationMesa',
  async (arg, thunkAPI) => {
    await api.deleteNotificationMesa(arg);
    return null;
  },
);

export const cargarUser = createAsyncThunk<
  cargarUserPayload,
  undefined,
  thunkAPiConfig
>('user/cargarUser', async (data, thunkAPI) => {
  const {
    userSlice: {userDetail},
  } = thunkAPI.getState();
  if (!userDetail) return thunkAPI.rejectWithValue('usuario no logueado');
  const user: userStoreTypePrimary = {
    dni: userDetail.dni,
    fecha_nacimiento: new Date(userDetail.fecha_nacimiento),
    id_sexo: userDetail.id_sexo,
    lastname: userDetail.lastname,
    name: userDetail.name,
    nick: userDetail.nick,
  };
  const images: userImagesType = {
    foto_perfil:
      userDetail.imagenes.find(
        element => element.tipo_imagen === 'foto_perfil',
      ) || null,
    foto_frontal_identificacion:
      userDetail.imagenes.find(
        element => element.tipo_imagen === 'foto_frontal_identificacion',
      ) || null,

    foto_trasera_identificacion:
      userDetail.imagenes.find(
        element => element.tipo_imagen === 'foto_trasera_identificacion',
      ) || null,
  };
  return {user, images};
});

export const Logout = createAsyncThunk<null, undefined, thunkAPiConfig>(
  'user/Logout',
  async (data, thunkAPI) => {
    resetReduxStore();
    return null;
  },
);
