import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {menuSliceInitState} from '.';
import {menuSliceType} from './menu.types';

const seleccionarCategoria = (
  state: Draft<menuSliceType>,
  action: PayloadAction<number | null>,
) => {
  if (action.payload === null) {
    state.selectedCategory = null;
    return;
  }
  const categoria = state.categorias.find(
    element => element.id_categoria_producto === action.payload,
  );
  if (!categoria) return;
  state.selectedCategory = categoria;
};

const seleccionarSubcategoria = (
  state: Draft<menuSliceType>,
  action: PayloadAction<number | null>,
) => {
  if (action.payload === null) {
    state.selectedSubcategory = null;
    return;
  }
  const subcategory = state.subcategorias.find(
    element => element.id_subcategoria_producto === action.payload,
  );
  if (!subcategory) return;
  state.selectedSubcategory = subcategory;
};

const ResetMenuSlice = (state: Draft<menuSliceType>) => {
  return menuSliceInitState;
};

export const actions = {
  seleccionarCategoria,
  seleccionarSubcategoria,
  ResetMenuSlice,
};
