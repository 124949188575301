import {Stack, TextField} from '@mui/material';
import {useState} from 'react';
import {useAppSelector} from '../../redux/store';

import NumericTextField from '../form-input/controlled-numeric-text-field';
import TitleText from '../title-text/title-text';

export default function MiscOrdenCambio() {
  const [value, setValue] = useState<undefined | number>();
  const total = useAppSelector(store => store.ordenSlice.cuenta.total);
  const propinasTotal = useAppSelector(
    store => store.ordenSlice.cuenta.propinasTotal,
  );

  const hasEfectivo = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado?.metodosPago,
  )?.metodos.some(element => element.metodoPago.metodo_pago_id === 3);

  if (!hasEfectivo) return null;
  if (!total) return null;

  const calc = (value ?? 0) - (total + propinasTotal);
  return (
    <Stack sx={{height: '30px'}}>
      <TitleText text="Calcular cambio" />
      <Stack direction="row">
        <NumericTextField
          label="Monto"
          sx={{width: '10rem'}}
          value={value}
          onChange={setValue}
        />
        <TextField
          label={calc < 0 ? 'Pendiente' : 'Cambio'}
          InputProps={{readOnly: true}}
          focused={false}
          value={calc.toFixed(2)}
          sx={{width: '10rem'}}
        />
      </Stack>
    </Stack>
  );
}
