import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Pagetemplate from '../../../layouts/page-template/Pagetemplate';
import {
  setSelectedRestaurante,
  setSelectedSucursal,
} from '../../../redux/slices/reporte-restaurante-slice';
import {getReporteDeVentasRestaurante} from '../../../redux/slices/reporte-restaurante-slice/reportes.thunks';
import {startLoading, stopLoading} from '../../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import RestaurantesTable from './data-table';

const Restaurantes = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      await dispatch(getReporteDeVentasRestaurante());
      dispatch(stopLoading());
    };
    fetchData();
    dispatch(setSelectedRestaurante(null));
    dispatch(setSelectedSucursal(null));
  }, [location.pathname]);
  return (
    <Pagetemplate
      isLoading={isLoading}
      title="Listado de restaurantes || IFUDIS"
      soloLayout>
      <RestaurantesTable />
    </Pagetemplate>
  );
};

export default Restaurantes;
