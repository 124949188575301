import {LoadingButton} from '@mui/lab';
import {Box, useMediaQuery} from '@mui/material';
import {useState} from 'react';
import {productMovement} from '../../redux/slices/orden-slice/orden.thunks';
import {
  carritoType,
  clienteStoreTypePrimary,
} from '../../redux/slices/orden-slice/orden.types';
import {useAppDispatch, useAppSelector} from '../../redux/store';

import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import ListProductosCheckbox from './list-productos-checkbox';
import ListProductosClienteSeleccionado from './list-productos-cliente-seleccionado';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

type PropsTypes = {
  handleClose: () => void;
};

export type carritoMovementType = {
  moveCant?: number;
} & carritoType;

const MoverCuentaModal = ({handleClose}: PropsTypes) => {
  const dispatch = useAppDispatch();
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );
  const clientes = useAppSelector(store => store.ordenSlice.orden.clientes);
  const [firstClient, setFirstClient] =
    useState<clienteStoreTypePrimary | null>(clienteSeleccionado);

  const [secondClient, setSecondClient] =
    useState<clienteStoreTypePrimary | null>(
      clientes.find(cliente => cliente.id !== firstClient?.id) ?? null,
    );

  const [selectedProducts, setSelectedProducts] = useState<
    carritoMovementType[]
  >([]);

  const [loading, setloading] = useState(false);

  const sm = useMediaQuery('(max-width:550px)');

  return (
    <DialogPopUp
      open={true}
      handleClose={handleClose}
      title="Mover productos"
      fullScreen={sm}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: '100%',
          position: 'relative',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}>
          <ListProductosCheckbox
            client={firstClient}
            setClient={setFirstClient}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <ArrowRightAltIcon
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '48px',
            }}
          />
          <ListProductosClienteSeleccionado
            client={secondClient}
            setClient={setSecondClient}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            mt: 5,
            justifyContent: 'center',
            display: 'flex',
          }}>
          <LoadingButton
            loading={loading}
            size="large"
            disabled={
              !firstClient ||
              !secondClient ||
              selectedProducts.length < 1 ||
              firstClient.id == secondClient.id
            }
            type="submit"
            onClick={async () => {
              if (!firstClient || !secondClient) return;
              setloading(true);
              await dispatch(
                productMovement({
                  firstClient,
                  secondClient,
                  products: selectedProducts,
                }),
              );
              setloading(false);
              handleClose();
            }}
            variant="contained"
            color="secondary">
            Guardar
          </LoadingButton>
        </Box>
      </Box>
    </DialogPopUp>
  );
};

export default MoverCuentaModal;
