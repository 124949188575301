import {Box, Stack, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {formatDateTime, getTimer} from '../../common/formatTime';

type PropsTypes = {
  cuenta?: number;
  date: Date;
  meseroName?: string;
  numeroMesa?: number;
  time: string;
};

const ComandaDetails = ({cuenta, date, meseroName, time}: PropsTypes) => {
  const isHistorial = useLocation().pathname === '/historial-comandas';
  const message = isHistorial
    ? time === '00:00:00'
      ? 'Tiempo abierta: PENDIENTE'
      : `Tiempo abierta: ${time}`
    : '';
  return (
    <Box
      sx={{
        px: 1.5,
        py: 0.25,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Stack
        flexDirection={{xs: 'column', md: 'row'}}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
        }}>
        <Stack
          flexDirection="column"
          sx={{
            width: {
              xs: '100%',
              md: '60%',
            },
          }}>
          <Typography
            noWrap
            sx={{
              fontSize: '0.8rem',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}>
            CUENTA: #{cuenta}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: '0.8rem',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}>
            MESERO: {meseroName}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: isHistorial ? '0.8rem' : '1.1rem',
              fontWeight: 'bold',
              color: 'black',
              textTransform: 'uppercase',
            }}>
            {message}
          </Typography>
        </Stack>
        <Box sx={{flexGrow: 1}} />
        <Typography
          noWrap
          sx={{
            fontSize: isHistorial ? '0.8rem' : '1.1rem',
            fontWeight: 'bold',
            color: 'red',
            textTransform: 'uppercase',
          }}>
          {isHistorial ? formatDateTime(date) : getTimer(date)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ComandaDetails;
