import {
  Box,
  Card,
  CardContent,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import {ChangeEvent} from 'react';
import TableHelper from './table-helper';

type PropsTypes = {
  title?: string;
  children: React.ReactNode;
  setOpenCrear?: (arg: boolean) => void;
  onSearch?: (row: any, searchTerm: string) => any;
  searchTerm: string;
  totalRows: number;
  handleOnSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  noCard: boolean;
};

const TableWrapper = ({
  title,
  children,
  setOpenCrear,
  onSearch,
  searchTerm,
  totalRows,
  handleOnSearch,
  noCard,
}: PropsTypes) => {
  return (
    <Box
      component={noCard ? 'div' : Card}
      raised={noCard ? false : true}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        p: 2,
        my: 0,
        width: '100%',
      }}>
      <Box
        component={CardContent}
        sx={{
          border: '0.5px solid #c3c3c3c3',
          borderRadius: '15px',
          width: '100%',
          position: 'relative',
        }}>
        {title && (
          <Typography
            sx={{
              position: 'absolute',
              top: -12,
              left: 15,
              backgroundColor: 'white',
              px: 3,
              color: 'gray',
            }}>
            {title}
          </Typography>
        )}
        <TableHelper
          setOpenCrear={setOpenCrear}
          title={title}
          onSearch={onSearch}
          searchTerm={searchTerm}
          totalRows={totalRows}
          handleOnSearch={handleOnSearch}
        />
        <TableContainer sx={{mt: 3}}>
          <Table size="small" aria-label="data table component">
            {children}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TableWrapper;
