import {TableCell, TableRow, TableSortLabel} from '@mui/material';

export const TopRow = () => {
  return (
    <TableRow>
      {/* <TableCell
        sx={{
          backgroundColor: '#c3c3c3',
          border: 0,
        }}/> */}
      <TableCell sx={{backgroundColor: '#c3c3c3', border: 0}}></TableCell>
      <TableCell
        sx={{
          backgroundColor: 'gray',
          color: 'white',
          border: 0,
        }}
        align="center"
        colSpan={1}>
        Mes anterior
      </TableCell>
      <TableCell
        sx={{backgroundColor: '#000', color: 'white', border: 0}}
        align="center"
        colSpan={1}>
        Mes corriente
      </TableCell>
      {/* <TableCell
        colSpan={2}
        align="center"
        sx={{backgroundColor: '#c3c3c3', border: 0}}>
        Acuerdo
      </TableCell> */}
      <TableCell sx={{backgroundColor: '#c3c3c3', border: 0}} />
    </TableRow>
  );
};

export const BottomRow = () => {
  return (
    <TableRow>
      <TableCell align="left" sx={{backgroundColor: '#c3c3c3', border: 0}}>
        <TableSortLabel active={false} direction="asc" onClick={() => {}}>
          Nombre
        </TableSortLabel>
      </TableCell>
      {/* <TableCell align="left" sx={{backgroundColor: '#c3c3c3', border: 0}}>
        <TableSortLabel active={false} direction="asc" onClick={() => {}}>
          Sucursal
        </TableSortLabel>
      </TableCell> */}
      <TableCell
        align="center"
        sx={{backgroundColor: 'gray', color: 'white', border: 0}}>
        <TableSortLabel
          sx={{
            '&:hover': {
              color: '#fff',
            },
          }}
          active={false}
          direction="asc"
          onClick={() => {}}>
          Venta
        </TableSortLabel>
      </TableCell>
      {/* <TableCell
        align="center"
        sx={{backgroundColor: 'gray', color: 'white', border: 0}}>
        <TableSortLabel
          sx={{
            '&:hover': {
              color: '#fff',
            },
          }}
          active={false}
          direction="asc"
          onClick={() => {}}>
          Factura
        </TableSortLabel>
      </TableCell> */}
      <TableCell
        align="center"
        sx={{backgroundColor: '#000', color: 'white', border: 0}}>
        <TableSortLabel
          sx={{
            '&:hover': {
              color: '#fff',
            },
          }}
          active={false}
          direction="asc"
          onClick={() => {}}>
          Venta
        </TableSortLabel>
      </TableCell>
      {/* <TableCell
        align="center"
        sx={{backgroundColor: '#000', color: 'white', border: 0}}>
        <TableSortLabel
          sx={{
            '&:hover': {
              color: '#fff',
            },
          }}
          active={false}
          direction="asc"
          onClick={() => {}}>
          Prox. Factura
        </TableSortLabel>
      </TableCell> */}
      {/* <TableCell align="center" sx={{backgroundColor: '#c3c3c3', border: 0}}>
        <TableSortLabel active={false} direction="asc" onClick={() => {}}>
          $ Fijo (USD)
        </TableSortLabel>
      </TableCell>
      <TableCell align="center" sx={{backgroundColor: '#c3c3c3', border: 0}}>
        <TableSortLabel active={false} direction="asc" onClick={() => {}}>
          %
        </TableSortLabel>
      </TableCell>
      <TableCell sx={{border: 0}}></TableCell> */}
      <TableCell sx={{backgroundColor: '#c3c3c3', border: 0}} />
    </TableRow>
  );
};
