import {createSlice} from '@reduxjs/toolkit';
import isBoolean from '../../../common/isBoolean';
import {actions} from './restaurante.actions';
import {restauranteSliceType} from './restaurante.types';
import {
  cargarRestaurante,
  cargarSucursal,
  checkCajaEmpleadoUser,
  fetchRestauranteAndSucursal,
  getRestaurantes,
  getSucursales,
  getTiposDeComida,
  putPostRestaurante,
} from './restaurente.thunks';

//initialState

export const initialRestaurante = {
  avisoDeOperacion: null,
  direccion: null,
  email: null,
  id: null,
  identificacionDelantera: null,
  identificacionTrasera: null,
  logo: null,
  nick: null,
  nombre: null,
  numSucur: null,
  pisOfic: null,
  razonSocial: null,
  ruc: null,
  slogan: null,
  telefono: null,
  tiposDeComida: [],
};

export const initialSucursalState = {
  descripcion_horario: null,
  descripcion_ubicacion: null,
  estado_sucursal: null,
  latitud: null,
  longitud: null,
  sucursal_id: null,
  restaurante_id: null,
  cobra_impuesto: 0,
  ruc_sucursal: null,
};

export const initialState = {
  restauranteEmpleados: [],
  cajaEmpleado: null,
  editable: {
    sucursal: initialSucursalState,
    restaurante: initialRestaurante,
    restauranteCopy: initialRestaurante,
  },
  sucursales: [],
  restauranteEmpleadoSeleccionado: null,
  empleadoAsignadoSucursalSeleccionado: null,
  sucursalesEmpleadoAsignado: [],
  restauranteSeleccionado: null,
  sucursalSeleccionada: null,

  tiposDeComida: [],
  cargoEmpleado: null,
} as restauranteSliceType;

//slice

export const restauranteSlice = createSlice({
  name: 'restaurante-slice',
  initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(checkCajaEmpleadoUser.fulfilled, (state, action) => {
        state.cajaEmpleado = action.payload;
      })
      .addCase(getRestaurantes.fulfilled, (state, action) => {
        state.restauranteEmpleados = action.payload;
      })
      .addCase(fetchRestauranteAndSucursal.fulfilled, (state, action) => {
        state.restauranteSeleccionado = action.payload.restaurante;
        state.sucursalSeleccionada = action.payload.sucursal;
      })
      .addCase(putPostRestaurante.fulfilled, (state, action) => {})
      .addCase(getSucursales.fulfilled, (state, action) => {
        state.sucursales = action.payload.map(sucursal => ({
          ...sucursal,
          estado_sucursal: isBoolean(sucursal.estado_sucursal),
        }));
      })
      .addCase(getTiposDeComida.fulfilled, (state, action) => {
        state.tiposDeComida = action.payload;
      })
      .addCase(cargarRestaurante.fulfilled, (state, action) => {
        state.editable.restaurante.email =
          action.payload.correo_electronico_negocio;
        state.editable.restaurante.id = action.payload.id_restaurante;
        state.editable.restaurante.logo = action.payload.imagenes.find(
          element => element.tipo_imagen === 'logo',
        )!;
        state.editable.restaurante.identificacionDelantera =
          action.payload.imagenes.find(
            element =>
              element.tipo_imagen ===
              'foto_frontal_identificacion_representante_legal',
          )!;

        state.editable.restaurante.identificacionTrasera =
          action.payload.imagenes.find(
            element =>
              element.tipo_imagen ===
              'foto_trasera_identificacion_representante_legal',
          )!;

        state.editable.restaurante.avisoDeOperacion =
          action.payload.imagenes.find(
            element => element.tipo_imagen === 'aviso_operaciones_restaurante',
          )!;

        state.editable.restaurante.direccion =
          action.payload.direccion_restaurante;
        state.editable.restaurante.nombre = action.payload.nombre;
        state.editable.restaurante.nick = action.payload.nick;
        state.editable.restaurante.numSucur = action.payload.numero_sucursales;
        state.editable.restaurante.pisOfic = action.payload.piso_oficina;
        state.editable.restaurante.razonSocial = action.payload.razon_social;
        state.editable.restaurante.ruc = action.payload.ruc;
        state.editable.restaurante.slogan = action.payload.slogan;
        state.editable.restaurante.telefono =
          action.payload.numero_telefono_movil;
        state.editable.restaurante.tiposDeComida =
          action.payload.tipo_comida.map(element => element.nombre_tipo_comida);

        state.editable.restauranteCopy = state.editable.restaurante;
      })
      .addCase(cargarSucursal.fulfilled, (state, action) => {
        state.editable.sucursal.descripcion_horario =
          action.payload.descripcion_horario;
        state.editable.sucursal.descripcion_ubicacion =
          action.payload.descripcion_ubicacion;
        state.editable.sucursal.estado_sucursal =
          action.payload.estado_sucursal;
        state.editable.sucursal.latitud = action.payload.latitud;
        state.editable.sucursal.longitud = action.payload.longitud;
        state.editable.sucursal.sucursal_id = action.payload.id_sucursal;
        state.editable.sucursal.restaurante_id = action.payload.restaurante;
        state.editable.sucursal.cobra_impuesto = action.payload.cobra_impuesto;
        state.editable.sucursal.ruc_sucursal = action.payload.ruc_sucursal;
      });
  },
});

export const {
  seleccionarRestaurante,
  seleccionarEmpleadoAsignadoSucursal,
  actualizarRestaurante,
  actualizarSucursal,
  resetSucursal,
  resetRestaurante,
  ResetRestauranteSlice,
  actualizarUserSliceData,
} = restauranteSlice.actions;

export default restauranteSlice.reducer;
