import {Grid, Stack} from '@mui/material';
import ComprasBottomBar from '../../components/bottom-bar/compras-bottom-bar';
import BottomBar from '../../components/bottom-bar/bottom-bar';
import FilterButton from '../../components/filter-button/filter-button';
import {useCallback, useEffect, useState} from 'react';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import ComprasForm from '../../components/compras-form/compras-form';
import {
  getComprasSucursal,
  getEstadoDeCompra,
  getProveedores,
} from '../../redux/slices/compras-slice/compras.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {filterCompras, selectCategoria} from '../../redux/slices/compras-slice';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {
  fetchCategoriaInsumo,
  fetchInsumoSucursal,
} from '../../redux/slices/insumo-slice/insumo.thunks';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import CrudTable from '../../components/crud-table';
import sortArrayObject from '../../common/sortArrayObject';
import getNestedValue from '../../common/getNestedValue';
import {useCrudTable} from '../../common/hooks/use-crud-table';

function Compras() {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('nombre_proveedor');
  const [arrayKeys, setArrayKeys] = useState<string[]>(['nombre_proveedor']);
  const {editId, handleClose, handleOpen, isOpen, setEditId, setOpen} =
    useCrudTable();
  const [proveedorId, setproveedorId] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const proveedores = useAppSelector(store => store.comprasSlice.proveedores);
  const comprasSucursal = useAppSelector(
    store => store.comprasSlice.comprasSucursal,
  );

  const categoriaSeleccionada = useAppSelector(
    store => store.comprasSlice.selectedCategoriaInsumo,
  );
  const filteredProveedores = useAppSelector(
    store => store.comprasSlice.filteredProveedores,
  );
  const categorias = useAppSelector(
    store => store.insumoSlice.categoriaInsumos,
  );
  const selectedCategoria = useAppSelector(
    store => store.comprasSlice.selectedCategoriaInsumo,
  );

  useEffect(() => {
    dispatch(filterCompras());
  }, [selectedCategoria, comprasSucursal, proveedores]);

  const isLoading = useAppSelector(store => store.uiSlice.isLoading);

  const fetchScreenData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(getEstadoDeCompra());
    await dispatch(getProveedores());
    await dispatch(fetchCategoriaInsumo());
    await dispatch(getComprasSucursal());
    await dispatch(fetchInsumoSucursal());
    dispatch(stopLoading());
  }, []);

  useEffect(() => {
    fetchScreenData();
  }, []);

  const columns = [
    {
      label: 'Proveedor',
      key: ['nombre_proveedor'],
    },
    {
      label: 'Compras',
      key: [''],
    },
    {
      label: 'Compra total(USD)',
      key: ['monto_compra'],
    },
    {
      label: '',
      key: ['id_proveedor'],
    },
  ];

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  const handleOnSearch = useCallback(
    (row: any, searchTerm: string) =>
      getNestedValue(row, ['nombre_proveedor'])
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    [],
  );

  const customHandleClose = () => {
    handleClose();
    setproveedorId(null);
  };

  return (
    <Pagetemplate isLoading={isLoading} title="Insumos || IFUDIS" soloLayout>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1} flexGrow={1}>
              {categorias.map(element => (
                <FilterButton
                  value={element.id_categoria_insumo}
                  key={element.id_categoria_insumo}
                  label={element.nombre_categoria_insumo}
                  onClick={(value: any) => dispatch(selectCategoria(value))}
                  isselected={selectedCategoria === element.id_categoria_insumo}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12} item>
          <CrudTable
            title="Compras"
            rows={[...filteredProveedores].sort((a, b) =>
              sortArrayObject(a, b, arrayKeys, order),
            )}
            columns={columns}
            onEditCompra={id_compra => {
              handleOpen(id_compra);
            }}
            onAddCompra={id_proveedor => {
              setproveedorId(id_proveedor);
              handleOpen();
            }}
            onNewCompra={() => handleOpen()}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
            columnBlacklisted={['Compras', 'Compra total(USD)']}
            onSearch={handleOnSearch}
          />
          <BottomBar>
            <ComprasBottomBar />
          </BottomBar>
        </Grid>
      </Grid>

      <DialogPopUp
        open={isOpen}
        handleClose={customHandleClose}
        title="Editar esta compra.">
        <ComprasForm
          handleClose={customHandleClose}
          defaultValues={comprasSucursal.find(
            element => element.id_compra_sucursal === editId,
          )}
          categoriaInsumoId={categoriaSeleccionada ?? undefined}
          proveedorId={proveedorId ?? undefined}
        />
      </DialogPopUp>
    </Pagetemplate>
  );
}

export default Compras;
