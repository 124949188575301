import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  ListItem,
  Typography,
} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  seleccionarCategoria,
  seleccionarSubcategoria,
} from '../../redux/slices/menu-slice';
import sortItBro from '../../common/sortItBro';
import BorderedBoxMenu from './bordered-box-menu';
import getNestedValue from '../../common/getNestedValue';

type propsTypes = {
  title: string;
  valueKey: string[] | string;
  idKey: string[] | string;

  showPorcentaje?: boolean;
  elements: any;
  onClickSelected?: (id: number) => void;
  selectedKey?: number;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  onSwitch?: (id: number) => void;
  onOpenModalCrear: (arg: boolean) => void;
};

function BorderedBox({
  title,
  valueKey,
  idKey,
  elements,
  onClickSelected,
  showPorcentaje = false,
  selectedKey,
  onDelete,
  onEdit,
  onSwitch,
  onOpenModalCrear,
}: propsTypes) {
  return (
    <Card
      raised
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        p: 2,
        my: 2,
      }}>
      <CardContent
        sx={{
          border: '0.5px solid #c3c3c3',
          borderRadius: '15px',
          width: '100%',
          position: 'relative',
        }}>
        <Typography
          sx={{
            position: 'absolute',
            top: -12,
            left: 15,
            backgroundColor: 'white',
            px: 3,
            color: 'gray',
          }}>
          {title}
        </Typography>
        <ListItem
          component={TransitionGroup}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}>
          {elements.map((value: any, index: number) => {
            const idKeyValue =
              idKey instanceof Array
                ? getNestedValue(value, idKey)
                : value[idKey];
            const valueKeyvalue =
              valueKey instanceof Array
                ? getNestedValue(value, valueKey)
                : value[valueKey];

            let subtitle = '';

            if (showPorcentaje) {
              subtitle = `(${value['porcentaje']}%)`;
              if (value?.tipo_pago_descuento_id === 8) {
                subtitle = `($${value['cantidad']})`;
              }
            }

            return (
              <Collapse key={index}>
                <Box
                  onClick={() => {
                    if (onClickSelected) return onClickSelected(idKeyValue);
                  }}
                  sx={{
                    cursor: onClickSelected ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'all 0.2s ease-in-out',
                    backgroundColor:
                      selectedKey === idKeyValue ? '#f59823' : '#e1e1e1',
                    color: selectedKey === idKeyValue ? 'white' : 'black',
                    borderRadius: '15px',
                    px: 2,
                    mr: 1,
                    mb: 1,
                  }}>
                  <Typography
                    sx={{mr: 1, fontWeight: 'bold', fontSize: '0.9rem'}}>
                    {`${valueKeyvalue} ${subtitle}`}
                  </Typography>
                  <BorderedBoxMenu
                    idKey={idKeyValue}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onSwitch={onSwitch}
                  />
                </Box>
              </Collapse>
            );
          })}
        </ListItem>
        <Button
          onClick={() => onOpenModalCrear(true)}
          fullWidth
          sx={{
            mt: 2,
            mr: 2,
            width: '220px',
            fontWeight: 'bold',
          }}
          variant="contained"
          startIcon={<AddIcon />}>
          {`Agregar ${title}`}
        </Button>
      </CardContent>
    </Card>
  );
}

export const CategoriesBorderedBox = () => {
  const dispatch = useAppDispatch();
  const categoria = useAppSelector(store => store.menuSlice.categorias);

  const selectedCategory = useAppSelector(
    store => store.menuSlice.selectedCategory?.id_categoria_producto,
  );
  return (
    <Card
      raised
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        p: 2,
        my: 2,
      }}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          border: '0.5px solid #c3c3c3',
          borderRadius: '15px',
          width: '100%',
        }}>
        <Box
          onClick={() => {
            dispatch(seleccionarCategoria(null));
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor:
              selectedCategory !== undefined ? '#e1e1e1' : '#f59823',
            color: selectedCategory !== undefined ? 'black' : 'white',
            borderRadius: '15px',
            px: 2,
            mr: 1,
            mb: 1,
            height: 60,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#c2c2c2',
              color: 'black',
            },
          }}>
          <Typography sx={{mr: 1, fontWeight: 'bold', fontSize: '0.9rem'}}>
            TODOS
          </Typography>
        </Box>
        {[...categoria]
          .sort((a, b) =>
            sortItBro(a.nombre_categoria, b.nombre_categoria, 'desc'),
          )
          .map(value => (
            <Box
              key={value.id_categoria_producto}
              onClick={() => {
                dispatch(seleccionarCategoria(value.id_categoria_producto));
                dispatch(seleccionarSubcategoria(null));
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor:
                  selectedCategory !== value.id_categoria_producto
                    ? '#e1e1e1'
                    : '#f59823',
                color:
                  selectedCategory !== value.id_categoria_producto
                    ? 'black'
                    : 'white',
                borderRadius: '15px',
                px: 2,
                mr: 1,
                mb: 1,
                height: 60,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#c2c2c2',
                  color: 'black',
                },
              }}>
              <Typography sx={{mr: 1, fontWeight: 'bold', fontSize: '0.9rem'}}>
                {value.nombre_categoria}
              </Typography>
            </Box>
          ))}
      </CardContent>
    </Card>
  );
};

export const InsumosCategoriesBorderedBox = () => {
  const dispatch = useAppDispatch();
  const categoria = useAppSelector(store => store.menuSlice.categorias);

  const selectedCategory = useAppSelector(
    store => store.menuSlice.selectedCategory?.id_categoria_producto,
  );
  return (
    <Card
      raised
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        p: 2,
        my: 2,
      }}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          border: '0.5px solid #c3c3c3',
          borderRadius: '15px',
          width: '100%',
        }}>
        <Box
          onClick={() => dispatch(seleccionarCategoria(null))}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor:
              selectedCategory !== undefined ? '#e1e1e1' : '#f59823',
            color: selectedCategory !== undefined ? 'black' : 'white',
            borderRadius: '15px',
            px: 2,
            mr: 1,
            mb: 1,
            height: 60,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#c2c2c2',
              color: 'black',
            },
          }}>
          <Typography sx={{mr: 1, fontWeight: 'bold', fontSize: '0.9rem'}}>
            TODOS
          </Typography>
        </Box>
        {categoria.map((value, index) => (
          <Box
            key={value.id_categoria_producto}
            onClick={() =>
              dispatch(seleccionarCategoria(value.id_categoria_producto))
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                selectedCategory !== value.id_categoria_producto
                  ? '#e1e1e1'
                  : '#f59823',
              color:
                selectedCategory !== value.id_categoria_producto
                  ? 'black'
                  : 'white',
              borderRadius: '15px',
              px: 2,
              mr: 1,
              mb: 1,
              height: 60,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#c2c2c2',
                color: 'black',
              },
            }}>
            <Typography sx={{mr: 1, fontWeight: 'bold', fontSize: '0.9rem'}}>
              {value.nombre_categoria}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default BorderedBox;
