import {Box, Card} from '@mui/material';
import {ReactNode} from 'react';

type PropsTypes = {
  children: ReactNode;
};

const DetailsLayout = ({children}: PropsTypes) => {
  return (
    <Box
      component={Card}
      raised
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        p: 2,
        width: '100%',
        pb: 8,
      }}>
      {children}
    </Box>
  );
};

export default DetailsLayout;
