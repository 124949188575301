import {Icon, IconifyIcon} from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import slideshowIcon from '@iconify/icons-tabler/slideshow';
import accountCash from '@iconify/icons-mdi/account-cash';
import cashappIcon from '@iconify/icons-cib/cashapp';
import orderIcon from '@iconify/icons-icon-park-outline/order';
import settingsOutline from '@iconify/icons-eva/settings-outline';
import employeeGroupLine from '@iconify/icons-clarity/employee-group-line';
import windowSection from '@iconify/icons-uim/window-section';
import cashRegister from '@iconify/icons-fa-solid/cash-register';
import chartDonutVariant from '@iconify/icons-mdi/chart-donut-variant';
import tableChair from '@iconify/icons-mdi/table-chair';
import bxEdit from '@iconify/icons-bx/bx-edit';
import outlineStore from '@iconify/icons-ic/outline-store';
import materialThree from '@iconify/icons-icon-park-outline/material-three';
import productDownloadable from '@iconify/icons-gridicons/product-downloadable';
import databaseSetting from '@iconify/icons-icon-park-outline/database-setting';
import adProduct from '@iconify/icons-icon-park-outline/ad-product';
import bxCategory from '@iconify/icons-bx/bx-category';
import baselineSoupKitchen from '@iconify/icons-ic/baseline-soup-kitchen';
import categoryIcon from '@iconify/icons-carbon/category';
import baselinePointOfSale from '@iconify/icons-ic/baseline-point-of-sale';
import baselineRestaurant from '@iconify/icons-ic/baseline-restaurant';
import baselineBallot from '@iconify/icons-ic/baseline-ballot';
import barChar from '@iconify/icons-ic/baseline-bar-chart';
import addChart from '@iconify/icons-ic/baseline-add-chart';
import historialComanda from '@iconify/icons-ic/baseline-history-toggle-off';
import foodMenu from '@iconify/icons-bx/food-menu';
import time from '@iconify/icons-bx/time';
import apiextractorIcon from '@iconify/icons-file-icons/apiextractor';
import calendar from '@iconify/icons-ant-design/calendar-outlined';
import contentDeleted from '@iconify/icons-eos-icons/content-deleted';
import fileInvoiceDollar from '@iconify/icons-fa-solid/file-invoice-dollar';
import currencyUsdOff from '@iconify/icons-mdi/currency-usd-off';
import personPinOutlineSharp from '@iconify/icons-material-symbols/person-pin-outline-sharp';
import listIcon from '@iconify/icons-material-symbols/list';
import moneyOffRounded from '@iconify/icons-material-symbols/money-off-rounded';
// ----------------------------------------------------------------------

const getIcon = (name: IconifyIcon) => (
  <Icon icon={name} width={22} height={22} />
);

const sidebarConfig = [
  {
    title: 'Reportes',
    path: '/reportes',
    toolTip: 'tooltip test sdad',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'Balance Actual',
        toolTip: 'tooltip test',
        path: '/reportes/balance-actual',
        icon: getIcon(chartDonutVariant),
      },
      {
        title: 'Facturas Anuladas',
        toolTip: 'tooltip test',
        path: '/reportes/facturas-anuladas',
        icon: getIcon(fileInvoiceDollar),
      },
      {
        title: 'Por artículos',
        toolTip: 'tooltip test',
        path: '/reportes/ventas-articulos',
        icon: getIcon(adProduct),
      },
      {
        title: 'Por artículos borrados',
        toolTip: 'tooltip test',
        path: '/reportes/articulos-borrados',
        icon: getIcon(contentDeleted),
      },
      {
        title: 'Por método de pago',
        toolTip: 'tooltip test',
        path: '/reportes/ventas-metodo-pago',
        icon: getIcon(accountCash),
      },
      {
        title: 'Por categoría',
        toolTip: 'tooltip test',
        path: '/reportes/ventas-categorias',
        icon: getIcon(bxCategory),
      },
      {
        title: 'Por sub-categoria',
        toolTip: 'tooltip test',
        path: '/reportes/ventas-subcategorias',
        icon: getIcon(bxCategory),
      },
      {
        title: 'Por empleado/usuario',
        toolTip: 'tooltip test',
        path: '/reportes/ventas-empleados',
        icon: getIcon(employeeGroupLine),
      },
      {
        title: 'Por horas',
        path: '/reportes/ventas-tiempo/hora',
        toolTip: 'tooltip test',
        icon: getIcon(time),
      },
      {
        title: 'Por dia',
        path: '/reportes/ventas-tiempo/dia',
        toolTip: 'tooltip test',
        icon: getIcon(calendar),
      },
      {
        title: 'Por mesa',
        path: '/reportes/ventas-mesas',
        toolTip: 'tooltip test',
        icon: getIcon(tableChair),
      },
      {
        title: 'Por Sección',
        path: '/reportes/ventas-seccion',
        toolTip: 'tooltip test',
        icon: getIcon(tableChair),
      },
      {
        title: 'Descuentos',
        path: '/reportes/ventas-descuentos',
        toolTip: 'tooltip test',
        icon: getIcon(currencyUsdOff),
      },
      // {
      //   title: 'Descuentos',
      //   path: '/reportes/ventas-descuentos',
      //   toolTip: 'tooltip test',
      //   icon: getIcon(currencyUsdOff),
      // },
      // {
      //   title: 'Cortesias/Invitaciones',
      //   path: '/reportes/ventas-cortesias',
      //   toolTip: 'tooltip test',
      //   icon: getIcon(accountPlus),
      // },
      // {
      //   title: 'Articulos borrados',
      //   path: '/reportes/articulos-borrados',
      //   toolTip: 'tooltip test',
      //   icon: getIcon(contentDeleted),
      // },
    ],
  },
  {
    title: 'Ordenes',
    path: '/orden',
    toolTip: 'tooltip test',
    icon: getIcon(orderIcon),
  },
  {
    title: 'Diario de ventas',
    path: '/diario-ventas',
    toolTip: 'tooltip test',
    icon: getIcon(baselinePointOfSale),
  },
  {
    title: 'Cierres',
    path: '/cierre',
    toolTip: 'tooltip test sdad',
    icon: getIcon(baselineBallot),
    children: [
      {
        title: 'Cierre X',
        toolTip: 'tooltip test',
        path: '/cierre/x',
        icon: getIcon(addChart),
      },
      {
        title: 'Cierre Z',
        toolTip: 'tooltip test',
        path: '/cierre/z',
        icon: getIcon(barChar),
      },
    ],
  },

  {
    title: 'Comandas',
    path: '/comandas',
    toolTip: 'tooltip test',
    icon: getIcon(baselineSoupKitchen),
  },
  {
    title: 'Historial comandas',
    path: '/historial-comandas',
    toolTip: 'tooltip test',
    icon: getIcon(historialComanda),
  },
  {
    title: 'Compras',
    path: '/compras',
    toolTip: 'tooltip test',
    icon: getIcon(outlineStore),
  },
  {
    title: 'Insumos',
    path: '/insumos',
    toolTip: 'tooltip test',
    icon: getIcon(materialThree),
  },
  {
    title: 'Inventario',
    path: '/inventario',
    toolTip: 'tooltip test',
    icon: getIcon(productDownloadable),
  },

  {
    title: 'Menú',
    path: '/menu',
    toolTip: 'tooltip test',
    icon: getIcon(foodMenu),
  },
  {
    title: 'Menú Sucursal',
    path: '/menu-sucursal',
    toolTip: 'tooltip test',
    icon: getIcon(foodMenu),
  },
  {
    title: 'Empleados',
    path: '/empleados',
    toolTip: 'tooltip test',
    icon: getIcon(employeeGroupLine),
  },
  {
    title: 'Caja',
    path: '/caja',
    toolTip: 'tooltip test',
    icon: getIcon(cashRegister),
  },
  {
    title: 'Clientes',
    path: '/clientes',
    toolTip: 'tooltip test',
    icon: getIcon(personPinOutlineSharp),
    children: [
      {
        title: 'Clientes',
        path: '/clientes',
        toolTip: 'tooltip test',
        icon: getIcon(listIcon),
      },
      {
        title: 'Deudas de clientes',
        path: '/deudas_clientes',
        toolTip: 'tooltip test',
        icon: getIcon(moneyOffRounded),
      },
    ],
  },
  {
    title: 'Administración',
    path: '/administracion',
    toolTip: 'tooltip test',
    icon: getIcon(settingsOutline),
    children: [
      {
        title: 'Editar restaurante',
        path: '/administracion/restaurante',
        toolTip: 'tooltip test',
        icon: getIcon(bxEdit),
      },
      {
        title: 'Modificadores',
        path: '/administracion/modificadores',
        toolTip: 'tooltip test',
        icon: getIcon(apiextractorIcon),
      },
      {
        title: 'Mercadeo',
        path: '/administracion/mercadeo',
        toolTip: 'tooltip test',
        icon: getIcon(slideshowIcon),
      },
      {
        title: 'Finanzas',
        path: '/administracion/finanzas',
        toolTip: 'tooltip test',
        icon: getIcon(cashappIcon),
      },
      {
        title: 'Secciones',
        path: '/administracion/seccion',
        toolTip: 'tooltip test',
        icon: getIcon(windowSection),
      },

      {
        title: 'Mesas',
        path: '/administracion/mesas',
        toolTip: 'tooltip test',
        icon: getIcon(tableChair),
      },
    ],
  },

  {
    title: 'Administración ifudis',
    path: '/administracion-ifudis',
    toolTip: 'tooltip test',
    icon: getIcon(databaseSetting),
    children: [
      {
        title: 'Restaurantes',
        path: '/administracion-ifudis/restaurantes',
        toolTip: 'tooltip test',
        icon: getIcon(baselineRestaurant),
      },
      {
        title: 'Proveedores',
        path: '/administracion-ifudis/proveedores',
        toolTip: 'tooltip test',
        icon: getIcon(bxCategory),
      },
      {
        title: 'Categorias de insumos',
        path: '/administracion-ifudis/categoria-insumos',
        toolTip: 'tooltip test',
        icon: getIcon(categoryIcon),
      },
    ],
  },
];

export default sidebarConfig;
