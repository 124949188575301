import {createSlice} from '@reduxjs/toolkit';
import {DBtoDate, formatDateTime} from '../../../common/formatTime';
import {actions} from './cierre.actions';
import {
  cargarCierre,
  deleteReporteCaja,
  getReporteCaja,
  postCierre,
  postCierre2,
} from './cierre.thunks';
import {reporteStateType} from './cierre.types';

export const initialState = {
  efectivoDeclarado: [],
  metodoPagoDeclarado: [],
  efectivoTotal: 0,
  metodoPagoTotal: 0,
  fechaFinal: new Date(),
  fechaInicial: null,
  type: null,
  reporteCaja: [],
  selectedReporteCaja: null,
  observaciones: '',
} as reporteStateType;

export const cierreSlice = createSlice({
  name: 'cierre-slice',
  initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(cargarCierre.fulfilled, (state, action) => {
        state.efectivoDeclarado = action.payload.efectivoDeclarado;
        state.metodoPagoDeclarado = action.payload.metodoPagoDeclarado.sort(
          (a, b) => {
            if (a.nombre_metodo_pago < b.nombre_metodo_pago) {
              return -1;
            }
            if (b.nombre_metodo_pago > a.nombre_metodo_pago) {
              return 1;
            }
            return 0;
          },
        );
      })
      .addCase(postCierre.fulfilled, (state, action) => {})
      .addCase(postCierre2.fulfilled, (state, action) => {})
      .addCase(deleteReporteCaja.fulfilled, (state, action) => {
        state.reporteCaja = action.payload.map(e => ({
          ...e,
          formated_fecha_inicial: formatDateTime(
            DBtoDate(e.fecha_hora_inicial),
          ),
          formated_fecha_final: formatDateTime(DBtoDate(e.fecha_hora_final)),
        }));
      })
      .addCase(getReporteCaja.fulfilled, (state, action) => {
        state.reporteCaja = action.payload.map(e => ({
          ...e,
          formated_fecha_inicial: formatDateTime(
            DBtoDate(e.fecha_hora_inicial),
            true,
          ),
          formated_fecha_final: formatDateTime(
            DBtoDate(e.fecha_hora_final),
            true,
          ),
        }));
      });
  },
});

export const {
  editarEfectivo,
  editarMetodoPago,
  calcularEfectivo,
  calcularMetodoPago,
  setDate,
  resetCierreSLice,
  setCierreType,
  preCargarReporteCaja,
  setObservaciones,
} = cierreSlice.actions;

export default cierreSlice.reducer;
