import {MenuItem, Select, Stack, Typography} from '@mui/material';
import {memo, useEffect, useMemo} from 'react';
import {fCurrency} from '../../common/formatNumber';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {setEstadoFactura} from '../../redux/slices/diario-ventas-slice';
import {SelectChangeEvent} from '@mui/material/Select/SelectInput';
import {getReportePorMetodoPago} from '../../redux/slices/reportes-slice/reportes.thunks';
import {shallowEqual} from 'react-redux';
import {setDate} from '../../redux/slices/reportes-slice';

const HeaderBar = () => {
  const facturas = useAppSelector(store => store.diarioVentasSlice.facturas);
  const estado = useAppSelector(
    state => state.diarioVentasSlice.estado_factura,
  );

  const ventas = useAppSelector(
    store => store.reportes.reporteVentasPorMetodoPago,
  );

  const fechas = useAppSelector(
    store => ({
      fecha_inicial: store.diarioVentasSlice.fecha_hora_inicial,
      fecha_final: store.diarioVentasSlice.fecha_hora_final,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  const getImpuestoTotal = useMemo(() => ventas.total_impuestos, [ventas]);

  const getTotalNeto = useMemo(
    () => ventas.total_ventas - ventas.total_impuestos,
    [ventas],
  );

  const getTotalBruto = useMemo(
    () => ventas.total_ventas + ventas.total_propinas,
    [ventas],
  );

  const getPropinasTotal = useMemo(() => ventas.total_propinas, [ventas]);

  const sucursal = useAppSelector(
    store => store.restauranteSlice.sucursalSeleccionada,
  );

  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );

  const facturaCaja = useAppSelector(
    store => store.diarioVentasSlice.facturaCaja,
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getReportePorMetodoPago());
    };
    if (fechas.fecha_inicial)
      dispatch(setDate({type: 'inicial', date: fechas.fecha_inicial}));
    if (fechas.fecha_final)
      dispatch(setDate({type: 'final', date: fechas.fecha_final}));
    fetchData();
  }, [fechas]);

  return (
    <Stack flexDirection="column" gap="10px">
      <Stack
        flexDirection="row"
        px="30px"
        pt="30px"
        justifyContent="space-between">
        <Stack gap="5px">
          <Typography
            sx={{
              fontSize: '0.7rem',
              textAlign: 'right',
              lineHeight: 1,
              fontWeight: 'bold',
            }}>
            PROPINAS: {fCurrency(getPropinasTotal)}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.7rem',
              lineHeight: 1,
              fontWeight: 'bold',
            }}>
            IMPUESTO: {fCurrency(getImpuestoTotal)}
          </Typography>
        </Stack>
        <Stack gap="5px" justifyContent="flex-end">
          <Typography
            sx={{
              fontSize: '0.7rem',
              lineHeight: 1,
              fontWeight: 'bold',
            }}>
            TOTAL-BRUTO:{fCurrency(getTotalBruto)}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.7rem',
              textAlign: 'right',
              lineHeight: 1,
              fontWeight: 'bold',
            }}>
            TOTAL-NETO: {fCurrency(getTotalNeto)}
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection={'row'} px="30px">
        <Select
          value={estado}
          onChange={(event: SelectChangeEvent<number>) => {
            dispatch(setEstadoFactura(event.target.value as number));
          }}>
          <MenuItem value={1}>Emitida</MenuItem>
          <MenuItem value={0}>Anulada</MenuItem>
        </Select>
      </Stack>
      <Stack width="100%" alignItems="center">
        <Typography
          sx={{
            fontSize: '0.7rem',
            lineHeight: 0.9,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}>
          {facturaCaja
            ? `caja: #${cajaEmpleado?.caja.numero_caja}`
            : `sucursal: #${sucursal?.descripcion_ubicacion}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default memo(HeaderBar);
