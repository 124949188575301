import {Grid} from '@mui/material';
import isBoolean from '../../common/isBoolean';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import NewEmpleado from '../../components/new-empleado/new-empleado';
import SucursalTable from '../../components/new-sucursal-form/new-sucursal-form';
import {SucursalesBox} from '../../components/restaurante-box/restaurante-box';
import {resetOrdenSlice} from '../../redux/slices/orden-slice';
import {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
} from '../../redux/slices/restaurante-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};

const SucursalSelectModal = ({state, handleClose}: PropsTypes) => {
  const dispatch = useAppDispatch();

  const EmpleadoAsignadoSucursal = useAppSelector(
    state => state.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  const sucursalesEmpleadoAsignado = useAppSelector(
    state => state.restauranteSlice.sucursalesEmpleadoAsignado,
  );

  const handleOnClose = () => {
    dispatch(seleccionarRestaurante(null));
    dispatch(seleccionarEmpleadoAsignadoSucursal(null));
    dispatch(resetOrdenSlice());
    handleClose();
  };

  const handleSelectEmpleadoAsignadoSucursal = (
    id_empleado_asignado_sucursal: number,
  ) => {
    dispatch(
      seleccionarEmpleadoAsignadoSucursal(id_empleado_asignado_sucursal),
    );
  };

  return (
    <DialogPopUp
      open={state}
      handleClose={handleOnClose}
      title={
        sucursalesEmpleadoAsignado.length ? 'Seleccione una sucursal' : ''
      }>
      <Grid container>
        {sucursalesEmpleadoAsignado.length ? (
          sucursalesEmpleadoAsignado
            .filter(element => isBoolean(element.sucursal.estado_sucursal))
            .map((sucursal, index) => (
              <SucursalesBox
                key={sucursal.empleado_id + index.toString()}
                nombre={sucursal.sucursal.descripcion_ubicacion}
                onClick={() =>
                  handleSelectEmpleadoAsignadoSucursal(
                    sucursal.id_empleado_asignado_sucursal,
                  )
                }
                isselected={
                  EmpleadoAsignadoSucursal &&
                  EmpleadoAsignadoSucursal.id_empleado_asignado_sucursal ===
                    sucursal.id_empleado_asignado_sucursal
                    ? 1
                    : 0
                }
              />
            ))
        ) : (
          <InicialCreation />
        )}
      </Grid>
    </DialogPopUp>
  );
};

export default SucursalSelectModal;

const InicialCreation = () => {
  return (
    <Grid item xs={12}>
      <SucursalTable />
      <NewEmpleado />
    </Grid>
  );
};
