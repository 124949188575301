import {memo, useCallback, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/store';

import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import Mesas from './mesas';
import CategoriasProductos from './categorias-productos';
import Misc from './misc';
import FacturaEmitida from './factura-emitida';

import {
  getMensajesDeCancelacion,
  getMenuSucursal,
  getOrdenMesas,
  getUbicaciones,
} from '../../redux/slices/orden-slice/orden.thunks';
import {checkCajaEmpleadoUser} from '../../redux/slices/restaurante-slice/restaurente.thunks';
import Ordentemplate from '../../layouts/orden-layout/orden-layout';
import {
  fetchDescuentos,
  fetchMetodosDePago,
  fetchPropinas,
} from '../../redux/slices/finanzas-slice/finanzas.thunks';
import {getSeccionSucursal} from '../../redux/slices/restaurante-utils-slice/restaurante-utils.thunks';
import {
  fetchAccionesModificador,
  fetchModificador,
} from '../../redux/slices/modificador-slice/modificadores.thunk';

function Ordenes() {
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector(state => state.uiSlice.isLoading);
  const currentStep = useAppSelector(state => state.ordenSlice.currentStep);
  const restauranteSlice = useAppSelector(state => state.restauranteSlice);
  const sucursalSeleccionada = useAppSelector(
    store => store.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );
  const GetContent = useCallback(() => {
    switch (currentStep) {
      case 1:
        return <Mesas />;
      case 2:
        return <CategoriasProductos />;
      case 3:
        return <Misc />;
      case 4:
        return <FacturaEmitida />;
      default:
        return null;
    }
  }, [currentStep]);

  const startFetchingData = useCallback(async () => {
    if (
      !restauranteSlice.restauranteEmpleadoSeleccionado &&
      !restauranteSlice.empleadoAsignadoSucursalSeleccionado
    )
      return;
    dispatch(startLoading());
    await Promise.all([
      dispatch(getMenuSucursal()),
      dispatch(getUbicaciones()),
      dispatch(getOrdenMesas()),
      dispatch(getMensajesDeCancelacion()),
      dispatch(getSeccionSucursal()),
      dispatch(fetchDescuentos()),
      dispatch(fetchPropinas()),
      dispatch(fetchModificador()),
      dispatch(fetchAccionesModificador()),
      dispatch(fetchMetodosDePago()),
    ]);
    dispatch(stopLoading());
  }, [restauranteSlice]);

  useEffect(() => {
    dispatch(checkCajaEmpleadoUser());
    startFetchingData();
  }, [sucursalSeleccionada]);

  useEffect(() => {
    if (
      !restauranteSlice.restauranteEmpleadoSeleccionado &&
      !restauranteSlice.empleadoAsignadoSucursalSeleccionado
    )
      return;
  }, [
    restauranteSlice.restauranteEmpleadoSeleccionado,
    restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  ]);

  return (
    <Ordentemplate isLoading={loadingState} title="Ordenes || IFUDIS">
      <GetContent />
    </Ordentemplate>
  );
}

export default memo(Ordenes);
