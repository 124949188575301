import {useAppDispatch} from '../../redux/store';
import {Box} from '@mui/material';
import {
  EntidadReceptora,
  clienteStoreTypePrimary,
} from '../../redux/slices/orden-slice/orden.types';
import EntidadReceptoraForm from './entidad-receptora-form';
import EntidadReceptoraAutocomplete from './entidad-receptora-autocomplete';
import {useState} from 'react';

const AUTOCOMPLETE_COMPONENT = 'auto-complete';
const FORM_COMPONENT = 'form';
const DEFAULT_COMPONENT_VALUE = AUTOCOMPLETE_COMPONENT;

type propsType = {
  cliente?: clienteStoreTypePrimary | null;
  tipo_receptor_id: number;
  handleFormSubmission?: any | null;
  handleReduxUpdate?: any | null;
  handleCancelButton?: any | null;
};

const EntidadReceptoraSwitchForm = function ({
  cliente,
  tipo_receptor_id,
  handleFormSubmission,
  handleReduxUpdate,
  handleCancelButton,
}: propsType) {
  const dispatch = useAppDispatch();

  const [componentCalled, setComponentCalled] = useState(
    DEFAULT_COMPONENT_VALUE,
  );
  const [entidadReceptoraSelected, setEntidadReceptoraSelected] = useState<
    EntidadReceptora | undefined
  >(undefined);

  const selectEntidadReceptora = (entidadReceptora: EntidadReceptora) => {
    console.log(entidadReceptora);
    setEntidadReceptoraSelected(entidadReceptora);
    setComponentCalled(FORM_COMPONENT);
  };

  if (componentCalled == AUTOCOMPLETE_COMPONENT) {
    return (
      <EntidadReceptoraAutocomplete
        tipo_receptor_id={tipo_receptor_id}
        handleReduxUpdate={handleReduxUpdate}
        selectEntidadReceptora={selectEntidadReceptora}
      />
    );
  }

  return (
    <Box sx={{my: 2}}>
      {/* Form component for entidad receptora management. */}
      {/* Defined on ./entidad-receptora-form */}
      {/* Params */}
      {/* cliente (clienteStoreTypePrimary) */}
      {/* handleFormSubmission (function) */}
      {/* handleReduxUpdate (function) */}
      <EntidadReceptoraForm
        cliente={cliente}
        handleFormSubmission={handleFormSubmission}
        handleReduxUpdate={handleReduxUpdate}
        handleCancelButton={handleCancelButton}
        incomingEntidadReceptora={entidadReceptoraSelected}
      />
      {/* Form component for entidad receptora management. */}
    </Box>
  );
};

export default EntidadReceptoraSwitchForm;
