import {createAsyncThunk} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';
import addDelTogg, {options} from '../../../common/add-del-togg';
import {clientToMetaData} from '../../../common/client-meta-data';
import getOrdenFinanzasData from '../../../common/get-orden.finanzas-data';
import iOS from '../../../common/isIos';
import MapOrdenProduct from '../../../common/map-orden-product';
import {deleteCarritoType} from '../../../components/totalizarGrupo/borrar-productos-modal';
import {carritoMovementType} from '../../../components/totalizarGrupo/mover-cuenta-modal';
import {api} from '../../../services';
import {DataWrapper} from '../../../services/dto/common';
import {
  ClientesAsignadosMesa,
  descuentoRestaurantType,
  FacturaDirecta,
  MenuSucursal,
  MesaSucursal,
  motivoDeCancelacion,
  propinaRestaurantType,
  ubicationType,
} from '../../../services/dto/orden';
import {
  cuentaOrdenIndirecta,
  ordenIndirecta,
} from '../../../services/dto/orden-indirecta';
import {TiposImpuesto} from '../../../services/dto/restaurante';
import {RootState} from '../../store';
import {finanzasSliceType} from '../finanzas-slice/finanzas.types';
import {thunkAPiConfig} from '../user-slice/user.types';
import {
  _setDescuentoPorLinea,
  resetOrden,
  setCurrentStep,
  updateCarrito,
  updateMetaData,
} from './index';
import {
  carritoType,
  clienteStoreTypePrimary,
  descuentoType,
  orderMetodoPago,
  EntidadReceptora,
  SearchEntidadReceptora,
} from './orden.types';
import {msgSuccess} from '../../../common/toast';
import yup from '../../../common/yup';
import {entidadReceptoraSchema} from './orden.schemas';

export const getUbicaciones = createAsyncThunk<
  ubicationType[],
  undefined,
  {state: RootState}
>(
  'orden/getUbicaciones',

  async (data, thunkAPI) => {
    const {
      restauranteSlice: {
        restauranteEmpleadoSeleccionado: restauranteSeleccionado,
        empleadoAsignadoSucursalSeleccionado,
      },
    } = thunkAPI.getState();
    if (!restauranteSeleccionado || !empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue(
        'datos del restaurante y sucursal no seleccionados',
      );

    const response = await api.getUbicaciones();

    if (!response)
      return thunkAPI.rejectWithValue('error al obtener ubicaciones');

    return response.data;
  },
);
//ENTIDAD RECEPTORA
export const getEntidadReceptora = createAsyncThunk<
  EntidadReceptora[],
  SearchEntidadReceptora,
  {state: RootState}
>('orden/getEntidadReceptora', async (data, thunkAPI) => {
  const response = await api.getEntidadReceptora(
    data.tipo_receptor_id,
    data.buscador,
  );
  console.log('ENTIDAD RECEPTORA:', response);
  if (!response?.data)
    return thunkAPI.rejectWithValue('error al obtener la entidad receptora');
  return response.data;
});

export const putPostEntidadReceptora = createAsyncThunk<
  any,
  {
    editId?: number | null;
  } & yup.InferType<typeof entidadReceptoraSchema>,
  {state: RootState}
>('orden/putPostEntidadReceptora', async (data, thunkAPI) => {
  const {
    ordenSlice: {
      orden: {clienteSeleccionado},
    },
  } = thunkAPI.getState();

  const tipo_receptor_id = data.tipo_receptor_id;

  console.log(tipo_receptor_id);

  if (tipo_receptor_id === 1 || tipo_receptor_id === 3) {
    console.log('1 or 3');
    delete data.info_cliente;
  }

  if (tipo_receptor_id === 2 || tipo_receptor_id === 4) {
    console.log('2 or 4');
    delete data.ruc_receptor;
  }

  if (data.email === '') {
    delete data.email;
  }
  const body = await entidadReceptoraSchema.validate(
    {
      ...data,
      id: data.id,
    },
    {stripUnknown: true, context: {request: true}},
  );

  // Check if ruc_receptor is null, and exclude it
  if (body.ruc_receptor === null) {
    delete body.ruc_receptor;
  }

  if (body.info_cliente === null) {
    delete body.info_cliente;
  }

  const copy = clienteSeleccionado?.entidad_receptora;

  if (data.id) {
    // Use the updated body object instead of the original data object
    const response = await api.putEntidadReceptora(body, data.id);

    if (!response)
      return thunkAPI.rejectWithValue(
        'Error al actualizar la entidad receptora.',
      );
    msgSuccess('Entidad receptora agregada con éxito');
    return response;
  } else {
    // Use the updated body object instead of the original data object
    const response = await api.postEntidadReceptora(body);
    if (!response)
      return thunkAPI.rejectWithValue('Error al crear la entidad receptora.');
    msgSuccess('Entidad receptora creada con éxito');

    return response;
  }
});

export const getMenuSucursal = createAsyncThunk<
  MenuSucursal[],
  undefined,
  {state: RootState}
>(
  'orden/getMenuSucursal',

  async (data, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();
    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue(
        'datos del restaurante y sucursal no seleccionados',
      );

    const response = await api.getMenuSucursal(
      empleadoAsignadoSucursalSeleccionado.sucursal_id,
    );
    if (!response) return thunkAPI.rejectWithValue('error al obtener menu');

    return response.data;
  },
);

export const putPostMenuSucursal = createAsyncThunk<
  any,
  {
    data: MenuSucursal;
    editId: number;
  },
  {state: RootState}
>('orden/putPostMenuSucursal', async ({data, editId}, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue(
      'datos del restaurante y sucursal no seleccionados',
    );
  const request = {
    ...data,
    type: data.type ? '1' : '0',
    cantidad_producto: data.cantidad_producto || 0,
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    estado_producto_local:
      data.estado_producto_local === 'DISPONIBLE' ? '1' : '0',
  };
  const response = await api.putMenuSucursal(request, editId);
  return true;
});

export const getOrdenMesas = createAsyncThunk<
  MesaSucursal[],
  undefined,
  {state: RootState}
>(
  'orden/getOrdenMesas',

  async (data, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();
    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue(
        'datos del restaurante y sucursal no seleccionados',
      );

    const responses = await api.getMesaSucursal(
      empleadoAsignadoSucursalSeleccionado.sucursal_id,
    );

    if (!responses)
      return thunkAPI.rejectWithValue('error al obtener las mesas');

    return responses.data;
  },
);

export const getMensajesDeCancelacion = createAsyncThunk<
  motivoDeCancelacion[],
  undefined,
  {state: RootState}
>(
  'orden/getMensajesDeCancelacion',

  async (data, thunkAPI) => {
    const responses = await api.getMotivoDeCancelacion();

    if (!responses)
      return thunkAPI.rejectWithValue('error al obtener las mesas');

    return responses.data;
  },
);

export const facturarDirecta = createAsyncThunk<
  DataWrapper<FacturaDirecta>,
  undefined,
  {state: RootState}
>('orden/facturarDirecta', async (data, thunkAPI) => {
  const {
    restauranteSlice: {cajaEmpleado, restauranteSeleccionado},
  } = thunkAPI.getState();
  if (!cajaEmpleado)
    return thunkAPI.rejectWithValue('el usuario no tiene caja asignada');

  let {
    ordenSlice: {orden},
    finanzasSlice: {propinas: propinasArray, descuentos: descuentosArray},
  } = thunkAPI.getState();
  if (!orden.clienteSeleccionado)
    return thunkAPI.rejectWithValue('no hay clientes');
  const ordenFinanzas = getOrdenFinanzasData({
    cliente: orden.clienteSeleccionado,
    propinasArray,
  });

  const productos = MapOrdenProduct({
    carrito: orden.carrito,
    descuentosArray,
    shouldMergeCarrito: true,
    tipoDescuento: orden.clienteSeleccionado.descuentoType,
  });
  if (!orden.ubicacion || !ordenFinanzas.metodo_pago || !productos)
    return thunkAPI.rejectWithValue('datos incompletos');

  const {name, email} = orden.clienteSeleccionado;

  let inf_client_rest_id = undefined;

  if (restauranteSeleccionado && orden.infoCliente) {
    if (orden.infoCliente.restaurantes) {
      const check = orden.infoCliente.restaurantes.find(
        r => r.restaurante_id === restauranteSeleccionado.id_restaurante,
      );
      if (check) {
        inf_client_rest_id = check.id_inf_client_rest;
      }
    }
  }

  const payload = {
    cliente_id: orden.clienteSeleccionado.id,
    cliente: orden.clienteSeleccionado
      ? {
          nombre_apellido: name,
          email,
          inf_client_rest_id,
          info_cliente_id: orden.infoCliente
            ? orden.infoCliente.id_info_cliente
            : undefined,
          ...orden.clienteSeleccionado,
          entidad_receptora_id: orden.clienteSeleccionado.entidad_receptora
            ? orden.clienteSeleccionado.entidad_receptora.id
            : undefined,
          entidad_receptora: orden.clienteSeleccionado.entidad_receptora
            ? {
                ...orden.clienteSeleccionado.entidad_receptora,
                email:
                  orden.clienteSeleccionado.entidad_receptora.email !== null
                    ? orden.clienteSeleccionado.entidad_receptora.email
                    : undefined,
              }
            : undefined,
        }
      : undefined,
    entidad_receptora_id: orden.clienteSeleccionado.entidad_receptora
      ? orden.clienteSeleccionado.entidad_receptora.id
      : undefined,
    raz_soc_id: orden.clienteSeleccionado.id_razon_social || undefined,
    caja_empleado_id: cajaEmpleado.id_caja_empleado,
    productos: productos,
    tipo_ubicacion_id: orden.ubicacion?.id_tipo_ubicacion,
    ...ordenFinanzas,
  };

  // Eliminar la propiedad 'email' si es null en cliente
  if (payload.cliente && payload.cliente.email === null) {
    delete payload.cliente.email;
  }

  // Eliminar la propiedad 'email' si es null en entidad_receptora
  if (
    payload.cliente &&
    payload.cliente.entidad_receptora &&
    payload.cliente.entidad_receptora.email === null
  ) {
    delete payload.cliente.entidad_receptora.email;
  }

  console.log(payload);

  const response = await api.postFacturaDirecta(payload);
  if (!response) return thunkAPI.rejectWithValue('error');
  return response;
});

export const resetSlice = createAsyncThunk<null, undefined, {state: RootState}>(
  'orden/resetSlice',
  async (data, {dispatch}) => {
    dispatch(resetOrden());
    await Promise.all([dispatch(getMenuSucursal()), dispatch(getOrdenMesas())]);
    return null;
  },
);

export const cargarImpuestos = createAsyncThunk<
  TiposImpuesto[],
  undefined,
  {state: RootState}
>('orden/cargarImpuestos', async (data, thunkAPI) => {
  const {
    restauranteSlice: {restauranteSeleccionado, sucursalSeleccionada},
    ordenSlice,
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('no hay restaurante seleccionado');
  if (!sucursalSeleccionada)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  if (restauranteSeleccionado.tipos_impuestos.length === 0)
    return thunkAPI.rejectWithValue('no hay impuestos');
  if (sucursalSeleccionada.cobra_impuesto === 0) return [];

  if (
    ordenSlice.orden.clienteSeleccionado?.descuentos.some(
      e => e.tipo_pago_descuento_id === 4,
    )
  )
    return [];

  return restauranteSeleccionado.tipos_impuestos;
});

export const editOrdenMetodoPago = createAsyncThunk<
  orderMetodoPago,
  {
    type: 'agregar' | 'togglear' | 'eliminar';
    id: number;
  },
  {state: RootState}
>('orden/editOrdenMetodoPago', async (data, thunkAPI) => {
  const {
    finanzasSlice: {metodosPago: metodosPagoArray},
    ordenSlice: {
      orden: {clienteSeleccionado},
      cuenta,
    },
  } = thunkAPI.getState();

  const {type, id} = data;
  const metodoPagoElem = metodosPagoArray.find(
    element => element.id_metodo_pago_restaurante === id,
  );

  if (!metodoPagoElem)
    return thunkAPI.rejectWithValue('metodo de pago no encontrado');

  if (!clienteSeleccionado)
    return thunkAPI.rejectWithValue('no hay cliente seleccionado');

  let metodosCopy = [...clienteSeleccionado.metodosPago.metodos];

  switch (type) {
    case 'agregar':
      const metodo = {
        metodoPago: metodoPagoElem,
        cantidad: metodosCopy.length <= 0 ? cuenta.total : 0,
      };
      metodosCopy.push(metodo);
      break;
    case 'eliminar':
      metodosCopy = metodosCopy.filter(
        element => element.metodoPago.id_metodo_pago_restaurante !== id,
      );
      break;
    case 'togglear':
      const index = metodosCopy.findIndex(
        element => element.metodoPago.id_metodo_pago_restaurante === id,
      );
      if (index === -1) {
        const metodo = {
          metodoPago: metodoPagoElem,
          cantidad: metodosCopy.length <= 0 ? cuenta.total : 0,
        };
        metodosCopy.push(metodo);
      } else {
        metodosCopy.splice(index, 1);
      }
      break;
    default:
      break;
  }

  return {...clienteSeleccionado.metodosPago, metodos: metodosCopy};
});

export const editOrdenPropina = createAsyncThunk<
  propinaRestaurantType[],
  {
    type: options;
    id: number;
  },
  {state: RootState}
>('orden/editOrdenPropina', async (data, thunkAPI) => {
  const {
    finanzasSlice: {propinas: propinasArray},
    ordenSlice: {
      orden: {clienteSeleccionado},
    },
  } = thunkAPI.getState();

  const {type, id} = data;

  if (!clienteSeleccionado)
    return thunkAPI.rejectWithValue('no hay cliente seleccionado');

  return addDelTogg({
    array: clienteSeleccionado.propinas,
    baseArray: propinasArray,
    id,
    type,
    key: ['id_tipo_propina_restaurante'],
  });
});

export const editOrdenDescuento = createAsyncThunk<
  descuentoRestaurantType[],
  {
    type: options;
    id: number;
  },
  {state: RootState}
>('orden/editOrdenDescuento', async (data, thunkAPI) => {
  const {
    finanzasSlice: {descuentos: descuentosArray},
    ordenSlice: {
      orden: {clienteSeleccionado},
    },
  } = thunkAPI.getState();

  if (!clienteSeleccionado)
    return thunkAPI.rejectWithValue('no hay cliente seleccionado');
  const {type, id} = data;

  return addDelTogg({
    array: clienteSeleccionado.descuentos,
    baseArray: descuentosArray,
    id,
    type,
    key: ['id_tipo_descuento_restaurante'],
  });
});

export const setDescuentoPorLinea = createAsyncThunk<
  null,
  {
    products: carritoType[];
    productsAPI: carritoType[];
    descuentoId?: number;
  },
  {state: RootState}
>('orden/setDescuentoPorLinea', async (data, thunkAPI) => {
  const {
    finanzasSlice: {descuentos},
  } = thunkAPI.getState();

  thunkAPI.dispatch(_setDescuentoPorLinea({...data, descuentos}));

  return null;
});

export const facturaIndirectaInit = createAsyncThunk<
  undefined,
  undefined,
  {state: RootState}
>('orden/facturaIndirectaInit', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  let {
    ordenSlice: {orden, mesaSeleccionada},
    finanzasSlice: {descuentos: descuentosArray},
  } = thunkAPI.getState();

  if (!mesaSeleccionada)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');

  const payload = {
    cantidad_comensales: orden.metaData.cantidadDeComenzales ?? 0,
    mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    tipo_ubicacion_id: 2,
    clientes: orden.clientes
      .map(element => {
        const products = orden.carrito.filter(
          carrito => carrito.clienteId === element.id,
        );
        if (products.length === 0) return null;
        const productos = MapOrdenProduct({
          carrito: products,
          descuentosArray,
          shouldMergeCarrito: true,
          tipoDescuento: element.descuentoType,
        });
        return {
          nombre_apellido: element.name ?? 'SIN NOMBRE',
          productos,
          email: element.email || undefined,
          entidad_receptora: element.entidad_receptora,
        };
      })
      .filter(element => element !== null),
  };

  const response = await api.postFacturaIndirectaInit(payload);
  if (!response) return thunkAPI.rejectWithValue('error');
  thunkAPI.dispatch(loadCuenta({cuenta: response.data}));
  return;
});

export const cargarCuenta = createAsyncThunk<
  undefined,
  number,
  {state: RootState}
>('orden/cargarCuenta', async (data, thunkAPI) => {
  const response = await api.getCuenta(data);

  if (!response) return thunkAPI.rejectWithValue('error');
  thunkAPI.dispatch(loadCuenta({cuenta: response.data}));
  return;
});

export const loadCuenta = createAsyncThunk<
  {cuenta: cuentaOrdenIndirecta; finanzas: finanzasSliceType},
  {
    cuenta: cuentaOrdenIndirecta;
    resetOptions?: {
      maintainSelectedClient?: boolean;
    };
  },
  {state: RootState}
>('orden/loadCuenta', async (data, thunkAPI) => {
  const finanzas = thunkAPI.getState().finanzasSlice;
  thunkAPI.dispatch(resetOrden({onlyOrden: true, ...data.resetOptions}));
  return {cuenta: data.cuenta, finanzas};
});

export const agregarProductosIndirecta = createAsyncThunk<
  undefined,
  undefined,
  {state: RootState}
>('orden/agregarProductosIndirecta', async (data, thunkAPI) => {
  let {
    ordenSlice: {orden, mesaSeleccionada},
    finanzasSlice: {descuentos: descuentosArray},
  } = thunkAPI.getState();

  if (!mesaSeleccionada)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');

  if (!mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una cuenta');

  const payload = {
    cuenta_id: mesaSeleccionada.cuenta.id_cuenta,
    mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
    clientes: orden.clientes
      .map(element => {
        const products = orden.carrito.filter(
          carrito => carrito.clienteId === element.id,
        );
        if (products.length === 0) return null;
        const productos = MapOrdenProduct({
          carrito: products,
          descuentosArray,
          shouldMergeCarrito: true,
          tipoDescuento: element.descuentoType,
        });
        let clientPayload: any = {
          nombre_apellido: element.name ?? 'SIN NOMBRE',
          email: element.email || undefined,
          productos,
        };
        if (typeof element.id === 'number') {
          clientPayload.cliente_id = element.id;
        }
        return clientPayload;
      })
      .filter(element => element !== null),
  };

  const response = await api.postIndirectaAddProduct(payload);
  if (!response) return thunkAPI.rejectWithValue('error');
  thunkAPI.dispatch(loadCuenta({cuenta: response.data}));
  return;
});

export const updateProductosIndirecta = createAsyncThunk<
  undefined,
  undefined,
  {state: RootState}
>('orden/updateProductosIndirecta', async (data, thunkAPI) => {
  let {
    ordenSlice: {orden, mesaSeleccionada},
    finanzasSlice: {descuentos: descuentosArray},
  } = thunkAPI.getState();

  if (!mesaSeleccionada)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');

  if (!mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una cuenta');

  const payload = {
    cuenta_id: mesaSeleccionada.cuenta.id_cuenta,
    mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
    clientes: orden.clientes
      .map(client => {
        const products = orden.carritoAPI.filter(
          carrito => carrito.clienteId === client.id,
        );
        const metaData = clientToMetaData(client);
        if (products.length === 0) return null;
        const productos = MapOrdenProduct({
          carrito: products,
          descuentosArray,
          shouldDefaultDescuento: true,
          tipoDescuento: client.descuentoType,
        });
        return {
          productos,
          meta_data_cliente: JSON.stringify(metaData),
          cliente_id: client.id,
          nombre_apellido: client.name,
          email: client.email || undefined,
        };
      })
      .filter(element => element !== null),
  };

  const response = await api.putIndirectaActualizarProduct(payload);
  if (!response) return thunkAPI.rejectWithValue('error');
  thunkAPI.dispatch(loadCuenta({cuenta: response.data}));
  return;
});

export const facturaIndirecta = createAsyncThunk<
  DataWrapper<ordenIndirecta>,
  undefined,
  {state: RootState}
>('orden/facturaIndirecta', async (data, thunkAPI) => {
  const {
    restauranteSlice: {cajaEmpleado, restauranteSeleccionado},
  } = thunkAPI.getState();
  if (!cajaEmpleado)
    return thunkAPI.rejectWithValue('el usuario no tiene caja asignada');

  let {
    ordenSlice: {orden, mesaSeleccionada},
    finanzasSlice: {propinas: propinasArray},
  } = thunkAPI.getState();

  if (!orden.clienteSeleccionado)
    return thunkAPI.rejectWithValue('no se ha seleccionado un cliente');

  if (!mesaSeleccionada)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');

  if (!mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una cuenta');

  if (orden.carrito.length) {
    await thunkAPI.dispatch(agregarProductosIndirecta());
    await thunkAPI.dispatch(cargarCuenta(mesaSeleccionada.cuenta.id_cuenta));
  }

  if (!orden.clienteSeleccionado)
    return thunkAPI.rejectWithValue('no se ha seleccionado un cliente');

  if (orden.shouldUpdate) {
    await thunkAPI.dispatch(updateProductosIndirecta());
  }

  const ordenFinanzas = getOrdenFinanzasData({
    cliente: orden.clienteSeleccionado,
    propinasArray,
  });

  if (!orden.ubicacion || !ordenFinanzas.metodo_pago)
    return thunkAPI.rejectWithValue('datos incompletos');

  let inf_client_rest_id = undefined;

  if (restauranteSeleccionado && orden.infoCliente) {
    if (orden.infoCliente.restaurantes) {
      const check = orden.infoCliente.restaurantes.find(
        r => r.restaurante_id === restauranteSeleccionado.id_restaurante,
      );
      if (check) {
        inf_client_rest_id = check.id_inf_client_rest;
      }
    }
  }
  const payload = {
    caja_empleado_id: cajaEmpleado.id_caja_empleado,
    cuenta_id: mesaSeleccionada.cuenta.id_cuenta,
    cliente_id: orden.clienteSeleccionado.id,
    cliente: orden.clienteSeleccionado
      ? {
          ...orden.clienteSeleccionado,
          entidad_receptora_id: orden.clienteSeleccionado.entidad_receptora
            ? orden.clienteSeleccionado.entidad_receptora.id
            : undefined,
          entidad_receptora: orden.clienteSeleccionado.entidad_receptora
            ? {
                ...orden.clienteSeleccionado.entidad_receptora,
                email:
                  orden.clienteSeleccionado.entidad_receptora.email !== null
                    ? orden.clienteSeleccionado.entidad_receptora.email
                    : undefined,
              }
            : undefined,
        }
      : undefined,
    entidad_receptora_id: orden.clienteSeleccionado.entidad_receptora
      ? orden.clienteSeleccionado.entidad_receptora.id
      : undefined,
    raz_soc_id: orden.clienteSeleccionado.id_razon_social || undefined,
    mesa_asignada_id:
      mesaSeleccionada.clientes_asignados_mesa[0].id_mesa_asignada,
    mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
    ...ordenFinanzas,
  };

  // Eliminar la propiedad 'email' si es null en cliente
  if (payload.cliente && payload.cliente.email === null) {
    delete payload.cliente.email;
  }

  // Eliminar la propiedad 'email' si es null en entidad_receptora
  if (
    payload.cliente &&
    payload.cliente.entidad_receptora &&
    payload.cliente.entidad_receptora.email === null
  ) {
    delete payload.cliente.entidad_receptora.email;
  }

  console.log(payload);

  const response = await api.postFacturaIndirecta(payload);
  if (!response) return thunkAPI.rejectWithValue('error');
  thunkAPI.dispatch(updateMetaData({cantidadDeComenzales: null}));
  return response;
});

export const addUpdateProductIndirecta = createAsyncThunk<
  null,
  undefined,
  {state: RootState}
>('orden/addUpdateProductIndirecta', async (data, thunkAPI) => {
  const orden = thunkAPI.getState().ordenSlice.orden;
  if (orden.carrito.length)
    await thunkAPI.dispatch(agregarProductosIndirecta());
  if (orden.shouldUpdate) await thunkAPI.dispatch(updateProductosIndirecta());
  return null;
});

export const borrarProducto = createAsyncThunk<
  null,
  deleteCarritoType,
  {state: RootState}
>('orden/addUpdateProductIndirecta', async (data, thunkAPI) => {
  const {
    ordenSlice: {
      orden: {clienteSeleccionado, carritoAPI, clientes},
      mesaSeleccionada,
    },
    finanzasSlice: {descuentos: descuentosArray},
  } = thunkAPI.getState();

  if (!clienteSeleccionado)
    return thunkAPI.rejectWithValue('no se ha seleccionado un cliente');
  if (!mesaSeleccionada)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');
  if (!mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una cuenta');
  if (!data.deleteCant)
    return thunkAPI.rejectWithValue('no se ha seleccionado una cantidad');

  if (!data.metaData)
    return thunkAPI.rejectWithValue('no se ha seleccionado una cantidad');

  if (data.cantidad === data.deleteCant) {
    const dataCopy: carritoType = {
      ...data,
      metaData: {
        ...data.metaData,
        estado_orden_id: 3,
        cantidadCancelada: data.deleteCant,
        motivoDeCancelacion: data.motivoId,
        observacionBorrado: data.obervacionBorrar,
      },
    };
    const producto = MapOrdenProduct({
      carrito: [dataCopy],
      descuentosArray,

      tipoDescuento: clienteSeleccionado.descuentoType,
    });
    const body = {
      cuenta_id: mesaSeleccionada.cuenta.id_cuenta,
      mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
      restaurante_id: mesaSeleccionada.sucursal.restaurante,
      clientes: [
        {
          cliente_id: clienteSeleccionado.id,
          productos: producto,
        },
      ],
    };
    const response = await api.deleteIndirectaBorrarProduct(body);
    if (!response) return thunkAPI.rejectWithValue('error');
    const copyCarrito = [...carritoAPI]
      .filter(element => element.clienteId === clienteSeleccionado.id)
      .filter(element => element.id !== data.id);
    if (copyCarrito.length === 0) {
      await thunkAPI.dispatch(borrarCliente(clienteSeleccionado.id));
      if (clientes.length === 1) {
        await thunkAPI.dispatch(resetSlice());
        thunkAPI.dispatch(setCurrentStep(1));
        return null;
      }
      await thunkAPI.dispatch(cargarCuenta(mesaSeleccionada.cuenta.id_cuenta));
    } else {
      thunkAPI.dispatch(loadCuenta({cuenta: response.data}));
    }
    return null;
  }
  const index = carritoAPI.findIndex(element => element.id === data.id);

  if (index === -1) return thunkAPI.rejectWithValue('error');

  const copy = [...carritoAPI];
  copy[index] = {
    ...copy[index],
    cantidad: data.cantidad - data.deleteCant,
    metaData: {
      ...data.metaData,
      observacionBorrado: data.obervacionBorrar,
      cantidadCancelada: data.deleteCant,
      motivoDeCancelacion: data.motivoId,
    },
  };
  thunkAPI.dispatch(updateCarrito({carrito: copy, isCarritoAPI: true}));
  await thunkAPI.dispatch(addUpdateProductIndirecta());
  return null;
});

export const initPrecuenta = createAsyncThunk<
  null,
  undefined,
  {state: RootState}
>('orden/initPrecuenta', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  let {
    ordenSlice: {
      orden: {clienteSeleccionado},
      mesaSeleccionada,
    },
  } = thunkAPI.getState();

  if (!mesaSeleccionada || !mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');

  if (!clienteSeleccionado)
    return thunkAPI.rejectWithValue('no se ha seleccionado un cliente');

  const payload = {
    mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
    cuenta_id: mesaSeleccionada.cuenta.id_cuenta,
    accion: 1,
    clientes: [{cliente_id: clienteSeleccionado.id}],
  };

  const response = await api.putFacturaPreCuenta(payload);
  if (!response) return thunkAPI.rejectWithValue('error');
  thunkAPI.dispatch(
    loadCuenta({
      cuenta: response.data,
      resetOptions: {maintainSelectedClient: true},
    }),
  );
  return null;
});

export const getPrecuentaPDF = createAsyncThunk<
  null,
  undefined,
  {state: RootState}
>('orden/getPrecuentaPDF', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado, cajaEmpleado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('no hay sucursal seleccionada');

  let {
    ordenSlice: {
      orden: {clienteSeleccionado},
      mesaSeleccionada,
    },
  } = thunkAPI.getState();

  if (!clienteSeleccionado)
    return thunkAPI.rejectWithValue('no se ha seleccionado un cliente');

  if (!mesaSeleccionada || !mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');
  const body = {
    cuenta_id: mesaSeleccionada.cuenta.id_cuenta,
    mesa_sucursal_id: mesaSeleccionada.id_mesa_sucursal,
    nombre_cajero: cajaEmpleado
      ? `${cajaEmpleado.empleado_sucursal.name} ${cajaEmpleado.empleado_sucursal.lastname}`
      : null,
    clientes: [
      {
        meta_data_cliente: JSON.stringify(
          clientToMetaData(clienteSeleccionado),
        ),
        cliente_id: clienteSeleccionado.id,
        nombre_apellido: clienteSeleccionado.name,
      },
    ],
  };

  const response = await api.getPreFactura(body);

  if (!response) return thunkAPI.rejectWithValue('error');

  if (!iOS()) {
    const url = window.URL.createObjectURL(response);
    window.open(url, '_blank');
    return null;
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(response);
  link.download = `pre-factura-${clienteSeleccionado.id}.pdf`;
  link.click();
  return null;
});

export const borrarCliente = createAsyncThunk<
  null,
  number | string,
  {state: RootState}
>('orden/borrarCliente', async (data, thunkAPI) => {
  const {
    ordenSlice: {
      orden: {clientes},
      mesaSeleccionada,
    },
  } = thunkAPI.getState();

  if (!mesaSeleccionada || !mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('no se ha seleccionado una mesa');
  const cliente = clientes.find(cliente => cliente.id === data);

  if (!cliente) return thunkAPI.rejectWithValue('no se encontro el cliente');

  const body = {
    cliente_id: cliente.id,
  };

  const response = await api.deleteClient(
    mesaSeleccionada.cuenta.id_cuenta,
    body,
  );
  if (!response) return thunkAPI.rejectWithValue('error');

  return null;
});

export const productMovement = createAsyncThunk<
  null,
  {
    firstClient: clienteStoreTypePrimary;
    secondClient: clienteStoreTypePrimary;
    products: carritoMovementType[];
  },
  {state: RootState}
>('orden/productMovement', async (data, thunkAPI) => {
  if (data.products.some(product => product.clienteId != data.firstClient.id))
    return thunkAPI.rejectWithValue(
      'some of the products does not belong to the first client',
    );
  const {
    ordenSlice: {mesaSeleccionada},
  } = thunkAPI.getState();
  if (!mesaSeleccionada || !mesaSeleccionada.cuenta)
    return thunkAPI.rejectWithValue('a table have not been selected');
  let body: any = {
    cliente_envia_id: data.firstClient.id,
    productos: data.products.map(product => ({
      id_orden_producto_menu: product.id,
      cantidad: product.moveCant,
    })),
  };

  if (typeof data.secondClient.id === 'number') {
    body.cliente_recibe_id = data.secondClient.id;
  } else {
    body.cliente = {
      nombre_apellido: data.secondClient.name,
    };
  }
  const response = await api.postMoveProduct(body);

  if (!response)
    return thunkAPI.rejectWithValue('error while moving the products');
  await thunkAPI.dispatch(cargarCuenta(mesaSeleccionada.cuenta.id_cuenta));
  return null;
});

export const moveClient = createAsyncThunk<
  null,
  {
    clients: ClientesAsignadosMesa[];
  },
  {state: RootState}
>('orden/ClientesAsignadosMesa', async (data, thunkAPI) => {
  const {
    ordenSlice: {
      uiData: {mesaA, mesaB, mesas},
      orden: {metaData},
    },
  } = thunkAPI.getState();

  const mesa = mesas.find(element => element.id_mesa_sucursal == mesaB);
  if (!mesaA || !mesaB)
    return thunkAPI.rejectWithValue('one of the tables are not selected');

  let body: any = {
    mesa_sucursal_recibe_id: mesaB,
    mesa_sucursal_envia_id: mesaA,
    mesas_asignadas: data.clients.map(element => element.id_mesa_asignada),
  };

  if (!mesa || !mesa.cuenta) {
    body.tipo_ubicacion_id = 2;
    body.cantidad_comensales = metaData.cantidadDeComenzales ?? 1;
  } else {
    body.cuenta_recibe_id = mesa.cuenta.id_cuenta;
  }

  const response = await api.postMoveClient(body);

  if (!response)
    return thunkAPI.rejectWithValue('error while moving the client');
  thunkAPI.dispatch(updateMetaData({cantidadDeComenzales: null}));
  await thunkAPI.dispatch(getOrdenMesas());
  return null;
});

export const moveProduct = createAsyncThunk<null, any, {state: RootState}>(
  'orden/MoveProductsMesa',
  async (arg, thunkAPI) => {
    const {
      ordenSlice: {
        uiData: {mesaA, mesaB, mesas},
        orden: {metaData},
      },
    } = thunkAPI.getState();

    const mesa = mesas.find(element => element.id_mesa_sucursal == mesaB);
    if (!mesaA || !mesaB)
      return thunkAPI.rejectWithValue('one of the tables are not selected');
    const response = await api.postMoveProducts(arg);

    if (!response)
      return thunkAPI.rejectWithValue('error while moving the client');
    await thunkAPI.dispatch(getOrdenMesas());
    return null;
  },
);
export const CreateClient = createAsyncThunk<
  {
    shoudSelect: boolean;
    client: clienteStoreTypePrimary;
    clients: clienteStoreTypePrimary[];
  },
  {name?: string; email?: string; shouldSelect?: boolean},
  thunkAPiConfig
>('orden/CreateClient', async (data, thunkAPI) => {
  const {
    ordenSlice: {
      orden: {clientes},
    },
  } = thunkAPI.getState();

  let id = nanoid();
  while (clientes.some(cliente => cliente.id === id)) {
    id = nanoid();
  }
  const name = data.name || `CLIENTE ${clientes.length + 1}`;
  const client = {
    name,
    id,
    email: data.email,
    descuentoType: descuentoType.none,
    descuentos: [],
    impuestos: [],
    metodosPago: {
      cantidad: 0,
      metodos: [],
      restante: 0,
      zeroMetodo: false,
    },
    propinaEfectivo: null,
    propinas: [],
  };

  const clientsCopy = [...clientes];
  clientsCopy.push(client);

  return {
    client,
    clients: clientsCopy,
    shoudSelect: data.shouldSelect ?? false,
  };
});

export const CambiarMesero = createAsyncThunk<
  null,
  {cuentaId: number; empleadoId: number},
  thunkAPiConfig
>('orden/CambiarMesero', async (data, thunkAPI) => {
  const response = await api.putCuenta(data.cuentaId, {
    host_id: data.empleadoId,
    estado_cuenta: 1,
  });

  if (!response) return thunkAPI.rejectWithValue('error');

  return null;
});
