import {styled, Box} from '@mui/material';
import Page from '../../components/Page';
import Waves from '../../components/waves/waves';
import {memo} from 'react';
import {SpinnerContainer} from '../../components/with-spinner/with-spinner.styles';

const RootStyle = styled(Page)(() => ({
  display: 'flex',
  width: '100%',
  height: window.location.pathname === '/orden' ? '90vh' : 'auto',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'center',
}));

type ordenTemplateProps = {
  title?: string;
  isLoading?: boolean;
  children: React.ReactNode;
};

const Ordentemplate = function ({
  title = '',
  isLoading = false,
  children,
}: ordenTemplateProps) {
  const CenteredLoadingComponent = () => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <SpinnerContainer />
      </Box>
    );
  };

  return (
    <RootStyle title={title}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <Waves />
        {isLoading ? <CenteredLoadingComponent /> : <>{children}</>}
      </Box>
    </RootStyle>
  );
};

export default memo(Ordentemplate);
