import {createAsyncThunk} from '@reduxjs/toolkit';
import isNullish from '../../../common/isNullish';
import {api} from '../../../services';
import {
  categoriaInsumo,
  insumoSucursal,
  subCategoriaInsumo,
  unidadMedida,
} from '../../../services/dto/insumo';
import {RootState} from '../../store';
import {putPost} from '../menu-slice/menu.types';
import {
  categoriaInsumoStoreTypePrimary,
  insumosStoreTypePrimary,
  subCategoriaInsumoStoreTypePrimary,
} from './insumo.types';

export const putPostInsumo = createAsyncThunk<
  null,
  putPost,
  {state: RootState}
>('insumo/putPostInsumo', async (data, thunkAPI) => {
  const {
    insumoSlice: {
      editable: {insumo: tempInsumo},
    },
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  if (data == 'crear') {
    const {
      restaurante_id,
      sucursal_id,
      id_insumo,
      id_insumo_sucursal,
      ...rest
    } = tempInsumo;
    if (isNullish(rest)) return thunkAPI.rejectWithValue('algun valor es nulo');
  } else {
    if (isNullish(tempInsumo))
      return thunkAPI.rejectWithValue('algun valor es nulo');
  }
  const insumo = tempInsumo as insumosStoreTypePrimary;

  const body = {
    sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    restaurante_id:
      empleadoAsignadoSucursalSeleccionado.sucursal.restaurante_id,
    categoria_insumo_id: insumo.categoria_insumo_id,
    sub_categoria_insumo_id: insumo.sub_categoria_insumo_id,
    unidad_medida_id: insumo.unidad_medida_id,
    nombre_insumo: insumo.nombre,
    peso_unitario: insumo.peso_unitario,
    costo: insumo.costo,
  };

  if (data == 'crear') {
    const response = await api.postInsumoSucursal(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear insumo');
    thunkAPI.dispatch(fetchInsumoSucursal());
    return null;
  } else {
    const response = await api.putInsumoSucursal(
      body,
      insumo.id_insumo,
      insumo.id_insumo_sucursal,
    );
    if (!response) return thunkAPI.rejectWithValue('error al editar insumo');
    thunkAPI.dispatch(fetchInsumoSucursal());
    return null;
  }
});

export const deleteInsumo = createAsyncThunk<any, number, {state: RootState}>(
  'insumo/deleteInsumo',
  async (body, thunkAPI) => {
    const response = await api.deleteInsumoSucursal(body);
    if (!response) return thunkAPI.rejectWithValue('error al eliminar insumo');
    return null;
  },
);

export const putPostCategoriaInsumo = createAsyncThunk<
  null,
  putPost,
  {state: RootState}
>('insumo/putPostCategoriaInsumo', async (data, thunkAPI) => {
  const {
    insumoSlice: {
      editable: {categoriaInsumo: tempCategoriaInsumo},
    },
  } = thunkAPI.getState();

  if (data == 'crear') {
    const {id_categoria_insumo, ...rest} = tempCategoriaInsumo;
    if (isNullish(rest)) return thunkAPI.rejectWithValue('algun valor es nulo');
  } else {
    if (isNullish(tempCategoriaInsumo))
      return thunkAPI.rejectWithValue('algun valor es nulo');
  }
  const categoriaInsumo =
    tempCategoriaInsumo as categoriaInsumoStoreTypePrimary;

  const body = {
    nombre_categoria_insumo: categoriaInsumo.nombre_categoria_insumo,
  };

  if (data == 'crear') {
    const response = await api.postCategoriaInsumo(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear categoria');
    thunkAPI.dispatch(fetchCategoriaInsumo());
    return null;
  } else {
    const response = await api.putCategoriaInsumo(
      body,
      categoriaInsumo.id_categoria_insumo,
    );
    if (!response)
      return thunkAPI.rejectWithValue('error al editar categoria, insumo');
    thunkAPI.dispatch(fetchCategoriaInsumo());
    return null;
  }
});

export const putPostSubcategoriaInsumo = createAsyncThunk<
  null,
  putPost,
  {state: RootState}
>('insumo/putSubcategoriaInsumo', async (data, thunkAPI) => {
  const {
    insumoSlice: {
      editable: {subCategoriaInsumo: tempSubcategoriaInsumo},
    },
  } = thunkAPI.getState();

  if (data == 'crear') {
    const {id_sub_categoria_insumo, ...rest} = tempSubcategoriaInsumo;
    if (isNullish(rest)) return thunkAPI.rejectWithValue('algun valor es nulo');
  } else {
    if (isNullish(tempSubcategoriaInsumo))
      return thunkAPI.rejectWithValue('algun valor es nulo');
  }
  const subcategoriaInusmo =
    tempSubcategoriaInsumo as subCategoriaInsumoStoreTypePrimary;

  const body = {
    nombre_sub_categoria_insumo: subcategoriaInusmo.nombre_sub_categoria_insumo,
    categoria_insumo_id: subcategoriaInusmo.categoria_insumo_id,
  };

  if (data == 'crear') {
    const response = await api.postSubCategoriaInsumo(body);
    if (!response)
      return thunkAPI.rejectWithValue('error al crear subcategoria');
    thunkAPI.dispatch(fetchSubCategoriaInsumo());
    return null;
  } else {
    const response = await api.putSubCategoriaInsumo(
      body,
      subcategoriaInusmo.id_sub_categoria_insumo,
    );
    if (!response)
      return thunkAPI.rejectWithValue('error al editar subcategoria');
    thunkAPI.dispatch(fetchSubCategoriaInsumo());
    return null;
  }
});

export const fetchInsumoSucursal = createAsyncThunk<
  insumoSucursal[],
  undefined,
  {state: RootState}
>('insumo/fetchInsumoSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  const response = await api.getInsumoSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
  );

  if (!response) return thunkAPI.rejectWithValue('error al obtener insumos');

  return response.data;
});

export const deleteSubcategoriaInsumo = createAsyncThunk<
  subCategoriaInsumo,
  number,
  {state: RootState}
>('insumo/deleteSubcategoriaInsumo', async (data, thunkAPI) => {
  const response = await api.deleteSubCategoriaInsumo(data);
  if (!response)
    return thunkAPI.rejectWithValue('error al eliminar subcategoria');
  thunkAPI.dispatch(fetchSubCategoriaInsumo());
  return response.data;
});

export const deleteCategoriaInsumo = createAsyncThunk<
  categoriaInsumo,
  number,
  {state: RootState}
>('insumo/deletecategoriaInsumo', async (data, thunkAPI) => {
  const response = await api.deleteCategoriaInsumo(data);
  if (!response) return thunkAPI.rejectWithValue('error al eliminar categoria');
  thunkAPI.dispatch(fetchCategoriaInsumo());
  return response.data;
});

export const fetchCategoriaInsumo = createAsyncThunk<
  categoriaInsumo[],
  undefined,
  {state: RootState}
>('insumo/fetchCategoriaInsumo', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  const response = await api.getCategoriaInsumo();

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener las categorias');

  return response.data;
});

export const fetchSubCategoriaInsumo = createAsyncThunk<
  subCategoriaInsumo[],
  undefined,
  {state: RootState}
>('insumo/fetchSubCategoriaInsumo', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  const response = await api.getSubCategoriaInsumo();

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener las subcategorias');

  return response.data;
});

export const fetchUnidadMedida = createAsyncThunk<
  unidadMedida[],
  undefined,
  {state: RootState}
>('insumo/fetchUnidadMedida', async (data, thunkAPI) => {
  const response = await api.getUnidadMedida();

  if (!response)
    return thunkAPI.rejectWithValue('error al obtener unidades de medida');

  return response.data;
});
