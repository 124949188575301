import {Box, Card, Grid} from '@mui/material';
import FacturaViewer from '../../components/factura-viewer/factura-viewer';
import FilterBar from './filter-bar';
import HeaderBar from './header-bar';
import TableContainer from './table-container';
import Pagination from '@mui/material/Pagination';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {fetchFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';

const DesktopView = () => {
  const page = useAppSelector(
    store => store.diarioVentasSlice.pagination.current,
  );
  const total = useAppSelector(
    store => store.diarioVentasSlice.pagination.total,
  );

  const dispatch = useAppDispatch();
  const handleChange = async (_: any, value: number) => {
    dispatch(startLoading());
    await dispatch(fetchFactura({page: value, perPage: 50}));
    dispatch(stopLoading());
  };
  return (
    <Box
      sx={{
        height: '83.5vh',
        padding: 0,
        margin: 0,
        display: 'flex',
      }}>
      <Box
        raised
        component={Card}
        sx={{
          width: '50%',
          maxHeight: '100%',
          pr: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
        <HeaderBar />
        <Box
          sx={{
            minHeight: 'calc(100%) - 40px',
            height: '100%',
            maxHeight: 'calc(100%) - 58px',
            overflowY: 'auto',
            p: 2,
          }}>
          <TableContainer />
          <Grid container justifyContent={'center'}>
            <Pagination
              page={page}
              count={total}
              color={'primary'}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
        </Box>
      </Box>
      <Box flex={1} />
      <Box
        sx={{
          width: '49%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
        <FilterBar />
        <Box
          raised
          component={Card}
          sx={{
            minHeight: 'calc(100%) - 40px',
            height: '100%',
            maxHeight: 'calc(100%) - 58px',
            overflowY: 'auto',
            p: 2,
          }}>
          <FacturaViewer />
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopView;
