import {Draft, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {initialState} from '.';
import {proveedor} from '../../../services/dto/compras';
import {
  comprasSliceType,
  EditableTypes,
  updateProveedorStoreType,
} from './compras.types';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const actualizarProveedor = (
  state: Draft<comprasSliceType>,
  actions: PayloadAction<updateProveedorStoreType>,
) => {
  state.editable.proveedor = {
    ...state.editable.proveedor,
    ...actions.payload,
  };
};

const setDates = (
  state: Draft<comprasSliceType>,
  actions: PayloadAction<{type: 'start' | 'end'; date: Date}>,
) => {
  actions.payload.date.setHours(0, 0, 0, 0);
  if (actions.payload.type === 'start') {
    state.fecha_inicio = actions.payload.date;
    return;
  }
  state.fecha_final = actions.payload.date;
};

const resetEditables = (
  state: Draft<comprasSliceType>,
  actions: PayloadAction<EditableTypes>,
) => {
  switch (actions.payload) {
    case 'proveedor':
      Object.assign(state.editable.proveedor, initialState.editable.proveedor);
      break;
    default:
      break;
  }
};

const cargarPreveedor = (
  state: Draft<comprasSliceType>,
  actions: PayloadAction<number>,
) => {
  const proveedor = state.proveedores.find(
    element => element.id_proveedor === actions.payload,
  );
  if (!proveedor) return;
  state.editable.proveedor.nombre_proveedor = proveedor.nombre_proveedor;
  state.editable.proveedor.id_proveedor = proveedor.id_proveedor;
  state.editable.proveedor.telefono = proveedor.telefono;
  state.editable.proveedor.correo = proveedor.correo;
  state.editable.proveedor.ruc = proveedor.ruc;
  state.editable.proveedor.categorias = proveedor.categorias_proveedor.map(
    element => element.categoria_insumo_id,
  );
};

const resetComprasSlice = (state: Draft<comprasSliceType>) => {
  Object.assign(state, initialState);
};

const selectCategoria = (
  state: Draft<comprasSliceType>,
  action: PayloadAction<number | null>,
) => {
  state.selectedCategoriaInsumo = action.payload;
};

const filterCompras = (state: Draft<comprasSliceType>) => {
  const compras = state.comprasSucursal.filter(
    compra => compra.categoria_insumo_id === state.selectedCategoriaInsumo,
  );

  let tempProveedores: {[key: number]: proveedor} = {};
  compras.forEach(compra => {
    tempProveedores[compra.proveedor_id] = compra.proveedor;
  });

  state.filteredProveedores = Object.values(tempProveedores);
  state.filteredCompras = compras;
};

export const actions = {
  resetEditables,

  resetComprasSlice,
  actualizarProveedor,
  setDates,
  cargarPreveedor,
  selectCategoria,
  filterCompras,
};
