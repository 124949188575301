import {Box, Stack, Typography} from '@mui/material';
import {fCurrency} from '../../common/formatNumber';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {ReactComponent as UserIcon} from './user.svg';

const BottomText = ({
  time,
  subTotal,
  qtyClients,
  color = 'gray',
}: {
  time: string;
  subTotal: number;
  qtyClients: string;
  color?: 'green' | 'red' | 'yellow' | 'gray' | 'blue';
}) => {
  const green = 'green';
  const red = 'red';
  const yellow = 'orange';
  const gray = 'gray';
  const blue = '#4463fc';

  const getTextColor = (color: string): string => {
    switch (color) {
      case 'green':
        return green;
      case 'red':
        return red;
      case 'blue':
        return blue;
      case 'yellow':
        return yellow;
      default:
        return gray;
    }
  };
  return (
    <Stack
      direction="row"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          <UserIcon
            fill="currentColor"
            stroke="currentColor"
            style={{
              width: 17,
              height: 17,
              color: getTextColor(color),
            }}
          />
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}>
            {qtyClients}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          <AccessTimeIcon sx={{width: 17, mr: 0.1}} />
          <Typography
            noWrap
            sx={{
              fontSize: '10px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}>
            {time}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <Typography
          sx={{
            fontSize: '10px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          sub-total
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {fCurrency(subTotal)}
        </Typography>
      </Box>
    </Stack>
  );
};
export default BottomText;
