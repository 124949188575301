import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialStoreType} from './reportes.type';

const setSelectedRestaurante = (
  state: Draft<initialStoreType>,
  action: PayloadAction<number | null>,
) => {
  state.selectedRestauranteID = action.payload;
};

const setSelectedSucursal = (
  state: Draft<initialStoreType>,
  action: PayloadAction<number | null>,
) => {
  state.selectedSucursalID = action.payload;
};
export const actions = {setSelectedRestaurante, setSelectedSucursal};
