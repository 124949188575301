import {useAppDispatch, useAppSelector} from '../../redux/store';
import LobbyContainer from './lobby-container';
import {useEffect, useState} from 'react';
import {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
} from '../../redux/slices/restaurante-slice';
import {useHistory} from 'react-router-dom';
import {resetOrdenSlice} from '../../redux/slices/orden-slice';
import RestauranteList from './restaurante-list';
import SucursalSelectModal from '../../components/sucursal-select-modal';

function Lobby() {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const EmpleadoAsignadoSucursal = useAppSelector(
    state => state.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );
  const restauranteSeleccionado = useAppSelector(
    state => state.restauranteSlice.restauranteEmpleadoSeleccionado,
  );

  const cargoId = useAppSelector(
    state => state.restauranteSlice.cargoEmpleado?.id_cargo,
  );
  useEffect(() => {
    if (restauranteSeleccionado && EmpleadoAsignadoSucursal) {
      if (cargoId && cargoId === 13) history.push('/menu');
      else history.push('/orden');
    } else if (restauranteSeleccionado && !EmpleadoAsignadoSucursal) {
      setOpen(true);
    } else {
      dispatch(seleccionarRestaurante(null));
      dispatch(seleccionarEmpleadoAsignadoSucursal(null));
      dispatch(resetOrdenSlice());
    }
  }, [EmpleadoAsignadoSucursal, restauranteSeleccionado]);

  return (
    <LobbyContainer>
      <RestauranteList />
      <SucursalSelectModal state={open} handleClose={() => setOpen(false)} />
    </LobbyContainer>
  );
}

export default Lobby;
