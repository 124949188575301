import {useAppDispatch} from '../../redux/store';
import {completarComanda} from '../../redux/slices/comanda-slice/comanda.thunk';
import ConfirmDialog from '../confirm-dialog';

type PropsTypes = {
  state: boolean;
  orden: number;
  handleClose: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function ComandasConfirmDialog({
  state,
  orden,
  handleClose,
  setLoading,
}: PropsTypes) {
  const dispatch = useAppDispatch();

  const handleClickCompleted = async () => {
    setLoading(true);
    await dispatch(completarComanda(orden));
    setLoading(false);
    handleClose();
  };

  return (
    <ConfirmDialog
      state={state}
      handleClose={handleClose}
      handleClickCompleted={handleClickCompleted}
    />
  );
}

export default ComandasConfirmDialog;
