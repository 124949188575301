import yup from '../../../common/yup';

export const menuRestauranteSchema = yup
  .object({
    categoria_producto_id: yup.number().required(),
    subcategoria_producto_id: yup.number().required(),
    nombre_producto: yup.string().required(),
    descripcion_producto: yup.string().required(),
    precio_producto: yup.number().required(),
    sucursales: yup.array(yup.number().required()).min(1).required(),
    modificadores: yup.array(yup.number().required()).required(),
    //solo validar esto al momento de hacer la request, no en el form
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_menu_restaurante: yup.number(),
  })
  .required();

export const menuSucursalSchema = yup
  .object({
    min: yup.number().nullable().min(1),
    max: yup
      .number()
      .nullable()
      .when('min', (min, schema) => (min ? schema.min(min + 1) : schema)),
    actual: yup
      .number()
      .nullable()
      .when('min', (min, schema) => (min ? schema.min(min) : schema)),
    type: yup.boolean().required(),
  })
  .required();

export const subcategoriaSchema = yup
  .object({
    nombre_subcategoria: yup.string().required(),
    descripcion_subcategoria: yup.string().required(),
    categoria_producto_id: yup.number().required(),
    orden: yup.number().required(),
    //solo validar esto al momento de hacer la request, no en el form
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_subcategoria_producto: yup.number(),
  })
  .required();
