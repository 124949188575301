import {MutableRefObject, useState} from 'react';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  IconButton,
  InputAdornment,
  Divider,
  Box,
  Button,
} from '@mui/material';
import {Redirect} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import useMediaQuery from '../../common/hooks/use-media-query';
import {registerUser} from '../../redux/slices/user-slice/user.thunks';
import UploadImages from '../../components/upload-images/UploadImages';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import PoliciesForm from './policies-form';
import {useForm} from 'react-hook-form';
import yup from '../../common/yup';
import {userRegisterSchema} from '../../redux/slices/user-slice/user.schemas';
import {yupResolver} from '@hookform/resolvers/yup';
import {ControlledTextField} from '../../components/form-input/controlled-text-field';
import ControlledSelector from '../../components/form-input/controlled-selector';
import ControlledDatePicker from '../../components/form-input/controlled-date-picker';
import {userFoto} from '../../redux/slices/user-slice/user.types';

// ----------------------------------------------------------------------

export type photoType = {
  file: File;
  preview: string;
};

export default function RegisterForm() {
  const [open, setOpen] = useState<boolean>(false);
  const [reachedBottom, setReactBottom] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState<boolean>(false);

  const [fotos, setfotos] = useState<userFoto>({
    foto_frontal_identificacion: null,
    foto_perfil: null,
    foto_trasera_identificacion: null,
  });

  const onScroll = (ref: MutableRefObject<undefined>) => {
    if (ref.current) {
      const {scrollTop, scrollHeight, clientHeight} = ref.current;
      if (scrollTop + clientHeight > scrollHeight * 0.88) {
        setReactBottom(true);
      }
    }
  };

  const userState = useAppSelector(state => state.userSlice);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const padding = isDesktop ? 10 : 0;

  const onSubmit = async (data: yup.InferType<typeof userRegisterSchema>) => {
    await dispatch(registerUser({...data, fotos}));
    setOpen(false);
    setReactBottom(false);
  };

  const {control, handleSubmit, formState} = useForm<
    yup.InferType<typeof userRegisterSchema>
  >({
    mode: 'onBlur',
    resolver: yupResolver(userRegisterSchema),
  });

  if (userState.isLogged) return <Redirect to="/profile" />;
  return (
    <Box sx={{pl: padding}}>
      <Stack spacing={3}>
        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
          <ControlledTextField
            fullWidth
            label="Nombre"
            controllerProps={{control, name: 'name'}}
            required
            InputProps={{
              style: {backgroundColor: 'white'},
            }}
          />

          <ControlledTextField
            fullWidth
            label="Apellido"
            controllerProps={{control, name: 'lastname'}}
            required
            InputProps={{
              style: {backgroundColor: 'white'},
            }}
          />
        </Stack>

        <ControlledTextField
          fullWidth
          autoComplete="email"
          type="email"
          label="Correo electrónico"
          controllerProps={{control, name: 'email'}}
          required
          InputProps={{
            style: {backgroundColor: 'white'},
          }}
        />

        <Stack spacing={3}>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
            <ControlledTextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              controllerProps={{control, name: 'password'}}
              required
              InputProps={{
                style: {backgroundColor: 'white'},
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(prev => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ControlledTextField
              fullWidth
              autoComplete="verify-password"
              type={showPasswordVerify ? 'text' : 'password'}
              label="Verificar contraseña"
              controllerProps={{control, name: 'verifyPassword'}}
              required
              InputProps={{
                style: {backgroundColor: 'white'},
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPasswordVerify(prev => !prev)}>
                      <Icon icon={showPasswordVerify ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
            <ControlledTextField
              fullWidth
              label="Cédula"
              controllerProps={{control, name: 'dni'}}
              InputProps={{
                style: {backgroundColor: 'white'},
              }}
            />
            <ControlledTextField
              fullWidth
              label="Nick"
              controllerProps={{control, name: 'nick'}}
              InputProps={{
                style: {backgroundColor: 'white'},
              }}
            />
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
            <ControlledSelector
              idKey="value"
              valueKey={['label']}
              sourceData={[
                {value: 1, label: 'Masculino'},
                {value: 2, label: 'Femenino'},
                {value: 3, label: 'Prefiero no decir'},
              ]}
              title="Sexo"
              controllerProps={{control, name: 'id_sexo'}}
              fullWidth
            />
            <ControlledDatePicker
              disableFuture
              label="Fecha de nacimiento"
              controllerProps={{control, name: 'fecha_nacimiento'}}
            />
          </Stack>
        </Stack>

        <Divider />
        <UploadImages
          state={fotos.foto_perfil}
          setState={file => {
            setfotos(prev => ({...prev, foto_perfil: file}));
          }}
          buttonLabel="Subir foto de perfil"
          perfil
        />

        <Divider />
        <UploadImages
          state={fotos.foto_frontal_identificacion}
          setState={file => {
            setfotos(prev => ({...prev, foto_frontal_identificacion: file}));
          }}
          buttonLabel="Subir foto frontal de la cédula"
        />
        <Divider />
        <UploadImages
          state={fotos.foto_trasera_identificacion}
          setState={file => {
            setfotos(prev => ({...prev, foto_trasera_identificacion: file}));
          }}
          buttonLabel="Subir foto trasera de la cédula"
        />
        <Divider />
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={Object.keys(formState.errors).length > 0}>
          Siguiente
        </Button>
        <DialogPopUp
          PaperProps={{
            sx: {
              minWidth: '90%',
              height: '100%',
              position: 'relative',
            },
          }}
          open={open}
          handleClose={() => {
            setOpen(false);
            setReactBottom(false);
          }}
          title="POLÍTICAS DE PRIVACIDAD Y TÉRMINOS & CONDICIONES"
          onScroll={onScroll}>
          <PoliciesForm
            handleClose={() => {
              setOpen(false);
              setReactBottom(false);
            }}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={formState.isSubmitting}
            reachedBottom={reachedBottom}
          />
        </DialogPopUp>
      </Stack>
    </Box>
  );
}
