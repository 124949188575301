import {useState} from 'react';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import {sendEmailPing} from '../../redux/slices/user-slice/user.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import PinCodeForm from './pin-code-form';
import SendPinForm from './send-pin-form';

type PropsTypes = {
  handleClose: () => void;
};

const RecoverPassword = ({handleClose}: PropsTypes) => {
  const [step, setStep] = useState<number>(1);
  const userData = useAppSelector(store => store.userSlice.editable.login);
  const dispatch = useAppDispatch();

  const handleSendEmail = async (): Promise<void> => {
    if (!userData.email) return;
    try {
      await dispatch(sendEmailPing(userData.email)).unwrap();
      setStep(2);
    } catch (error) {}
  };

  const getStep = (step: number) => {
    switch (step) {
      case 1:
        return <SendPinForm handleOnSubmit={handleSendEmail} />;
      case 2:
        return <PinCodeForm handleOnResendCode={handleSendEmail} />;
      default:
        return null;
    }
  };

  return (
    <DialogPopUp open={true} handleClose={handleClose} title="">
      {getStep(step)}
    </DialogPopUp>
  );
};

export default RecoverPassword;
