import {LoadingButton} from '@mui/lab';
import {Card, CardContent, Grid, Stack} from '@mui/material';
import {useEffect} from 'react';
import {actualizarCaja} from '../../redux/slices/restaurante-utils-slice';
import {resetEditable} from '../../redux/slices/restaurante-utils-slice';
import {postCaja} from '../../redux/slices/restaurante-utils-slice/restaurante-utils.thunks';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import NumericTextField from '../form-input/controlled-numeric-text-field';

function NewCajaForm({handleClose}: {handleClose: VoidFunction}) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.uiSlice.isLoading);
  const caja = useAppSelector(
    store => store.restauranteUtilsSlice.editable.caja,
  );

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    await dispatch(postCaja())
      .unwrap()
      .then(x => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  useEffect(() => {
    return () => {
      dispatch(resetEditable('caja'));
    };
  }, []);

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <NumericTextField
                    label="Número de caja"
                    fullWidth
                    value={caja.numero_caja}
                    onChange={value =>
                      dispatch(actualizarCaja({numero_caja: value}))
                    }
                    required
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoading}>
            Guardar Cambios
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewCajaForm;
