import {
  Box,
  Grid,
  Typography,
  Card,
  Stack,
  CardContent,
  Divider,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import UploadImages from '../../components/upload-images/UploadImages';
import {actualizarUserImages} from '../../redux/slices/user-slice';
import {Fragment} from 'react';

const ImagesForm = function ({submitImages}: {submitImages: any}) {
  const dispatch = useAppDispatch();
  const userImage = useAppSelector(
    store => store.userSlice.editable.userImages,
  );

  return (
    <Fragment>
      {/* @ts-ignore */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} sx={{my: 5}}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Box sx={{pb: 5}}>
                <Typography variant="h6">Imagenes de identificación</Typography>
                <Typography variant="body1">
                  Para la validación de su cuenta de IFUDIS necesitamos validar
                  su identificación ( cédula o pasaporte ).
                </Typography>
              </Box>

              <Stack spacing={3}>
                <Stack
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                  direction={{xs: 'column', lg: 'row'}}
                  spacing={2}>
                  <UploadImages
                    state={userImage.foto_frontal_identificacion}
                    setState={file => {
                      dispatch(
                        actualizarUserImages({
                          foto_frontal_identificacion: file,
                        }),
                      );
                    }}
                    buttonLabel="Subir foto frontal"
                  />
                  <Divider orientation="vertical" flexItem />
                  <UploadImages
                    state={userImage.foto_trasera_identificacion}
                    setState={file => {
                      dispatch(
                        actualizarUserImages({
                          foto_trasera_identificacion: file,
                        }),
                      );
                    }}
                    buttonLabel="Subir foto trasera"
                  />
                </Stack>
              </Stack>
              <Grid
                item
                xs={12}
                style={{display: 'flex', justifyContent: 'center'}}
                sx={{my: 2.5}}>
                <LoadingButton
                  size="large"
                  onClick={submitImages}
                  variant="contained"
                  color="secondary"
                  loading={false}
                  // disabled={!(fotoFrontal && fotoTrasera && fotoPerfil)}
                >
                  Actualizar imagenes
                </LoadingButton>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ImagesForm;
