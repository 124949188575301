import {Box} from '@mui/material';
import Logo from '../../components/Logo';
import {Link as RouterLink} from 'react-router-dom';

const LogoIcon = () => {
  return (
    <Box component={RouterLink} to="/" sx={{display: 'inline-flex'}}>
      <Logo sx={{mr: 2}} width={75} height={75} />
    </Box>
  );
};

export default LogoIcon;
