import {Stack, TextField} from '@mui/material';
import {MobileDateTimePicker} from '@mui/lab';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {setDate} from '../../redux/slices/cierre-slice';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};

const DateModal = ({state, handleClose}: PropsTypes) => {
  const dispatch = useAppDispatch();
  const cierreSlice = useAppSelector(store => store.cierreSlice);
  return (
    <DialogPopUp
      open={state}
      handleClose={handleClose}
      title="Seleccione las fechas">
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        spacing={2}>
        <Stack direction="column" spacing={2} flex={1}>
          <MobileDateTimePicker
            label="Fecha inicial"
            ampm
            ampmInClock
            value={cierreSlice.fechaInicial}
            onChange={date => {
              if (!date) return;
              dispatch(setDate({date: date, type: 'inicial'}));
            }}
            disableFuture
            renderInput={params => <TextField {...params} />}
          />
          <MobileDateTimePicker
            label="Fecha final"
            ampm
            ampmInClock
            disableFuture
            minDate={cierreSlice.fechaInicial}
            minDateTime={cierreSlice.fechaInicial}
            value={cierreSlice.fechaFinal}
            onChange={date => {
              if (!date) return;
              dispatch(setDate({date: date, type: 'final'}));
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Stack>
      </Stack>
    </DialogPopUp>
  );
};

export default DateModal;
