import {store} from '../redux/store';

const isRoleAllowed = (allowed: number[], blacklisted?: number[]) => {
  const cargoId = store.getState().restauranteSlice.cargoEmpleado?.id_cargo;
  if (blacklisted && blacklisted.some(element => element === cargoId)) {
    return false;
  }
  if (cargoId === 1 || cargoId === 8) return true;
  if (allowed.length === 1 && allowed[0] === 0) return true;
  if (allowed.some(element => element === cargoId)) {
    return true;
  }
  return false;
};

export default isRoleAllowed;
