import DescuentoPorLineaPopUp from '../descuento-linea-popup';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};

const DescuentoPorLineaModal = ({state, handleClose}: PropsTypes) => {
  return (
    <DialogPopUp
      open={state}
      handleClose={handleClose}
      title="Descuentos por linea">
      <DescuentoPorLineaPopUp handleClose={handleClose} />
    </DialogPopUp>
  );
};

export default DescuentoPorLineaModal;
