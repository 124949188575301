import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {DBtoDate} from '../../../common/formatTime';
import {CierreZDesgloseJSON} from '../../../services/dto/cierre';
import {initialState} from '../user-slice';
import {reporteStateType} from './cierre.types';

const editarEfectivo = (
  state: Draft<reporteStateType>,
  actions: PayloadAction<{index: number; value?: number}>,
) => {
  if (!state.efectivoDeclarado[actions.payload.index]) return;

  state.efectivoDeclarado[actions.payload.index].cantidad =
    actions.payload.value;
};

const editarMetodoPago = (
  state: Draft<reporteStateType>,
  actions: PayloadAction<{index: number; value?: number}>,
) => {
  if (!state.metodoPagoDeclarado[actions.payload.index]) return;

  if (!actions.payload.value) {
    state.metodoPagoDeclarado[actions.payload.index].cantidad = 0;
    return;
  }

  state.metodoPagoDeclarado[actions.payload.index].cantidad =
    actions.payload.value;
};

const calcularEfectivo = (state: Draft<reporteStateType>) => {
  const total = state.efectivoDeclarado.reduce(
    (acc, cur) => acc + (cur.cantidad || 0) * cur.value,
    0,
  );

  state.efectivoTotal = total;
};

const calcularMetodoPago = (state: Draft<reporteStateType>) => {
  const total = state.metodoPagoDeclarado.reduce(
    (acc, cur) => acc + cur.cantidad,
    0,
  );
  state.metodoPagoTotal = total + state.efectivoTotal;
};

const setDate = (
  state: Draft<reporteStateType>,
  actions: PayloadAction<{type: 'inicial' | 'final'; date: Date}>,
) => {
  if (actions.payload.type == 'inicial') {
    state.fechaInicial = actions.payload.date;
    return;
  }
  state.fechaFinal = actions.payload.date;
};

const resetCierreSLice = (state: Draft<reporteStateType>) => {
  Object.assign(state, initialState);
  state.fechaInicial = null;
  state.fechaFinal = new Date();
  state.selectedReporteCaja = null;
  state.observaciones = '';
};

const setCierreType = (
  state: Draft<reporteStateType>,
  actions: PayloadAction<'z' | 'x'>,
) => {
  state.type = actions.payload;
};

const setObservaciones = (
  state: Draft<reporteStateType>,
  actions: PayloadAction<string>,
) => {
  state.observaciones = actions.payload;
};

const preCargarReporteCaja = (
  state: Draft<reporteStateType>,
  actions: PayloadAction<{id: number; loadReporteID?: boolean}>,
) => {
  const reporteCaja = state.reporteCaja.find(
    element => element.id_reporte_caja === actions.payload.id,
  );
  if (!reporteCaja) return;
  if (actions.payload.loadReporteID)
    state.selectedReporteCaja = reporteCaja.id_reporte_caja;

  state.fechaInicial = DBtoDate(reporteCaja.fecha_hora_inicial);
  state.fechaFinal = DBtoDate(reporteCaja.fecha_hora_final);
  state.type = 'z';
  if (!reporteCaja.desglose) return;
  const data = JSON.parse(reporteCaja.desglose) as CierreZDesgloseJSON;

  state.efectivoDeclarado = data.efectivoDesglose;

  state.metodoPagoDeclarado = data.declarado.map(({total, ...rest}) => {
    return {
      ...rest,
      cantidad: rest.id_metodo_pago === 3 ? 0 : total,
    };
  });
};

export const actions = {
  editarEfectivo,
  editarMetodoPago,
  calcularEfectivo,
  calcularMetodoPago,
  setDate,
  resetCierreSLice,
  setCierreType,
  preCargarReporteCaja,
  setObservaciones,
};
