import {TextField, TextFieldProps} from '@mui/material';

import {Control, Controller} from 'react-hook-form';
export type ControllerProps = {
  name: string;
  control: Control<any, any>;
};
type propType = {
  controllerProps: ControllerProps;
} & TextFieldProps;

export function ControlledTextField({
  controllerProps: {control, name},
  ...props
}: propType) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({field, fieldState}) => (
        <TextField {...field} {...props} error={!!fieldState?.error?.message} />
      )}
    />
  );
}
