import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  mesaSucursalSchema,
  seccionSucursalSchema,
} from './restaurante-utils.schemas';
import {msgSuccess} from '../../../common/toast';
import yup from '../../../common/yup';
import {RootState} from '../../../redux/store';
import {api} from '../../../services';
import {CajaEmpleado} from '../../../services/dto/auth';
import {ApodoMesa, MesaSucursal} from '../../../services/dto/orden';
import {
  caja,
  empleadoAsignadoSeccion,
  SeccionSucursal,
  tipoSeccion,
} from '../../../services/dto/restaurante';

export const getSeccionSucursal = createAsyncThunk<
  SeccionSucursal[],
  undefined,
  {state: RootState}
>('restaurantes/getSeccionSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
  const response = await api.getSeccionSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
  );
  if (!response) return thunkAPI.rejectWithValue('No se encontro la sucursal');
  return response.data;
});

export const putPostSeccionSucursal = createAsyncThunk<
  SeccionSucursal[],
  {
    editId?: number;
  } & yup.InferType<typeof seccionSucursalSchema>,
  {state: RootState}
>('restaurantes/putPostSeccionSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    restauranteUtilsSlice: {sucursalSecciones},
    menuSlice: {subcategorias},
  } = thunkAPI.getState();

  const body = await seccionSucursalSchema.validate(
    {
      ...data,
      id_seccion_sucursal: data.editId,
      sucursal_id: empleadoAsignadoSucursalSeleccionado?.sucursal_id,
    },
    {stripUnknown: true, context: {request: true}},
  );

  const copy = [...sucursalSecciones];

  if (!body.id_seccion_sucursal) {
    const response = await api.postSeccionSucursal(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear la seccion');
    msgSuccess('Seccion creada con éxito');
    copy.push(response.data);
  } else {
    const response = await api.putSeccionSucursal(
      body,
      body.id_seccion_sucursal!,
    );
    if (!response) return thunkAPI.rejectWithValue('error al actualizar');

    const index = copy.findIndex(
      e => e.id_seccion_sucursal === response.data.id_seccion_sucursal,
    );
    copy[index] = response.data;
    msgSuccess('Seccion actualizada con éxito');
  }
  return copy;
});

export const deleteSeccionSucursal = createAsyncThunk<
  SeccionSucursal,
  number,
  {state: RootState}
>('restaurantes/deleteSeccionSucursal', async (data, thunkAPI) => {
  const response = await api.deleteSeccionSucursal(data);
  if (!response) return thunkAPI.rejectWithValue('error al eliminar');
  msgSuccess('Seccion eliminada con éxito');
  thunkAPI.dispatch(getSeccionSucursal());
  return response.data;
});

export const getMesaSucursal = createAsyncThunk<
  MesaSucursal[],
  undefined,
  {state: RootState}
>('restaurantes/getMesaSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
  const response = await api.getMesaSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
    true,
  );
  if (!response) return thunkAPI.rejectWithValue('No se encontro la sucursal');
  return response.data;
});

export const getApodosMesa = createAsyncThunk<
  ApodoMesa[],
  undefined,
  {state: RootState}
>('restaurantes/getApodosMEsa', async (arg, thunkAPI) => {
  const response = await api.getApodosMesa();
  if (!response) return thunkAPI.rejectWithValue('No se encontro la sucursal');
  return response.data;
});

export const putPostMesaSucursal = createAsyncThunk<
  null,
  {
    editId?: number;
  } & yup.InferType<typeof mesaSucursalSchema>,
  {state: RootState}
>('restaurantes/putPostMesaSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();

  const body = await mesaSucursalSchema.validate(
    {
      ...data,
      id_mesa_sucursal: data.editId,
      sucursal_id: empleadoAsignadoSucursalSeleccionado?.sucursal_id,
    },
    {stripUnknown: true, context: {request: true}},
  );

  if (!body.id_mesa_sucursal) {
    const response = await api.postMesaSucursal(body);
    if (!response) return thunkAPI.rejectWithValue('error al crear la mesa');
    msgSuccess('Mesa creada con éxito');
    thunkAPI.dispatch(getMesaSucursal());
    return null;
  }
  const response = await api.putMesaSucursal(body, body.id_mesa_sucursal);
  if (!response) return thunkAPI.rejectWithValue('error al actualizar');
  msgSuccess('Mesa actualizada con éxito');
  thunkAPI.dispatch(getMesaSucursal());
  return null;
});

export const deleteMesaSucursal = createAsyncThunk<
  null,
  number,
  {state: RootState}
>('restaurantes/deleteMesaSucursal', async (data, thunkAPI) => {
  const response = await api.deleteMesaSucursal(data);

  if (!response) return thunkAPI.rejectWithValue('Error Al Actualizar');

  msgSuccess('Mesa actualizada con éxito');
  thunkAPI.dispatch(getMesaSucursal());
  return null;
});

export const getCajas = createAsyncThunk<caja[], undefined, {state: RootState}>(
  'restaurantes/getCajas',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    } = thunkAPI.getState();
    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
    const response = await api.getCaja(
      empleadoAsignadoSucursalSeleccionado.sucursal_id,
    );
    if (!response)
      return thunkAPI.rejectWithValue('error al obtener las cajas');
    return response.data;
  },
);

export const getCajasEmpleado = createAsyncThunk<
  CajaEmpleado[],
  undefined,
  {state: RootState}
>('restaurantes/getCajasEmpleado', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
  const response = await api.getCajaEmpleado(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
  );
  if (!response)
    return thunkAPI.rejectWithValue('error al obtener las cajas empleado');
  return response.data;
});

export const postCaja = createAsyncThunk<caja[], undefined, {state: RootState}>(
  'restaurantes/postCaja',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
      restauranteUtilsSlice: {
        editable: {caja},
      },
    } = thunkAPI.getState();
    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

    if (!caja.numero_caja)
      return thunkAPI.rejectWithValue('algun valor es nulo');
    const body = {
      numero_caja: caja.numero_caja,
      sucursal_id: empleadoAsignadoSucursalSeleccionado.sucursal_id,
    };

    const response = await api.postCaja(body);

    if (!response) return thunkAPI.rejectWithValue('error al crear la caja');
    msgSuccess('Caja creada con éxito');
    thunkAPI.dispatch(getCajas());
    return response.data;
  },
);

export const postCajaEmpleado = createAsyncThunk<
  CajaEmpleado[],
  undefined,
  {state: RootState}
>('restaurantes/postCajaEmpleado', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    restauranteUtilsSlice: {
      editable: {cajaEmpleado},
    },
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  if (!cajaEmpleado.caja_sucursal_id || !cajaEmpleado.empleado_sucursal_id)
    return thunkAPI.rejectWithValue('algun valor es nulo');
  const body = {
    caja_sucursal_id: cajaEmpleado.caja_sucursal_id,
    empleado_sucursal_id: cajaEmpleado.empleado_sucursal_id,
    asignador_sucursal_id:
      empleadoAsignadoSucursalSeleccionado.id_empleado_asignado_sucursal,
  };

  const response = await api.postCajaEmpleado(body);

  if (!response)
    return thunkAPI.rejectWithValue('error al crear la caja empleado');
  msgSuccess('Empleado asignado con éxito');
  thunkAPI.dispatch(getCajasEmpleado());
  return response.data;
});

export const deleteCajaEmpleado = createAsyncThunk<
  CajaEmpleado,
  number,
  {state: RootState}
>('restaurantes/deleteCajaEmpleado', async (data, thunkAPI) => {
  const response = await api.deleteCajaEmpleado(data);
  if (!response)
    return thunkAPI.rejectWithValue('error al remover la asignacion');
  msgSuccess('Asignacion removida con éxito');
  thunkAPI.dispatch(getCajasEmpleado());
  return response.data;
});

export const getTipoSeccion = createAsyncThunk<
  tipoSeccion[],
  undefined,
  {state: RootState}
>('restaurantes/getTipoSeccion', async (data, thunkAPI) => {
  const response = await api.getTipoSeccion();
  if (!response)
    return thunkAPI.rejectWithValue('error al obtener los tipos de seccion');
  return response.data;
});

export const getEmpleadoAsignadoSeccion = createAsyncThunk<
  empleadoAsignadoSeccion[],
  boolean | undefined,
  {state: RootState}
>('restaurantes/getEmpleadoAsignadoSeccion', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');
  const response = await api.getEmpleadoAsignadoSeccion(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
  );
  if (!response)
    return thunkAPI.rejectWithValue('error al obtener los empleados');
  return data
    ? response.data.filter(
        element =>
          element.empleado_sucursal_id ===
          empleadoAsignadoSucursalSeleccionado.id_empleado_asignado_sucursal,
      )
    : response.data;
});

export const postEmpleadoAsignadoSeccion = createAsyncThunk<
  empleadoAsignadoSeccion,
  undefined,
  {state: RootState}
>('restaurantes/postEmpleadoAsignadoSeccion', async (data, thunkAPI) => {
  const {
    restauranteUtilsSlice: {
      editable: {empleadoAsignadoSeccion},
    },
  } = thunkAPI.getState();
  if (
    !empleadoAsignadoSeccion.empleado_sucursal_id ||
    !empleadoAsignadoSeccion.seccion_id
  )
    return thunkAPI.rejectWithValue('algun valor es nulo');

  const body = {
    empleado_sucursal_id: empleadoAsignadoSeccion.empleado_sucursal_id,
    seccion_id: empleadoAsignadoSeccion.seccion_id,
  };

  const response = await api.postEmpleadoAsignadoSeccion(body);

  if (!response)
    return thunkAPI.rejectWithValue('error al crear la asignacion');
  msgSuccess('Empleado asignado con éxito');
  thunkAPI.dispatch(getEmpleadoAsignadoSeccion());
  return response.data;
});

export const deleteEmpleadoAsignadoSeccion = createAsyncThunk<
  empleadoAsignadoSeccion,
  number,
  {state: RootState}
>('restaurantes/deleteEmpleadoAsignadoSeccion', async (data, thunkAPI) => {
  if (!data) return thunkAPI.rejectWithValue('error al remover la asignacion');

  const response = await api.deleteEmpleadoAsignadoSeccion(data);

  if (!response)
    return thunkAPI.rejectWithValue('error al remover la asignacion');

  msgSuccess('Asignacion removida con éxito');
  thunkAPI.dispatch(getEmpleadoAsignadoSeccion());
  return response.data;
});
