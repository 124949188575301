import {Button} from '@mui/material';
import {useState} from 'react';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import {useAppSelector} from '../../redux/store';
import DiarioVentasForm from './diario-ventas-form';
import {shallowEqual} from 'react-redux';

const DataRangeDiarioVentas = () => {
  const [open, setOpen] = useState<boolean>(false);

  const fechas = useAppSelector(
    store => ({
      fecha_inicial: store.diarioVentasSlice.fecha_hora_inicial,
      fecha_final: store.diarioVentasSlice.fecha_hora_final,
    }),
    shallowEqual,
  );

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        variant="contained"
        color="secondary">
        Filtros
      </Button>
      <DialogPopUp
        open={open}
        handleClose={() => setOpen(false)}
        title="Filtros">
        <DiarioVentasForm fechas={fechas} setOpen={setOpen} />
      </DialogPopUp>
    </>
  );
};

export default DataRangeDiarioVentas;
