import {Box, Divider, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {fCurrency} from '../../common/formatNumber';
import FacturaBar from './factura-bar';
import {facturaStoreType} from '../../redux/slices/diario-ventas-slice/diario-ventas.type';
import {useAppSelector} from '../../redux/store';
import {Descuento, Impuesto, Propina} from '../../services/dto/orden';
import {useMemo} from 'react';
import {ModificadorProducto, Orden} from '../../services/dto/orden-indirecta';

function FacturaViewer() {
  const facturaSeleccionada = useAppSelector(
    store => store.diarioVentasSlice.factura_seleccionada,
  );

  const facturas = useAppSelector(store => store.diarioVentasSlice.facturas);

  const facturaItem = useMemo(() => {
    const item = facturas.find(
      factura => factura.id_factura_sucursal === facturaSeleccionada,
    );
    if (item) return item;
    return null;
  }, [facturaSeleccionada]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: !facturaItem ? 'center' : 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      {facturaItem ? (
        <>
          <FacturaBar />
          <Heading facturaItem={facturaItem} />
          <Divider
            flexItem
            sx={{my: 1, color: 'black', backgroundColor: 'black'}}
          />
          <ProductList facturaItem={facturaItem} />
        </>
      ) : (
        <Typography variant="body1">
          Seleccione una factura de la lista para visualizar.
        </Typography>
      )}
    </Box>
  );
}

export default FacturaViewer;

const ProductList = ({facturaItem}: {facturaItem: facturaStoreType}) => {
  const listProduct = facturaItem.cliente.ordenes;

  const calcSubTotal = (acc: number, currentValue: Orden) =>
    acc + currentValue.sub_total_a_pagar;

  const calcMisc = (
    acc: number,
    currentValue: Impuesto | Propina | Descuento,
  ) => acc + currentValue.cantidad;

  const subTotal = listProduct.reduce(calcSubTotal, 0);

  const impuestos = facturaItem.impuestos.reduce(calcMisc, 0);

  const propinas = facturaItem.propinas.reduce(calcMisc, 0);

  const descuentos = facturaItem.descuentos.reduce(calcMisc, 0);

  const monto = subTotal - descuentos;

  const total = monto + impuestos;

  return (
    <Box sx={{textAlign: 'center', width: '100%'}}>
      <Stack
        direction="row"
        sx={{justifyContent: 'space-between', width: '100%'}}
        spacing={2}>
        <Typography
          component="h3"
          sx={{lineHeight: 0.9, fontSize: '12px', fontWeight: 'bold'}}>
          DESCRIPCIÓN
        </Typography>
        <Typography
          component="h3"
          sx={{lineHeight: 0.9, fontSize: '12px', fontWeight: 'bold'}}>
          PRECIO
        </Typography>
      </Stack>
      {listProduct.map((producto, index) => (
        <>
          <ProductItem
            modificator={producto.modificador_producto}
            total_price={producto.sub_total_a_pagar}
            key={`${producto.id_orden_producto_menu}-${index}`}
            itemName={producto?.menu_restaurante?.producto?.nombre_producto}
            quantity={producto.cantidad}
            price={producto.precio_unitario}
          />
          <Typography
            noWrap
            align={'left'}
            textAlign={'left'}
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Fecha de pedido: ${dayjs(producto.created_at).format(
              'YYYY-MM-DD hh:mm A',
            )}`}
          </Typography>
        </>
      ))}
      <Divider
        flexItem
        sx={{my: 1, color: 'black', backgroundColor: 'black'}}
      />
      <Stack
        direction="row"
        sx={{justifyContent: 'space-between', width: '100%'}}
        spacing={2}>
        <Typography
          component="h3"
          sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
          SUBTOTAL
        </Typography>
        <Typography
          component="h3"
          sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
          {`${fCurrency(subTotal)}`}
        </Typography>
      </Stack>
      {impuestos ? (
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'normal'}}>
            IMPUESTO (ITBMS)
          </Typography>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
            {`${fCurrency(impuestos)}`}
          </Typography>
        </Stack>
      ) : (
        ''
      )}
      {descuentos ? (
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'normal'}}>
            DESCUENTOS
          </Typography>
          <Typography
            component="h3"
            sx={{
              lineHeight: 1,
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'red',
            }}>
            {`-${fCurrency(descuentos)}`}
          </Typography>
        </Stack>
      ) : (
        ''
      )}
      {propinas ? (
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'normal'}}>
            PROPINAS
          </Typography>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
            {`${fCurrency(propinas)}`}
          </Typography>
        </Stack>
      ) : (
        ''
      )}
      {propinas ? (
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
            TOTAL SIN PROPINAS
          </Typography>
          <Typography
            component="h3"
            sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
            {`${fCurrency(total)}`}
          </Typography>
        </Stack>
      ) : (
        ''
      )}
      <Stack
        direction="row"
        sx={{justifyContent: 'space-between', width: '100%'}}
        spacing={2}>
        <Typography
          component="h3"
          sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'normal'}}>
          TOTAL
        </Typography>

        <Typography
          component="h3"
          sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
          {`${fCurrency(total + propinas)}`}
        </Typography>
      </Stack>
      {facturaItem.metodo_pago.length ? (
        <Divider
          flexItem
          sx={{my: 1, color: 'black', backgroundColor: 'black'}}
        />
      ) : (
        ''
      )}
      {facturaItem.metodo_pago.map(metodo => {
        return (
          <Stack
            key={`metodo${metodo.metodo_pago_id}`}
            direction="row"
            sx={{justifyContent: 'space-between', width: '100%'}}
            spacing={2}>
            <Typography
              component="h3"
              sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'normal'}}>
              {metodo.metodo_pago.nombre_metodo_pago}
            </Typography>

            <Typography
              component="h3"
              sx={{lineHeight: 1, fontSize: '14px', fontWeight: 'bold'}}>
              {`${fCurrency(metodo.cantidad)}`}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
};

const ProductItem = ({
  itemName,
  quantity,
  price,
  total_price,
  modificator,
}: {
  itemName: string;
  quantity: number;
  price: number;
  total_price: number;
  modificator: ModificadorProducto[];
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        mt: 0.5,
      }}>
      <Stack width="100%" direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="flex-start">
          <Typography
            noWrap
            sx={{
              lineHeight: 1.2,
              fontSize: '13px',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}>
            {quantity}
            <span>&nbsp;&nbsp;</span>
          </Typography>
          <Typography
            noWrap
            sx={{
              lineHeight: 1.2,
              fontSize: '13px',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}>
            {itemName ? itemName.toLowerCase() : ''}
          </Typography>
        </Stack>
        <Typography
          sx={{lineHeight: 1.2, fontSize: '14px', fontWeight: 'bold'}}>
          {`${itemName ? fCurrency(total_price) : ''}`}
        </Typography>
      </Stack>
      {modificator.map(
        (
          {
            id_modificador_p_ordenado,
            modificador_menu: {
              accion_asignada_modificador: {accion, modificador},
            },
          },
          index,
        ) => {
          if (!modificador.precio) return null;
          const shouldShowPrice =
            accion.id_accion_modificador !== 2 &&
            accion.id_accion_modificador !== 3;
          if (!shouldShowPrice) return null;

          return (
            <Stack
              key={id_modificador_p_ordenado}
              width="100%"
              mb="2px"
              direction="row"
              justifyContent="space-between">
              <Typography
                sx={{
                  lineHeight: 1.2,
                  fontSize: '12px',
                }}>
                + {modificador.nombre_modificador}
              </Typography>
              <Typography
                sx={{
                  lineHeight: 1.2,
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}>
                ${modificador.precio}
              </Typography>
            </Stack>
          );
        },
      )}
    </Box>
  );
};

const Heading = ({facturaItem}: {facturaItem: facturaStoreType}) => {
  return (
    <>
      <Box sx={{textAlign: 'center'}}>
        <Typography
          component="h3"
          sx={{lineHeight: 0.9, fontSize: '18px', fontWeight: 'bold'}}>
          FACTURA
        </Typography>
        <Typography
          sx={{lineHeight: 1.2, fontSize: '12px', fontWeight: 'normal'}}>
          Auxiliar de factura electrónica
        </Typography>
      </Box>
      <Divider
        flexItem
        sx={{my: 1, color: 'black', backgroundColor: 'black'}}
      />
      <Box sx={{textAlign: 'center', width: '100%'}}>
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Número:  ${facturaItem.id_factura_sucursal}`}
          </Typography>
          <Typography
            noWrap
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Mesa: #${facturaItem.cliente.numero_mesa}`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            noWrap
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Fecha de emisión: ${dayjs(facturaItem.fecha_hora_factura).format(
              'YYYY-MM-DD hh:mm A',
            )}`}
          </Typography>
          <Typography
            noWrap
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Cajero: ${
              facturaItem.caja_empleado.empleado_sucursal.empleado
                ? facturaItem.caja_empleado.empleado_sucursal.empleado.usuario
                    .name
                : ''
            }  ${
              facturaItem.caja_empleado.empleado_sucursal.empleado
                ? facturaItem.caja_empleado.empleado_sucursal.empleado.usuario
                    .lastname
                : ''
            } `}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between', width: '100%'}}
          spacing={2}>
          <Typography
            noWrap
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Cliente: ${facturaItem.cliente.nombre_apellido}`}
          </Typography>
          <Typography
            noWrap
            sx={{lineHeight: 1.2, fontSize: '11px', fontWeight: 'normal'}}>
            {`Mesero: ${facturaItem.cliente.host.name} ${facturaItem.cliente.host?.lastname}`}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
