import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {initialState} from '.';
import {
  editablesType,
  updateUserImagesType,
  updateUserLoginType,
  updateUserRegister,
  updateUserStoreType,
  UserStateType,
} from './user.types';

const actualizarLogin = (
  state: Draft<UserStateType>,
  action: PayloadAction<updateUserLoginType>,
) => {
  state.editable.login = {...state.editable.login, ...action.payload};
};

const actualizarRegister = (
  state: Draft<UserStateType>,
  action: PayloadAction<updateUserRegister>,
) => {
  state.editable.register = {...state.editable.register, ...action.payload};
};

const actualizarUser = (
  state: Draft<UserStateType>,
  action: PayloadAction<updateUserStoreType>,
) => {
  state.editable.user = {...state.editable.user, ...action.payload};
};

const resetEditables = (
  state: Draft<UserStateType>,
  action: PayloadAction<editablesType>,
) => {
  switch (action.payload) {
    case 'user':
      Object.assign(state.editable.user, initialState.editable.user);
      Object.assign(
        state.editable.userImages,
        initialState.editable.userImages,
      );
      break;
    case 'register':
      Object.assign(state.editable.register, initialState.editable.register);
      break;
    case 'login':
      Object.assign(state.editable.login, initialState.editable.login);
      break;
    default:
      break;
  }
};

const actualizarUserImages = (
  state: Draft<UserStateType>,
  action: PayloadAction<updateUserImagesType>,
) => {
  state.editable.userImages = {...state.editable.userImages, ...action.payload};
};

const ResetUserSlice = (state: Draft<UserStateType>) => {
  return initialState;
};

export const actions = {
  actualizarLogin,
  actualizarRegister,
  actualizarUser,
  resetEditables,
  actualizarUserImages,
  ResetUserSlice,
};
