import {Stack, Typography} from '@mui/material';
import {memo} from 'react';
import {ReactComponent as AccountIcon} from './cuenta.svg';

const UpperText = ({
  mesaNumber,
  hostname,
  qtyAccountsOpen,
  clientName,
  apodo,
  color = 'gray',
}: {
  mesaNumber: number;
  hostname?: string;
  clientName?: string;
  qtyAccountsOpen: number;
  apodo?: string;
  color?: 'green' | 'red' | 'yellow' | 'gray' | 'blue';
}) => {
  const green = 'green';
  const red = 'red';
  const yellow = 'orange';
  const gray = 'gray';
  const blue = '#4463fc';

  const getTextColor = (color: string): string => {
    switch (color) {
      case 'green':
        return green;
      case 'red':
        return red;
      case 'blue':
        return blue;
      case 'yellow':
        return yellow;
      default:
        return gray;
    }
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {apodo ? apodo : 'Mesa '} #{mesaNumber}
        </Typography>
        {qtyAccountsOpen > 0 && (
          <>
            <AccountIcon
              fill="currentColor"
              stroke="currentColor"
              style={{
                width: 25,
                height: 25,
                position: 'absolute',
                top: 0,
                right: '18px',
                color: getTextColor(color),
              }}
            />
            <Typography
              sx={{
                position: 'absolute',
                top: '1px',
                right: '10px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}>
              {qtyAccountsOpen}
            </Typography>
          </>
        )}
      </Stack>
      <Stack
        direction="column"
        sx={{display: 'flex', justifyContent: 'space-between', mt: 0.5}}>
        {hostname && (
          <Typography
            noWrap
            sx={{
              fontSize: '10px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              maxWidth: '85%',
            }}>
            Mesero: {hostname}
          </Typography>
        )}
        {clientName && (
          <Typography
            noWrap
            sx={{
              fontSize: '10px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}>
            Cliente: {clientName}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default memo(UpperText);
