import {LoadingButton} from '@mui/lab';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {
  getOrdenMesas,
  moveClient,
} from '../../redux/slices/orden-slice/orden.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {ClientesAsignadosMesa} from '../../services/dto/orden';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

type PropsTypes = {
  handleClose: () => void;
};

const DividirUnirForm = ({handleClose}: PropsTypes) => {
  const mesaA = useAppSelector(store => store.ordenSlice.uiData.mesaA);
  const mesaB = useAppSelector(store => store.ordenSlice.uiData.mesaB);
  const dispatch = useAppDispatch();
  const clientesMesaA = useAppSelector(store =>
    store.ordenSlice.uiData.mesas.find(mesa => mesa.id_mesa_sucursal === mesaA),
  )?.clientes_asignados_mesa;
  const clientesMesaB = useAppSelector(store =>
    store.ordenSlice.uiData.mesas.find(mesa => mesa.id_mesa_sucursal === mesaB),
  )?.clientes_asignados_mesa;

  const [selectedClients, setSelectedClients] = useState<
    ClientesAsignadosMesa[]
  >([]);

  const [loading, setloading] = useState(false);

  const handleOnMarcarTodos = () => {
    if (selectedClients.length === clientesMesaA?.length)
      return setSelectedClients([]);

    if (clientesMesaA) setSelectedClients(clientesMesaA);
  };

  const handleCheckClient = (
    isSelected: boolean,
    cliente: ClientesAsignadosMesa,
  ) => {
    if (isSelected)
      return setSelectedClients(prev =>
        prev.filter(element => element.cliente_id != cliente.cliente_id),
      );

    setSelectedClients(prev => [...prev, cliente]);
  };

  const handleOnSaveChanges = async () => {
    setloading(true);
    await dispatch(moveClient({clients: selectedClients}));
    setloading(false);
    handleClose();
    await dispatch(getOrdenMesas());
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      gap={1}
      sx={{width: '100%', minHeight: '100%', position: 'relative'}}>
      <Stack
        direction="row"
        spacing={1}
        gap={1}
        sx={{width: '100%'}}
        overflow="scroll">
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              my: 2,
              textAlign: 'center',
            }}>
            Mesa A
          </Typography>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedClients.length === clientesMesaA?.length}
                  onChange={handleOnMarcarTodos}
                  name="Marcar todo"
                />
              }
              label={`Marcar todo`}
            />
          </FormControl>
          <Divider sx={{width: '100%'}} />
          <FormControl component="fieldset" variant="standard">
            {clientesMesaA?.map(cliente => {
              const isSelected = selectedClients.some(
                client => client.cliente_id === cliente.cliente_id,
              );
              return (
                <Box
                  key={cliente.cliente_id}
                  component={Stack}
                  gap={1}
                  spacing={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => handleCheckClient(isSelected, cliente)}
                        checked={isSelected}
                      />
                    }
                    label={cliente.cliente.nombre_apellido}
                  />
                </Box>
              );
            })}
          </FormControl>
        </Box>
        <ArrowRightAltIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '48px',
          }}
        />
        <List sx={{width: '50%'}}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              my: 2,
              textAlign: 'center',
            }}>
            Mesa B
          </Typography>
          {clientesMesaB?.map(cliente => (
            <ListItemText
              key={cliente.cliente_id}
              primary={cliente.cliente.nombre_apellido}
              primaryTypographyProps={{
                style: {
                  fontSize: '14px',
                  textAlign: 'center',
                  marginTop: '15px',
                  marginBottom: '15px',
                },
              }}
            />
          ))}
        </List>
      </Stack>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <LoadingButton
          fullWidth
          disabled={!selectedClients.length}
          loading={loading}
          onClick={handleOnSaveChanges}
          sx={{maxWidth: '200px', alignSelf: 'center'}}
          variant="contained"
          color="secondary">
          Guardar cambios
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default DividirUnirForm;
