import {useHistory} from 'react-router-dom';
import {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
} from '../../redux/slices/restaurante-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import NavBarLabel from '../navbar-labels/navbar-labels';

function RestauranteSucursalLabel({setOpen}: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const restauranteSeleccionado = useAppSelector(
    state => state.restauranteSlice.restauranteEmpleadoSeleccionado,
  );

  const nombreRestaurante = restauranteSeleccionado
    ? restauranteSeleccionado.nombre
    : '';

  const restaurantesDisponibles = useAppSelector(
    store => store.restauranteSlice.restauranteEmpleados.length,
  );

  const empleadoAsignadoSucursalSeleccionado = useAppSelector(
    state => state.restauranteSlice.empleadoAsignadoSucursalSeleccionado,
  );

  const sucursalesEmpleadoAsignado = useAppSelector(
    state => state.restauranteSlice.sucursalesEmpleadoAsignado,
  );

  return (
    <>
      {nombreRestaurante && (
        <NavBarLabel
          onClick={() => {
            dispatch(seleccionarRestaurante(null));
            dispatch(seleccionarEmpleadoAsignadoSucursal(null));
            history.push('/');
          }}
          text={nombreRestaurante}
          hovertext={`${
            restaurantesDisponibles > 1
              ? 'Cambiar de restaurante'
              : nombreRestaurante
          }`}
        />
      )}
      {empleadoAsignadoSucursalSeleccionado?.sucursal && (
        <NavBarLabel
          text={
            empleadoAsignadoSucursalSeleccionado?.sucursal.descripcion_ubicacion
          }
          hovertext={`${
            sucursalesEmpleadoAsignado.length > 1
              ? 'Cambiar de sucursal'
              : empleadoAsignadoSucursalSeleccionado?.sucursal
                  .descripcion_ubicacion
          }`}
          onClick={() => {
            if (sucursalesEmpleadoAsignado.length > 1) {
              setOpen(true);
            }
          }}
        />
      )}
    </>
  );
}

export default RestauranteSucursalLabel;
