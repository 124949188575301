import {Box, Card} from '@mui/material';
import {ReactNode} from 'react';
import Header from './header';

type PropsTypes = {
  children: ReactNode;
};

const ContainerBox = ({children}: PropsTypes) => {
  return (
    <Box
      sx={{
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
      }}>
      <Header />
      <Box
        raised
        component={Card}
        sx={{
          width: '100%',
          minHeight: '90%',
          maxHeight: '90%',
          p: 1,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default ContainerBox;
