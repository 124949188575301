import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {actions} from './comanda.actions';
import {
  completarComanda,
  getComandas,
  selectedCurrrentSeccion,
} from './comanda.thunk';
import {comandaSliceType} from './comanda.type';

export const initialState = {
  comadas: [],
  currentSelectedSeccion: null,
  lastComandaId: 0,
  lastNotifyDate: 0,
  historyDate: dayjs().startOf('day').toDate(),
} as comandaSliceType;

export const comandaSlice = createSlice({
  name: 'comanda-slice',
  initialState: initialState,
  reducers: {
    ...actions,
  },
  extraReducers: builder => {
    builder
      .addCase(getComandas.fulfilled, (state, action) => {
        const maxComanda = Math.max(
          ...action.payload.map(comanda => comanda.id_codigo_orden),
        );
        state.comadas = action.payload;
        state.lastComandaId = maxComanda || 0;
      })
      .addCase(selectedCurrrentSeccion.fulfilled, (state, action) => {
        state.currentSelectedSeccion = action.payload;
      })
      .addCase(completarComanda.fulfilled, (state, action) => {});
  },
});

export const {resetComandaSlice, resetComandas, onHistoryDateChanged} =
  comandaSlice.actions;

export default comandaSlice.reducer;
