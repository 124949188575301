import {useAppDispatch, useAppSelector} from '../../redux/store';
import {Stack, Typography} from '@mui/material';
import TitleText from '../title-text/title-text';
import CustomButton from '../custom-button/custom-button';
import {Box} from '@mui/material';

import {
  calcularMetodosPagos,
  setCantidadMetodoPago,
} from '../../redux/slices/orden-slice';
import {memo, useCallback, useEffect} from 'react';

import {editOrdenMetodoPago} from '../../redux/slices/orden-slice/orden.thunks';
import NumericTextField from '../form-input/controlled-numeric-text-field';

function MetodoPagoList() {
  const stateMetodoPagos = useAppSelector(
    state => state.finanzasSlice.metodosPago,
  );

  const stateMetodoPagosOrden = useAppSelector(
    state => state.ordenSlice.orden.clienteSeleccionado?.metodosPago,
  );
  const stateTotalOrden = useAppSelector(
    state => state.ordenSlice.cuenta.total,
  );
  const dispatch = useAppDispatch();

  const handleTextFieldChange = useCallback((id: number, cantidad?: number) => {
    dispatch(
      setCantidadMetodoPago({
        id,
        cantidad,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(calcularMetodosPagos());
  }, [stateMetodoPagosOrden?.metodos, stateTotalOrden]);

  return (
    <Stack sx={{mb: 1}}>
      <TitleText text="metodo de pago" />
      <Box
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          display: 'flex',
          flexWrap: 'wrap',
        }}>
        {stateMetodoPagos.map((value, index) => {
          const selected = stateMetodoPagosOrden?.metodos.find(
            element =>
              element.metodoPago.id_metodo_pago_restaurante ===
              value.id_metodo_pago_restaurante,
          );
          return (
            <Box
              key={value.id_metodo_pago_restaurante}
              sx={{
                mb: 1,
                mr: 2,
              }}>
              <CustomButton
                noBorderRadius={
                  !!selected &&
                  stateMetodoPagosOrden &&
                  stateMetodoPagosOrden?.metodos.length > 1
                }
                isselected={!!selected}
                name={value.metodo_pago.nombre_metodo_pago}
                addEm={() => {
                  dispatch(
                    editOrdenMetodoPago({
                      type: 'togglear',
                      id: value.id_metodo_pago_restaurante,
                    }),
                  );
                }}
              />
              {!!selected &&
                stateMetodoPagosOrden &&
                stateMetodoPagosOrden?.metodos.length > 1 && (
                  <Box
                    sx={{
                      height: '30px',
                      border: '2px solid #f59823',
                      px: '2px',
                      display: 'flex',
                      alignItems: 'flex-end',
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }}>
                    <NumericTextField
                      type="money"
                      sx={{textAlign: 'center'}}
                      variant="standard"
                      InputLabelProps={{
                        style: {fontSize: '0.9rem', fontWeight: 'bold'},
                      }}
                      InputProps={{
                        style: {
                          fontSize: '0.9rem',
                          fontWeight: 'bold',
                          height: 25,
                          padding: 5,
                        },
                      }}
                      value={selected.cantidad}
                      onChange={value => {
                        handleTextFieldChange(
                          selected.metodoPago.id_metodo_pago_restaurante,
                          value,
                        );
                      }}
                      required
                    />
                  </Box>
                )}
            </Box>
          );
        })}
      </Box>

      <>
        {stateMetodoPagosOrden && stateMetodoPagosOrden?.metodos.length > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexGrow: 1,
              alignItems: 'center',
            }}>
            <Typography
              color="red"
              sx={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
                mr: 2,
              }}>
              PENDIENTE: ${stateMetodoPagosOrden?.restante.toFixed(2)}
            </Typography>
            <Typography
              color="red"
              sx={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
              }}>
              TOTAL: ${stateTotalOrden.toFixed(2)}
            </Typography>
          </Box>
        )}
      </>
    </Stack>
  );
}

export default memo(MetodoPagoList);
