import {TableBody, TableCell, TableRow} from '@mui/material';
import getNestedValue from '../../common/getNestedValue';
import {ChangeEvent, useEffect, useState} from 'react';
import {useDebouncedValue} from '../../common/hooks/use-debounced-value';
import TableRowButton from './table-row-buttons';
import TableWrapper from './table-wrapper';
import TableHeader from './table-header';
import FooterTable from './table-footer';
import EmptyTableRow from './table-empty-row';
import TableGetContent from './table-get-content';

type CrudTableProps = {
  title?: string;
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
  rows: any;
  OnEdit?: (arg: number) => void;
  onView?: (arg: number) => void;
  onRowClick?: (arg: number) => void;
  onDelete?: (arg: number) => void;
  onExport?: (arg: number) => void;
  onPreload?: (arg: number) => void;
  onAnular?: (arg: number) => Promise<void>;
  onSwitch?: (arg: boolean, id: number) => void;
  onSwitchRender?: (id: number) => {disabled: boolean};

  setOpenCrear?: (arg: boolean) => void;
  onSearch?: (row: any, searchTerm: string) => any;
  order?: 'asc' | 'desc';
  orderBy?: string;
  sortHandler?: (key: string, allKeys: string[]) => void;
  columnBlacklisted?: string[];
  isSelected?: (row: any) => boolean;
  noCard?: boolean;
  onEditCompra?: (id_purchase: number) => void;
  onAddCompra?: (id_proveedor: number) => void;
  onNewCompra?: () => void;
  total?: number;
};

const CrudTable = ({
  title,
  noCard = false,
  columns,
  rows,
  OnEdit,
  onView,
  onDelete,
  onSwitch,
  onSwitchRender,
  onExport,
  onRowClick,
  setOpenCrear,
  onSearch,
  order,
  orderBy,
  sortHandler,
  columnBlacklisted,
  isSelected,
  onEditCompra,
  onAddCompra,
  onNewCompra,
  onAnular,
  onPreload,
  total,
}: CrudTableProps) => {
  const [sortedRows, setSortedRows] = useState(rows);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debauncedValue = useDebouncedValue({value: searchTerm, delay: 400});

  const filterFormula = (row: any) => {
    if (onSearch) return onSearch(row, searchTerm);
    return true;
  };

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  useEffect(() => {
    if (onSearch) return setSortedRows(rows.filter(filterFormula));
    setSortedRows(rows);
  }, [debauncedValue, rows]);

  return (
    <TableWrapper
      title={title}
      totalRows={total ? total : sortedRows.length}
      setOpenCrear={setOpenCrear}
      onSearch={onSearch}
      searchTerm={searchTerm}
      handleOnSearch={handleOnSearch}
      noCard={noCard}>
      <>
        <TableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          sortHandler={sortHandler}
          columnBlacklisted={columnBlacklisted}
        />
        <TableBody>
          {sortedRows.length ? (
            sortedRows.map((row: any, indexRows: number) => {
              const selected = isSelected ? isSelected(row) : false;
              return (
                <TableRow
                  key={indexRows}
                  hover
                  style={{
                    backgroundColor: selected ? '#f597231d' : undefined,
                    cursor: 'pointer',
                  }}>
                  {columns.map((column, index) =>
                    index === columns.length - 1 ? (
                      <TableRowButton
                        key={index}
                        OnEdit={OnEdit}
                        onView={onView}
                        onDelete={onDelete}
                        onExport={onExport}
                        onAnular={onAnular}
                        onPreload={onPreload}
                        column={column}
                        row={row}
                      />
                    ) : (
                      <TableCell
                        onClick={
                          onRowClick
                            ? () =>
                                onRowClick(
                                  getNestedValue(
                                    row,
                                    columns[columns.length - 1].key,
                                  ),
                                )
                            : undefined
                        }
                        key={index}
                        align={
                          column.label === 'Precio\u00A0(USD)'
                            ? 'center'
                            : 'left'
                        }>
                        <TableGetContent
                          keyArray={column}
                          valueArray={row}
                          id_registro={columns[columns.length - 1].key[0]}
                          columns={columns}
                          onSwitch={onSwitch}
                          OnEdit={OnEdit}
                          onEditCompra={onEditCompra}
                          onAddCompra={onAddCompra}
                          onSwitchRender={onSwitchRender}
                        />
                      </TableCell>
                    ),
                  )}
                </TableRow>
              );
            })
          ) : (
            <EmptyTableRow columns={columns} />
          )}
        </TableBody>
        <FooterTable
          columns={columns}
          sortedRows={sortedRows}
          onNewCompra={onNewCompra}
        />
      </>
    </TableWrapper>
  );
};

export default CrudTable;
