import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {daysOfTheWeek} from '../../../common/formatTime';

import {actions} from './reportes.actions';
import {
  getReporteArticulosBorrados,
  getReporteBalanceActual,
  getReporteContadores,
  getReporteFacturasAnuladas,
  getReportePorCategoria,
  getReportePorMesa,
  getReportePorMetodoPago,
  getReportePorSeccion,
  getReportePorSubCategoria,
  getReportePorTiempo,
  getReportePorUsuario,
  getReporteVentasPorArticulo,
  getReporteVentasPorDescuentos,
} from './reportes.thunks';

import {initialStoreType} from './reportes.type';
import {cierreMap, reporteContadorMap} from '../../../services/dto/reportes';

//initialState

export const initialState = {
  reporteBalanceActual: {
    reporte: null,
    planilla: 0,
    fecha_inicio: dayjs().startOf('month').toDate(),
    fecha_final: new Date(),
    isInventarioFilterActive: false,
  },
  reporteVentasPorArticulo: {
    reporte: [],
    isGlobal: false,
  },
  reporteArticulosBorrados: {
    reporte: [],
    isGlobal: false,
  },
  reporteVentasPorMetodoPago: {
    reporte: [],
    isGlobal: false,
    total_ventas: 0,
    total_impuestos: 0,
    total_propinas: 0,
  },
  fecha: {
    fecha_inicio: dayjs().startOf('month').toDate(),
    fecha_final: new Date(),
    refetch: 0,
  },
  reporteVentasRestaurante: {
    reporte: [],
  },
  reporteVentasPorCatergoria: {
    reporte: [],
    isGlobal: false,
  },
  reporteVentasPorSubCatergoria: {
    reporte: [],
    isGlobal: false,
  },
  reportePorDescuento: {
    reporte: [],
  },
  reporteFactorasAnuladas: {
    reporte: [],
    isGlobal: false,
  },
  reporteVentasPorTiempo: {
    reporteDia: [],
    reporteHora: [],
    isGlobal: false,
  },
  reporteVentasPorMesa: {
    reporte: [],
    isGlobal: false,
  },
  reporteVentasPorSeccion: {
    reporte: [],
    isGlobal: false,
  },
  reporteVentasPorUsuario: {reporte: [], isGlobal: false},
  reporteContador: {
    reporte: {},
  },
} as initialStoreType;

//slice

export const reportes = createSlice({
  name: 'reportes',
  initialState: initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(getReporteBalanceActual.fulfilled, (state, action) => {
        state.reporteBalanceActual.reporte = {
          ...action.payload,
          inventarios: {
            inicial: action.payload.inventarios.inicial ?? {},
            final: action.payload.inventarios.final ?? {},
          },
        };
      })
      .addCase(getReporteVentasPorArticulo.fulfilled, (state, action) => {
        const total = action.payload.ventas.reduce(
          (acc, curr) => acc + curr.total_ventas_producto,
          0,
        );
        state.reporteVentasPorArticulo.reporte = action.payload.ventas.map(
          venta => ({
            percentage: (venta.total_ventas_producto / total) * 100,
            ...venta,
          }),
        );
      })
      .addCase(getReporteArticulosBorrados.fulfilled, (state, action) => {
        state.reporteArticulosBorrados.reporte = action.payload;
      })
      .addCase(getReportePorMetodoPago.fulfilled, (state, action) => {
        const total = action.payload.ventas.reduce(
          (acc, curr) => acc + curr.total,
          0,
        );
        state.reporteVentasPorMetodoPago.reporte = action.payload.ventas.map(
          venta => ({
            percentage: (venta.total / total) * 100,
            ...venta,
          }),
        );
        state.reporteVentasPorMetodoPago.total_impuestos =
          action.payload.total_impuestos;
        state.reporteVentasPorMetodoPago.total_propinas =
          action.payload.total_propinas;
        state.reporteVentasPorMetodoPago.total_ventas =
          action.payload.total_ventas;
      })
      .addCase(getReportePorTiempo.fulfilled, (state, action) => {
        const total = action.payload.reduce((acc, curr) => acc + curr.total, 0);

        if (action.meta.arg === 'dia') {
          state.reporteVentasPorTiempo.reporteDia = action.payload.map(
            venta => ({
              percentage: (venta.total / total) * 100,
              ...venta,
              value: daysOfTheWeek[Number(venta.value) - 1],
            }),
          );
        } else {
          state.reporteVentasPorTiempo.reporteHora = action.payload.map(
            venta => ({
              percentage: (venta.total / total) * 100,
              ...venta,
            }),
          );
        }
      })
      .addCase(getReportePorUsuario.fulfilled, (state, action) => {
        const total = action.payload.reduce((acc, curr) => acc + curr.total, 0);
        state.reporteVentasPorUsuario.reporte = action.payload.map(venta => ({
          percentage: (venta.total / total) * 100,
          ...venta,
        }));
      })
      .addCase(getReportePorMesa.fulfilled, (state, action) => {
        const total = action.payload.reduce((acc, curr) => acc + curr.total, 0);
        state.reporteVentasPorMesa.reporte = action.payload.map(venta => ({
          percentage: (venta.total / total) * 100,
          ...venta,
        }));
      })
      .addCase(getReportePorSeccion.fulfilled, (state, action) => {
        const total = action.payload.reduce((acc, curr) => acc + curr.total, 0);
        state.reporteVentasPorSeccion.reporte = action.payload.map(venta => ({
          percentage: (venta.total / total) * 100,
          ...venta,
        }));
      })
      .addCase(getReportePorCategoria.fulfilled, (state, action) => {
        const total = action.payload.reduce((acc, curr) => acc + curr.monto, 0);
        state.reporteVentasPorCatergoria.reporte = action.payload.map(
          venta => ({
            percentage: (venta.monto / total) * 100,
            ...venta,
          }),
        );
      })
      .addCase(getReportePorSubCategoria.fulfilled, (state, action) => {
        const total = action.payload.reduce((acc, curr) => acc + curr.total, 0);
        state.reporteVentasPorSubCatergoria.reporte = action.payload.map(
          venta => ({
            percentage: (venta.total / total) * 100,
            ...venta,
          }),
        );
      })
      .addCase(getReporteFacturasAnuladas.fulfilled, (state, action) => {
        state.reporteFactorasAnuladas.reporte = action.payload;
      })
      .addCase(getReporteVentasPorDescuentos.fulfilled, (state, action) => {
        state.reportePorDescuento.reporte = action.payload;
      })
      .addCase(getReporteContadores.fulfilled, (state, action) => {
        const reportes: reporteContadorMap = {};
        Object.keys(action.payload.cierres).forEach(fecha => {
          let totalCierres = 0;
          const cierres = action.payload.cierres[fecha]
            .map<cierreMap>(cierre => {
              return cierre.desglose.declarado.reduce<cierreMap>(
                (previousValue, currentValue) => {
                  totalCierres += currentValue.total;
                  if (previousValue[currentValue.nombre_metodo_pago]) {
                    previousValue[currentValue.nombre_metodo_pago].total +=
                      currentValue.total;
                  } else {
                    previousValue[currentValue.nombre_metodo_pago] =
                      currentValue;
                  }
                  return previousValue;
                },
                {},
              );
            })
            .reduce<cierreMap>((previousValue, currentValue) => {
              Object.keys(currentValue).forEach(metodo => {
                if (previousValue[metodo]) {
                  previousValue[metodo].total += currentValue[metodo].total;
                } else previousValue[metodo] = currentValue[metodo];
              });
              return previousValue;
            }, {});
          reportes[fecha] = {
            cierres,
            totalCierres,
            totalFacturas: 0,
            propina: 0,
            impuestos: 0,
          };
        });
        Object.keys(action.payload.facturas).forEach(fecha => {
          const totalFacturas = action.payload.facturas[fecha].reduce<number>(
            (previousValue, currentValue) => {
              return previousValue + currentValue.total;
            },
            0,
          );
          if (reportes[fecha]) {
            reportes[fecha].facturas = action.payload.facturas[fecha];
            reportes[fecha].totalFacturas = totalFacturas;
          } else {
            reportes[fecha] = {
              facturas: action.payload.facturas[fecha],
              totalCierres: 0,
              totalFacturas,
              propina: 0,
              impuestos: 0,
            };
          }
        });
        action.payload.propinas.forEach((propina, index) => {
          if (reportes[propina.fecha]) {
            reportes[propina.fecha].propina += propina.total;
            reportes[propina.fecha].impuestos += propina.impuestos;
          } else {
            reportes[propina.fecha] = {
              totalCierres: 0,
              totalFacturas: 0,
              propina: propina.total,
              impuestos: propina.impuestos,
            };
          }
        });

        const ordered = Object.keys(reportes)
          .sort()
          .reduce<reporteContadorMap>((obj, key) => {
            obj[key] = reportes[key];
            return obj;
          }, {});
        state.reporteContador.reporte = ordered;
      });
  },
});

export const {
  actualizarPlanilla,
  refetch,
  setDate,
  setDateBalanceActual,
  setInventarioFilter,
  setGlobalVentasPorArticulo,
  setGlobalFacturasAnuladas,
  setGlobalArticulosBorrados,
  setGlobalVentasMetodoDePago,
  setGlobalVentasCategoria,
  setGlobalVentasSubCategoria,
  setGlobalVentasEmpleado,
  setGlobalVentasTiempo,
  setGlobalVentasMesa,
  setGlobalVentasSeccion,
} = reportes.actions;

export default reportes.reducer;
