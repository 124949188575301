import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {Box, Card, CardContent, Divider, Grid, Typography} from '@mui/material';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {RestauranteEmpleados} from '../../services/dto/auth';
import {api} from '../../services';
import {infoCliente} from '../../services/dto/orden';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import CrudTable from '../../components/crud-table';
import Pagination from '@mui/material/Pagination';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';

const Clientes = () => {
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const dispatch = useAppDispatch();

  type columnType = {
    label: string;
    key: string[];
  }[];

  const column = [
    {
      label: 'Nombre',
      key: ['nombre'],
    },
    {
      label: 'Documento de Identidad',
      key: ['num_id'],
    },
    {
      label: '',
      key: ['id_info_cliente'],
    },
  ] as columnType;

  const restaurante = useAppSelector<RestauranteEmpleados | null>(
    store => store.restauranteSlice.restauranteEmpleadoSeleccionado,
  );

  const [clientes, setClientes] = useState<readonly infoCliente[]>([]);

  const [currentClient, setCurrentClient] = useState<infoCliente | null>(null);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalItem, setTotalItem] = useState(0);

  const [openCliente, setopenCliente] = useState(false);

  const fetchData = useCallback(async () => {
    if (restaurante) {
      dispatch(startLoading());
      const data = await api.getInfoClientesByRestaurant(restaurante.id, page);
      dispatch(stopLoading());
      if (data) {
        if (data.data.length) setCurrentClient(data.data[0]);
        setClientes(data.data);
        setPage(data.meta?.current_page || 1);
        setTotal(data.meta?.last_page || 1);
        setTotalItem(data.meta?.total || data.data.length);
      }
    }
  }, [restaurante, page]);

  useEffect(() => {
    fetchData().finally(() => null);
  }, [restaurante, page]);

  const closeModal = useCallback(() => {
    setopenCliente(false);
  }, []);

  return (
    <Pagetemplate isLoading={isLoading} title="Clientes || IFUDIS" soloLayout>
      <Box
        sx={{
          height: '83.5vh',
          padding: 0,
          margin: 0,
        }}>
        <Grid container height={'100%'} sx={{overflowY: 'auto'}} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{minHeight: '100%'}}>
              <CardContent>
                <Typography variant={'subtitle1'}>
                  Clientes registrados
                </Typography>
                <CrudTable
                  noCard
                  columns={column}
                  rows={clientes}
                  total={totalItem}
                  onView={arg => {
                    setCurrentClient(
                      clientes.find(value => value.id_info_cliente === arg) ||
                        null,
                    );
                  }}
                />
                <Grid container justifyContent={'center'} marginTop={2}>
                  <Pagination
                    page={page}
                    count={total}
                    color={'primary'}
                    variant="outlined"
                    onChange={(_, page1) => {
                      setPage(page1);
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{minHeight: '100%', height: '100%', overflow: 'auto'}}>
              <CardContent>
                {currentClient ? (
                  <Fragment>
                    <Typography variant={'subtitle1'}>
                      Cliente selecciondo
                    </Typography>
                    <Box
                      sx={{
                        border: '0.5px solid #c3c3c3c3',
                        borderRadius: '15px',
                        width: '100%',
                        position: 'relative',
                      }}
                      padding={2}
                      marginTop={2}>
                      <Typography variant="h5" component="div">
                        {currentClient.nombre}
                      </Typography>
                      <Typography sx={{mb: 1.5}} color="text.secondary">
                        {currentClient.num_id}
                      </Typography>
                      {currentClient.contactos ? (
                        <Fragment>
                          <Typography variant="h6" gutterBottom>
                            Contactos:
                          </Typography>
                          {currentClient.contactos.map((value, index) => (
                            <Grid container key={index} spacing={1} margin={1}>
                              <Grid item xs={'auto'}>
                                <Typography variant={'body2'}>
                                  {value.nombre}:
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography variant={'body2'}>
                                  {value.num_contacto}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Fragment>
                      ) : (
                        ''
                      )}
                      {currentClient.tipo_personas ? (
                        <Fragment>
                          <Typography variant="h6" gutterBottom>
                            Razón social:
                          </Typography>
                          {currentClient.tipo_personas.map((value, index) => (
                            <Fragment key={index}>
                              <Grid container spacing={1} margin={1}>
                                <Grid item xs={12}>
                                  <Typography variant={'subtitle1'}>
                                    Nombre: {value.nombre}:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant={'body2'}>
                                    Email: {value.email}:
                                  </Typography>
                                </Grid>
                                <Grid item xs={'auto'}>
                                  <Typography variant={'body2'}>
                                    TIPO: {value.tipo_persona}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant={'body2'}>
                                    RUC: {value.ruc}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider variant={'inset'}></Divider>
                            </Fragment>
                          ))}
                        </Fragment>
                      ) : (
                        ''
                      )}
                      {/*<Grid container justifyContent={'end'}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => setopenCliente(true)}
                              color="primary">
                              Editar
                            </Button>
                          </Grid>
                        </Grid>*/}
                    </Box>
                  </Fragment>
                ) : (
                  ''
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <DialogPopUp
        open={openCliente}
        handleClose={() => closeModal()}
        title={'Actualizar datos de cliente'}>
        <div></div>
      </DialogPopUp>
    </Pagetemplate>
  );
};

export default Clientes;
