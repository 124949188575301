import {Grid, Stack, Avatar, Typography} from '@mui/material';
import {ReactNode} from 'react';
import TwoBubbles from '../../components/two-bubbles/two-bubbles';
import {useAppSelector} from '../../redux/store';

type PropsTypes = {
  children: ReactNode;
};

function LobbyContainer({children}: PropsTypes) {
  const userState = useAppSelector(store => store.userSlice);
  const imagenes = userState?.userDetail?.imagenes;
  const fotoPerfil = imagenes?.find(
    value => value.tipo_imagen === 'foto_perfil',
  );
  const ruta_imagen = fotoPerfil ? fotoPerfil.ruta_imagen : '';
  const name = userState.userDetail
    ? userState.userDetail.name.toLocaleUpperCase()
    : '';
  const lastname = userState.userDetail
    ? userState.userDetail.lastname.toLocaleUpperCase()
    : '';

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}>
      <TwoBubbles />
      <Grid
        xs={12}
        item
        sx={{
          display: 'flex',
          justifyContent: {
            xs: 'center',
            sm: 'flex-start',
          },
          alignItems: 'center',
          mx: {
            xs: 5,
            sm: 15,
          },
          mt: 1,
        }}>
        <Avatar
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: {
              xs: '120px',
              sm: '100px',
            },
            height: {
              xs: '120px',
              sm: '100px',
            },
            mr: 2.5,
          }}
          src={ruta_imagen}
          alt="photoURL"
        />
        <Stack>
          <Typography
            color="primary"
            sx={{
              lineHeight: {
                xs: '20px',
                sm: '30px',
              },
              fontSize: '18px',
              fontWeight: 'bold',
            }}>
            BIENVENIDO
          </Typography>
          <Typography
            color="secondary"
            sx={{
              lineHeight: {
                xs: '30px',
                sm: '30px',
              },
              fontSize: '28px',
              fontWeight: 'bold',
            }}>
            {`${name}`}
          </Typography>
          <Typography
            color="secondary"
            sx={{
              lineHeight: {
                xs: '30px',
                sm: '30px',
              },
              fontSize: '28px',
              fontWeight: 'bold',
            }}>
            {`${lastname}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        xs={12}
        item
        sx={{
          mx: {
            xs: 2,
            sm: 15,
          },
        }}>
        <Typography
          sx={{
            fontSize: '18px',
            mb: 1,
            mt: 2.5,
            fontWeight: 'bold',
            lineHeight: {
              xs: '20px',
              sm: '30px',
            },
          }}
          color="primary">
          SELECCIONA UN RESTAURANTE
        </Typography>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
            },
            alignItems: 'center',
          }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LobbyContainer;
