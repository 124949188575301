import {styled, alpha} from '@mui/material/styles';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import {useState} from 'react';
import {Box} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

type PropsTypes = {
  addMore?: () => void;
  getLess?: () => void;
  deleteEm?: () => void;
  edit?: () => void;
  onModificador?: () => void;
  observacion: string;
  sx?: any;
};

function MenuCartItem({
  getLess,
  onModificador,
  deleteEm,
  edit,
  observacion,
  sx,
}: PropsTypes) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnEdit = async () => {
    if (edit) {
      await edit();
      handleClose();
    }
  };

  return (
    <Box sx={sx}>
      <IconButton
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        size="small"
        color="secondary"
        sx={{
          ml: 1,
        }}
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={deleteEm} disableRipple>
          <DeleteForeverIcon />
          Eliminar
        </MenuItem>
        <Divider sx={{my: 0.5}} />
        {onModificador && (
          <MenuItem onClick={onModificador} disableRipple>
            <FormatAlignJustifyIcon />
            Modificador
          </MenuItem>
        )}
        <MenuItem onClick={handleOnEdit} disableRipple>
          <EditIcon />
          {observacion ? `Editar observación` : `Agregar observación`}
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}

export default MenuCartItem;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
