import {MobileDateTimePicker} from '@mui/lab';
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import {useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  seleccionarFactura,
  setDate,
  setFacturaCaja,
} from '../../redux/slices/diario-ventas-slice';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {fetchFactura} from '../../redux/slices/diario-ventas-slice/diario-venta.thunk';

type DiarioVentasFormProps = {
  fechas: {
    fecha_inicial: Date | null;
    fecha_final: Date | null;
  };
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DiarioVentasForm = ({fechas, setOpen}: DiarioVentasFormProps) => {
  const dispatch = useAppDispatch();
  const facturaCaja = useAppSelector(
    store => store.diarioVentasSlice.facturaCaja,
  );
  const [checked, setChecked] = useState(!facturaCaja);
  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );
  const fetchData = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(fetchFactura());
    dispatch(stopLoading());
  }, []);

  return (
    <Stack
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      spacing={2}>
      <Stack direction="column" spacing={2}>
        <MobileDateTimePicker
          label="Fecha nueva"
          ampm
          ampmInClock
          value={fechas.fecha_inicial}
          onChange={date => {
            if (!date) return;
            dispatch(setDate({type: 'inicial', date: date}));
          }}
          disableFuture
          renderInput={params => <TextField {...params} />}
        />
        <MobileDateTimePicker
          label="Fecha final"
          ampm
          ampmInClock
          value={fechas.fecha_final}
          minDate={fechas.fecha_inicial}
          minDateTime={fechas.fecha_inicial}
          onChange={date => {
            if (!date) return;
            dispatch(setDate({type: 'final', date: date}));
          }}
          disableFuture
          renderInput={params => <TextField {...params} />}
        />
      </Stack>
      <Stack
        direction="column"
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        <FormControlLabel
          control={
            <Switch
              disabled={!cajaEmpleado}
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked);
              }}
            />
          }
          label="Ver facturas de toda la sucursal."
        />
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={async () => {
            dispatch(setFacturaCaja(!checked));
            await fetchData();
            dispatch(seleccionarFactura(null));
            setOpen(false);
          }}>
          Filtrar
        </Button>
      </Stack>
    </Stack>
  );
};

export default DiarioVentasForm;
