import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {
  editableType,
  restauranteUtilsStoreType,
  updateCajaEmpleadoStoreType,
  updateCajaStoreType,
  updateEmpleadoAsignadoSeccionStoreType,
} from './restaurante-utils.types';

import {
  initialCajaEmpleado,
  initialCajaState,
  initialEmpleadoAsignadoSeccion,
  initialState,
} from '.';

const actualizarCaja = (
  state: Draft<restauranteUtilsStoreType>,
  action: PayloadAction<updateCajaStoreType>,
) => {
  state.editable.caja = {
    ...state.editable.caja,
    ...action.payload,
  };
};

const actualizarCajaEmpleado = (
  state: Draft<restauranteUtilsStoreType>,
  action: PayloadAction<updateCajaEmpleadoStoreType>,
) => {
  state.editable.cajaEmpleado = {
    ...state.editable.cajaEmpleado,
    ...action.payload,
  };
};

export const resetEditable = (
  state: Draft<restauranteUtilsStoreType>,
  action: PayloadAction<editableType>,
) => {
  switch (action.payload) {
    case 'caja':
      Object.assign(state.editable.caja, initialCajaState);
      break;
    case 'cajaEmpleado':
      Object.assign(state.editable.cajaEmpleado, initialCajaEmpleado);
      break;
    case 'empleadoAsignadoSeccion':
      Object.assign(
        state.editable.empleadoAsignadoSeccion,
        initialEmpleadoAsignadoSeccion,
      );
      break;
    default:
      break;
  }
};

const actualizarEmpleadoAsignadoSeccion = (
  state: Draft<restauranteUtilsStoreType>,
  action: PayloadAction<updateEmpleadoAsignadoSeccionStoreType>,
) => {
  state.editable.empleadoAsignadoSeccion = {
    ...state.editable.empleadoAsignadoSeccion,
    ...action.payload,
  };
};

const ResetRestauranteUtilsSlice = (
  state: Draft<restauranteUtilsStoreType>,
) => {
  return initialState;
};

export const Actions = {
  resetEditable,
  actualizarCaja,
  actualizarCajaEmpleado,
  ResetRestauranteUtilsSlice,
  actualizarEmpleadoAsignadoSeccion,
};
