import {createAsyncThunk} from '@reduxjs/toolkit';
import {msgSuccess} from '../../../common/toast';
import yup from '../../../common/yup';
import {api} from '../../../services';
import {imageType} from '../../../services/dto/auth';
import {categoriaMenu, subcategoriaMenu} from '../../../services/dto/menu';
import {MenuRestaurante} from '../../../services/dto/orden';
import {RootState} from '../../store';
import {menuRestauranteSchema, subcategoriaSchema} from './menu.schemas';

export const getCategorias = createAsyncThunk<
  {data: categoriaMenu[]; id: number},
  undefined,
  {state: RootState}
>('menuSlice/getCategorias', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();
  if (!restauranteSeleccionado) {
    return thunkAPI.rejectWithValue('debes primero seleccionar un restaurante');
  }

  const response = await api.getCategorias();
  if (!response?.data)
    return thunkAPI.rejectWithValue('error al obtener las categorias');
  return {
    data: response.data,
    id: restauranteSeleccionado.id,
  };
});

export const getSubCategorias = createAsyncThunk<
  subcategoriaMenu[],
  undefined,
  {state: RootState}
>('menuSlice/getSubCategorias', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();
  if (!restauranteSeleccionado) {
    return thunkAPI.rejectWithValue('debes primero seleccionar un restaurante');
  }
  const response = await api.getSubCategorias(restauranteSeleccionado.id);
  if (!response?.data)
    return thunkAPI.rejectWithValue('error al obtener las sub-categorias');
  return response.data;
});

////putpost de ejemplo
export const putPostSubCategory = createAsyncThunk<
  subcategoriaMenu[],
  {
    editId?: number;
  } & yup.InferType<typeof subcategoriaSchema>,
  {state: RootState}
>('menuSlice/putPostSubCategory', async (data, thunkAPI) => {
  const {
    restauranteSlice: {restauranteEmpleadoSeleccionado},
    menuSlice: {subcategorias},
  } = thunkAPI.getState();

  const body = await subcategoriaSchema.validate(
    {
      ...data,
      id_seccion_sucursal: data.editId,
      restaurante_id: restauranteEmpleadoSeleccionado?.id,
      id_menu_restaurante: data.editId,
    },
    {stripUnknown: true, context: {request: true}},
  );
  const copy = [...subcategorias];
  if (!body.id_subcategoria_producto) {
    const response = await api.postSubCategorias(body);
    if (!response)
      return thunkAPI.rejectWithValue('error al crear la subcategoria.');
    msgSuccess('Subcategoria creada con exito');
    copy.push(response.data);
  } else {
    const response = await api.putSubCategorias(
      body,
      body.id_subcategoria_producto,
    );
    if (!response)
      return thunkAPI.rejectWithValue('error al actualizar la subcategoria.');
    msgSuccess('Subcategoria actualizada con exito');
    const index = copy.findIndex(
      e => e.id_subcategoria_producto === body.id_subcategoria_producto,
    );
    copy[index] = response.data;
  }
  return copy;
});

export const putPostMenu = createAsyncThunk<
  MenuRestaurante[],
  {
    editId?: number;
    image: File | imageType | null;
  } & yup.InferType<typeof menuRestauranteSchema>,
  {state: RootState}
>('menuSlice/putPostMenu', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
    menuSlice: {menuRestaurantes},
  } = thunkAPI.getState();
  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('debes primero seleccionar un restaurante');
  const body = await menuRestauranteSchema.validate(
    {
      ...data,
      restaurante_id: restauranteSeleccionado?.id,
      id_menu_restaurante: data.editId,
    },
    {stripUnknown: true, context: {request: true}},
  );

  const formData = new FormData();
  formData.append('restaurante_id', body.restaurante_id?.toString()!);
  formData.append(
    'categoria_producto_id',
    body.categoria_producto_id.toString(),
  );
  formData.append(
    'subcategoria_producto_id',
    body.subcategoria_producto_id.toString(),
  );
  formData.append('nombre_producto', body.nombre_producto);
  formData.append('descripcion_producto', body.descripcion_producto);
  formData.append('precio_producto', body.precio_producto.toString());
  formData.append('estado_producto_global', '1');

  if (data.image instanceof File) {
    formData.append('imagen', data.image);
  }

  if (body.modificadores.length) {
    body.modificadores.forEach((modificador, index) => {
      formData.append(`modificadores[${index}]`, modificador.toString());
    });
  } else {
    formData.append('modificadores', 'null');
  }

  body.sucursales.forEach((menuSucursal, index) => {
    formData.append(`sucursales[${index}]`, menuSucursal.toString());
  });
  const copy = [...menuRestaurantes];
  if (!body.id_menu_restaurante) {
    const response = await api.postMenuRestaurante(formData);
    if (!response) return thunkAPI.rejectWithValue('error al crear el menu');
    msgSuccess('Producto creado con exito');
    copy.push(response.data);
  } else {
    const response = await api.putMenuRestaurante(
      formData,
      body.id_menu_restaurante!,
    );
    if (!response)
      return thunkAPI.rejectWithValue('error al actualizar el menu');
    msgSuccess('Producto actualizado con exito');
    thunkAPI.dispatch(getMenuRestaurante());
    const index = copy.findIndex(
      e => e.id_menu_restaurante === response.data.id_menu_restaurante,
    );
    copy[index] = response.data;
  }
  return copy;
});

export const getMenuRestaurante = createAsyncThunk<
  MenuRestaurante[],
  undefined,
  {state: RootState}
>('menuSlice/cargarMenuRestaurante', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();
  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('error al cargar el menu restaurante.');
  const response = await api.getMenuRestaurante(restauranteSeleccionado.id);

  if (!response)
    return thunkAPI.rejectWithValue('error al cargar el menu restaurante.');
  return response.data;
});

export const deleteSubCategoria = createAsyncThunk<
  subcategoriaMenu[],
  number,
  {state: RootState}
>('menuSlice/deleteSubCategoria', async (data, thunkAPI) => {
  const {
    menuSlice: {subcategorias},
  } = thunkAPI.getState();
  const response = await api.deleteSubCategoria(data);
  if (!response)
    return thunkAPI.rejectWithValue('error al eliminar la subcategoria');
  msgSuccess('Subcategoria eliminada con exito');

  return subcategorias
    .slice()
    .filter(
      e => e.id_subcategoria_producto != response.data.id_subcategoria_producto,
    );
});

export const deleteMenuRestaurante = createAsyncThunk<
  MenuRestaurante[],
  number,
  {state: RootState}
>('menuSlice/deleteMenuRestaurante', async (data, thunkAPI) => {
  const {
    menuSlice: {menuRestaurantes},
  } = thunkAPI.getState();
  const response = await api.deleteMenuRestaurante(data);
  if (!response)
    return thunkAPI.rejectWithValue('error al eliminar el producto');
  msgSuccess('Producto eliminado con exito');
  return menuRestaurantes
    .slice()
    .filter(e => e.id_menu_restaurante != response.data.id_menu_restaurante);
});
