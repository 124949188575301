import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
} from '@mui/material';

type categoriesItemProps = {
  categoryName: string;
  selected: boolean;
  addEm: Function;
};

function CategoriesItem({categoryName, selected, addEm}: categoriesItemProps) {
  const backgroundColor = selected ? '#f59823' : '';
  const textcolor = selected ? 'white' : 'black';

  return (
    <Grid item xs={3} sm={2}>
      <Card sx={{borderRadius: '10px'}} raised>
        <CardActionArea
          sx={{backgroundColor: backgroundColor, color: textcolor}}
          onClick={() => addEm()}>
          <CardContent
            sx={{
              minHeight: '70px',
              maxHeight: '70px',
              width: '150px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                lineHeight: 0.9,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}>
              {categoryName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default CategoriesItem;
