import {createAsyncThunk} from '@reduxjs/toolkit';
import {compraSucursalSchema} from '.';
import isNullish from '../../../common/isNullish';
import {api} from '../../../services';
import {
  compraSucursal,
  estadoDeCompra,
  proveedor,
} from '../../../services/dto/compras';
import {putPost} from '../menu-slice/menu.types';
import {thunkAPiConfig} from '../user-slice/user.types';
import {proveedorStoreTypePrimary} from './compras.types';
import * as yup from 'yup';

export const getProveedores = createAsyncThunk<
  proveedor[],
  undefined,
  thunkAPiConfig
>('compras/getProveedores', async (data, thunkAPI) => {
  const response = await api.getProveedores();
  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener los proveedores');
  return response.data;
});

export const getEstadoDeCompra = createAsyncThunk<
  estadoDeCompra[],
  undefined,
  thunkAPiConfig
>('compras/getEstadoDeCompra', async (data, thunkAPI) => {
  const response = await api.getEstadoDeCompra();
  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener los estados de compra');
  return response.data;
});

export const getComprasSucursal = createAsyncThunk<
  compraSucursal[],
  undefined,
  thunkAPiConfig
>('compras/getComprasSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    comprasSlice: {fecha_final, fecha_inicio},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue(
      'No hay un empleado asignado a la sucursal',
    );

  if (!fecha_inicio || !fecha_final)
    return thunkAPI.rejectWithValue('No hay fecha de inicio o final');

  const response = await api.getComprasSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
    fecha_inicio.toISOString(),
    fecha_final.toISOString(),
  );

  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener las compras');
  return response.data;
});

export const putPostCompraSucursal = createAsyncThunk<
  compraSucursal[],
  {
    editId?: number;
  } & yup.InferType<typeof compraSucursalSchema>,
  thunkAPiConfig
>('compras/putPostCompraSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
    comprasSlice: {comprasSucursal},
  } = thunkAPI.getState();

  const body = await compraSucursalSchema.validate(
    {
      ...data,
      id_compra_sucursal: data.editId,
      sucursal_id: empleadoAsignadoSucursalSeleccionado?.sucursal_id,
      fecha_hora_compra: data.fecha_hora_compra.toISOString(),
      desglose: '',
    },
    {stripUnknown: true, context: {request: true}},
  );

  const copy = [...comprasSucursal];

  body.desglose = JSON.stringify({
    items: body.items,
    subtotal: body.subtotal || null,
    impuestos: body.impuestos || null,
  });

  if (!body.id_compra_sucursal) {
    const response = await api.postComprasSucursal(body);
    if (!response) return thunkAPI.rejectWithValue('Error al crear');
    copy.push(response.data);
  } else {
    const response = await api.putComprasSucursal(
      body,
      body.id_compra_sucursal,
    );
    if (!response) return thunkAPI.rejectWithValue('Error al actualizar');
    const index = copy.findIndex(
      element =>
        element.id_compra_sucursal === response.data.id_compra_sucursal,
    );
    copy[index] = response.data;
  }
  return copy;
});

export const putPostProveedor = createAsyncThunk<null, putPost, thunkAPiConfig>(
  'compras/putPostProveedor',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
      comprasSlice: {
        editable: {proveedor: tempProveedor},
      },
    } = thunkAPI.getState();

    if (!empleadoAsignadoSucursalSeleccionado)
      return thunkAPI.rejectWithValue(
        'No hay un empleado asignado a la sucursal',
      );

    if (data === 'crear') {
      const {id_proveedor, ...rest} = tempProveedor;
      if (isNullish(rest))
        return thunkAPI.rejectWithValue('algun valor es nulo');
    } else {
      if (isNullish(tempProveedor))
        return thunkAPI.rejectWithValue('algun valor es nulo');
    }

    const proveedor = tempProveedor as proveedorStoreTypePrimary;

    const body = {
      nombre_proveedor: proveedor.nombre_proveedor,
      telefono: proveedor.telefono,
      correo: proveedor.correo,
      ruc: proveedor.ruc,
      categorias: proveedor.categorias,
    };

    if (data === 'crear') {
      const response = await api.postProveedor(body);
      if (!response)
        return thunkAPI.rejectWithValue('Error al crear el proveedor');
      thunkAPI.dispatch(getProveedores());
      return null;
    } else {
      const response = await api.putProveedor(body, proveedor.id_proveedor);
      if (!response)
        return thunkAPI.rejectWithValue('Error al actualizar el proveedor');
      thunkAPI.dispatch(getProveedores());
      return null;
    }
  },
);
