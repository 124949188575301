import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
} from '@mui/material';
import {useMemo} from 'react';
import {fCurrency} from '../../common/formatNumber';
import {useAppSelector} from '../../redux/store';

type productItemProps = {
  productName: string;
  menuRestauranteId: number;
  productPrice: number;
  addProduct: () => void;
};

function ProductoItem({
  productName,
  addProduct,
  productPrice,
  menuRestauranteId,
}: productItemProps) {
  const carrito = useAppSelector(store => store.ordenSlice.orden.carrito);
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );

  const total = useMemo(() => {
    return carrito.filter(
      e =>
        e.producto.menu_restaurante_id === menuRestauranteId &&
        clienteSeleccionado?.id === e.clienteId,
    ).length;
  }, [carrito]);
  return (
    <Grid item xs={4} sm={3} md={3} lg={2}>
      <Card
        raised
        sx={{
          borderRadius: '10px',
          width: '150px',
          m: 0.2,
        }}>
        <CardActionArea sx={{backgroundColor: '#f59823'}} onClick={addProduct}>
          <CardContent
            sx={{
              minHeight: {
                xs: '90px',
                md: '90px',
              },
              maxHeight: {
                xs: '90px',
                md: '90px',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography
              sx={{
                fontSize: '0.7rem',
                lineHeight: 0.7,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'white',
                top: 8,
                right: 8,
                position: 'absolute',
              }}>
              {total || ''}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.7rem',
                lineHeight: 0.7,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'white',
                bottom: 8,
                left: 8,
                position: 'absolute',
              }}>
              {fCurrency(productPrice)}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.9rem',
                lineHeight: 0.9,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'white',
              }}>
              {productName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default ProductoItem;
