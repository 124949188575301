import {Card, Grid} from '@mui/material';
import {ReactNode} from 'react';
import useMediaQuery from '../../common/hooks/use-media-query';

type PropsTypes = {
  children: ReactNode;
  p?: number;
};

const RightContainer = ({p = 0, children}: PropsTypes) => {
  const isMobile = useMediaQuery('(max-width: 899px)');
  return (
    <Grid item xs={12} md={7} sx={{width: '100%', height: '89.9vh'}}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          borderRadius: '10px',
          overflowY: 'auto',
          p: p,
          paddingBottom: isMobile ? '120px' : p,
        }}
        raised>
        {children}
      </Card>
    </Grid>
  );
};

export default RightContainer;
