import {Box, Card, CardActionArea, CardContent} from '@mui/material';
import {ReactNode} from 'react';
import {makeStyles} from '@mui/styles';

type PropsTypes = {
  color?: 'green' | 'red' | 'yellow' | 'gray' | 'blue';
  children: ReactNode;
  handleOnClick: () => void;
  disabled: boolean;
  isSelected?: boolean;
  hasNotification: boolean;
};

const useStyles = makeStyles({
  pulse: {
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
    borderRadius: '15px',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.97)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 5px rgba(0, 0, 0, 0)',
    },
    '100%': {
      transform: 'scale(0.97)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
});

const MesaWrapper = ({
  color = 'gray',
  children,
  disabled = false,
  handleOnClick,
  isSelected = false,
  hasNotification = false,
  ...rest
}: PropsTypes) => {
  const green = '#abffaf';
  const red = '#ffb8b8';
  const yellow = '#f7ed81';
  const gray = '#ededed';
  const blue = '#78f3fe';

  const greenBorder = '5px solid green';
  const yellowBorder = '5px solid orange';
  const redBorder = '5px solid red';
  const blueBorder = '5px solid #4463fc';
  const grayBorder = '0px solid';

  const getBackgroundColor = (color: string): string => {
    switch (color) {
      case 'green':
        return green;
      case 'red':
        return red;
      case 'blue':
        return blue;
      case 'yellow':
        return yellow;
      default:
        return gray;
    }
  };

  const getBorderColor = (color?: string): string => {
    switch (color) {
      case 'green':
        return greenBorder;
      case 'red':
        return redBorder;
      case 'blue':
        return blueBorder;
      case 'yellow':
        return yellowBorder;
      default:
        return grayBorder;
    }
  };

  const animationClass = hasNotification ? useStyles().pulse : '';

  return (
    <Box
      className={!disabled ? animationClass : ''}
      sx={{
        border: isSelected ? getBorderColor('red') : getBorderColor('gray'),
        borderRadius: '20px',
      }}>
      <Card
        raised
        sx={{
          border: getBorderColor(hasNotification ? 'yellow' : color),
          height: '125px',
          boxShadow: '10px 10px 17px -11px rgba(0,0,0,0.75)',
          transition: 'all 400ms ease',
          borderRadius: '15px',
          backgroundColor: getBackgroundColor(
            hasNotification ? 'yellow' : color,
          ),
          '&:hover': disabled ? undefined : {transform: 'scale(1.02,1.02)'},
          cursor: disabled ? 'auto' : 'pointer',
        }}
        {...rest}>
        <CardActionArea
          disabled={disabled}
          sx={{
            height: '100%',
            backgroundColor: disabled ? gray : 'transparent',
            opacity: disabled ? 0.6 : 1,
          }}
          onClick={handleOnClick}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'space-around',
              flexDirection: 'column',
              px: 1,
              py: 0.5,
              height: '100%',
            }}>
            {children}
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default MesaWrapper;
