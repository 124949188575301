import {Box} from '@mui/material';
import {useState} from 'react';
import sortArrayObject from '../../common/sortArrayObject';
import {deleteCajaEmpleado} from '../../redux/slices/restaurante-utils-slice/restaurante-utils.thunks';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import ConfirmDialog from '../confirm-dialog';
import CrudTable from '../crud-table';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import NewCajaEmpleadoForm from './new-caja-empleado-form';
import NewCajaForm from './new-caja-form';

function NewCaja() {
  const [openCrear, setOpenCrear] = useState(false);
  const [openCrearEmpleados, setOpenCrearEmpleados] = useState(false);

  const [orderCaja, setOrderCaja] = useState<'asc' | 'desc'>('desc');
  const [orderByCaja, setOrderByCaja] = useState<string>('numero_caja');
  const [arrayKeysCaja, setArrayKeysCaja] = useState<string[]>(['numero_caja']);

  const [orderEmpleado, setOrderEmpleado] = useState<'asc' | 'desc'>('desc');
  const [orderByEmpleado, setOrderByEmpleado] = useState<string>('numero_caja');
  const [arrayKeysEmpleado, setArrayKeysEmpleado] = useState<string[]>([
    'caja',
    'numero_caja',
  ]);
  const [deleteID, setDeleteID] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  const cajas = useAppSelector(store => store.restauranteUtilsSlice.cajas);
  const cajasEmpleado = useAppSelector(
    store => store.restauranteUtilsSlice.cajaEmpleados,
  );
  const handleCloseCrear = () => setOpenCrear(false);

  const handleCloseCrearEmpleados = () => setOpenCrearEmpleados(false);

  const sortHandlerCaja = (key: string, allKeys: string[]) => {
    setOrderCaja(orderCaja === 'asc' ? 'desc' : 'asc');
    setOrderByCaja(key);
    setArrayKeysCaja(allKeys);
  };
  const sortHandlerEmpleado = (key: string, allKeys: string[]) => {
    setOrderEmpleado(orderEmpleado === 'asc' ? 'desc' : 'asc');
    setOrderByEmpleado(key);
    setArrayKeysEmpleado(allKeys);
  };

  return (
    <>
      <CrudTable
        setOpenCrear={setOpenCrear}
        title="Cajas"
        columns={columnCaja}
        rows={[...cajas].sort((a, b) =>
          sortArrayObject(a, b, arrayKeysCaja, orderCaja),
        )}
        order={orderCaja}
        orderBy={orderByCaja}
        sortHandler={sortHandlerCaja}
      />
      <Box sx={{my: 1}} />
      <CrudTable
        setOpenCrear={setOpenCrearEmpleados}
        title="Empleados asignados"
        columns={columnEmpleadoAsignado}
        rows={[...cajasEmpleado].sort((a, b) =>
          sortArrayObject(a, b, arrayKeysEmpleado, orderEmpleado),
        )}
        onDelete={async (id: number) => setDeleteID(id)}
        order={orderEmpleado}
        orderBy={orderByEmpleado}
        sortHandler={sortHandlerEmpleado}
      />
      <ConfirmDialog
        secondaryText={'Estas seguro que deseas borrar esta asignacion?'}
        state={deleteID != null}
        handleClose={() => {
          setDeleteID(null);
        }}
        handleClickCompleted={async () => {
          if (!deleteID) return;
          await dispatch(deleteCajaEmpleado(deleteID));
          setDeleteID(null);
        }}
      />
      <DialogPopUp
        open={openCrear}
        handleClose={handleCloseCrear}
        title="Crear Caja">
        <NewCajaForm handleClose={handleCloseCrear} />
      </DialogPopUp>
      <DialogPopUp
        open={openCrearEmpleados}
        handleClose={handleCloseCrearEmpleados}
        title="Asignar empleado a Caja">
        <NewCajaEmpleadoForm handleClose={handleCloseCrearEmpleados} />
      </DialogPopUp>
    </>
  );
}

export default NewCaja;

const columnCaja = [
  {
    label: 'Número de caja',
    key: ['numero_caja'],
  },
  {
    label: '',
    key: ['id_caja_sucursal'],
  },
];

const columnEmpleadoAsignado = [
  {
    label: 'Número de caja',
    key: ['caja', 'numero_caja'],
  },
  {
    label: 'Empleado Asignado',
    key: ['empleado_sucursal', 'name'],
  },
  {
    label: 'Quien Asigna',
    key: ['asignador', 'name'],
  },
  {
    label: 'Estado de asignación	',
    key: ['estado_caja_asignada'],
  },
  {
    label: 'Fecha de asignación',
    key: ['date'],
  },
  {
    label: '',
    key: ['id_caja_empleado'],
  },
];
