import {createAsyncThunk} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {resetCierreSLice} from '.';
import iOS from '../../../common/isIos';
import {msgSuccess} from '../../../common/toast';
import {api} from '../../../services';
import {CierreZData} from '../../../services/dto/cierre';

import {RootState} from '../../store';

import {fetchMetodosDePago} from '../finanzas-slice/finanzas.thunks';

import {efectivoInputType, metodoPagoInputType} from './cierre.types';

const efectivoVariants = [100, 50, 20, 10, 5, 1, 0.5, 0.25, 0.1, 0.05, 0.01];

export const cargarCierre = createAsyncThunk<
  {
    efectivoDeclarado: efectivoInputType[];
    metodoPagoDeclarado: metodoPagoInputType[];
  },
  undefined,
  {state: RootState}
>('reportes/cargarCierre', async (data, thunkAPI) => {
  await thunkAPI.dispatch(fetchMetodosDePago());

  const {
    finanzasSlice: {metodosPago},
  } = thunkAPI.getState();

  if (metodosPago.length === 0)
    return thunkAPI.rejectWithValue('No hay metodos de pago');

  const metodoPago: metodoPagoInputType[] = metodosPago.map(element => ({
    id_metodo_pago_restaurante: element.id_metodo_pago_restaurante,
    nombre_metodo_pago: element.metodo_pago.nombre_metodo_pago,
    id_metodo_pago: element.metodo_pago_id,
    cantidad: 0,
  }));

  const efectivo: efectivoInputType[] = efectivoVariants.map(element => ({
    cantidad: 0,
    name: element.toString(),
    value: element,
  }));

  return {efectivoDeclarado: efectivo, metodoPagoDeclarado: metodoPago};
});

export const getReporteCaja = createAsyncThunk<
  CierreZData[],
  undefined,
  {state: RootState}
>('reportes/getReporteCaja', async (data, thunkAPI) => {
  const {
    restauranteSlice: {cajaEmpleado, sucursalSeleccionada, cargoEmpleado},
  } = thunkAPI.getState();

  if (cargoEmpleado && cargoEmpleado.id_cargo === 1 && sucursalSeleccionada) {
    const response = await api.getReporteCajaSucursal(
      sucursalSeleccionada.id_sucursal,
    );
    if (!response) return thunkAPI.rejectWithValue('error al obtener la caja');
    return response.data;
  }

  if (!cajaEmpleado) {
    if (!sucursalSeleccionada)
      return thunkAPI.rejectWithValue('no hay sucursal seleccionada');
    const response = await api.getReporteCajaSucursal(
      sucursalSeleccionada.id_sucursal,
    );
    if (!response) return thunkAPI.rejectWithValue('error al obtener la caja');

    return response.data;
  } else {
    const response = await api.getReporteCaja(cajaEmpleado.caja_sucursal_id);
    if (!response) return thunkAPI.rejectWithValue('error al obtener la caja');

    return response.data;
  }
});

export const postCierre = createAsyncThunk<null, undefined, {state: RootState}>(
  'reportes/postCierre',
  async (data, thunkAPI) => {
    const {
      restauranteSlice: {cajaEmpleado},
      cierreSlice,
    } = thunkAPI.getState();

    if (!cajaEmpleado) return thunkAPI.rejectWithValue('No hay caja');
    if (!cierreSlice.type)
      return thunkAPI.rejectWithValue('no hay type seleccionado');
    if (!cierreSlice.fechaFinal || !cierreSlice.fechaInicial)
      return thunkAPI.rejectWithValue('no hay fecha seleccionada');
    cierreSlice.fechaInicial.setMilliseconds(0);
    cierreSlice.fechaFinal.setMilliseconds(0);

    const declarado = cierreSlice.metodoPagoDeclarado.map(element => {
      if (element.id_metodo_pago != 3) {
        return {
          id_metodo_pago_restaurante: element.id_metodo_pago_restaurante,
          id_metodo_pago: element.id_metodo_pago,
          total: element.cantidad ?? 0,
          nombre_metodo_pago: element.nombre_metodo_pago,
        };
      }
      return {
        id_metodo_pago_restaurante: element.id_metodo_pago_restaurante,
        id_metodo_pago: element.id_metodo_pago,
        total: cierreSlice.efectivoTotal ?? 0,
        nombre_metodo_pago: element.nombre_metodo_pago,
      };
    });

    const metaDataJson = {
      declarado,
      efectivoDesglose: cierreSlice.efectivoDeclarado,
    };

    let bodyPut: any = {
      fecha_hora_inicial: cierreSlice.fechaInicial.toISOString(),
      fecha_hora_final: cierreSlice.fechaFinal.toISOString(),
      empleado_asignado_sucursal_id: cajaEmpleado.empleado_sucursal_id,
      caja_sucursal_id: cajaEmpleado.caja.id_caja_sucursal,
      tipo_reporte: cierreSlice.type,
      desglose: JSON.stringify(metaDataJson),
      declarado: [
        ...declarado.map(element => ({...element, id_metodo_pago: undefined})),
        {
          total_declarado: cierreSlice.metodoPagoTotal,
        },
      ],
    };

    if (cierreSlice.selectedReporteCaja)
      bodyPut.id_reporte_caja = cierreSlice.selectedReporteCaja;

    if (cierreSlice.observaciones)
      bodyPut.observaciones = cierreSlice.observaciones;

    const response = await api.postCierre(bodyPut);

    if (!response)
      return thunkAPI.rejectWithValue('No se pudo cargar el cierre');

    msgSuccess('Datos enviados correctamente');
    if (iOS()) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response);
      link.download = `cierre-${cierreSlice.type}-${dayjs().format(
        'DD/MM/YYYY',
      )}.pdf`;
      link.click();
    } else {
      const url = window.URL.createObjectURL(response);
      window.open(url);
    }
    thunkAPI.dispatch(resetCierreSLice());
    thunkAPI.dispatch(cargarCierre());
    return null;
  },
);

export const postCierre2 = createAsyncThunk<
  null,
  {id: number},
  {state: RootState}
>('reportes/postCierre2', async (data, thunkAPI) => {
  const {
    restauranteSlice: {cajaEmpleado},
    cierreSlice,
  } = thunkAPI.getState();

  //if (!cajaEmpleado) return thunkAPI.rejectWithValue('No hay caja');
  if (!cierreSlice.type)
    return thunkAPI.rejectWithValue('no hay type seleccionado');
  if (!cierreSlice.fechaFinal || !cierreSlice.fechaInicial)
    return thunkAPI.rejectWithValue('no hay fecha seleccionada');
  cierreSlice.fechaInicial.setMilliseconds(0);
  cierreSlice.fechaFinal.setMilliseconds(0);

  const declarado = cierreSlice.metodoPagoDeclarado.map(element => {
    if (element.id_metodo_pago != 3) {
      return {
        id_metodo_pago_restaurante: element.id_metodo_pago_restaurante,
        id_metodo_pago: element.id_metodo_pago,
        total: element.cantidad ?? 0,
        nombre_metodo_pago: element.nombre_metodo_pago,
      };
    }
    return {
      id_metodo_pago_restaurante: element.id_metodo_pago_restaurante,
      id_metodo_pago: element.id_metodo_pago,
      total: cierreSlice.efectivoTotal ?? 0,
      nombre_metodo_pago: element.nombre_metodo_pago,
    };
  });

  const metaDataJson = {
    declarado,
    efectivoDesglose: cierreSlice.efectivoDeclarado,
  };

  const reporteCaja = cierreSlice.reporteCaja.find(
    element => element.id_reporte_caja === data.id,
  );

  if (!reporteCaja) return thunkAPI.rejectWithValue('No hay caja');

  let bodyPut: any = {
    fecha_hora_inicial: cierreSlice.fechaInicial.toISOString(),
    fecha_hora_final: cierreSlice.fechaFinal.toISOString(),
    empleado_asignado_sucursal_id: cajaEmpleado
      ? cajaEmpleado.empleado_sucursal_id
      : reporteCaja.empleado_asignado_sucursal_id,
    caja_sucursal_id: reporteCaja.caja_sucursal_id,
    tipo_reporte: cierreSlice.type,
    desglose: JSON.stringify(metaDataJson),
    declarado: [
      ...declarado.map(element => ({...element, id_metodo_pago: undefined})),
      {
        total_declarado: cierreSlice.metodoPagoTotal,
      },
    ],
  };

  if (cierreSlice.selectedReporteCaja)
    bodyPut.id_reporte_caja = cierreSlice.selectedReporteCaja;

  if (cierreSlice.observaciones)
    bodyPut.observaciones = cierreSlice.observaciones;

  const response = await api.postCierre(bodyPut);

  if (!response) return thunkAPI.rejectWithValue('No se pudo cargar el cierre');

  msgSuccess('Datos enviados correctamente');
  if (iOS()) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = `cierre-${cierreSlice.type}-${dayjs().format(
      'DD/MM/YYYY',
    )}.pdf`;
    link.click();
  } else {
    const url = window.URL.createObjectURL(response);
    window.open(url);
  }
  thunkAPI.dispatch(resetCierreSLice());
  thunkAPI.dispatch(cargarCierre());
  return null;
});

export const deleteReporteCaja = createAsyncThunk<
  CierreZData[],
  number,
  {state: RootState}
>('reportes/deleteReporteCaja', async (data, thunkAPI) => {
  const {
    cierreSlice: {reporteCaja},
  } = thunkAPI.getState();

  const response = await api.deleteReporteCaja(data);

  if (!response)
    return thunkAPI.rejectWithValue('error while deleting reporte caja');

  return reporteCaja
    .slice()
    .filter(
      element => element.id_reporte_caja != response.data.id_reporte_caja,
    );
});
