import {Stack, Typography} from '@mui/material';
import {fCurrency} from '../../common/formatNumber';
import NumericTextField from '../../components/form-input/controlled-numeric-text-field';
import {editarEfectivo} from '../../redux/slices/cierre-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';

interface desgloseInputProps {
  unit: string;
  quantity?: number;
  total: number;
  index: number;
}

const DesgloseInput = ({unit, quantity, total, index}: desgloseInputProps) => {
  const dispatch = useAppDispatch();
  const metodos = useAppSelector(
    store => store.cierreSlice.metodoPagoDeclarado,
  );

  const haveEfectivo = !!metodos.find(element => element.id_metodo_pago === 3);
  return (
    <Stack
      flexDirection="row"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        py: 1,
      }}>
      <Stack direction="row" alignItems="center" spacing={1} mr={2}>
        <Typography
          sx={{
            fontWeight: 'bold',
            width: 45,
            fontSize: '1.1rem',
          }}>
          {`$${unit}`}
        </Typography>
        <Typography
          sx={{
            color: '#9b9696',
            fontSize: '1.1rem',
            fontWeight: 'bold',
          }}>
          x
        </Typography>
      </Stack>
      <NumericTextField
        delay={400}
        variant="outlined"
        disabled={!haveEfectivo}
        value={quantity}
        name={`${unit}`}
        size="small"
        inputProps={{
          style: {
            height: 15,
            width: 35,
            fontSize: '1rem',
            textAlign: 'center',
          },
        }}
        onChange={value => {
          dispatch(editarEfectivo({index, value}));
        }}
      />
      <Stack direction="row" alignItems="center" spacing={1} ml={2}>
        <Typography
          sx={{
            color: '#9b9696',
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}>
          =
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '1.1rem',
            width: 60,
          }}>
          {fCurrency(total)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DesgloseInput;
