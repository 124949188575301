import {MutableRefObject, useEffect, useRef} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  PaperProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type propsType = {
  open: boolean;
  handleClose?: () => void;
  title?: string;
  children?: React.ReactElement | string | React.ReactNode;
  fullScreen?: boolean;
  PaperProps?: Partial<PaperProps<'div', {}>> | undefined;
  onScroll?: (ref: MutableRefObject<undefined>) => void | undefined;
  noPadding?: boolean;
};

const DialogPopUp = function ({
  open,
  handleClose,
  title,
  children,
  fullScreen = false,
  PaperProps,
  noPadding = false,
  onScroll,
  ...rest
}: propsType) {
  const descriptionElementRef = useRef(null);
  const modalContainerRef = useRef();

  useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        //@ts-ignore
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperProps={PaperProps}
      fullWidth={fullScreen ? fullScreen : true}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {title}
        <Button size="small" variant="outlined" onClick={handleClose}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: noPadding ? 0 : undefined,
        }}
        dividers
        onScroll={() => {
          if (onScroll) return onScroll(modalContainerRef);
        }}
        ref={modalContainerRef}
        {...rest}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DialogPopUp;
