import {memo, useState} from 'react';
import {Global} from '@emotion/react';
import {styled} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {CssBaseline, Box, Typography} from '@mui/material';

import {useAppSelector} from '../../redux/store';
import {fCurrency} from '../../common/formatNumber';

type propsTypes = {
  children?: React.ReactNode;
};

function DrawerPuller({children}: propsTypes) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen);

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const drawerBleeding = 100;

  const fixedHeight = open ? `calc(90% - ${drawerBleeding}px)` : `calc(100%)`;

  const Root = styled('div')(() => ({
    height: '100%',
    backgroundColor: '#f5f5f5',
  }));

  const cliente = useAppSelector(
    state => state.ordenSlice.orden.clienteSeleccionado,
  );

  const subtotal = useAppSelector(store => store.ordenSlice.cuenta).subtotal;
  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );
  const mesaSeleccionada = useAppSelector(
    store => store.ordenSlice.mesaSeleccionada,
  );

  return (
    <Root>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: fixedHeight,
            overflow: 'visible',
          },
        }}
      />
      <CssBaseline />
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}>
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            height: open ? drawerBleeding : drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            left: 0,
            width: '100%',
          }}>
          <Puller />
          <TitleBox
            leftTitle={`Subtotal: ${fCurrency(subtotal)}`}
            rightTitle={cliente?.name ?? 'Sin nombre'}
            rightSubtitle={
              mesaSeleccionada
                ? `mesa: #${mesaSeleccionada.numero_mesa}`
                : `caja: #${cajaEmpleado?.caja.numero_caja}`
            }
          />
        </StyledBox>
        <StyledBox
          sx={{
            px: 1.5,
            pb: 1,
            height: '100%',
            overflowY: 'auto',
          }}>
          {children}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

export default memo(DrawerPuller);

const StyledBox = styled(Box)(() => ({
  backgroundColor: grey[100],
}));

const Puller = styled(Box)(() => ({
  width: 30,
  height: 8,
  backgroundColor: '#0000007b',
  borderRadius: 3,
  position: 'absolute',
  top: 12,
  left: 'calc(50% - 15px)',
}));

type titleboxType = {
  leftTitle?: string;
  rightTitle?: string;
  rightSubtitle?: string;
};

const TitleBox = ({leftTitle, rightTitle, rightSubtitle}: titleboxType) => {
  return (
    <Box
      sx={{
        px: 2,
        py: 4,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
      }}>
      <Typography
        sx={{
          fontSize: {
            xs: '0.9rem',
            md: '1.1rem',
          },
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}>
        {leftTitle}
      </Typography>
      <Box sx={{flexGrow: 1}} />
      <Box>
        <Typography
          sx={{
            fontSize: {
              xs: '0.9rem',
              md: '1.1rem',
            },
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {rightTitle}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: '0.9rem',
              md: '1.1rem',
            },
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {rightSubtitle}
        </Typography>
      </Box>
    </Box>
  );
};
