import {Card, CardContent, Chip, Grid, Stack, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {LoadingButton} from '@mui/lab';
import UploadImages from '../upload-images/UploadImages';
import {useState} from 'react';
import {putPostMenu} from '../../redux/slices/menu-slice/menu.thunks';
import {useForm} from 'react-hook-form';
import {menuRestauranteSchema} from '../../redux/slices/menu-slice/menu.schemas';
import {yupResolver} from '@hookform/resolvers/yup';
import {ControlledNumericTextField} from '../form-input/controlled-numeric-text-field';
import yup from '../../common/yup';
import {ControlledTextField} from '../form-input/controlled-text-field';
import ControlledSelector from '../form-input/controlled-selector';
import {MenuRestaurante} from '../../services/dto/orden';
import {imageType} from '../../services/dto/auth';
import {filteredModificadores} from '../../redux/slices/modificador-slice';

type propsTypes = {
  defaultValues?: MenuRestaurante;
  handleClose: VoidFunction;
};

function NewMenuForm({defaultValues, handleClose}: propsTypes) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.uiSlice.isLoading);

  const categorias = useAppSelector(state => state.menuSlice.categorias);
  const subcategorias = useAppSelector(state => state.menuSlice.subcategorias);
  const sucursales = useAppSelector(state => state.restauranteSlice.sucursales);
  const modificadores = useAppSelector(filteredModificadores);

  const [image, setImage] = useState<File | imageType | null>(
    defaultValues?.producto.imagen ?? null,
  );

  const onSubmit = async (data: any) => {
    if (!image) return;
    dispatch(startLoading());
    await dispatch(
      putPostMenu({
        ...data,
        editId: defaultValues?.id_menu_restaurante,
        image: image,
      }),
    )
      .unwrap()
      .then(() => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  const {control, handleSubmit, formState, watch} = useForm<
    yup.InferType<typeof menuRestauranteSchema>
  >({
    mode: 'onBlur',
    defaultValues: {
      categoria_producto_id:
        defaultValues?.producto.categoria_producto.id_categoria_producto,
      descripcion_producto: defaultValues?.producto.descripcion,

      nombre_producto: defaultValues?.producto.nombre_producto,
      precio_producto: defaultValues?.producto.precio,
      subcategoria_producto_id:
        defaultValues?.producto.subcategoria_producto.id_subcategoria_producto,
      modificadores:
        defaultValues?.modificadores_menu?.map(
          e => e.accion_a_modificador_id,
        ) ?? [],
      sucursales: defaultValues?.menu_sucursales?.map(e => e.sucursal_id) ?? [],
    },
    resolver: yupResolver(menuRestauranteSchema),
  });

  const selectedCategory = watch('categoria_producto_id');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <UploadImages
                  state={image}
                  setState={image => {
                    setImage(image);
                    // dispatch(actualizarMenuRestaurante({imagen: image}));
                  }}
                  buttonLabel="Cargar foto"
                />
                {!image && (
                  <Typography variant={'caption'} color={'red'}>
                    Imagen requerida
                  </Typography>
                )}
                <ControlledNumericTextField
                  label="Precio"
                  type="money"
                  minValue={0}
                  controllerProps={{control, name: 'precio_producto'}}
                  required
                />
                <ControlledTextField
                  fullWidth
                  label="Nombre"
                  controllerProps={{control, name: 'nombre_producto'}}
                  required
                />
                <ControlledTextField
                  fullWidth
                  multiline
                  label="Descripcion"
                  required
                  controllerProps={{control, name: 'descripcion_producto'}}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <ControlledSelector
                  sourceData={categorias}
                  controllerProps={{control, name: 'categoria_producto_id'}}
                  title="Categoria"
                  idKey="id_categoria_producto"
                  valueKey={['nombre_categoria']}
                  fullWidth
                />
                <ControlledSelector
                  sourceData={subcategorias}
                  controllerProps={{control, name: 'subcategoria_producto_id'}}
                  title="Sub-categorias"
                  idKey="id_subcategoria_producto"
                  valueKey={['nombre_subcategoria']}
                  fullWidth
                  helperText={
                    selectedCategory ? '' : 'Debes seleccionar una categoria.'
                  }
                  disabled={!selectedCategory}
                  filter={opts =>
                    opts.filter(
                      e =>
                        e.categoria_producto.id_categoria_producto ===
                        selectedCategory,
                    )
                  }
                />

                <ControlledSelector
                  multiple
                  sourceData={sucursales}
                  controllerProps={{control, name: 'sucursales'}}
                  title="Sucursales"
                  idKey="id_sucursal"
                  valueKey={['descripcion_ubicacion']}
                  fullWidth
                />

                <ControlledSelector
                  multiple
                  getOptionLabel={opt => {
                    if (!opt) return '';
                    return `${opt.nombre} - ($${opt.precio})`;
                  }}
                  sourceData={modificadores}
                  controllerProps={{control, name: 'modificadores'}}
                  title="Modificadores"
                  idKey="id"
                  renderTags={(value, getTagProps) =>
                    value.map((e, index) => (
                      <Chip
                        {...getTagProps({index})}
                        key={e.id}
                        label={`${e.accion} - ${e.nombre} - ($${e.precio})`}
                      />
                    ))
                  }
                  groupBy={opt => opt.accion}
                  valueKey={['nombre']}
                  fullWidth
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoading}
            disabled={!!Object.keys(formState.errors).length && image == null}>
            Guardar Cambios
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewMenuForm;
