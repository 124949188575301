import {useMemo, useState} from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from 'recharts';
import {fCurrency} from '../../common/formatNumber';
import roundMoney from '../../common/roundMoney';

type CustomPieChartType = {
  data: {name: string; value: number}[];
  COLORS: string[];
  tooltip?: boolean;
  legend?: boolean;
  width?: number;
  height?: number;
  innerRadius?: number;
  outerRadius?: number;
  fontSizeInnerText?: string | number;
  noText?: boolean;
};

const CustomPieChart = ({
  data,
  COLORS,
  tooltip = false,
  legend = false,
  width = 200,
  height = 200,
  innerRadius = 40,
  outerRadius = 80,
  fontSizeInnerText = '1.5rem',
  noText = false,
}: CustomPieChartType) => {
  const roundedData = useMemo(
    () =>
      data.map(item => ({
        ...item,
        value: roundMoney(item.value),
      })),
    [data],
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          style={{fontWeight: 'bold', fontSize: fontSizeInnerText}}
          textAnchor="middle"
          fill="#000">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {!noText && (
          <>
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <path
              d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
              stroke={fill}
              fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              style={{fontSize: '0.7rem'}}
              textAnchor={textAnchor}
              fill="#333">{`${
              typeof value == 'number' ? fCurrency(value) : value
            }`}</text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              dy={18}
              style={{fontSize: '0.6rem'}}
              textAnchor={textAnchor}
              fill="#999">
              {`(${(percent * 100).toFixed(2)}%)`}
            </text>
          </>
        )}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={height}>
        <Pie
          activeIndex={activeIndex}
          dataKey="value"
          activeShape={renderActiveShape}
          data={roundedData}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#82ca9d"
          onMouseEnter={onPieEnter}>
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {tooltip && <Tooltip />}
        {legend && <Legend />}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
