import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import MoverProductoForm from './MoverProductoForm';
type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};
const MoverProductosModal = ({state, handleClose}: PropsTypes) => {
  return (
    <DialogPopUp
      PaperProps={{
        sx: {
          minWidth: '31%',
          maxWidth: '800px',
          height: 400,
          position: 'relative',
        },
      }}
      open={state}
      handleClose={handleClose}
      title="Mover Productos">
      <MoverProductoForm handleClose={handleClose}></MoverProductoForm>
    </DialogPopUp>
  );
};

export default MoverProductosModal;
