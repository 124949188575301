import {Box} from '@mui/material';
import {useAppSelector} from '../../redux/store';
import BottomText from './bottom-text';
import MetodoPagoInput from './metodo-pago-input';

const MetodoPagoForm = () => {
  const cierreSlice = useAppSelector(store => store.cierreSlice);
  return (
    <Box sx={{width: '100%'}} py={2}>
      {cierreSlice.metodoPagoDeclarado.map((element, index) => (
        <MetodoPagoInput
          index={index}
          id={element.id_metodo_pago}
          key={element.id_metodo_pago_restaurante}
          text={element.nombre_metodo_pago}
          value={element.cantidad ? element.cantidad : NaN}
        />
      ))}
      <BottomText
        text="TOTAL MÉTODO DE PAGO"
        number={cierreSlice.metodoPagoTotal}
      />
    </Box>
  );
};

export default MetodoPagoForm;
