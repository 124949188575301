import {Button, Stack, TableCell, TableRow} from '@mui/material';
import {fCurrency, fPercent} from '../../../../common/formatNumber';
import './stylerestaurant.css';
type PropsTypes = {
  restauranteNombre?: string;
  idrestaurante?: number;
  sucursal?: string;
  ventaAnterior?: number;
  facturaAnterior?: number;
  ventaActual?: number;
  facturaActual?: number;
  acuerdoFijoMoney?: number;
  acuerdoFijoPercent?: number;
  onClickDetalle?: () => void;
  onClickFacturar?: () => void;
};

const TableBodyRow = ({
  restauranteNombre = '---',
  idrestaurante = 0,
  sucursal = '---',
  ventaAnterior = 0,
  facturaAnterior = 0,
  ventaActual = 0,
  facturaActual = 0,
  acuerdoFijoMoney,
  acuerdoFijoPercent,
  onClickDetalle,
  onClickFacturar,
}: PropsTypes) => {
  const handleClickDetalle = () => {
    if (onClickDetalle) onClickDetalle();
  };

  const handleClickFacturar = () => {
    if (onClickFacturar) onClickFacturar();
  };

  const acuerdoFijoMoneyValidated = acuerdoFijoMoney
    ? fCurrency(acuerdoFijoMoney)
    : '';
  const acuerdoFijoPercentValidated = acuerdoFijoPercent
    ? fPercent(acuerdoFijoPercent)
    : '';

  return (
    <TableRow hover>
      {/*LÓGICA PARA STATUS INACTIVO*/}
      <TableCell align="left" className="name-restaurant">
        {restauranteNombre}
        {/* {restauranteNombre === 'El Trio de Tres' && (
          <Chip label="Inactivo" size="small" className="chip-inactivo" />
        )} */}
      </TableCell>

      {/* <TableCell align="left">{sucursal}</TableCell> */}
      <TableCell align="center">{fCurrency(ventaAnterior)}</TableCell>
      {/* <TableCell align="center">{fCurrency(facturaAnterior)}</TableCell> */}
      <TableCell align="center">{fCurrency(ventaActual)}</TableCell>
      {/* <TableCell align="center">{fCurrency(facturaActual)}</TableCell> */}

      {/* <TableCell align="center">{acuerdoFijoMoneyValidated}</TableCell>
      <TableCell align="center">{acuerdoFijoPercentValidated}</TableCell> */}
      <TableCell>
        <Stack direction="row" spacing={1}>
          {onClickDetalle && (
            <Button
              onClick={handleClickDetalle}
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              sx={{fontSize: '0.5rem', minWidth: '80px'}}>
              VER DETALLE
            </Button>
          )}
          {onClickFacturar && (
            <Button
              onClick={handleClickFacturar}
              fullWidth
              size="small"
              variant="contained"
              color="success"
              sx={{fontSize: '0.5rem', color: '#FFF', minWidth: '80px'}}>
              FACTURAR
            </Button>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default TableBodyRow;
