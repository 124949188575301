import yup from '../../../common/yup';

export const marketingSchema = yup
  .object({
    id_banner: yup.number(),
    fecha_inicio: yup
      .mixed()
      .when('$request', request =>
        request ? yup.string().required() : yup.date().required(),
      ),
    fecha_final: yup
      .mixed()
      .when('$request', request =>
        request ? yup.string() : yup.date().nullable().default(null),
      ),
    orden_banners: yup.number().required(),
    sucursales: yup.array(yup.number().required()).min(1).required(),
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
  })
  .required();
