import {create} from 'apisauce';
import {store} from '../redux/store';

//configs
const commonRequestConfigs = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
};

//requests instances
export const requestBaseURL = create({
  baseURL: process.env.REACT_APP_BACK_END,
  ...commonRequestConfigs,
});

export const requestToken = create({
  baseURL: `${process.env.REACT_APP_BACK_END}/api`,
  ...commonRequestConfigs,
});

export const requestAuthURL = create({
  baseURL: `${process.env.REACT_APP_BACK_END}/api`,
  ...commonRequestConfigs,
});

//interceptors

//request with token interceptor
requestToken.axiosInstance.interceptors.request.use(
  async config => {
    const {userSlice} = store.getState();
    if (!userSlice.isLogged) return Promise.reject('necesitas iniciar sesion');
    if (!userSlice.tokenDetails?.access_token)
      return Promise.reject('necesitas iniciar sesion');
    const {access_token} = userSlice.tokenDetails;
    config.headers.Authorization = `Bearer ${access_token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
