import {store} from '../redux/store';

export const authHeader = () => {
  const {userSlice} = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${userSlice.tokenDetails?.access_token}`,
    },
  };
};

export const login = () => `${process.env.REACT_APP_BACK_END}/api/login`;

export const register = () => `${process.env.REACT_APP_BACK_END}/api/sign-in`;

export const resetPassword = () =>
  `${process.env.REACT_APP_BACK_END}/api/update-password`;

export const token = () => `${process.env.REACT_APP_BACK_END}/oauth/token`;

export const validarEmail = (pin: string, email: string) =>
  `${process.env.REACT_APP_BACK_END}/api/validar-email?pin=${pin}&email=${email}`;

export const reenviarPin = (email: string) =>
  `${process.env.REACT_APP_BACK_END}/api/reenviar-pin-email?email=${email}`;

//

export const getRestaurants = (page: number | string) =>
  `${process.env.REACT_APP_BACK_END}/api/restaurantes?page=${page}`;

export const getTipoComidas = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_comidas`;

export const getRestaurantsFlat = () =>
  `${process.env.REACT_APP_BACK_END}/api/restaurantes`;

export const getSucursales = (
  page: string | number,
  id_restaurante: number | string,
) =>
  `${process.env.REACT_APP_BACK_END}/api/sucursal_restaurante?page=${page}&restaurante_id=${id_restaurante}`;

export const getSucursalesFlat = () =>
  `${process.env.REACT_APP_BACK_END}/api/sucursal_restaurante`;

export const getUsuario = () => `${process.env.REACT_APP_BACK_END}/api/usuario`;

export const getImagen = () => `${process.env.REACT_APP_BACK_END}/api/imagen`;

///

export const getTipoBanner = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_banners`;

export const getBanners = () => `${process.env.REACT_APP_BACK_END}/api/banners`;

///

export const getMetodoPago = () =>
  `${process.env.REACT_APP_BACK_END}/api/metodo_pago`;

export const getMetodoPagoRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/metodo_pago_restaurante`;

export const getPropinas = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_pago_propina`;
export const getPropinasRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_propina_restaurante`;

export const getImpuestos = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_impuesto`;
export const getImpuestosRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_impuesto_restaurante`;

export const getDescuentos = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_pago_descuento`;

export const getDescuentosRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_descuento_restaurante`;

//

export const getEmpleadoRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/empleado_restaurante`;

export const getCategoriasRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/categoria_producto`;

export const getSubCategoriasRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/subcategoria_producto`;

export const getProductosRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/producto`;

export const getMenuRestaurante = () =>
  `${process.env.REACT_APP_BACK_END}/api/menu_restaurante`;

export const getPermisos = () =>
  `${process.env.REACT_APP_BACK_END}/api/permisos`;

export const getCargoEmpleado = () =>
  `${process.env.REACT_APP_BACK_END}/api/cargo_empleados`;

export const getSecciones = () =>
  `${process.env.REACT_APP_BACK_END}/api/seccion_sucursal`;

export const getMesasSucursales = () =>
  `${process.env.REACT_APP_BACK_END}/api/mesa_sucursal`;

export const getCajaSucursales = () =>
  `${process.env.REACT_APP_BACK_END}/api/caja`;
export const getMenuSucursales = () =>
  `${process.env.REACT_APP_BACK_END}/api/menu_sucursal`;

export const getUrlUsuario = () =>
  `${process.env.REACT_APP_BACK_END}/api/usuario`;

export const getUrlInvitacion = () =>
  `${process.env.REACT_APP_BACK_END}/api/empleado_verificacion_email`;

export const getUrlEmpleadoAsignadoSurcursal = () =>
  `${process.env.REACT_APP_BACK_END}/api/empleado_asignado_sucursal`;

export const getUrlEmpleadoAsignadoSeccion = () =>
  `${process.env.REACT_APP_BACK_END}/api/empleado_sucursal_area`;

export const getUrlTipoUbicacion = () =>
  `${process.env.REACT_APP_BACK_END}/api/tipo_ubicacion`;

export const getUrlEmpleadoAsignadoCaja = () =>
  `${process.env.REACT_APP_BACK_END}/api/caja_empleado`;
