import {createAsyncThunk} from '@reduxjs/toolkit';
import isNullish from '../../../common/isNullish';
import {sendImage2} from '../../../common/transformUrlToImage';
import {api} from '../../../services';
import {CajaEmpleado, EmpleadoRestaurante} from '../../../services/dto/auth';
import {
  restaurante,
  sucursalRestaurante,
  TipoComida,
} from '../../../services/dto/restaurante';
import {RootState} from '../../store';
import {putPost} from '../menu-slice/menu.types';
import {refreshUser} from '../user-slice/user.thunks';
import {
  restauranteStoretypePrimary,
  sucursal,
  sucursalStoreTypePrimary,
} from './restaurante.types';

import * as urls from '../../../common/url';
import {msgSuccess} from '../../../common/toast';

export const checkCajaEmpleadoUser = createAsyncThunk<
  CajaEmpleado | null,
  undefined,
  {state: RootState}
>('restaurantes/checkCajaEmpleado', async (data, thunkAPI) => {
  const {
    restauranteSlice: {empleadoAsignadoSucursalSeleccionado},
  } = thunkAPI.getState();
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay empleado');
  const response = await api.getCajaEmpleadoSucursal(
    empleadoAsignadoSucursalSeleccionado.id_empleado_asignado_sucursal,
  );
  if (
    response?.data.length &&
    response.data[0].estado_caja_asignada === 'ASIGNADA'
  ) {
    return response.data[0];
  }
  return thunkAPI.rejectWithValue('No hay caja asignada');
});

export const getRestaurantes = createAsyncThunk<
  EmpleadoRestaurante[],
  undefined,
  {state: RootState}
>('restaurantes/getRestaurantes', async (data, thunkAPI) => {
  const restaurantes =
    thunkAPI.getState().userSlice.userDetail?.empleado_restaurante;

  return restaurantes ? restaurantes : [];
});

export const getTiposDeComida = createAsyncThunk<TipoComida[], undefined>(
  'restaurantes/getTiposDeComida',
  async (data, thunkAPI) => {
    const response = await api.getTipoDeComida();
    if (!response) return thunkAPI.rejectWithValue('No hay tipos de comida');
    return response.data;
  },
);

export const getSucursales = createAsyncThunk<
  sucursalRestaurante[],
  undefined,
  {state: RootState}
>('restaurantes/getSucursales', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();
  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');

  const response = await api.getSucursales(restauranteSeleccionado.id);
  if (!response) return thunkAPI.rejectWithValue('No hay sucursales');
  return response.data;
});

export const putPostRestaurante = createAsyncThunk<
  null,
  putPost,
  {state: RootState}
>('restaurantes/putPostRestaurante', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      editable: {
        restauranteCopy: restauranteCopyTest,
        restaurante: restauranteTemp,
      },
      tiposDeComida,
    },
    userSlice: {userDetail},
  } = thunkAPI.getState();
  if (!userDetail) return thunkAPI.rejectWithValue('No hay usuario');

  if (data === 'crear') {
    const {id, ...rest} = restauranteTemp;
    if (isNullish(rest))
      return thunkAPI.rejectWithValue('error al crear el restaurante');
  } else {
    const {
      logo,
      avisoDeOperacion,
      identificacionDelantera,
      identificacionTrasera,
      ...rest
    } = restauranteTemp;
    if (isNullish(rest))
      return thunkAPI.rejectWithValue('error al actualizar el restaurante ');
  }
  const restaurante = restauranteTemp as restauranteStoretypePrimary;

  const filtrado = [
    ...new Set(
      tiposDeComida
        .filter(comida =>
          restaurante.tiposDeComida.includes(comida.nombre_tipo_comida),
        )
        .map(
          ({nombre_tipo_comida, descripcion_tipo_comida, ...item}) =>
            item.id_tipo_comida,
        ),
    ),
  ];

  if (data === 'crear') {
    const form = new FormData();

    form.append('primer_empleado', userDetail.id.toString());
    form.append('nombre', restaurante.nombre);
    form.append('nick_restaurante', restaurante.nick);
    form.append('razon_social', restaurante.razonSocial);
    form.append('ruc', restaurante.ruc);
    form.append('slogan', restaurante.slogan);
    form.append('numero_sucursales', restaurante.numSucur.toString());
    form.append('direccion_restaurante', restaurante.direccion);
    form.append('piso_oficina', restaurante.pisOfic.toString());
    form.append('correo_electronico_negocio', restaurante.email);
    form.append('numero_telefono_movil', restaurante.telefono);

    if (restaurante.logo instanceof File) {
      form.append('logo', restaurante.logo, restaurante.logo.name);
    }

    if (restaurante.avisoDeOperacion instanceof File) {
      form.append(
        'aviso_operaciones_restaurante',
        restaurante.avisoDeOperacion,
        restaurante.avisoDeOperacion.name,
      );
    }

    if (restaurante.identificacionDelantera instanceof File) {
      form.append(
        'foto_frontal_identificacion_representante_legal',
        restaurante.identificacionDelantera,
        restaurante.identificacionDelantera.name,
      );
    }

    if (restaurante.identificacionTrasera instanceof File) {
      form.append(
        'foto_trasera_identificacion_representante_legal',
        restaurante.identificacionTrasera,
        restaurante.identificacionTrasera.name,
      );
    }

    for (let i = 0; i < filtrado.length; i++) {
      form.append('tipo_comida[]', filtrado[i].toString());
    }

    const response = await api.postRestaurante(form);
    if (!response)
      return thunkAPI.rejectWithValue('error al crear el restaurante');
    thunkAPI.dispatch(refreshUser());
    msgSuccess('Restaurante creado con éxito');
    return null;
  } else {
    const bodyPut = {
      primer_empleado: userDetail.id.toString(),
      nombre: restaurante.nombre,
      nick_restaurante: restaurante.nick,
      razon_social: restaurante.razonSocial,
      ruc: restaurante.ruc,
      slogan: restaurante.slogan,
      numero_sucursales: restaurante.numSucur.toString(),
      direccion_restaurante: restaurante.direccion,
      piso_oficina: restaurante.pisOfic.toString(),
      correo_electronico_negocio: restaurante.email,
      numero_telefono_movil: restaurante.telefono,
      tipo_comida: filtrado,
    };

    const response = await api.putRestaurante(bodyPut, restaurante.id);

    const urlImagen = urls.getImagen();
    const authHeader = urls.authHeader();
    const restauranteCopy = restauranteCopyTest as restauranteStoretypePrimary;
    if (restaurante.logo instanceof File) {
      if (restauranteCopy.logo instanceof File)
        return thunkAPI.rejectWithValue('error al actualizar');
      await sendImage2({
        urlImagen,
        authHeader,
        image: restaurante.logo,
        type: `logo`,
        modelo: `Restaurante`,
        id_registro: restauranteCopy.logo.id_imagen,
      });
    }

    if (restaurante.avisoDeOperacion instanceof File) {
      if (restauranteCopy.avisoDeOperacion instanceof File)
        return thunkAPI.rejectWithValue('error al actualizar');
      await sendImage2({
        urlImagen,
        authHeader,
        image: restaurante.avisoDeOperacion,
        type: `aviso_operaciones_restaurante`,
        modelo: `Restaurante`,
        id_registro: restauranteCopy.avisoDeOperacion.id_imagen,
      });
    }

    if (restaurante.identificacionDelantera instanceof File) {
      if (restauranteCopy.identificacionDelantera instanceof File)
        return thunkAPI.rejectWithValue('error al actualizar');
      await sendImage2({
        urlImagen,
        authHeader,
        image: restaurante.identificacionDelantera,
        type: `foto_frontal_identificacion_representante_legal`,
        modelo: `Restaurante`,
        id_registro: restauranteCopy.identificacionDelantera.id_imagen,
      });
    }

    if (restaurante.identificacionTrasera instanceof File) {
      if (restauranteCopy.identificacionTrasera instanceof File)
        return thunkAPI.rejectWithValue('error al actualizar');
      await sendImage2({
        urlImagen,
        authHeader,
        image: restaurante.identificacionTrasera,
        type: `foto_trasera_identificacion_representante_legal`,
        modelo: `Restaurante`,
        id_registro: restauranteCopy.identificacionTrasera.id_imagen,
      });
    }

    if (!response) return thunkAPI.rejectWithValue('error al actualizar');
    thunkAPI.dispatch(refreshUser());
    msgSuccess('Restaurante actualizado con éxito');
    return null;
  }
});

export const cargarRestaurante = createAsyncThunk<
  restaurante,
  undefined,
  {state: RootState}
>('restaurantes/cargarRestaurante', async (data, thunkAPI) => {
  const {
    restauranteSlice: {restauranteSeleccionado},
  } = thunkAPI.getState();
  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');
  return restauranteSeleccionado;
});

export const putPostSucursal = createAsyncThunk<
  null,
  putPost,
  {state: RootState}
>('restaurantes/putPostSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      editable: {sucursal: sucursalTemp},
      restauranteEmpleadoSeleccionado: restauranteSeleccionado,
    },
  } = thunkAPI.getState();

  if (!restauranteSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');

  if (data === 'crear') {
    const {sucursal_id, restaurante_id, ...rest} = sucursalTemp;
    if (isNullish(rest)) return thunkAPI.rejectWithValue('algun valor es nulo');
  } else {
    if (isNullish(sucursalTemp))
      return thunkAPI.rejectWithValue('algun valor es nulo');
  }
  const sucursal = sucursalTemp as sucursalStoreTypePrimary;

  const bodyPut = {
    restaurante_id: restauranteSeleccionado.id,
    descripcion_ubicacion: sucursal.descripcion_ubicacion,
    descripcion_horario: sucursal.descripcion_horario,
    estado_sucursal: sucursal.estado_sucursal,
    latitud: sucursal.latitud,
    longitud: sucursal.longitud,
    cobra_impuesto: sucursal.cobra_impuesto,
    ruc_sucursal: sucursal.ruc_sucursal,
  };

  if (data === 'crear') {
    const response = await api.postSucursal(bodyPut);
    if (!response)
      return thunkAPI.rejectWithValue('error al crear la sucursal');
    msgSuccess('Sucursal creada con éxito');
    await thunkAPI.dispatch(getSucursales());
    await thunkAPI.dispatch(refreshUser());

    return null;
  }
  const response = await api.putSucursal(bodyPut, sucursal.sucursal_id);
  if (!response) return thunkAPI.rejectWithValue('error al actualizar');
  msgSuccess('Sucursal actualizada con éxito');
  await thunkAPI.dispatch(getSucursales());
  await thunkAPI.dispatch(refreshUser());
  return null;
});

export const cargarSucursal = createAsyncThunk<
  sucursal,
  number,
  {state: RootState}
>('restaurantes/cargarSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {sucursales},
  } = thunkAPI.getState();

  const sucursal = sucursales.find(element => element.id_sucursal === data);
  if (!sucursal) return thunkAPI.rejectWithValue('No se encontro la sucursal');
  return sucursal;
});

export const fetchRestauranteAndSucursal = createAsyncThunk<
  {restaurante: restaurante; sucursal: sucursalRestaurante},
  undefined,
  {state: RootState}
>('restaurantes/fetchRestauranteAndSucursal', async (data, thunkAPI) => {
  const {
    restauranteSlice: {
      restauranteEmpleadoSeleccionado,
      empleadoAsignadoSucursalSeleccionado,
    },
  } = thunkAPI.getState();
  if (!restauranteEmpleadoSeleccionado)
    return thunkAPI.rejectWithValue('No hay restaurante seleccionado');
  if (!empleadoAsignadoSucursalSeleccionado)
    return thunkAPI.rejectWithValue('No hay sucursal seleccionada');

  const restaurante = await api.getRestaurante(
    restauranteEmpleadoSeleccionado.id,
  );

  if (!restaurante)
    return thunkAPI.rejectWithValue('No se encontro el restaurante');

  const sucursal = await api.getSucursal(
    empleadoAsignadoSucursalSeleccionado.sucursal_id,
  );

  if (!sucursal) return thunkAPI.rejectWithValue('No se encontro la sucursal');

  return {restaurante: restaurante.data, sucursal: sucursal.data};
});
