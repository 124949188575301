import {TableBody} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {useDebouncedValue} from '../../common/hooks/use-debounced-value';
import roundMoney from '../../common/roundMoney';
import EmptyTableRow from '../../components/crud-table/table-empty-row';
import TableWrapper from '../../components/crud-table/table-wrapper';
import {actualizarCantidad} from '../../redux/slices/inventario-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {InsumosInventariado} from '../../services/dto/inventario';
import CustomRowItem from './custom-row-item';
import CustomTableHeader from './custom-table-header';

type PropsTypes = {
  title?: string;
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
  rows: any;
  onSearch?: (row: any, searchTerm: string) => any;
  order?: 'asc' | 'desc';
  orderBy?: string;
  sortHandler?: (key: string, allKeys: string[]) => void;
};

const InventarioTable = ({
  title,
  columns,
  rows,
  onSearch,
  order,
  orderBy,
  sortHandler,
}: PropsTypes) => {
  const [sortedRows, setSortedRows] = useState(rows);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debauncedValue = useDebouncedValue({value: searchTerm, delay: 400});

  const filterFormula = (row: any) => {
    if (onSearch) return onSearch(row, searchTerm);
    return true;
  };

  const inventarioPasado = useAppSelector(
    store => store.inventarioSlice.inventarioPasado,
  );

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (onSearch) return setSortedRows(rows.filter(filterFormula));
    setSortedRows(rows);
  }, [debauncedValue, rows]);
  return (
    <TableWrapper
      title={title}
      totalRows={sortedRows.length}
      onSearch={onSearch}
      searchTerm={searchTerm}
      handleOnSearch={handleOnSearch}
      noCard={false}>
      <>
        <CustomTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          sortHandler={sortHandler}
        />
        <TableBody>
          {sortedRows.length ? (
            sortedRows.map((inventarioItem: any) => {
              const insumo = inventarioItem.insumo;
              let inventarioPasadoItem: InsumosInventariado | undefined;
              if (inventarioPasado) {
                inventarioPasadoItem =
                  inventarioPasado.insumos_inventariados.find(
                    element =>
                      element.id_insumo_sucursal === insumo.id_insumo_sucursal,
                  );
              }

              return (
                <CustomRowItem
                  key={insumo.id_insumo_sucursal}
                  productName={insumo.insumo.nombre_insumo}
                  subCategoryName={
                    insumo.insumo.sub_categoria_insumo
                      .nombre_sub_categoria_insumo
                  }
                  unitName={insumo.insumo.unidad_medida.nombre_unidad_medida}
                  cost={roundMoney(insumo.insumo.costo)}
                  lastInventoryQty={roundMoney(
                    inventarioPasadoItem ? inventarioPasadoItem.cantidad : 0,
                  )}
                  lastInventoryCost={roundMoney(
                    inventarioPasadoItem
                      ? inventarioPasadoItem.costo_inventario
                      : 0,
                  )}
                  newInventoryQty={
                    inventarioItem.cantidad
                      ? roundMoney(inventarioItem.cantidad)
                      : NaN
                  }
                  onChangeQty={value =>
                    dispatch(
                      actualizarCantidad({
                        id: insumo.id_insumo_sucursal,
                        value,
                      }),
                    )
                  }
                />
              );
            })
          ) : (
            <EmptyTableRow columns={columns} />
          )}
        </TableBody>
      </>
    </TableWrapper>
  );
};

export default InventarioTable;
