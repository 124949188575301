import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number?: number) {
  return numeral(number).format(
    Number.isInteger(number) ? '$0,0.00' : '$0,0.00',
  );
}

export function fPercent(number: number) {
  return `${number * 100}%`;
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fData(number: number) {
  return numeral(number).format('0.0 b');
}
