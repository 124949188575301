import {useAppDispatch, useAppSelector} from '../../redux/store';
import Pagetemplate from '../../layouts/page-template/Pagetemplate';
import {RestauranteEmpleados} from '../../services/dto/auth';
import {useCallback, useEffect, useState} from 'react';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {api} from '../../services';
import {VentasDeudasCliente} from '../../services/dto/orden';
import {Card, CardContent, Grid, Stack, Typography} from '@mui/material';
import CrudTable from '../../components/crud-table';
import {useCrudTable} from '../../common/hooks/use-crud-table';
import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ControlledNumericTextField} from '../../components/form-input/controlled-numeric-text-field';
import {LoadingButton} from '@mui/lab';
import sortArrayObject from '../../common/sortArrayObject';

type columnType = {
  label: string;
  key: string[];
}[];
const Deudas = () => {
  const isLoading = useAppSelector(store => store.uiSlice.isLoading);
  const dispatch = useAppDispatch();

  const [currentClient, setCurrentClient] = useState<VentasDeudasCliente>({
    id_balance_client_rest: 0,
    id_inf_client_rest: 0,
    info_cliente_id: 0,
    nombre: '',
    num_id: '',
    restaurante_id: 0,
    totBalanceCliente: 0,
    totDeudaCliente: 0,
    totMetodoPago: 0,
  });

  const column = [
    {
      label: 'Nombre',
      key: ['nombre'],
    },
    {
      label: 'Documento de Identidad',
      key: ['num_id'],
    },
    {
      label: 'Total consumido',
      key: ['totMetodoPago'],
    },
    {
      label: 'Deudas',
      key: ['totDeudaCliente'],
    },
    {
      label: 'Balance',
      key: ['totBalanceCliente'],
    },
    {
      label: '',
      key: ['info_cliente_id'],
    },
  ] as columnType;

  const restaurante = useAppSelector<RestauranteEmpleados | null>(
    store => store.restauranteSlice.restauranteEmpleadoSeleccionado,
  );

  const [clientes, setClientes] = useState<readonly VentasDeudasCliente[]>([]);

  const customHandleClose = () => {
    handleClose();
  };

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const {control, handleSubmit, formState, reset} = useForm<{balance: number}>({
    defaultValues: {
      balance: 0,
    },
    resolver: yupResolver(
      yup.object({
        balance: yup.number().required(),
      }),
    ),
  });

  const onSubmit = useCallback(
    async (data: {balance: number}) => {
      setLoadingUpdate(true);
      const response = await api.updateDeudasCliente(
        currentClient.id_inf_client_rest,
        data.balance,
      );
      if (response) {
        reset();
        currentClient.totBalanceCliente = response.total;
        const selected = clientes.find(
          value => value.id_inf_client_rest === response.inf_client_rest_id,
        );
        if (selected) selected.totBalanceCliente = response.total;
        fetchData().finally(() => null);
        handleClose();
      }
      setLoadingUpdate(false);
    },
    [clientes, currentClient],
  );

  const fetchData = useCallback(async () => {
    if (restaurante) {
      dispatch(startLoading());
      const data = await api.getDeudasClientesByRestaurant(restaurante.id);
      if (data) {
        setClientes(data.data);
      }
      dispatch(stopLoading());
    }
  }, [restaurante]);

  const {isOpen, handleOpen, handleClose} = useCrudTable();

  const openModal = useCallback(
    (id: number) => {
      const selected = clientes.find(value => value.info_cliente_id === id);
      if (selected) {
        setCurrentClient(selected);
        handleOpen(id);
      }
    },
    [currentClient, clientes],
  );

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('nombre');
  const [arrayKeys, setArrayKeys] = useState<string[]>(['nombre']);

  const sortHandler = (key: string, allKeys: string[]) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
    setArrayKeys(allKeys);
  };

  useEffect(() => {
    fetchData().finally(() => null);
  }, [restaurante]);
  return (
    <Pagetemplate
      isLoading={isLoading}
      title="Deudas de clientes || IFUDIS"
      soloLayout>
      <Grid container height={'100%'} sx={{overflowY: 'auto'}} spacing={2}>
        <Grid item xs={12}>
          <Card sx={{minHeight: '100%'}}>
            <CardContent>
              <Typography variant={'subtitle1'}>Clientes</Typography>
              <CrudTable
                noCard
                columns={column}
                rows={[...clientes].sort((a, b) =>
                  sortArrayObject(a, b, arrayKeys, order),
                )}
                onView={openModal}
                order={order}
                orderBy={orderBy}
                sortHandler={sortHandler}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogPopUp
        open={isOpen}
        handleClose={customHandleClose}
        title="Detalle de la deuda del cliente">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant={'h6'}>Nombre:</Typography>
              <Typography>{currentClient.nombre}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant={'h6'}>Documento de identidad:</Typography>
              <Typography>{currentClient.num_id}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant={'h6'}>Total Consumido:</Typography>
              <Typography>{currentClient.totMetodoPago}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant={'h6'}>Deudas:</Typography>
              <Typography>{currentClient.totDeudaCliente}</Typography>
            </Grid>
            <Grid item xs>
              <Typography
                textAlign={'center'}
                justifyContent={'center'}
                variant={'h6'}>
                Balance o saldo en credito:
              </Typography>
              <Typography
                textAlign={'center'}
                justifyContent={'center'}
                color={
                  currentClient.totBalanceCliente >= 0 ? 'success' : 'error'
                }>
                {currentClient.totBalanceCliente}
              </Typography>
            </Grid>
          </Grid>
          <Grid container paddingY={3} justifyContent={'center'}>
            <Grid item md={6}>
              <ControlledNumericTextField
                fullWidth
                allowNegative
                type={'money'}
                label={'Agregar al saldo del balance'}
                variant={'outlined'}
                controllerProps={{
                  control,
                  name: 'balance',
                }}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent={'center'} spacing={2}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loadingUpdate}
              disabled={Object.values(formState.errors).length > 0}>
              Actualizar
            </LoadingButton>
          </Stack>
        </form>
      </DialogPopUp>
    </Pagetemplate>
  );
};

export default Deudas;
