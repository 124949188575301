import {store} from '../redux/store';
import isIfudisAllowed from './isIfudisAdminAllowed';
import isWhiteListed from './isWhiteListed';

const onwerAllowedRoutes = [
  '/diario-ventas',
  '/compras',
  '/insumos',
  '/inventario',
  '/orden',
  '/menu',
  '/menu-sucursal',
  '/empleados',
  '/caja',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/historial-cierre',
  '/comandas',
  '/historial-comandas',
  '/administracion',
  '/administracion/mercadeo',
  '/administracion/finanzas',
  '/administracion/seccion',
  '/administracion/mesas',
  '/administracion/restaurante',
  '/administracion/modificadores',
  '/reportes',
  '/reportes/balance-actual',
  '/reportes/facturas-anuladas',
  '/reportes/ventas-articulos',
  '/reportes/ventas-metodo-pago',
  '/reportes/ventas-categorias',
  '/reportes/ventas-subcategorias',
  '/reportes/ventas-empleados',
  '/reportes/ventas-tiempo/hora',
  '/reportes/ventas-tiempo/dia',
  '/reportes/ventas-mesas',
  '/reportes/ventas-descuentos',
  '/reportes/ventas-cortesias',
  '/reportes/articulos-borrados',
  '/reportes/ventas-seccion',
  '/clientes',
  '/deudas_clientes',
];

const supervisorAllowedRoutes = [
  '/compras',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/insumos',
  '/comandas',
  '/historial-comandas',
  '/inventario',
  '/orden',
  '/diario-ventas',
  '/historial-cierre',
  '/clientes',
  '/deudas_clientes',
];

const sellerAllowedRoutes = [
  '/orden',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/historial-cierre',
];

const cashierAllowedRoutes = [
  '/orden',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/diario-ventas',
  '/historial-cierre',
];

const AdminMenuAllowedRoutes = [
  '/menu',
  '/menu-sucursal',
  '/inventario',
  '/compras',
];

const gmAllowedRoutes = [
  '/diario-ventas',
  '/compras',
  '/insumos',
  '/inventario',
  '/orden',
  '/menu',
  '/menu-sucursal',
  '/empleados',
  '/caja',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/comandas',
  '/historial-comandas',
  '/administracion',
  '/administracion/mercadeo',
  '/administracion/finanzas',
  '/administracion/seccion',
  '/administracion/mesas',
  '/administracion/modificadores',
  '/reportes',
  '/reportes/balance-actual',
  '/reportes/facturas-anuladas',
  '/reportes/ventas-articulos',
  '/reportes/ventas-metodo-pago',
  '/reportes/ventas-categorias',
  '/reportes/ventas-subcategorias',
  '/reportes/ventas-empleados',
  '/reportes/ventas-tiempo/hora',
  '/reportes/ventas-tiempo/dia',
  '/reportes/ventas-mesas',
  '/reportes/ventas-descuentos',
  '/reportes/ventas-cortesias',
  '/reportes/articulos-borrados',
  '/historial-cierre',
  '/reportes/ventas-seccion',
  '/clientes',
  '/deudas_clientes',
];

const comandaAllowedRoutes = ['/comandas', '/historial-comandas'];

const contabilidadAllowedRoutes = [
  '/compras',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/diario-ventas',
  '/reportes',
  '/reportes/balance-actual',
  '/reportes/ventas-articulos',
  '/reportes/facturas-anuladas',
  '/reportes/ventas-metodo-pago',
  '/reportes/ventas-categorias',
  '/reportes/ventas-subcategorias',
  '/reportes/ventas-empleados',
  '/reportes/ventas-tiempo/hora',
  '/reportes/ventas-tiempo/dia',
  '/reportes/ventas-mesas',
  '/reportes/ventas-descuentos',
  '/reportes/ventas-cortesias',
  '/reportes/articulos-borrados',
  '/reportes/ventas-seccion',
  '/historial-cierre',
];

const asistenteAdminAllowedRoutes = [
  '/orden',
  '/comandas',
  '/historial-comandas',
  '/inventario',
  '/historial-cierre',
  '/cierre',
  '/cierre/x',
  '/cierre/z',
  '/historial-cierre',
];

export const listOfRolesInOrderID = [
  1, //  Owner
  8, //  GM
  2, // Supervisor
  11, // Asistente Admin
  10, // Contabilidad
  3, // Seller
  4, // CASHIER (Discount)
  12, // CASHIER
  9, // Comanda
  13, // Administracion de Menu
];

export const listOfRolesInOrder = [
  'OWNER', // 1
  'GM', // 8
  'SUPERVISO', // 2
  'ASISTENTE ADMIN', // 11
  'CONTABILIDAD', // 10
  'SELLER', // 3
  'CASHIER (Discount)', // 4
  'CASHIER', // 12
  'COMANDAS', // 9
  'ADMINISTRACION DE MENU',
];

const isRouteAllowed = (): boolean => {
  const cargoId = store.getState().restauranteSlice.cargoEmpleado?.id_cargo;
  const cargo_ifudis = store.getState().userSlice.cargo_ifudis?.id_cargo;

  if (!isWhiteListed()) return true;

  if (!cargo_ifudis) return false;

  if (isIfudisAllowed()) return true;

  switch (cargoId) {
    case 1:
      return onwerAllowedRoutes.includes(window.location.pathname);
    case 2:
      return supervisorAllowedRoutes.includes(window.location.pathname);
    case 3:
      return sellerAllowedRoutes.includes(window.location.pathname);
    case 4:
      return cashierAllowedRoutes.includes(window.location.pathname);
    case 8:
      return gmAllowedRoutes.includes(window.location.pathname);
    case 9:
      return comandaAllowedRoutes.includes(window.location.pathname);
    case 10:
      return contabilidadAllowedRoutes.includes(window.location.pathname);
    case 11:
      return asistenteAdminAllowedRoutes.includes(window.location.pathname);
    case 12:
      return cashierAllowedRoutes.includes(window.location.pathname);
    case 13:
      return AdminMenuAllowedRoutes.includes(window.location.pathname);
    default:
      return false;
  }
};

export const getRoutesArray = (): string[] => {
  const cargoId = store.getState().restauranteSlice.cargoEmpleado?.id_cargo;

  switch (cargoId) {
    case 1:
      return onwerAllowedRoutes;

    case 2:
      return supervisorAllowedRoutes;

    case 3:
      return sellerAllowedRoutes;

    case 4:
      return cashierAllowedRoutes;

    case 8:
      return gmAllowedRoutes;

    case 9:
      return comandaAllowedRoutes;

    case 10:
      return contabilidadAllowedRoutes;

    case 11:
      return asistenteAdminAllowedRoutes;

    case 12:
      return cashierAllowedRoutes;
    case 13:
      return AdminMenuAllowedRoutes;
    default:
      return [];
  }
};

export default isRouteAllowed;
