import {Grid} from '@mui/material';
import useMediaQuery from '../../common/hooks/use-media-query';
import {useAppSelector} from '../../redux/store';
import DrawerPuller from '../drawer-puller/drawer-puller';
import Precuenta from './precuenta';

type precuentaWrapperProps = {
  handleAtras: () => void;
};

function PreCuentaWrapper({handleAtras}: precuentaWrapperProps) {
  const isMobile = useMediaQuery('(max-width: 899px)');

  const cajaActiva = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );

  const currentStep = useAppSelector(state => state.ordenSlice.currentStep);

  if (isMobile)
    return (
      <DrawerPuller>
        <Precuenta handleAtras={handleAtras} />
      </DrawerPuller>
    );

  const viewPort = !cajaActiva && currentStep === 3 ? 12 : 5;

  return (
    <Grid
      item
      md={viewPort}
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
        width: '100%',
        height: '100%',
      }}>
      <Precuenta handleAtras={handleAtras} />
    </Grid>
  );
}

export default PreCuentaWrapper;
