import yup from '../../../common/yup';

export const descuentoSchema = yup
  .object({
    descripcion_tipo_descuento: yup.string().required(),
    tipo_pago_descuento_id: yup.number(),
    porcentaje: yup.number().when('tipo_pago_descuento_id', (value, schema) => {
      if (value !== 8 && value !== 4) return schema.required();
      return schema;
    }),
    cantidad: yup.number().when('tipo_pago_descuento_id', {
      is: 8,
      then: s => s.required(),
    }),
    //solo validar esto al momento de hacer la request, no en el form
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_tipo_descuento_restaurante: yup.number(),
  })
  .required();

export const propinaSchema = yup
  .object({
    tipo_pago_propina_id: yup.number().required(),
    porcentaje: yup.number().when('tipo_pago_propina_id', {
      is: 2,
      then: s => s.notRequired(),
      otherwise: s => s.required(),
    }),
    //solo validar esto al momento de hacer la request, no en el form
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_tipo_propina_restaurante: yup.number(),
  })
  .required();

export const metodoPagoSchema = yup
  .object({
    metodo_pago_id: yup.number().required(),
    //solo validar esto al momento de hacer la request, no en el form
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_metodo_pago_restaurante: yup.number(),
  })
  .required();

export const impuestoSchema = yup
  .object({
    tipo_impuesto_id: yup.number().required(),
    porcentaje: yup.number().required(),
    ley: yup.string().required(),
    //solo validar esto al momento de hacer la request, no en el form
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    //id de registro
    id_tipo_impuesto_restaurante: yup.number(),
  })
  .required();
