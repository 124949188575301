import {Box, Button} from '@mui/material';
import {memo, useEffect, useMemo, useRef, useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ComandaPlatoItem from './comanda-plato-item';
import {Orden} from '../../services/dto/comanda';

type PropsTypes = {ordenes: Orden[]; id_codigo_orden: number};

const ComandaPlatosList = ({ordenes, id_codigo_orden}: PropsTypes) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverFlow, setIsOverFlow] = useState(false);
  const box = useRef<any>(null);

  useEffect(() => {
    const hasOverflowingChildren =
      box.current.scrollHeight - box.current.clientHeight >= 10;
    if (hasOverflowingChildren) setIsOverFlow(true);
  }, [box, isExpanded]);

  const platos = useMemo(() => {
    const blacklistIndex: number[] = [];
    const mergedCarrito: Orden[] = [];
    ordenes.forEach((item, index) => {
      let cantidad = item.cantidad;
      if (blacklistIndex.includes(index)) return;
      ordenes.forEach((el, index2) => {
        if (!item) return;
        if (!item.motivo_cancelacion_producto) return;
        if (!el.motivo_cancelacion_producto) return;
        if (
          index !== index2 &&
          item.menu_restaurante_id === el.menu_restaurante_id &&
          item.observaciones === el.observaciones &&
          item.motivo_cancelacion_producto.length < 1 &&
          el.motivo_cancelacion_producto.length < 1 &&
          item.modificador_producto.length === 0 &&
          el.modificador_producto.length === 0
        ) {
          blacklistIndex.push(index2);
          cantidad += el.cantidad;
        }
      });
      mergedCarrito.push({...item, cantidad});
    });

    const copyCarrito = [...mergedCarrito];

    mergedCarrito.forEach(item => {
      if (!item) return;
      if (!item.motivo_cancelacion_producto) return;

      if (
        !(
          item.estado_orden_id != 3 &&
          item.motivo_cancelacion_producto.length > 0
        )
      )
        return;

      item.motivo_cancelacion_producto.forEach(el => {
        const copy: Orden = {
          ...item,
          cantidad: el.cantidad,
          motivo_cancelacion_producto: [],
          estado_orden_id: 3,
        };
        copyCarrito.push(copy);
      });
    });

    return copyCarrito;
  }, [ordenes]);

  useEffect(() => setIsExpanded(true), []);

  return (
    <Box
      sx={{
        minHeight: '200px',
        maxHeight: isExpanded ? 'auto' : '200px',
        px: 2,
        py: 1,
      }}>
      <Box
        ref={box}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          overflowY: isExpanded ? undefined : 'hidden',
          maxHeight: isExpanded ? undefined : '165px',
          pb: 2,
        }}>
        {platos.map((value: Orden) => (
          <ComandaPlatoItem
            id_orden_producto_menu={value.id_orden_producto_menu}
            isCanceled={value.estado_orden_id === 3}
            modificadores={value.modificador_producto}
            observacion={value.observaciones || undefined}
            key={value.id_orden_producto_menu}
            id_codigo_orden={id_codigo_orden}
            name={value.menu_restaurante.producto.nombre_producto}
            cantidad={value.cantidad}
          />
        ))}
      </Box>
      <>
        {isOverFlow && (
          <Box
            sx={{
              pt: 1,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Button
              fullWidth
              sx={{maxWidth: '50%'}}
              size="small"
              onClick={() => setIsExpanded(prev => !prev)}
              color="secondary"
              variant="contained">
              {!isExpanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </Button>
          </Box>
        )}
      </>
    </Box>
  );
};

export default memo(ComandaPlatosList);
