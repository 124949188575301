import React from 'react';
import {LoadingButton} from '@mui/lab';
import {Card, CardContent, Grid, Stack, TextField} from '@mui/material';
import {useEffect} from 'react';

import {
  actualizarProveedor,
  resetEditables,
} from '../../redux/slices/compras-slice';
import {putPostProveedor} from '../../redux/slices/compras-slice/compras.thunks';
import {fetchCategoriaInsumo} from '../../redux/slices/insumo-slice/insumo.thunks';
import {putPost} from '../../redux/slices/menu-slice/menu.types';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {MultiSelector2} from '../multi-selector/MultiSelector';

type propsTypes = {
  type: putPost;
  handleClose: VoidFunction;
};

interface Error {
  phone: string;
}

function NewProveedorForm({type, handleClose}: propsTypes) {
  const dispatch = useAppDispatch();
  const proveedor = useAppSelector(
    store => store.comprasSlice.editable.proveedor,
  );
  const categoriasInsumo = useAppSelector(
    store => store.insumoSlice.categoriaInsumos,
  );
  const [error, setError] = React.useState<Error>({phone: ''});
  const [phone, setPhone] = React.useState<string>(
    proveedor.telefono ? proveedor.telefono.replace('-', '') : '',
  );

  const formatPhone = () => {
    setError({phone: ''});

    if (phone.length === 7) {
      const phoneFormat = `${phone.slice(0, 3)}-${phone.slice(3)}`;
      actualizarProveedor({telefono: phoneFormat});
      dispatch(
        actualizarProveedor({
          telefono: phoneFormat,
        }),
      );
    } else if (phone.length === 8) {
      const phoneFormat = `${phone.slice(0, 4)}-${phone.slice(4)}`;
      dispatch(
        actualizarProveedor({
          telefono: phoneFormat,
        }),
      );
    } else {
      setError({phone: 'El campo debe contener entre 7 y 8 dígitos'});
      dispatch(
        actualizarProveedor({
          telefono: '',
        }),
      );
    }
  };
  const handlerPhone = (value: string) => {
    const onlyNumber = /^[0-9]*$/;
    if (onlyNumber.test(value)) {
      setPhone(value);
    }
  };

  useEffect(() => {
    dispatch(fetchCategoriaInsumo());
    return () => {
      dispatch(resetEditables('proveedor'));
    };
  }, []);

  const isLoading = useAppSelector(store => store.uiSlice.isLoading);

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    await dispatch(putPostProveedor(type))
      .unwrap()
      .then(() => {
        if (handleClose) handleClose();
      })
      .catch(() => {});
    dispatch(stopLoading());
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={proveedor.nombre_proveedor}
                  onChange={e => {
                    dispatch(
                      actualizarProveedor({
                        nombre_proveedor: e.target.value,
                      }),
                    );
                  }}
                  required
                />
                <TextField
                  fullWidth
                  label="RUC"
                  value={proveedor.ruc}
                  onChange={e => {
                    dispatch(
                      actualizarProveedor({
                        ruc: e.target.value,
                      }),
                    );
                  }}
                />
                <TextField
                  fullWidth
                  type="number"
                  label="Teléfono"
                  value={phone}
                  onChange={e => handlerPhone(e.target.value)}
                  onBlur={() => formatPhone()}
                  error={error.phone !== ''}
                  helperText={error.phone}
                />
                <TextField
                  fullWidth
                  type="email"
                  label="Correo"
                  value={proveedor.correo}
                  onChange={e => {
                    dispatch(
                      actualizarProveedor({
                        correo: e.target.value,
                      }),
                    );
                  }}
                />
                <MultiSelector2
                  sourceData={categoriasInsumo}
                  selectedData={proveedor.categorias || []}
                  onChange={value =>
                    dispatch(
                      actualizarProveedor({
                        categorias: value,
                      }),
                    )
                  }
                  title="Categoria Insumo"
                  type="categoria_insumo"
                />
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isLoading}
                  disabled={
                    !(
                      proveedor.nombre_proveedor &&
                      proveedor.ruc &&
                      proveedor.telefono &&
                      proveedor.correo &&
                      proveedor.categorias &&
                      proveedor.nombre_proveedor.length > 0 &&
                      proveedor.ruc.length > 0 &&
                      proveedor.telefono.length > 0 &&
                      proveedor.correo.length > 0 &&
                      proveedor.categorias.length > 0
                    )
                  }>
                  Guardar Cambios
                </LoadingButton>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewProveedorForm;
