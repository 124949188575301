import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {EntidadReceptora} from '../../redux/slices/orden-slice/orden.types';

const bull = (
  <Box
    component="span"
    sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}>
    •
  </Box>
);

type propsType = {
  entidad_receptora: EntidadReceptora;
  changeEntidadReceptora: any;
};

export default function EntidadReceptoraDetail({
  entidad_receptora,
  changeEntidadReceptora,
}: propsType) {
  var name_on_bill: any = '';
  var email_on_bill: any = '';
  var id_on_bill: any = '';
  if (
    entidad_receptora.tipo_receptor_id == 1 ||
    entidad_receptora.tipo_receptor_id == 3
  ) {
    name_on_bill = entidad_receptora.ruc_receptor?.name;
    email_on_bill = entidad_receptora.ruc_receptor?.email;
    id_on_bill = `${entidad_receptora.ruc_receptor?.ruc} DV${entidad_receptora.ruc_receptor?.dv}`;
  }
  if (
    entidad_receptora.tipo_receptor_id == 2 ||
    entidad_receptora.tipo_receptor_id == 4
  ) {
    name_on_bill = entidad_receptora.info_cliente_id?.nombre;
    email_on_bill = entidad_receptora.info_cliente_id?.email;
    id_on_bill = `${entidad_receptora.info_cliente_id?.num_id}`;
  }

  return (
    <Card sx={{minWidth: 300}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Datos de facturaci&oacute;n
        </Typography>
        <Typography variant="h5" component="div">
          {name_on_bill}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {id_on_bill}
        </Typography>
        <Typography variant="body2">{email_on_bill}</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => changeEntidadReceptora()} size="small">
          Cambiar
        </Button>
      </CardActions>
    </Card>
  );
}
