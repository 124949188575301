import {LoadingButton} from '@mui/lab';
import {Button, Stack} from '@mui/material';

type PropsTypes = {
  handleClose: () => void;
  isSubmitting: boolean;
  onSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
  reachedBottom: boolean;
};

const PoliciesForm = ({
  handleClose,
  isSubmitting,
  onSubmit,
  reachedBottom,
}: PropsTypes) => {
  return (
    <form
      onSubmit={onSubmit}
      style={{display: 'flex', flexDirection: 'column'}}>
      <PoliciesText />
      <Stack
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          py: 2,
          backgroundColor: 'white',
        }}
        direction="row"
        spacing={2}>
        <Button
          sx={{width: '45%'}}
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleClose}>
          No estoy de acuerdo.
        </Button>
        <LoadingButton
          sx={{width: '45%'}}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          color="primary"
          disabled={!reachedBottom}>
          Estoy de acuerdo
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default PoliciesForm;

const PoliciesText = () => {
  return (
    <>
      <span
        style={{
          textAlign: 'left',
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        POLÍTICAS DE PRIVACIDAD Y TÉRMINOS & CONDICIONES
      </span>
      <p style={{textAlign: 'left', marginBottom: 15}}>
        Usted acepta que utiliza el sitio Plataforma o APP de{'  '}
        <a href="https://ifudis.com/" target="_blank" rel="noreferrer">
          ifudis.com
        </a>
        {'  '}
        y, a su vez acepta, los términos y condiciones, políticas internas y
        disposiciones que se establecen o que sean establecidas más adelante (la
        “Plataforma IFUDIS”) bajo su propio riesgo y responsabilidad:
      </p>
      <p style={{textAlign: 'left', marginLeft: 45}}>
        {` 1. La Plataforma IFUDIS es propiedad de la sociedad anónima IFUDIS,
            S.A. (“IFUDIS”), con RUC 155706541 2-2021 DV 12, una compañía de
            tecnología dueña de la aplicación digital, software, propiedad
            intelectual del portal web denominado IFUDIS "O" IFUDIS.COM "O"
            IFUDIS.APP (en adelante, la “Plataforma IFUDIS”), disponibles para
            ser utilizados desde cualquier dispositivo móvil como celulares,
            tablets o computadoras. Mediante esta herramienta digital, IFUDIS
            busca generar un aumento de ventas a los Usuarios IFUDIS,
            incrementando su rentabilidad, competitividad, productividad y
            mejorando la experiencia de los clientes y usuarios de la Plataforma
            IFUDIS.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`2. IFUDIS brinda a las empresas afiliadas (el “Usuarios IFUDIS” ) los servicios de la Plataforma de IFUDIS, incluyendo los siguientes:`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 85, marginTop: 15}}>
        {`A. “Promoción Digital”: Consiste en promover digitalmente a EL USUARIO a través de un menú digital elaborado por fotos tomadas por especialistas, generando el beneficio de publicidad, promoción, mensajes masivos y otros similares, con el propósito de generar el aumento de ventas de EL USUARIO, incrementando su rentabilidad, competitividad, productividad y mejorando la experiencia de los clientes y usuarios de la Plataforma IFUDIS.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 85, marginTop: 15}}>
        {`B. “Sistema de Administración y Facturación IFUDIS POS”: Consiste en un sistema de facturación de manera digital, mediante la Plataforma IFUDIS, que se encargará de la administración del negocio de los Usuarios IFUDIS, realizando tanto la facturación, como el cobro, administración, control de inventario, relación directa de pago con los clientes, y, de ser necesario, brindará y se le integrarán otras funcionalidades y servicios, incluyendo pero no limitando a el servicio de delivery o entrega a domicilio, sistema de reservas, experiencias y todas aquellas que de tiempo en tiempo se vayan incorporando.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 85, marginTop: 15}}>
        {`C.	“Otros Servicios”: Futuras herramientas, funcionalidades, productos y/o servicios que sean incorporados a la Plataforma IFUDIS. `}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`(Todos los Servicios de la Plataforma IFUDIS, en adelante los “Servicios IFUDIS”)`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`3.	Los Usuarios IFUDIS por este medio al recibir, utilizar o interactuar con alguno de los Servicios IFUDIS, estará sujeto a los presentes Términos y Condiciones.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`4.	Queda entendido entre LAS PARTES que cualquier otro servicio o beneficio que sea ofrecido por IFUDIS, de tiempo en tiempo, a EL USUARIO dentro de la Plataforma de IFUDIS, será presentado como un Contrato y/o  Anexo, según sea el caso.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`5.	No es nuestra obligación (IFUDIS), función o derecho de aconsejar sobre lo conveniente para los Proveedores y Usuarios IFUDIS en los resultados derivados del uso de los Servciios IFUDIS y la Plataforma IFUDIS. FUDIS tampoco está o quedará obligado con respecto a las obligaciones que surjan de la prestación del servicio producto de las operaciones de los Usuarios IFUDIS, ni de sus obligaciones frente a terceros.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`6.	IFUDIS cobrará comisiones o tarifas conforme el porcentaje que se acuerde independientemente con cada Usuario IFUDIS. IFUDIS únicamente cobrará la comisión correspondiente según sea establecida y/o negociada con los Proveedores y Usuarios IFUDIS, individualmente o colectivamente, a la discreción de IFUDIS; siempre cumpliendo con las leyes tributarias, comerciales, civiles, competitividad y protección al consumidor de la República de Panamá.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`7.	Los términos y condiciones aplicables a los Servicios IFUDIS se regirán y serán interpretados y aplicados por la Ley de la República de Panamá. Usted acepta someterse a la jurisdicción y competencia exclusiva de los tribunales dentro del territorio de la República de Panamá.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`8.	IFUDIS podrá establecer, incluir, modificar, ocultar y/o eliminar cualquier tipo de funcionalidades, opciones, características o información que conste en la Plataforma IFUDIS sin cumplir con notificaciones o autorizaciones adicionales previas por parte de los Proveedores y/o Usuarios IFUDIS. IFUDIS podrá establecer instrucciones y/o limitarlas, con respecto al tipo y/ o la manera de información que es incluida en la Plataforma IFUDIS, las cuales deben ser cumplidas por los Usuarios IFUDIS y Proveedores, incluyendo, pero no limitando a información comercial, propiedad intelectual, personal, operacional, etc.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`9.	Los Proveedores y/o Usuarios IFUDIS aceptan y consienten que la información que agreguen o sea agregada en la Plataforma IFUDIS, incluyendo pero no limitando a los datos de contacto, generales, empresariales, propiedad intelectual de cualquier naturaleza de los podrán exhibirse a través de la Plataforma IFUDIS para la debida prestación de los Servicios IFUDIS al Usuario IFUDIS correspondiente; siempre resguardo las políticas de privacidad descritas más adelante o en sección separada (las “Políticas de Privacidad”). IFUDIS reconoce que esta información es propiedad y/o puede ser utilizada única y exclusivamente por cada Usuario IFUDIS, respectivamente.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`IFUDIS podrá promover a través de la Plataforma IFUDIS y para la debida prestación de los Servicios IFUDIS, material audiovisual, imágenes, precios de menú, descripciones, y otra información digital necesaria. `}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`10.	IFUDIS procurará dentro de sus posibilidades, tener alianzas estratégicas con entidad(es) bancaria(s), la cual(es) es (son) el(los) banco(s) receptor(es) de los pagos a través de una cuenta ARP. Dicha(s) entidad(es) bancaria(s) no es(son) responsable(s) del riesgo de crédito ni de las transacciones realizadas en la Plataforma IFUDIS, ni de las obligaciones que el Usuario IFUDIS y proveedores están asumiendo.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`11.	IFUDIS buscará realizar sus gestiones dentro de la diligencia racional requerida cumpliendo con las normativas de “Conozca a su Cliente” y “Anti-Lavado de Dinero”. Los Usuarios IFUDIS y Proveedores se comprometen a cumplir con los requerimientos de IFUDIS.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`12.	IFUDIS no es responsable de las obligaciones fiscales, comerciales, y/o de otra índole que sean responsabilidad de los Usuarios IFUDIS y Proveedores, respectivamente.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`13.	IFUDIS en todo momento asumirá la capacidad legal, la libertad financiera y buena fe de los Usuarios IFUDIS y Proveedores, al igual que estos para con IFUDIS y la Plataforma IFUDIS.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`14.	IFUDIS, en su calidad de intermediario de la reservación y pago, definido previamente, no será responsable de las negligencias, incumplimientos e irregularidades que se generen a raíz de la relación comercial entre el Proveedor y el Usuario IFUDIS generada a través de la plataforma.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`Producto de este servicio técnico, IFUDIS implementa herramientas de seguimiento (tracking) en tiempo real de las operaciones y usos de la Plataforma IFUDIS, pudiendo ver datos e interacciones propios de cada Usuario IFUDIS. Esta información se mantendrá bajo estricta confidencialidad de conformidad a la Política de Privacidad.`}
      </p>
      <p style={{textAlign: 'center', marginTop: 15, fontWeight: 'bold'}}>
        Política de Privacidad (Privacy Policy)
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`IFUDIS, S.A. (“IFUDIS”) por este medio desea poner en conocimiento de todos los usuarios (en adelante, los “Usuarios IFUDIS”) de la página Plataforma www.ifudis.com , o su APP, (en adelante, la “Plataforma”) su política de privacidad relativa al tratamiento y protección de los datos de carácter personal de los usuarios que recaba de los mismos con motivo de la visualización de la Plataforma o de la utilización de los servicios ofrecidos en el mismo.`}
      </p>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`Por dato de carácter personal se entenderá cualquier información numérica, alfabética, gráfica, fotográfica, acústica o de cualquier otro tipo concerniente a personas físicas identificadas o identificables.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`IFUDIS sólo será responsable y garantizará la confidencialidad y seguridad respecto de los datos de carácter personal que recabe del Usuario IFUDIS o que éste facilite a través de la Plataforma.`}
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        {`IFUDIS sólo será responsable y garantizará la confidencialidad y seguridad respecto de los datos de carácter personal que recabe del Usuario IFUDIS o que éste facilite a través de la Plataforma.`}
      </p>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Tratamiento de los Datos Personales
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        Le informamos que los datos personales que pueda facilitar durante el
        uso del sitio Plataforma serán tratados en calidad de responsable del
        tratamiento por IFUDIS, con domicilio social en Ciudad de Panamá,
        República de Panamá.
      </span>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        IFUDIS procurará la transparencia en cuanto a los datos personales,
        pudiendo ponerse en contacto con nosotros a través de la dirección de
        correo electrónico: sugerencias@ifudis.com. IFUDIS sólo será responsable
        y garantizará la confidencialidad y seguridad de los datos personales
        tratados por IFUDIS para las finalidades especificadas en el presente
        documento, no teniendo ningún tipo de responsabilidad respecto a los
        tratamientos y posteriores utilizaciones de los datos personales que
        pudieran efectuarse por terceros prestadores de servicios de la sociedad
        de la información que actúen fuera del sitio Plataforma.
      </p>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Obligación de facilitar sus Datos
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        La visita a la Plataforma no supone que el Usuario IFUDIS esté obligado
        a facilitar ningún dato personal. No obstante, el uso de algunos de los
        servicios disponibles en la Plataforma depende de la ejecución de
        formularios de datos personales y el uso de cookies.
      </span>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        Los datos recogidos a través de los diferentes formularios de la
        Plataforma son los justos y necesarios para la prestación de los
        servicios solicitados. La negativa a proporcionar los datos señalados
        como necesarios supone la imposibilidad de prestar adecuadamente dichos
        servicios. Así mismo, podrá facilitar datos de modo voluntario con el
        objetivo de que puedan prestarse de manera óptima estos servicios.
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        IFUDIS podrá restringir el uso total o parcial de la Plataforma en caso
        de que el usuario no facilite la información requerida y necesaria para
        la prestación de los servicios en IFUDIS.
      </p>
      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        Los datos personales suministrados por los Usuarios IFUDIS a IFUDIS,
        están sujetas a las disposiciones de las normativas vigentes en la
        República de Panamá, incluyendo la Ley de AML.
      </p>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Uso de la Información Recogida
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        Los datos personales que facilite el usuario para la prestación de los
        distintos servicios puestos a disposición a través de la Plataforma
        serán tratados por IFUDIS conforme a las siguientes finalidades:
      </span>
      <ul style={{textAlign: 'left', marginLeft: 85, marginTop: 15}}>
        <li>
          En el caso del registro de los Usuarios IFUDIS, la finalidad es llevar
          a cabo la creación de una cuenta de usuario para poder mantenerse
          informado y gestionar su participación en los servicios de la
          Plataforma IFUDIS, incluyendo la “Promoción Digital” “Administración y
          Facturación IFUDIS POS”.
        </li>
        <li>
          En el caso de la suscripción del boletín informativo, la finalidad es
          mantener informado a los suscriptores de las oportunidades en los
          servicios como la Plataforma IFUDIS prestados por IFUDIS y otra
          información relativa con este último.
        </li>
        <li>
          En el caso del formulario de contacto, la finalidad es dar respuesta a
          la solicitud realizada relacionada con los servicios prestados por
          IFUDIS.
        </li>

        <li>
          En el caso del formulario de suscripción a comunicaciones de terceros,
          la finalidad es mantenerse informado de productos relacionados con los
          prestados por IFUDIS que pudieran ser de interés para el Usuario
          IFUDIS.
        </li>

        <li>
          En el caso de incluir algún correo de contacto facilitado, la
          finalidad es dar respuesta a la solicitud realizada relacionada con
          los servicios prestados por IFUDIS.
        </li>
        <li>
          En el caso del servicio de atención personalizada por chat, la
          finalidad es dar respuesta a la solicitud realizada relacionada con
          los servicios prestados por IFUDIS.
        </li>

        <li>
          En el caso de Servicios de atención telefónica, la finalidad es dar
          respuesta a la solicitud realizada relacionada con los servicios
          prestados por IFUDIS. Las llamadas realizadas al servicio de atención
          al cliente de IFUDIS pueden ser grabadas por motivos de calidad del
          servicio.
        </li>
        <li>
          Le informamos de que determinadas funcionalidades de la Plataforma
          dependen del uso de las cookies, por lo que en el caso de que no haya
          denegado la posibilidad de su uso, se procederá al tratamiento de
          determinada información relacionada con su uso de la Plataforma.{' '}
        </li>

        <li>
          La información podrá usarse para fines estadísticos propios de IFUDIS,
          a fin de mejorar la Plataforma IFUDIS, los productos o servicios
          ofrecidos en la misma.
        </li>
      </ul>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Legitimación del Tratamiento de los Datos
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        El tratamiento de sus datos personales por parte de IFUDIS se ampara en
        la solicitud por su parte de los servicios puestos a su disposición a
        través de la Plataforma o, en su caso, en el consentimiento que se le
        solicita para las finalidades determinadas anteriormente y que podrá
        retirar en cualquier momento. No obstante, en caso de revocar el
        consentimiento esta circunstancia no afecta a la licitud de los
        tratamientos efectuados con anterioridad.
      </span>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Tiempo por el que se Mantendrán los Datos
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        Sus datos serán conservados mientras sean necesarios para la finalidad
        con la que fueron recabados y durante los plazos legalmente exigidos
        para las posibles responsabilidades derivadas, según las regulaciones
        vigentes.
      </span>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Información Recogida frente a Terceros
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        Los datos personales facilitados por el Usuario IFUDIS no serán
        comunicados o tratados a terceros, salvo que ello sea necesario para la
        prestación de los servicios solicitados, para la prestación de futuros
        servicios de IFUDIS o sus subsidiarias o cuando el usuario hubiera
        aceptado expresamente su comunicación (envío de comunicaciones de
        terceros relacionados con la actividad de IFUDIS); o en aquellas
        situaciones que legalmente sean requeridas, por ejemplo, los supuestos
        contemplados en la Ley de AML.
      </span>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        Este sitio Plataforma pudiera contener enlaces a otros sitios que
        pudieran ser de interés de los Usuarios IFUDIS. Una vez que ingrese a
        estos enlaces y abandone nuestra Plataforma, ya no tenemos control sobre
        al sitio al que es redirigido y por lo tanto no somos responsables de
        los términos o privacidad ni de la protección de sus datos en esos otros
        sitios terceros. Dichos sitios están sujetos a sus propias políticas de
        privacidad por lo cual es recomendable que los consulte para confirmar
        que usted está de acuerdo con estas.
      </p>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Responsabilidad del Usuario IFUDIS
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        El Usuario IFUDIS será responsable de que los datos que facilite a
        IFUDIS sean verdaderos, exactos, completos y actualizados. A estos
        efectos, usted responderá de la veracidad de todos los datos que
        comunique y deberá mantener debidamente actualizada la información
        facilitada, de tal forma que responda a su situación real.
      </span>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        Igualmente, será responsable de las informaciones falsas o inexactas que
        proporcione a través de la Plataforma y de los datos y perjuicios,
        directas o indirectas, que ello cause a IFUDIS o a terceros.
      </p>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Derechos de los Usuarios IFUDIS
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        El ejercicio de los derechos por parte del Usuario IFUDIS es gratuito y
        puede realizarse en cualquier momento, debiendo dirigir una comunicación
        escrita, junto con la fotocopia del documento de Identidad que acredite
        la identidad del Usuario IFUDIS, a través del correo electrónico:
        sugerencias@ifudis.com.
      </span>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        Los Usuarios IFUDIS podrán ejercer los siguientes derechos:
      </p>

      <ul style={{textAlign: 'left', marginLeft: 85, marginTop: 15}}>
        <li>
          Revocar el consentimiento a los tratamientos de la información
          suministrada a IFUDIS.
        </li>
        <li>Acceder a su información.</li>
        <li>Rectificar los datos inexactos o incompletos.</li>
        <li>
          Solicitar la supresión de sus datos cuando, entre otros motivos, los
          datos ya no sean necesarios para los fines para los que fueron
          recogidos.
        </li>
        <li>
          Obtener de IFUDIS la limitación del tratamiento cuando se cumpla
          alguna de las condiciones previstas en las políticas de protección de
          todos.
        </li>
      </ul>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        IFUDIS pone en conocimiento de los Usuarios IFUDIS que el ejercicio de
        estos derechos es personalísimo, por lo que únicamente el propio Usuario
        IFUDIS podrá ejercer dichos derechos respecto de los datos personales de
        los que es legítimo titular. No obstante, y en los casos en que
        excepcionalmente se admita, el representante autorizado del Usuario
        IFUDIS podrá ejercitar los derechos que a este le asisten en los
        términos expuestos, siempre que acompañe la mencionada comunicación con
        el documento acreditativo de tal representación.
      </p>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Confidencialidad de los Datos
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        IFUDIS tratará los datos personales en todo momento de forma
        absolutamente confidencial y guardando el preceptivo deber de secreto
        respecto de los mismos, de conformidad con lo previsto en la las normas
        de la República de Panamá, adoptando al efecto las medidas internas,
        necesarias para garantizar la seguridad de sus datos y eviten alguna
        modificación, pérdida o extravío, tratamiento o acceso no autorizado.
      </span>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Modificaciones a las Políticas de Privacidad
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        IFUDIS se reserva el derecho a modificar la presente Política de
        Privacidad a fin de adaptarla a las novedades legislativas pertinentes.
        En dichos supuestos, y si es preceptivo, se anunciará en la Plataforma
        y/o a través de correo electrónico los cambios introducidos con una
        antelación razonable a la de su puesta en práctica.
      </span>

      <span
        style={{
          textAlign: 'left',
          marginLeft: 45,
          marginTop: 15,
          fontWeight: 'bold',
        }}>
        Recomendaciones Para los Usuarios IFUDIS
      </span>
      <span style={{textAlign: 'left', marginLeft: 45}}>
        IFUDIS recomienda a los usuarios que utilicen las últimas versiones de
        los programas informáticos dada la incorporación en estos de mayores
        medidas de seguridad.
      </span>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        Igualmente, IFUDIS recomienda a los Usuarios IFUDIS utilizar los
        mecanismos de seguridad que tengan a su alcance (servidores Plataforma,
        seguros, criptografía, firma digital, firewall, etc.) para proteger la
        confidencialidad e integridad de sus datos en la medida en que le
        resulte necesario, dado que existen riesgos de suplantación de la
        personalidad o violación de la comunicación.
      </p>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        IFUDIS recuerda a los Usuarios IFUDIS que Internet no siempre es todo lo
        segura que esperamos por lo que deben adoptar las medidas técnicas
        necesarias y adecuadas con el fin de evitar el tratamiento no autorizado
        de sus datos.
      </p>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        IFUDIS advierte a los Usuarios IFUDIS que siempre que proporcionen
        información personal por Internet por medio de correo electrónico,
        grupos de noticias, foros de discusión, etc., tengan presente que dicha
        información puede ser recaba y tratada con finalidades no deseadas por
        los Usuarios IFUDIS, por lo que IFUDIS recomienda altamente a los
        usuarios que se informen sobre las políticas de confidencialidad y
        privacidad de los sitios en línea que visiten.
      </p>

      <p style={{textAlign: 'left', marginLeft: 45, marginTop: 15}}>
        IFUDIS advierte a los Usuarios IFUDIS que tengan presente que, salvo que
        empleen mecanismos de cifrado, el correo electrónico en Internet no es
        seguro. Los mensajes de correo y los foros de discusión pueden ser
        objeto de falsificación y suplantación de personalidad, lo que debe
        tenerse en cuenta siempre que se usen. Si no quieren publicar su
        dirección de correo electrónico, configuren su navegador para que no
        deje su dirección de correo en los servidores Plataforma a los que
        acceden.
      </p>

      <p
        style={{
          textAlign: 'left',
          marginTop: 15,
          fontWeight: 'bold',
          marginLeft: 45,
        }}>
        Política de Cookies (Cookies Policy)
      </p>

      <p
        style={{
          textAlign: 'left',
          marginTop: 15,
          marginLeft: 45,
        }}>
        Una cookie se refiere a un fichero que es enviado con la finalidad de
        solicitar permiso para almacenarse en su ordenador, al aceptar dicho
        fichero se crea y la cookie sirve entonces para tener información
        respecto al tráfico Plataforma, y también facilita las futuras visitas a
        una Plataforma recurrente. Otra función que tienen las cookies es que
        con ellas las Plataforma pueden reconocerse individualmente y por tanto
        brindarte el mejor servicio personalizado de su Plataforma.
      </p>

      <p
        style={{
          textAlign: 'left',
          marginTop: 15,
          marginLeft: 45,
        }}>
        Nuestro sitio Plataforma emplea las cookies para poder identificar las
        páginas que son visitadas y su frecuencia. Esta información es empleada
        únicamente para análisis estadístico y después la información se elimina
        de forma permanente. Usted puede eliminar las cookies en cualquier
        momento desde su ordenador. Sin embargo, las cookies ayudan a
        proporcionar un mejor servicio de los sitios Plataforma, estas no dan
        acceso a información de su ordenador ni de usted, a menos de que usted
        así lo quiera y la proporcione directamente. Usted puede aceptar o negar
        el uso de cookies, sin embargo, la mayoría de navegadores aceptan
        cookies automáticamente pues sirve para tener un mejor servicio
        Plataforma. También usted puede cambiar la configuración de su ordenador
        para declinar las cookies. Si se declinan es posible que no pueda
        utilizar algunos de nuestros servicios.
      </p>

      <p
        style={{
          textAlign: 'left',
          marginTop: 15,
          fontWeight: 'bold',
          marginLeft: 45,
        }}>
        Quejas y Sugerencias (Concerns and Suggestions)
      </p>

      <p
        style={{
          textAlign: 'left',
          marginTop: 15,
          marginLeft: 45,
          marginBottom: 85,
        }}>
        Si los Usuarios IFUDIS tienen alguna queja sobre los servicios operados
        por IFUDIS, puede comunicarse con nosotros a través del correo
        electrónico: sugerencias@ifudis.com.
      </p>
    </>
  );
};
