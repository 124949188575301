import React from 'react';
import {Card, CardContent, Grid, TextField, Stack, Button} from '@mui/material';
import MultiSelector from '../multi-selector/MultiSelector';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  cargarRestaurante,
  getTiposDeComida,
  putPostRestaurante,
} from '../../redux/slices/restaurante-slice/restaurente.thunks';
import {
  actualizarRestaurante,
  resetRestaurante,
} from '../../redux/slices/restaurante-slice';
import {updateRestauranteStoretype} from '../../redux/slices/restaurante-slice/restaurante.types';
import UploadImages from '../upload-images/UploadImages';
import isNullish from '../../common/isNullish';
import {putPost} from '../../redux/slices/menu-slice/menu.types';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';

type propType = {
  type: putPost;
  handleClose?: VoidFunction;
};
interface Error {
  phone: string;
}
function NewRestauranteForm({type, handleClose}: propType) {
  const [error, setError] = React.useState<Error>({phone: ''});
  const [phone, setPhone] = React.useState<string>('');
  const dispatch = useAppDispatch();
  const restaurante = useAppSelector(
    store => store.restauranteSlice.editable.restaurante,
  );

  const tiposDeComida = useAppSelector(
    store => store.restauranteSlice.tiposDeComida,
  );
  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {telefono} = restaurante;
    if (telefono) {
      dispatch(startLoading());
      await dispatch(putPostRestaurante(type))
        .unwrap()
        .then(x => {
          if (handleClose) handleClose();
        })
        .catch(() => {});
      dispatch(stopLoading());
    }
  };

  const formatPhone = () => {
    setError({phone: ''});

    if (phone.length === 7) {
      const phoneFormat = `${phone.slice(0, 3)}-${phone.slice(3)}`;
      actualizar({telefono: phoneFormat});
    } else if (phone.length === 8) {
      const phoneFormat = `${phone.slice(0, 4)}-${phone.slice(4)}`;
      actualizar({telefono: phoneFormat});
    } else {
      setError({phone: 'El campo debe contener entre 7 y 8 dígitos'});
      actualizar({telefono: ''});
    }
  };
  const handlerPhone = (value: string) => {
    const onlyNumber = /^[0-9]*$/;
    if (onlyNumber.test(value)) {
      setPhone(value);
    }
  };

  useEffect(() => {
    const fetchTipoComidas = async () => {
      dispatch(startLoading());
      await dispatch(getTiposDeComida());
      dispatch(stopLoading());
    };

    const fetchRestaurante = async () => {
      dispatch(startLoading());
      await dispatch(cargarRestaurante());
      dispatch(stopLoading());
    };

    if (type == 'actualizar') {
      fetchRestaurante();
    }
    fetchTipoComidas();

    return () => {
      dispatch(resetRestaurante());
    };
  }, []);

  const actualizar = (data: updateRestauranteStoretype) => {
    dispatch(actualizarRestaurante(data));
  };

  const shouldPass = () => {
    if (type == 'crear') {
      const {id, ...rest} = restaurante;
      return isNullish(rest);
    }
    return isNullish(restaurante);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Card raised>
            <CardContent
              style={{
                textAlign: 'center',
              }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Nombre del restaurante"
                  value={restaurante.nombre || ''}
                  helperText="Aquí va el nombre del restaurante."
                  onChange={e => actualizar({nombre: e.target.value})}
                  required
                />
                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <TextField
                    fullWidth
                    label="nick"
                    helperText="Este campo sera utilizado para mostrar en URL del menu digital."
                    value={restaurante.nick || ''}
                    onChange={e =>
                      actualizar({nick: e.target.value.trim().toLowerCase()})
                    }
                    required
                  />

                  <TextField
                    fullWidth
                    label="RUC"
                    value={restaurante.ruc || ''}
                    onChange={e => actualizar({ruc: e.target.value})}
                    required
                  />
                </Stack>
                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <TextField
                    fullWidth
                    label="Razón social"
                    value={restaurante.razonSocial || ''}
                    onChange={e => actualizar({razonSocial: e.target.value})}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Slogan"
                    helperText="(Opcional)"
                    value={restaurante.slogan || ''}
                    onChange={e => actualizar({slogan: e.target.value})}
                  />
                </Stack>

                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <TextField
                    fullWidth
                    label="Número sucursales"
                    value={restaurante.numSucur || ''}
                    onChange={e => {
                      if (parseInt(e.target.value) > 0) {
                        actualizar({numSucur: Number(e.target.value)});
                      }
                    }}
                    required
                  />

                  <TextField
                    fullWidth
                    label="Piso oficina"
                    value={restaurante.pisOfic || ''}
                    onChange={e => {
                      if (parseInt(e.target.value) > 0) {
                        actualizar({pisOfic: Number(e.target.value)});
                      }
                    }}
                    required
                  />
                </Stack>
                <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                  <TextField
                    fullWidth
                    label="Correo electrónico del negocio"
                    value={restaurante.email || ''}
                    required
                    onChange={e => actualizar({email: e.target.value})}
                  />
                  <TextField
                    fullWidth
                    label="Número movil del negocio o Whatsapp para contacto"
                    value={phone}
                    required
                    onChange={e => handlerPhone(e.target.value)}
                    onBlur={() => formatPhone()}
                    error={error.phone !== ''}
                    helperText={error.phone}
                  />
                </Stack>

                <TextField
                  fullWidth
                  label="Dirección de restaurante"
                  helperText="En este campo tienes que poner la dirección completa de restaurante principal."
                  value={restaurante.direccion || ''}
                  required
                  onChange={e => actualizar({direccion: e.target.value})}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card raised>
            <CardContent
              sx={{
                textAlign: 'center',
              }}>
              <MultiSelector
                fullWidth
                sourceData={tiposDeComida}
                selectedData={restaurante.tiposDeComida || []}
                setSelectedData={(data: string[]) => {
                  actualizar({tiposDeComida: data});
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={3} item>
          <Card raised>
            <CardContent sx={{minHeight: 475}}>
              <UploadImages
                state={restaurante.logo}
                setState={(file: File) => {
                  actualizar({logo: file});
                }}
                buttonLabel="Subir logo"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={3} item>
          <Card raised>
            <CardContent sx={{minHeight: 475}}>
              <UploadImages
                state={restaurante.avisoDeOperacion}
                setState={(file: File) => {
                  actualizar({avisoDeOperacion: file});
                }}
                buttonLabel="Subir aviso de operación"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={3} item>
          <Card raised>
            <CardContent sx={{minHeight: 475}}>
              <UploadImages
                state={restaurante.identificacionDelantera}
                setState={(file: File) => {
                  actualizar({identificacionDelantera: file});
                }}
                buttonLabel="ID representante legal (cara frontal)"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} lg={3} item>
          <Card raised>
            <CardContent sx={{minHeight: 475}}>
              <UploadImages
                state={restaurante.identificacionTrasera}
                setState={(file: File) => {
                  actualizar({identificacionTrasera: file});
                }}
                buttonLabel="ID representante legal (cara posterior)"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={shouldPass()}>
            Guardar Cambios
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewRestauranteForm;
