import {Grid} from '@mui/material';
import {ReactNode} from 'react';

type PropsTypes = {
  children: ReactNode;
};

const OrdenWrapper = ({children}: PropsTypes) => {
  return (
    <Grid container spacing={1} sx={{width: '100%', height: '100%'}}>
      {children}
    </Grid>
  );
};

export default OrdenWrapper;
