import {
  clienteStoreTypePrimary,
  descuentoType,
} from '../redux/slices/orden-slice/orden.types';
import {propinaRestaurantType} from '../services/dto/orden';

export default function getOrdenFinanzasData({
  cliente,
  propinasArray,
  descuentoType,
}: {
  cliente: clienteStoreTypePrimary;
  propinasArray: propinaRestaurantType[];
  descuentoType?: descuentoType;
}) {
  const propinas = cliente.propinas.map(
    element => element.id_tipo_propina_restaurante,
  );
  const metodoPago = cliente.metodosPago.metodos.map(element => ({
    metodo_pago_restaurante_id: element.metodoPago.id_metodo_pago_restaurante,
    cantidad: element.cantidad ?? 0,
  }));
  const descuento = cliente.descuentos.map(element => ({
    tipo_descuento_restaurante_id: element.id_tipo_descuento_restaurante,
  }));

  const impuesto = cliente.impuestos.map(
    element => element.id_tipo_impuesto_restaurante,
  );
  return {
    descuentoType: descuentoType ? descuentoType : undefined,
    metodo_pago: metodoPago.length ? metodoPago : undefined,
    tipo_descuento: descuento.length ? descuento : undefined,
    tipo_impuesto: impuesto.length ? impuesto : undefined,
    tipo_propina: propinas.length ? propinas : undefined,
    propina_efectivo:
      propinas.some(element => {
        const propina = propinasArray.find(
          element2 => element2.id_tipo_propina_restaurante === element,
        );
        return propina?.tipo_pago_propina_id === 2;
      }) && cliente.propinaEfectivo
        ? cliente.propinaEfectivo
        : undefined,
  };
}
