import PreCuentaWrapper from '../../components/pre-cuenta-wrapper/pre-cuenta-wrapper';
import {useAppDispatch} from '../../redux/store';
import {setCurrentStep} from '../../redux/slices/orden-slice';
import RightContainer from './right-container';
import FacturaEmitidaForm from './factura-emitida-form';
import OrdenWrapper from './orden-wrapper';

function FacturaEmitida() {
  const dispatch = useAppDispatch();

  const handleAtras = () => {
    return dispatch(setCurrentStep(2));
  };

  return (
    <OrdenWrapper>
      <PreCuentaWrapper handleAtras={handleAtras} />
      <RightContainer p={1}>
        <FacturaEmitidaForm />
      </RightContainer>
    </OrdenWrapper>
  );
}

export default FacturaEmitida;
