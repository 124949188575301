import {Stack, TableCell, TableRow, Typography} from '@mui/material';
import {fCurrency} from '../../common/formatNumber';
import useMediaQuery from '../../common/hooks/use-media-query';
import NumericTextField from '../../components/form-input/controlled-numeric-text-field';

type PropsTypes = {
  productName: string;
  subCategoryName: string;
  unitName: string | number;
  cost: number;
  lastInventoryQty: number;
  lastInventoryCost: number;
  newInventoryQty?: number;
  onChangeQty: (value?: number) => void;
};

const CustomRowItem = ({
  productName,
  subCategoryName,
  unitName,
  cost,
  lastInventoryQty,
  lastInventoryCost,
  newInventoryQty,
  onChangeQty,
}: PropsTypes) => {
  const sm = useMediaQuery('(max-width:390px)');
  const canSee = sm ? false : true;
  return (
    <TableRow hover>
      <TableCell align="left">{productName}</TableCell>
      {canSee ? (
        <>
          <TableCell align="left" sx={{maxWidth: 90}}>
            {subCategoryName}
          </TableCell>
          <TableCell align="left">{unitName}</TableCell>
          <TableCell align="left" sx={{maxWidth: 60}}>
            {fCurrency(cost)}
          </TableCell>
          <TableCell align="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography variant="body2" sx={{width: 20}}>
                {lastInventoryQty}
              </Typography>
              <Typography variant="body2" sx={{width: 5}}>
                x
              </Typography>
              <Typography variant="body2" sx={{width: 60}}>
                {fCurrency(lastInventoryCost)}
              </Typography>
              <Typography variant="body2" sx={{width: 5}}>
                =
              </Typography>
              <Typography variant="body2" sx={{width: 80}}>
                {fCurrency(lastInventoryQty * lastInventoryCost)}
              </Typography>
            </Stack>
          </TableCell>
        </>
      ) : null}

      <TableCell align="center" sx={{maxWidth: 160}}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <NumericTextField
            size="small"
            value={newInventoryQty}
            sx={{maxWidth: 80}}
            onChange={value => onChangeQty(value)}
          />
          <Typography variant="body2" sx={{width: 100}}>
            {fCurrency((newInventoryQty ?? 0) * cost)}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default CustomRowItem;
