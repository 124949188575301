import yup from '../../../common/yup';

export const modificatorSchema = yup
  .object({
    nombre_modificador: yup.string().required(),
    precio: yup.number().required(),
    acciones: yup.array(yup.number()).min(1).required(),
    required: yup.boolean().required(),
    restaurante_id: yup
      .number()
      .when('$request', (request, schema) =>
        request ? schema.required() : schema,
      ),
    id_modificador: yup.number(),
  })
  .required();
