import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {combineReducers} from 'redux';
import uiSlice from './slices/ui-slice';
import ordenSlice from './slices/orden-slice';
import userSlice from './slices/user-slice';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import restauranteSlice from './slices/restaurante-slice';
import empleadoSlice from './slices/empleados-slice';
import menuSlice from './slices/menu-slice';
import finanzasSlice from './slices/finanzas-slice';
import {userApi} from './queries/search-user-query';
import marketingSlice from './slices/marketing-slice';

import restauranteUtilsSlice from './slices/restaurante-utils-slice';
import comandaSlice from './slices/comanda-slice';
import inventarioSlice from './slices/inventario-slice';
import cierreSlice from './slices/cierre-slice';
import diarioVentasSlice from './slices/diario-ventas-slice';
import insumoSlice from './slices/insumo-slice';
import comprasSlice from './slices/compras-slice';

import reportes from './slices/reportes-slice';
import modificadorSlice from './slices/modificador-slice';
import reporteRestaurante from './slices/reporte-restaurante-slice';

const persistConfig = {
  key: 'user-slice',
  storage,
  whitelist: ['userDetail', 'tokenDetails', 'isLogged'],
};

const persistedReducer = persistReducer(persistConfig, userSlice);

const reducers = combineReducers({
  userSlice: persistedReducer,
  uiSlice,
  ordenSlice,
  restauranteSlice,
  menuSlice,
  finanzasSlice,
  marketingSlice,
  restauranteUtilsSlice,
  empleadoSlice,
  comandaSlice,
  cierreSlice,
  insumoSlice,
  diarioVentasSlice,
  comprasSlice,
  inventarioSlice,
  reportes,
  modificadorSlice,
  reporteRestaurante,
  [userApi.reducerPath]: userApi.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
