import {Box} from '@mui/material';
import {useAppSelector} from '../../redux/store';
import BottomText from './bottom-text';
import DesgloseInput from './desglose-input';

const DesgloseForm = () => {
  const efectivoArray = useAppSelector(
    store => store.cierreSlice.efectivoDeclarado,
  );
  const total = useAppSelector(store => store.cierreSlice.efectivoTotal);
  return (
    <Box sx={{width: '100%'}} py={2}>
      {efectivoArray.map((element, index) => (
        <DesgloseInput
          index={index}
          key={element.value}
          unit={element.name}
          quantity={element.cantidad ? element.cantidad : NaN}
          total={element.value * (element.cantidad || 0)}
        />
      ))}
      <BottomText text="TOTAL EFECTIVO" number={total} />
    </Box>
  );
};

export default DesgloseForm;
