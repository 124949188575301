import DialogPopUp from '../../components/dialog-pop-up/dialog-pop-up';
import NewRestauranteForm from '../../components/new-restaurante-form/new-restaurante-form';

type PropsTypes = {
  state: boolean;
  handleClose: () => void;
};

const RestauranteCrearModal = ({state, handleClose}: PropsTypes) => {
  return (
    <DialogPopUp
      open={state}
      handleClose={handleClose}
      title="Creación de restaurante"
      fullScreen>
      <NewRestauranteForm type="crear" handleClose={handleClose} />
    </DialogPopUp>
  );
};

export default RestauranteCrearModal;
