import {Box} from '@mui/material';

type PropsTypes = {
  children: React.ReactNode;
};

function BottomBar({children}: PropsTypes) {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '0',
        left: '50%',
        width: '100%',
        height: '50px',
        transform: 'translateX(-50%)',
        px: {
          sm: 0,
          md: 15,
        },
      }}>
      {children}
    </Box>
  );
}

export default BottomBar;
