//initialState

import {createSlice} from '@reduxjs/toolkit';
import {marketingStoreType} from './marketing.type';
import {actions} from './marketing.actions';
import {
  getTipoBanners,
  getTipoBannersRestaurante,
  postPutBannerRestaurante,
} from './marketing.thunks';

export const initialState = {
  bannersRestaurante: [],
  tiposDeBanners: [],
} as marketingStoreType;

//slice

export const marketingStore = createSlice({
  name: 'marketing-store',
  initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(postPutBannerRestaurante.fulfilled, (state, action) => {})
      .addCase(getTipoBanners.fulfilled, (state, action) => {
        state.tiposDeBanners = action.payload;
      })
      .addCase(getTipoBannersRestaurante.fulfilled, (state, action) => {
        state.bannersRestaurante = action.payload;
      });
  },
});

export const {resetMarketingSlice} = marketingStore.actions;

export default marketingStore.reducer;
