import {styled, Box} from '@mui/material';
import Page from '../../components/Page';
import FloatingButton from '../../components/floating-button/FloatingButton';
import Waves from '../../components/waves/waves';
import {SpinnerContainer} from '../../components/with-spinner/with-spinner.styles';

const RootStyle = styled(Page)(({theme}) => ({
  display: 'flex',
  minHeight: '86vh',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

type pageTemplateProps = {
  title?: string;
  floatingRoute?: string;
  action?: string;
  isLoading?: boolean;
  children: React.ReactNode;
  click?: () => void;
  soloLayout?: boolean;
};

const Pagetemplate = function ({
  title = '',
  floatingRoute = '',
  action = '',
  isLoading = false,
  children,
  click = () => {},
  soloLayout = false,
}: pageTemplateProps) {
  const getFloatingButton = () => {
    switch (action) {
      case 'Nuevo':
        return <FloatingButton to={floatingRoute} />;
      case 'Editar':
        return (
          <FloatingButton
            color="extended"
            to={floatingRoute}
            icon="backSpace"
          />
        );
      default:
        return <FloatingButton action={click} />;
    }
  };

  const CenteredLoadingComponent = () => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <SpinnerContainer />
      </Box>
    );
  };
  return (
    <RootStyle title={title}>
      <Box sx={{width: '89%'}}>
        <Waves />
        {isLoading ? (
          <CenteredLoadingComponent />
        ) : (
          <>
            {children}
            {!soloLayout && getFloatingButton()}
          </>
        )}
      </Box>
    </RootStyle>
  );
};

export default Pagetemplate;
