import {Box, MenuItem} from '@mui/material';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import {
  seleccionarEmpleadoAsignadoSucursal,
  seleccionarRestaurante,
} from '../../redux/slices/restaurante-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {Icon} from '@iconify/react';
import personFill from '@iconify/icons-eva/person-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import baselineRestaurant from '@iconify/icons-ic/baseline-restaurant';

type propsTypes = {
  handleClose: () => void;
};

function PopoverMenuList({handleClose}: propsTypes) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const restauranteSeleccionado = useAppSelector(
    state => state.restauranteSlice.restauranteEmpleadoSeleccionado?.nombre,
  );
  const userState = useAppSelector(store => store.userSlice);
  return (
    <>
      {userState.userDetail?.email_verified_at && (
        <>
          <MenuItem
            onClick={() => {
              if (restauranteSeleccionado) {
                dispatch(seleccionarRestaurante(null));
                dispatch(seleccionarEmpleadoAsignadoSucursal(null));
              }
              history.push('/');
              handleClose();
            }}
            sx={{typography: 'body2', py: 1, px: 2.5}}>
            <Box
              component={Icon}
              icon={homeFill}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            Home
          </MenuItem>

          {MENU_OPTIONS.map(option => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{typography: 'body2', py: 1, px: 2.5}}>
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />
              {option.label}
            </MenuItem>
          ))}
          {userState.cargo_ifudis?.id_cargo !== 4 && (
            <MenuItem
              onClick={() => {
                history.push('/administracion-ifudis/restaurantes');
              }}
              sx={{typography: 'body2', py: 1, px: 2.5}}>
              <Box
                component={Icon}
                icon={baselineRestaurant}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />
              Restaurante
            </MenuItem>
          )}
        </>
      )}
    </>
  );
}

export default PopoverMenuList;

const MENU_OPTIONS = [
  {
    label: 'Perfil',
    icon: personFill,
    linkTo: '/profile',
  },
];
