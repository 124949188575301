import {
  carritoType,
  descuentoType,
} from '../redux/slices/orden-slice/orden.types';
import {
  descuentoRestaurantType,
  ordenProductoType,
} from '../services/dto/orden';
import mergeCarrito from './merge-carrito';

export default function MapOrdenProduct({
  carrito,
  descuentosArray,
  shouldMergeCarrito = false,
  tipoDescuento,
  shouldDefaultDescuento = false,
}: {
  carrito: carritoType[];
  descuentosArray: descuentoRestaurantType[];
  shouldMergeCarrito?: boolean;
  tipoDescuento: descuentoType;
  shouldDefaultDescuento?: boolean;
}) {
  let copyCarrito: carritoType[] = [];
  if (shouldMergeCarrito) {
    copyCarrito = mergeCarrito(carrito);
  } else {
    copyCarrito = [...carrito];
  }

  return copyCarrito.map<ordenProductoType>(element => {
    const total = element.producto.producto.precio_producto * element.cantidad;
    const totalDescuento =
      element.producto.producto.precio_producto *
      (element.descuentoCant ?? element.cantidad);
    const descuento = descuentosArray.find(
      desc => desc.id_tipo_descuento_restaurante === element.descuentoId,
    );

    const modificador = element.modificadores.map(e => ({
      modificador_menu_id: e.id_modificador_menu,
      precio_modificador:
        e.accion_asignada_modificador.accion.id_accion_modificador === 5 ||
        e.accion_asignada_modificador.accion.id_accion_modificador === 4 ||
        e.accion_asignada_modificador.accion.id_accion_modificador === 1
          ? e.accion_asignada_modificador.modificador.precio
          : 0,
      modificador_p_ordenado_id: e.id_modificador_p_ordenado,
    }));
    const product: any = {
      cantidad: element.cantidad,
      menu_restaurante_id: element.producto.menu_restaurante_id,
      observaciones: element.observacion || null,

      total_a_pagar: descuento
        ? total - (totalDescuento * descuento.porcentaje) / 100
        : total,
    };

    if (modificador.length) product.modificadores_producto = modificador;
    if (shouldDefaultDescuento) product.descuento_producto = {};
    if (element.metaData) {
      if (element.cantidadCancelada)
        product.cantidad_cancelada = element.metaData.cantidadCancelada;
      product.id_orden_producto_menu = element.metaData.id_orden_producto_menu;
      product.codigo_orden_id = element.metaData.codigo_orden_id;
      product.estado_orden_id = element.metaData.estado_orden_id;
      product.precio_unitario = element.producto.producto.precio_producto;
      product.cuenta_id = element.metaData.cuenta_id;
      product.sub_total_a_pagar = total;
      if (
        element.metaData.cantidadCancelada &&
        element.metaData.motivoDeCancelacion
      ) {
        if (product.cantidad_cancelada != undefined)
          product.cantidad_cancelada += element.metaData.cantidadCancelada;
        else {
          product.cantidad_cancelada = element.metaData.cantidadCancelada;
        }
        const aux = element.metaData.cancelados
          ? element.metaData.cancelados
          : [];
        if (aux.length)
          product.motivo_cancelacion = [
            ...aux,
            {
              motivo_cancelacion_id: element.metaData.motivoDeCancelacion,
              cantidad: element.metaData.cantidadCancelada,
              observaciones: element.metaData.observacionBorrado || null,
            },
          ];
        else {
          product.motivo_cancelacion = [
            {
              motivo_cancelacion_id: element.metaData.motivoDeCancelacion,
              cantidad: element.metaData.cantidadCancelada,
              observaciones: element.metaData.observacionBorrado || null,
            },
          ];
        }
      }
    }

    if (descuento) {
      product.descuento_producto = {
        tipo_descuento_restaurante_id: descuento.id_tipo_descuento_restaurante,
        cantidad_descuento: (totalDescuento * descuento?.porcentaje) / 100,
        cantidad_producto: element.descuentoCant ?? element.cantidad,
      };

      switch (tipoDescuento) {
        case descuentoType.producto:
          product.descuento_producto.tipo_descuento_orden = '1';
          break;
        case descuentoType.global:
          product.descuento_producto.tipo_descuento_orden = '0';
          break;
        default:
          break;
      }
    }

    if (product.total_a_pagar < 0) product.total_a_pagar = 0;

    return product;
  });
}
