import {Button, TableCell, TableFooter, TableRow} from '@mui/material';
import {fCurrency} from '../../common/formatNumber';
import getNestedValue from '../../common/getNestedValue';
import roundMoney from '../../common/roundMoney';

type PropsTypes = {
  columns: {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: string;
  }[];
  sortedRows: any;
  onNewCompra?: () => void;
};

const FooterTable = ({columns, sortedRows, onNewCompra}: PropsTypes) => {
  if (onNewCompra) {
    return (
      <TableFooter>
        <TableRow hover>
          {columns.map((column, index) => (
            <TableCell
              key={index}
              align={column.label === 'Precio\u00A0(USD)' ? 'left' : 'left'}
              sx={{
                fontWeight: 'bold',
              }}>
              {column.label === 'Compras' && (
                <Button
                  color="secondary"
                  onClick={() => onNewCompra()}
                  variant="contained">
                  Agregar compra
                </Button>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableFooter>
    );
  }

  if (!columns.filter(column => column.hasOwnProperty('showTotal')).length)
    return <></>;

  return (
    <TableFooter>
      <TableRow hover>
        {columns.map((column, index) => (
          <TableCell
            key={index}
            align={column.label === 'Precio\u00A0(USD)' ? 'left' : 'left'}
            sx={{
              fontWeight: 'bold',
            }}>
            <Getdata column={column} sortedRows={sortedRows} />
          </TableCell>
        ))}
      </TableRow>
    </TableFooter>
  );
};

export default FooterTable;

type PropsTypeGetData = {
  column: {
    label: string;
    key: string[];
    showTotal?: boolean | undefined;
    type?: string;
  };
  sortedRows: any;
};

const Getdata = ({column, sortedRows}: PropsTypeGetData) => {
  if (!column.showTotal) return <></>;

  const formatData = (type: string | undefined) => {
    if (!type) return;
    switch (type) {
      case 'money': {
        return fCurrency(
          sortedRows.reduce((a: any, b: any) => {
            return a + getNestedValue(b, column.key);
          }, 0),
        );
      }

      case 'porcentaje': {
        return `${roundMoney(
          sortedRows.reduce((a: any, b: any) => {
            return a + getNestedValue(b, column.key);
          }, 0),
        )}%`;
      }

      case 'normal': {
        return sortedRows.reduce((a: any, b: any) => {
          return a + getNestedValue(b, column.key);
        }, 0);
      }

      default: {
        return sortedRows.reduce((a: any, b: any) => {
          return a + getNestedValue(b, column.key);
        }, 0);
      }
    }
  };

  return (
    <Button size="small" fullWidth variant="contained" color="secondary">
      {formatData(column.type)}
    </Button>
  );
};
