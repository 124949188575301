import {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  Typography,
  Avatar,
  IconButton,
} from '@mui/material';
// components
import MenuPopover from './MenuPopover';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {Logout} from '../../redux/slices/user-slice/user.thunks';
import PopoverMenuList from './popover-menulist';

export default function AccountPopover() {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(store => store.userSlice);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(Logout());
    history.replace('/login');
  };

  const imagenes = userState?.userDetail?.imagenes;

  const fotoPerfil = imagenes?.filter(
    value => value.tipo_imagen === 'foto_perfil',
  )[0];

  const history = useHistory();

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 35,
          height: 35,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}>
        <Avatar
          sx={{width: 35, height: 35}}
          src={fotoPerfil ? fotoPerfil.ruta_imagen : ''}
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 220}}>
        <Box sx={{my: 1.5, px: 2.5}} style={{textOverflow: 'ellipsis'}}>
          <Typography variant="subtitle1" noWrap>
            {`${userState.userDetail?.name.split(' ')[0]} ${
              userState.userDetail?.lastname.split(' ')[0]
            }`}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {`${userState.userDetail?.email}`}
          </Typography>
          <Typography
            variant="body2"
            sx={{color: 'text.secondary', fontSize: '8px'}}
            noWrap>
            BID:{' '}
            {process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA ?? 'Desarrollo...'}
          </Typography>
        </Box>

        <Divider sx={{my: 1}} />
        <PopoverMenuList handleClose={handleClose} />
        <Box sx={{p: 2, pt: 1.5}}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}>
            Cerrar sesión
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
