import {useAppDispatch, useAppSelector} from '../../redux/store';
import {useEffect} from 'react';
import {
  calculateTotal,
  calculateCarrito,
  calculateImpuesto,
  calculatePropinas,
  updateClienteArray,
} from '../../redux/slices/orden-slice';
import {cargarImpuestos} from '../../redux/slices/orden-slice/orden.thunks';

const useCalculateOrden = () => {
  const orden = useAppSelector(store => store.ordenSlice.orden);
  const cuenta = useAppSelector(store => store.ordenSlice.cuenta);
  const clienteSeleccionado = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(calculateTotal());
  }, [cuenta]);

  useEffect(() => {
    dispatch(calculatePropinas());
  }, [
    clienteSeleccionado?.propinas,
    clienteSeleccionado?.propinaEfectivo,
    cuenta.descuentos,
    cuenta.descuentoMontoFijo,
  ]);

  useEffect(() => {
    dispatch(calculateCarrito());
  }, [orden.carrito, clienteSeleccionado?.descuentos, orden.carritoAPI]);

  useEffect(() => {
    dispatch(calculateImpuesto());
  }, [
    clienteSeleccionado?.descuentos,
    clienteSeleccionado?.impuestos,
    cuenta.descuentos,
    cuenta.descuentoMontoFijo,
  ]);

  useEffect(() => {
    dispatch(updateClienteArray());
  }, [clienteSeleccionado]);

  useEffect(() => {
    dispatch(cargarImpuestos());
  }, [clienteSeleccionado?.descuentos, clienteSeleccionado?.id]);

  return null;
};

export default useCalculateOrden;
