import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../services';
import {comanda} from '../../../services/dto/comanda';
import {SeccionSucursal} from '../../../services/dto/restaurante';
import {thunkAPiConfig} from '../user-slice/user.types';
import dayjs from 'dayjs';

export const getComandas = createAsyncThunk<
  comanda[],
  {playNotify?: boolean; isHistorial?: boolean} | undefined,
  thunkAPiConfig
>('empleados/getComandas', async (data, thunkAPI) => {
  const {
    comandaSlice: {currentSelectedSeccion, lastComandaId, historyDate},
  } = thunkAPI.getState();

  // Sum one day to historyDate to get api
  const newDate = dayjs(historyDate).add(1, 'day').toDate();

  if (!currentSelectedSeccion)
    return thunkAPI.rejectWithValue('Error al obtener las comandas');
  const response = await api.getComandas(
    currentSelectedSeccion.sucursal_id,
    currentSelectedSeccion.id_seccion_sucursal,
    data?.isHistorial ? [1, 2] : [2],
    data?.isHistorial ? [1, 2] : [2],
    data?.isHistorial ? newDate : undefined,
  );
  if (!response)
    return thunkAPI.rejectWithValue('Error al obtener las comandas');
  const maxComanda = Math.max(
    ...response.data.map(comanda => comanda.id_codigo_orden),
  );
  if (data?.playNotify && maxComanda > lastComandaId) {
    const audio = new Audio(
      'https://res.cloudinary.com/ifudis/video/upload/v1646244741/assets/RZFWLXE-bell-hop-bell_uf0swj.mp3',
    );
    audio.play();
  }
  return response.data;
});

export const selectedCurrrentSeccion = createAsyncThunk<
  SeccionSucursal,
  number,
  thunkAPiConfig
>('empleados/selectedCurrrentSeccion', async (data, thunkAPI) => {
  const {
    restauranteUtilsSlice: {sucursalSecciones},
  } = thunkAPI.getState();

  const seccion = sucursalSecciones.find(
    seccion => seccion.id_seccion_sucursal === data,
  );

  if (!seccion) return thunkAPI.rejectWithValue('No se encontro la seccion');

  return seccion;
});

export const completarComanda = createAsyncThunk<null, number, thunkAPiConfig>(
  'empleados/completarComanda',
  async (data, thunkAPI) => {
    const {
      comandaSlice: {comadas},
    } = thunkAPI.getState();

    const comanda = comadas.find(element => element.id_codigo_orden === data);
    if (!comanda) return thunkAPI.rejectWithValue('No se encontro la comanda');
    const ordenes = comanda.ordenes.map(element => ({
      id_orden_producto_menu: element.id_orden_producto_menu,
      estado_orden_id: 1,
    }));
    const body = {
      ordenes,
      id_codigo_orden: comanda.id_codigo_orden,
    };
    const response = await api.postComandas(body);
    if (!response) return thunkAPI.rejectWithValue('Error al completar');
    thunkAPI.dispatch(getComandas());
    return null;
  },
);

export const completarItemComanda = createAsyncThunk<
  null,
  {
    id_codigo_orden: number;
    id_orden_producto_menu: number;
    estado_orden_id: number;
  },
  thunkAPiConfig
>('empleados/completarItemComanda', async (data, thunkAPI) => {
  const {
    comandaSlice: {comadas},
  } = thunkAPI.getState();

  const comanda = comadas.find(
    element => element.id_codigo_orden === data.id_codigo_orden,
  );
  if (!comanda) return thunkAPI.rejectWithValue('No se encontro la comanda');
  const ordenes = [
    {
      id_orden_producto_menu: data.id_orden_producto_menu,
      estado_orden_id: data.estado_orden_id,
    },
  ];
  const body = {
    id_orden_producto_menu: data.id_orden_producto_menu,
  };
  const response = await api.postNotification(body);
  if (!response) return thunkAPI.rejectWithValue('Error al completar');
  thunkAPI.dispatch(getComandas());
  return null;
});
