import {createSlice} from '@reduxjs/toolkit';

import {actions} from './menu.actions';
import {
  getMenuRestaurante,
  putPostMenu,
  putPostSubCategory,
  getCategorias,
  deleteSubCategoria,
  deleteMenuRestaurante,
  getSubCategorias,
} from './menu.thunks';
import {menuSliceType} from './menu.types';

//initialState

export const menuSliceInitState = {
  subcategorias: [],
  categorias: [],
  menuRestaurantes: [],
  selectedCategory: null,
  selectedSubcategory: null,
} as menuSliceType;

//slice

export const menuSlice = createSlice({
  name: 'menu-slice',
  initialState: menuSliceInitState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(putPostSubCategory.fulfilled, (state, action) => {
        state.subcategorias = action.payload;
      })
      .addCase(putPostMenu.fulfilled, (state, action) => {
        state.menuRestaurantes = action.payload;
      })
      .addCase(getMenuRestaurante.fulfilled, (state, action) => {
        state.menuRestaurantes = action.payload;
      })
      .addCase(getSubCategorias.fulfilled, (state, action) => {
        state.subcategorias = action.payload;
      })
      .addCase(getCategorias.fulfilled, (state, action) => {
        state.categorias = action.payload.data;
      })
      .addCase(deleteSubCategoria.fulfilled, (state, action) => {
        state.subcategorias = action.payload;
      })
      .addCase(deleteMenuRestaurante.fulfilled, (state, action) => {
        state.menuRestaurantes = action.payload;
      });
  },
});

export const {seleccionarCategoria, seleccionarSubcategoria, ResetMenuSlice} =
  menuSlice.actions;

export default menuSlice.reducer;
