import {useState} from 'react';
import {Icon} from '@iconify/react';
import {NavLink as RouterLink, useHistory} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import {alpha, useTheme, styled} from '@mui/material/styles';

import {getRoutesArray} from '../../common/isRouteAllowed';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
  List,
} from '@mui/material';

type PropsTypes = {
  item:
    | {
        title: string;
        path: string;
        icon: JSX.Element;
        toolTip: string;
        children: {
          title: string;
          path: string;
          toolTip: string;
          icon: JSX.Element;
        }[];
      }
    | {
        title: string;
        path: string;
        icon: JSX.Element;
        toolTip: string;
        children?: undefined;
      };
  active: (path: string) => boolean;
};

const NavItem = ({item, active}: PropsTypes) => {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const {title, path, icon, children, toolTip} = item;
  const [open, setOpen] = useState(isActiveRoot);
  const history = useHistory();

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
    '&:before': {display: 'block'},
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  const ListItemStyle = styled((props: any) => (
    <ListItemButton disableGutters {...props} />
  ))(({theme}) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    //textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  if (children) {
    return (
      <>
        <div onClick={handleOpen}>
          <ListItemStyle
            sx={{
              ...(isActiveRoot && activeRootStyle),
            }}>
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            <Box
              component={Icon}
              icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
              sx={{width: 16, height: 16, ml: 1}}
            />
          </ListItemStyle>
        </div>
        <Collapse in={open} timeout="auto" aria-label="xd" unmountOnExit>
          <List component="div" disablePadding>
            {children
              .filter(item => {
                if (path === '/administracion-ifudis') return true;
                const routesArray = getRoutesArray();
                return routesArray.includes(item.path);
              })
              .map((item, index) => {
                const {title, path, icon} = item;

                const isActiveSub = active(path);

                return (
                  <RouterLink
                    style={{width: '100%', height: '100%'}}
                    key={item.title + index.toString()}
                    to={path}>
                    <ListItemStyle
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                        ml: 2,
                      }}>
                      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  </RouterLink>
                );
              })}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <RouterLink style={{width: '100%', height: '100%'}} to={path}>
        <ListItemStyle
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
        </ListItemStyle>
      </RouterLink>
    );
  }
};

export default NavItem;
