import {Box, List, ListItemText} from '@mui/material';
import isOrdenIndirecta from '../../common/isOrdenIndirecta';
import {clienteStoreTypePrimary} from '../../redux/slices/orden-slice/orden.types';
import {useAppSelector} from '../../redux/store';
import SplitButton from '../pre-cuenta-wrapper/split-button';

export type propType = {
  client: clienteStoreTypePrimary | null;
  setClient: React.Dispatch<
    React.SetStateAction<clienteStoreTypePrimary | null>
  >;
};
const ListProductosClienteSeleccionado = ({client, setClient}: propType) => {
  const clientes = useAppSelector(store => store.ordenSlice.orden.clientes);
  const carritoAPI = useAppSelector(store => store.ordenSlice.orden.carritoAPI);

  return (
    <Box sx={{width: '50%'}}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <SplitButton
          onChange={id => {
            setClient(clientes.find(element => element.id === id) ?? null);
          }}
          onCreate={client => {
            setClient(client);
          }}
          showNewClientForm={isOrdenIndirecta()}
          selected={client?.id}
          options={clientes}
          hideEdit
        />
      </Box>
      <List
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: 2,
          px: 2,
          my: 2,
        }}>
        {carritoAPI
          .filter(carro => {
            if (!client) return false;
            return carro.clienteId === client.id;
          })
          .map(element => (
            <ListItemText
              primary={`${element.producto.producto.nombre_producto} x ${element.cantidad}`}
              key={element.id}
            />
          ))}
      </List>
    </Box>
  );
};

export default ListProductosClienteSeleccionado;
