import {Box, Button, Stack} from '@mui/material';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import useMediaQuery from '../../common/hooks/use-media-query';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  setCurrentStep,
  setMesaAyB,
  setUnirMesaMode,
} from '../../redux/slices/orden-slice';
import {useHistory} from 'react-router-dom';
import isRoleAllowed from '../../common/isRoleAllowed';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import {CreateClient} from '../../redux/slices/orden-slice/orden.thunks';
import {msgWarn} from '../../common/toast';
import {Icon} from '@iconify/react';
import {useEffect} from 'react';

function MesasMenu() {
  const sm = useMediaQuery('(max-width:900px)');
  const cajaEmpleado = useAppSelector(
    store => store.restauranteSlice.cajaEmpleado,
  );

  const history = useHistory();
  const dispatch = useAppDispatch();

  const mesas = useAppSelector(store => store.ordenSlice.uiData.mesas);
  const loading = useAppSelector(store => store.ordenSlice.uiData.loadingMesas);

  useEffect(() => {
    if (!mesas.length && !loading) {
      handleonClickFacturaRapida();
    }
  }, [mesas, loading]);

  const handleonClickFacturaRapida = () => {
    dispatch(CreateClient({shouldSelect: true, name: 'SIN NOMBRE'}));
    dispatch(setCurrentStep(2));
  };

  const mesaMode = useAppSelector(store => store.ordenSlice.uiData.mesaMode);

  const handleClickUnirMesas = () => {
    if (mesaMode === 'unirMesa') {
      dispatch(setUnirMesaMode('normal'));
    } else {
      msgWarn('Seleccione las mesas de los clientes que desea mover.');
      dispatch(setUnirMesaMode('unirMesa'));
    }
    dispatch(setMesaAyB({mesaID: null, which: 'A'}));
    dispatch(setMesaAyB({mesaID: null, which: 'B'}));
  };

  const handleClickMoverProductos = () => {
    if (mesaMode === 'moverProducto') {
      dispatch(setUnirMesaMode('normal'));
    } else {
      msgWarn('Seleccione las mesas de los productos que desea mover.');
      dispatch(setUnirMesaMode('moverProducto'));
    }
    dispatch(setMesaAyB({mesaID: null, which: 'A'}));
    dispatch(setMesaAyB({mesaID: null, which: 'B'}));
  };

  const handleClickCambiarMesero = () => {
    if (mesaMode === 'cambiarMesero') {
      dispatch(setUnirMesaMode('normal'));
    } else {
      msgWarn('Seleccione la mesa a modificar.');
      dispatch(setUnirMesaMode('cambiarMesero'));
    }
    dispatch(setMesaAyB({mesaID: null, which: 'A'}));
    dispatch(setMesaAyB({mesaID: null, which: 'B'}));
  };

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        color: 'white',
        px: {
          xs: 2,
          lg: 5,
        },
      }}>
      <Button
        variant="contained"
        disabled={!cajaEmpleado || mesaMode != 'normal'}
        onClick={handleonClickFacturaRapida}
        sx={{
          mr: 1,
          fontSize: '10px',
        }}>
        {sm ? <QuickreplyIcon /> : 'FACTURACIÓN RÁPIDA'}
      </Button>
      <Stack direction="row" spacing={2}>
        <Button
          disabled={!isRoleAllowed([8, 2, 3])}
          onClick={handleClickUnirMesas}
          variant="outlined"
          sx={{
            color: 'white',
            borderColor: 'white',
            position: 'relative',
            fontSize: '10px',
          }}>
          {sm ? <ChairAltIcon /> : 'MOVER CLIENTES'}
        </Button>
        <Button
          disabled={!isRoleAllowed([8, 2, 3])}
          onClick={handleClickMoverProductos}
          variant="outlined"
          sx={{
            color: 'white',
            borderColor: 'white',
            position: 'relative',
            fontSize: '10px',
          }}>
          {sm ? <TakeoutDiningIcon /> : 'MOVER PRODUCTOS'}
        </Button>

        <Button
          disabled={!isRoleAllowed([8, 2])}
          onClick={handleClickCambiarMesero}
          variant="outlined"
          sx={{
            color: 'white',
            borderColor: 'white',
            position: 'relative',
            fontSize: '10px',
          }}>
          {sm ? (
            <Icon icon="ic:baseline-social-distance" width={23} height={23} />
          ) : (
            'CAMBIAR HOST'
          )}
        </Button>

        {/* {isRoleAllowed([4, 2]) && (
          <Button
            onClick={handleOnClickDiarioVentas}
            variant="outlined"
            disabled={unirMesaMode}
            sx={{
              color: 'white',
              borderColor: 'white',
              position: 'relative',
              fontSize: '10px',
            }}>
            {sm ? <ReceiptIcon /> : 'DIARIO DE VENTAS'}
          </Button>
        )}
        <Button
          disabled={!cajaEmpleado || unirMesaMode}
          variant="outlined"
          onClick={() => handleOnClickCierre('x')}
          sx={{
            color: 'white',
            borderColor: 'white',
            position: 'relative',
            fontSize: '12px',
          }}>
          <Typography
            sx={{
              position: 'absolute',
              top: -8,
              left: '50%',
              color: 'white',
              fontSize: '10px',
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
              backgroundColor: 'black',
            }}>
            CIERRE
          </Typography>
          X
        </Button>
        <Button
          disabled={!cajaEmpleado || unirMesaMode}
          onClick={() => handleOnClickCierre('z')}
          variant="outlined"
          sx={{
            color: 'white',
            borderColor: 'white',
            position: 'relative',
            fontSize: '12px',
          }}>
          <Typography
            sx={{
              position: 'absolute',
              top: -8,
              left: '50%',
              color: 'white',
              fontSize: '10px',
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
              backgroundColor: 'black',
            }}>
            CIERRE
          </Typography>
          Z
        </Button> */}
      </Stack>
    </Box>
  );
}

export default MesasMenu;
