import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ReactNode, useState} from 'react';

type PropsTypes = {
  title: string;
  children: ReactNode;
  width?: string | number;
};

const AccordionTab = ({title, width = '100%', children}: PropsTypes) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = () => setExpanded(!expanded);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        backgroundColor: '#3C3C3C',
        mt: 1,
        borderRadius: '11px',
        width: width,
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color: 'whitesmoke'}} />}
        aria-controls="panel-content">
        <Typography fontWeight="bold" color="whitesmoke">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: '#fff',
          color: '#3C3C3C',
          display: 'block',
          margin: 0,
          padding: 0,
          width: '100%',
        }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionTab;
